import React, { Component } from 'react'
import CKEditors from "react-ckeditor-component";
import { withRouter } from "react-router";
import API_URL from '../../ApiUrl';
import { connect } from 'react-redux'
import axios from 'axios';
import { toast } from 'react-toastify'
import seven from '../../assets/images/user/7.jpg';
import 'react-toastify/dist/ReactToastify.css';

class FormPoint extends Component {

    constructor(props){
        super(props)
        this.state = {
            id:'',
            title: '',
            thumbnail: '',
            image:'',
            nominal: '',
            description: '',
            periode:'',
            status:1,
            syarat_ketentuan:'',
            link_image:'',
            link_thumbnail:'',
            modalPoint:false,
            btnDisabled:false,
            action:'',
            btnTextSave:'Save',
        }
    }

    componentDidMount(){
        if(this.props.location.state === undefined)
        this.setState({ action: 'tambah' })
    else {
        let data = this.props.location.state
        this.setState({
            action: 'edit',
            id: data.id,
            title: data.title,
            status: data.status,
            link_image: data.image,
            link_thumbnail: data.thumbnail,
            nominal: data.nominal,
            description: data.description,
            periode: data.periode,
            syarat_ketentuan: data.syarat_ketentuan,
         });
        }
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            description: newContent
        })
    }

    onChangeSyarat(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            syarat_ketentuan: newContent
        })
    }

    handleImages = (e) => {
        this.setState({
            image:e.target.files[0]
        })
    }

    handleThumbnail = (e) => {
        this.setState({
            thumbnail:e.target.files[0]
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        let formData = new FormData()
        formData.append('title', this.state.title)
        formData.append('image', this.state.image)
        formData.append('nominal', this.state.nominal)
        formData.append('description', this.state.description)
        formData.append('status', this.state.status)
        formData.append('periode', this.state.periode)
        formData.append('thumbnail', this.state.thumbnail)
        formData.append('syarat_ketentuan', this.state.syarat_ketentuan)

        this.setState({
            btnDisabled: true,
            btnTextSave: 'Saving...'
        })

        let url = API_URL.superStudent+'/point';
        if (this.state.action == 'edit') {
            formData.append('_method', 'PATCH');
            url = API_URL.superStudent+'/point/'+this.state.id
        }

        axios.post(url, formData, {
            headers:{
                Authorization: `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data' 
            }
        })
        .then(response => {
            toast.success("data updated successfully...");
            this.props.history.push('/pages/point')
        })
        .catch(error => {
            this.setState({
                btnDisabled: false,
                btnTextSave: 'Savie'
            })
            toast.error("something wrong");
        })
    }

    render(){
        return(
            <>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <form onSubmit={this.handleSubmit}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <div className="form-group">
                                                    <label className='f-w-600'>Nama Point</label>
                                                    <input className="form-control" onChange={this.handleInput} value={this.state.title} name="title" type="text" placeholder="example" />
                                                </div>
                                            </div>
                                            <div className='col-md-3'>
                                                <div className="form-group">
                                                    <label className='f-w-600'>Nominal</label>
                                                    <input className="form-control" onChange={this.handleInput} value={this.state.nominal} name="nominal" type="text" placeholder="20000" />
                                                </div>
                                            </div>
                                            <div className='col-md-3'>
                                                <div className="form-group">
                                                    <label className='f-w-600'>Periode</label>
                                                    <input className="form-control" onChange={this.handleInput} value={this.state.periode} name="periode" type="date" />
                                                </div>
                                            </div>
                                            <div className='col-md-2'>
                                                <div className="form-group">
                                                    <label className='f-w-600'>Status</label>
                                                    <select className='form-control' name='status' value={this.state.status} onChange={this.handleInput}>
                                                        <option value={1}>Active</option>
                                                        <option value={0}>Inactive</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group mb-0">
                                                    <label className='f-w-600'>Description</label>
                                                    <CKEditors
                                                        activeclassName="p10"
                                                        content={this.state.description}
                                                        events={{
                                                                "change": this.onChange.bind(this),
                                                        }}
                                                        config={{
                                                        toolbar:[
                                                                    ["Bold","Italic",'Strike','RemoveFormat'],
                                                                    ['NumberedList','BulletedList','Indent','Outdent'],
                                                                    ['Link','Unlink'],
                                                                    ['Styles','Format','Table','Blockquote','Maximize'],
                                                                    ["Cut","Copy","Paste","Pasteasplaintext","Undo","Redo"]
                                                                ]
                                                            }}     
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group mb-0">
                                                    <label className='f-w-600'>Syarat dan Ketentuan</label>
                                                    <CKEditors
                                                        activeclassName="p10"
                                                        content={this.state.syarat_ketentuan}
                                                        events={{
                                                                "change": this.onChangeSyarat.bind(this),
                                                        }}
                                                        config={{
                                                        toolbar:[
                                                                    ["Bold","Italic",'Strike','RemoveFormat'],
                                                                    ['NumberedList','BulletedList','Indent','Outdent'],
                                                                    ['Link','Unlink'],
                                                                    ['Styles','Format','Table','Blockquote','Maximize'],
                                                                    ["Cut","Copy","Paste","Pasteasplaintext","Undo","Redo"]
                                                                ]
                                                            }}     
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-6 mt-3'>
                                                <div className="form-group">
                                                    <label className='f-w-600'>Banner</label>
                                                    <input className="form-control" onChange={this.handleImages} type="file" />
                                                    {
                                                        this.state.action == 'edit' ? 
                                                        <img src={this.state.link_image !== null ? this.state.link_image : seven} className='img-thumbnail'  alt="logo" />
                                                        :''
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-md-6 mt-3'>
                                                <div className="form-group">
                                                    <label className='f-w-600'>Thumbnail</label>
                                                    <input className="form-control" onChange={this.handleThumbnail} type="file" />
                                                    {
                                                        this.state.action == 'edit' ?
                                                        <img src={this.state.link_thumbnail !== null ? this.state.link_thumbnail : seven} className='img-thumbnail'  alt="logo" />
                                                        : ''
                                                    }
                                                  
                                                </div>
                                            </div>
                                        </div>
                                        <button type='submit' className='btn btn-success' disabled={this.state.btnDisabled}>{this.state.btnTextSave}</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null) (withRouter(FormPoint))