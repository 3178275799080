import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux';
import DatatableRoles from '../common/datatable/datatableRoles';
import { withRouter } from "react-router";
import API_URL from '../../ApiUrl';
import axios from 'axios';

class DataTableRolesList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listRoles: [],
            listModules: [],
        }
    }

    componentDidMount(){
        this.getDataRoles();
        this.getDataModules();
    }

    getDataRoles(){
        axios.get(API_URL.superStudent+'/roles',{
            headers: {  Authorization: `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            this.setState({
                listRoles:response.data.data
            })
        })
        .catch(error => console.log(error))
    }

    getDataModules(){
        axios.get(API_URL.superStudent+'/modules',{
            headers: {  Authorization: `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            this.setState({
                listModules:response.data.data
            })
        })
        .catch(error => console.log(error))
    }

    render() {
        const {listRoles, listModules} = this.state
        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body datatable-react">
                                    <div className="table-responsive" style={{overflow: 'auto', background: '#fff', height: '500px'}}>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th width={100} style={{position: 'sticky', left: '0', top: '0', background: '#fff', zIndex: '20'}}></th>
                                                {
                                                    listRoles.map((l)=>{
                                                    return (
                                                        <th style={{position: 'sticky', top: '0', background: '#fff',verticalAlign: 'middle'}} width={100}>{l.roles}</th> 
                                                    )
                                                    })
                                                }
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                listModules.map((m) => {
                                                return (
                                                <tr>
                                                    <td style={{position: 'sticky', left: '0', background: '#fff'}}>{m.title}</td>
                                                {
                                                    listRoles.map((l, i)=>{
                                                        let kk = l.modules.filter(function(mod) {
                                                            return mod["code_modules"] === m.code_modules;
                                                       });
                                                    return (
                                                    <td width={100} className='text-center'>
                                                {
                                                    kk.length > 0 ? <i className='fa fa-check text-success'></i> : <i className='fa fa-times text-danger'></i>
                                                }
                                                    </td> 
                                                    )
                                                    })
                                                }
                                                </tr>
                                                )
                                                })
                                            }
                                            </tbody>
                                        </table> 
                                    </div>                             
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
  })

  
export default connect(reduxState, null) (withRouter(DataTableRolesList));