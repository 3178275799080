import React, { Fragment } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import ListUniversity from './list-university'
 
const UniversityData = () => {
    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    <Breadcrumb title="University Data" parent="SchoolRep" />
                    <div className="row">
                        <div className="col-md-12">
                            <ListUniversity/>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default UniversityData
;