import {
    Home,
    UserPlus,
    Users,
    Settings,
    Calendar, 
    Database,
} from 'react-feather';
// import data from '../data/icons/icoData';
// import IconMarkUp from './icon-markup';

export const MENUITEMS = [
    {
        title: 'Home', icon: Home, type: 'link', badgeType: 'primary', path: '/dashboard/default'
    },
    {
        title: 'Student', icon: Users, type: 'sub', active: false, children: [
            { path: '/pages/students', type: 'link', title: 'Dashboard Students' },
            { path: '/pages/masterstudents', type: 'link', title: 'Master Students' },
            // { path: '/pages/unlockUser', type: 'link', title: 'Unlock User' },
            // { path: '/pages/forgetPwd', type: 'link', title: 'Forget Password ' },
            // { path: '/pages/resetPwd', type: 'link', title: 'Reset Password ' }
        ]
    },
    {
        title: 'Membership', icon: UserPlus, type: 'link', badgeType: 'primary', path: '/pages/membership'
    },
    {
        title: 'Events', type: 'sub', icon: Calendar, active: false, children: [
            { path: '/pages/comingsoon', title: 'Virtual Expo', type: 'link' },
            { path: '/pages/comingsoon', title: 'Info Session', type: 'link'}
            
        ]
    },
    {
        title: 'Admin Setting', type: 'sub', icon: Settings, active: false, children: [
            { path: '/pages/users/users', title: 'Master User', type: 'link' },
        ]
    },
    {
        title: 'Master Data', type: 'sub', icon: Database, active: false, children: [
            { path: '/pages/office/office', title: 'Office', type: 'link' },
            { path: '/pages/consultant/consultant', title: 'Consultant', type: 'link' },
            { path: '/pages/schoolrep/schoolrep', title: 'School Rep.', type: 'link' },
        ]
    }

]
