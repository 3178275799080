import React, { Component, Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Home, Settings, User, UserPlus } from 'react-feather';
// import { Tabs as ReactTabs, TabList as ReactTablist } from 'react-tabs';
import { connect } from 'react-redux';
import { withRouter } from "react-router";

import { Switch, Route, Redirect} from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';

import "./styles/react-router-tabs.css";


class GlobalAmbassador extends Component {   
// const GlobalAmbassador = (dataUser) => {
    constructor(props) {
        super(props);

    }
    render(){
        const roles = this.props.dataUser.code_roles
        return (
            <Fragment>
                <Breadcrumb title="Global Ambassador" parent="Global Ambassador" />
                <div className="container-fluid">
                <div>
                    <button onClick={() => this.addRow()} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} >
                    </button>
                    </div>
                    <div className="row theme-tab">
                        <div className="col-sm-12">
                            {roles === 'RL008' ?
                                this.props.history.push({
                                pathname: `${process.env.PUBLIC_URL}/pages/ambassadordetail/`+this.props.dataUser.code_ambassador+'/'+this.props.dataUser.id_category
                            })
                            :
                                <RoutedTabs
                                    tabClassName="tab-link"
                                    activeTabClassName="active"
                                >
                                    <div className="tabs tab-title">
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/ambassador/data`}>
                                            {/* <ReactTab> */}
                                                <UserPlus /> Ambassador Data
                                            {/* </ReactTab> */}
                                        </NavTab>
                                    </div>
                                </RoutedTabs>
                            }

                            {roles == 'RL008' ?
                                <Switch>
                                    <Route
                                        exact
                                        path={`${process.env.PUBLIC_URL}/pages/ambassador`}
                                        render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/ambassador/data`} />}
                                    />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/ambassador/data`} />
                                </Switch>
                            :
                                <Switch>
                                    <Route
                                        exact
                                        path={`${process.env.PUBLIC_URL}/pages/ambassador`}
                                        render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/ambassador/data`} />}
                                    />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/ambassador/data`} />
                                </Switch>
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// export default GlobalAmbassador;
const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
  })
  
export default connect(reduxState, null)(withRouter(GlobalAmbassador));