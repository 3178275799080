import React, { Fragment } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Home, User, ShoppingCart, List } from 'react-feather';
import { connect } from 'react-redux';

import { Switch, Route, Redirect } from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';

const HomeEvent = () => {
    return (
        <Fragment>
            <Breadcrumb title="" />
            <div className="container-fluid">
                <div className="row theme-tab">
                    <div className="col-sm-12">
                        <RoutedTabs>
                            <div className="tabs tab-title">
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/university/event/home`}>
                                    <Home /> Event
                                </NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/university/event/cart`}>
                                    <ShoppingCart /> Cart
                                </NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/university/event/history`}>
                                    <List /> History
                                </NavTab>
                            </div>
                        </RoutedTabs>
                        <Switch>
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/pages/university/event`}
                                render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/university/event/home`} />}
                            />
                            <Route path={`${process.env.PUBLIC_URL}/pages/university/event/cart`} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/university/event/history`} />
                        </Switch>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

// export default Membership;
const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
})

export default connect(reduxState, null)(HomeEvent);