import React, { Component } from 'react';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import Moment from 'moment'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

class DataBudgeting extends Component{

    constructor(props){
        super(props)
        this.state = {
            indiktorBudget:[],
            dataBudget:[],
            dataOffice:[],
            dataDivisi:[],
            handleDivisi:[],
            budget_id:'',
            indikator_id:'',
            filter_indikator_id:'',
            office_id:'',
            periode:'',
            budget_periode:'3',
            amount:'',
            keterangan:'',
            modalBudget:false,
            modalDelete:false,
            modalDetailDelete:false,
            detailBudget:false,
            filterOffice:''
        }
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    resetState = () => {
        this.setState({
            office_id: '',
            periode:'',
            indikator_id:'',
            amount:''
        })
    }
    
    handleForm = () => {
        this.loadIndikator()
        this.resetState()
        this.setState({ modalBudget:true })
    }

    setHandleDivisi = (e) => {
        this.setState({
            handleDivisi:e
        });
    }

    componentDidMount(){
        this.loadOffice()
        this.loadBudgetData()
        this.loadIndikator()
    }

    loadOffice = () => {
        axios.get(API_URL.superStudent+'/master/office',{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({ dataOffice: response.data.data}))
        .catch(error => console.log(error))
    }

    loadDivisi = () => {
        axios.get(API_URL.superStudent+'/consultant/list-jobdesk',{
            headers:{  Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({dataDivisi:response.data.data}))
        .catch(error => console.log(error))
    }

    loadIndikator = () => {
        axios.get(API_URL.superStudent+'/accounting/indikator-budget',{
            headers:{  Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({indiktorBudget:response.data.data}))
        .catch(error => console.log(error))
    }

    loadBudgetData = () => {
        axios.get(API_URL.superStudent+'/accounting/budgets?office_id='+this.state.office_id+'&periode='+this.state.periode+'&budget_periode='+this.state.budget_periode+'&indikator='+this.state.filter_indikator_id,{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}`}
        })
        .then(response => {
            this.setState({ dataBudget: response.data.data})
        })
        .catch(error => console.log(error))
    }

    filterBudget = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.loadBudgetData())
    }

    closeModal(){
        this.setState({
            modalBudget:false,
            detailBudget:false,
            modalDelete:false,
            modalDetailDelete:false,
        })
    }

    handleBudget = () => {
        let formData = new FormData()
        formData.append('amount', this.state.amount)
        formData.append('indikator_id', this.state.indikator_id)
        formData.append('office_id', this.state.office_id)
        formData.append('periode', this.state.budget_periode)
        axios.post(API_URL.superStudent+'/accounting/budgets', formData,{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}`}
        })
        .then(() => {
            this.closeModal()
            this.resetState()
            this.loadBudgetData()
            toast.success('Successfully added data')
        })
        .catch(() => {
            this.closeModal()
            toast.error('something went error')
        })
    }

    handleDelete = () => {
        axios.delete(API_URL.superStudent+'/accounting/budgets/'+this.state.budget_id,{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}`}
        })
        .then(() => {
            this.closeModal()
            this.loadBudgetData()
            toast.success('Successfully deleted data');
        })
    }

    handleDetailDelete = () => {
        axios.delete(API_URL.superStudent+'/accounting/delete-detail/'+this.state.budget_id,{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}`}
        })
        .then(() => {
            this.closeModal()
            this.loadBudgetData()
            toast.success('Successfully deleted data');
        })
    }

    handleDetail = () => {
        let formData = new FormData()
        formData.append('budget_id', this.state.budget_id)
        formData.append('nominal', this.state.amount)
        formData.append('keterangan', this.state.keterangan)
        formData.append('periode', this.state.periode)
        formData.append('divisi', JSON.stringify(this.state.handleDivisi))
        axios.post(API_URL.superStudent+'/accounting/post-detail', formData,{
            headers:{  Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => {
            console.log(response)
            this.closeModal()
            toast.success('Data Updated Successfully...')
            this.loadBudgetData()
        })
        .catch(error => console.log(error))

    }

    formatRupiah = (nominal) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currencyDisplay:'narrowSymbol',
            currency: 'IDR',
        }).format(nominal);
    }

    renderBudgetModal(){
        return(
          <Modal className="modal-dialog" isOpen={this.state.modalBudget} toggle={() => this.closeModal()} size="md">
            <ModalHeader toggle={() => this.closeModal()}>Create Budget</ModalHeader>
            <ModalBody style={{
            maxHeight: 'calc(100vh - 210px)',
            overflowY: 'auto'
            }}>
              <form className="needs-validation tooltip-validation" noValidate="" >
                  <div className='form-group'>
                        <label className='control-label'>Office</label>
                        <select className='form-control' name='office_id' onChange={this.handleInput} value={this.state.office_id}>
                                <option>--Select Office--</option>
                                {
                                    this.state.dataOffice.length > 0 ?
                                    this.state.dataOffice.map(item => (
                                        <option key={item.id_kantor} value={item.id_kantor}>{item.nama_kantor}</option>
                                    ))
                                    :
                                    ''
                                }
                        </select>
                  </div>
                  <div className='form-group'>
                        <label className='control-label'>Indikator Budget</label>
                        <select className='form-control' name='indikator_id' onChange={this.handleInput} value={this.state.indikator_id}>
                                <option>--Select Indikator--</option>
                                {
                                    this.state.indiktorBudget.length > 0 ?
                                    this.state.indiktorBudget.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))
                                    :
                                    ''
                                }
                        </select>
                  </div>
                  <div className='form-group'>
                        <label className='control-label'>Jumlah Budget</label>
                        <input className='form-control' onChange={this.handleInput} value={this.state.amount} name='amount' />
                  </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={(e) => this.handleBudget()}>Save</Button>
              <Button color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )
    }

    renderDeleteModal(){
        return(
          <Modal className="modal-dialog" isOpen={this.state.modalDelete} toggle={() => this.closeModal()} size="md">
            <ModalHeader toggle={() => this.closeModal()}>Create Budget</ModalHeader>
            <ModalBody style={{
            maxHeight: 'calc(100vh - 210px)',
            overflowY: 'auto'
            }}>
                <p>Apakah anda yakin ingin menghapus data ini ?</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={(e) => this.handleDelete()}>Delete</Button>
              <Button color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )
    }

    renderDeleteDetailModal(){
        return(
          <Modal className="modal-dialog" isOpen={this.state.modalDetailDelete} toggle={() => this.closeModal()} size="md">
            <ModalHeader toggle={() => this.closeModal()}>Create Budget</ModalHeader>
            <ModalBody style={{
            maxHeight: 'calc(100vh - 210px)',
            overflowY: 'auto'
            }}>
                <p>Apakah anda yakin ingin menghapus data ini ?</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={(e) => this.handleDetailDelete()}>Delete</Button>
              <Button color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )
    }

    renderDetailBudgetModal(){
        return(
          <Modal className="modal-dialog" isOpen={this.state.detailBudget} toggle={() => this.closeModal()} size="md">
            <ModalHeader toggle={() => this.closeModal()}>Create Budget</ModalHeader>
            <ModalBody style={{
            maxHeight: 'calc(100vh - 210px)',
            overflowY: 'auto'
            }}>
              <form className="needs-validation tooltip-validation" noValidate="" >
                    <div className='form-group'>
                        <label className='control-label'>Divisi</label>
                        <Typeahead
                            id="basic-typeahead-multiple"
                            labelKey="jobdesk"
                            multiple
                            selected={this.state.handleDivisi}
                            name="code_jobdesk"  
                            options={this.state.dataDivisi}
                            onChange={this.setHandleDivisi}
                            placeholder="can choose several divisions."
                        />
                  </div>
                  <div className='form-group'>
                        <label className='control-label'>Keterangn</label>
                        <textarea type='text' className='form-control' onChange={this.handleInput} name='keterangan' value={this.state.keterangan}/>
                  </div>
                  <div className='form-group'>
                        <label className='control-label'>Jumlah Budget</label>
                        <input className='form-control' type='text' onChange={this.handleInput} value={this.state.amount} name='amount' />
                  </div>
                  <div className='form-group'>
                        <label className='control-label'>Periode</label>
                        <input className='form-control' type='month' onChange={this.handleInput} value={this.state.periode} name='periode' />
                  </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={(e) => this.handleDetail()}>Save</Button>
              <Button color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )
    }

    createBudget = (id) => {
        this.loadDivisi()
        this.setState({
            detailBudget:true,
            budget_id: id,
            amount:'',
            keterangan:'',
            periode:'',
            handleDivisi:[]
        })
    }

    removeBudget = (id) => {
        this.setState({
            modalDelete:true,
            budget_id: id
        })
    }

    removeDetailBudget = (id) => {
        this.setState({
            modalDetailDelete:true,
            budget_id: id
        })
    }

    render(){

        const column_budget = [
            {
                Header: 'Office',
                accessor: 'office_id',
                style: {textAlign: 'center'},
                Cell:(row) => (
                    <>{row.original.office.nama_kantor}</>
                )
            },
            {
                Header: 'Indikator',
                accessor: 'indikator_id',
                style: {textAlign: 'center'},
                Cell:(row) => (
                    <>{row.original.indikator.name}</>
                ),
            },
            {
                Header: 'Budget',
                accessor: 'budget',
                style: {textAlign: 'center'},
                Cell:(row) => (
                    this.formatRupiah(row.original.budget)
                )
            },
            {
                Header: 'Terpakai',
                accessor: 'budget',
                style: {textAlign: 'center'},
                Cell:(row) => (
                    this.formatRupiah(row.original.terpakai)
                )
            },
            {
                Header: '%',
                accessor: 'persion',
                style: {textAlign: 'center'},
                Cell:(row) => (
                    (100 - (row.original.sisa/row.original.budget * 100)).toFixed(3) + '%'
                ),
                width:70,
            },
            {
                Header: 'Sisa',
                accessor: 'sisa',
                style: {textAlign: 'center'},
                Cell:(row) => (
                    this.formatRupiah(row.original.sisa)
                )
            },
            {
                Header: <b>#</b>,
                id: 'delete',
                accessor: str => "delete",
                width:90,
                Cell: (row) => (
                    <>
                        <button  onClick={() => this.createBudget(row.original.id)} className="btn btn-success btn-xs" type="button" ><i className="fa fa-plus"></i></button> &nbsp;
                        <button  onClick={() => this.removeBudget(row.original.id)} className="btn btn-danger btn-xs" type="button" ><i className="fa fa-remove"></i></button>
                    </>
                ),
                style: { textAlign: 'center' }
            }
        ]

        const detail_budget = [
            {
                Header: 'Description',
                accessor: 'keterangan',
                style: {textAlign: 'center'},
            },
            {
                Header: 'Nominal',
                accessor: 'nominal',
                width:130,
                style: {textAlign: 'center'},
                Cell:(row) => (
                    this.formatRupiah(row.original.nominal)
                )
            },
            {
                Header: 'Periode',
                accessor: 'periode',
                width:100,
                style: {textAlign: 'center'},
                Cell : (row) => (
                    <>{Moment(row.original.periode).format('MMM YYYY')}</>
                )   
            },
            {
                Header: 'Divisi',
                accessor: 'divisi',
                style: {textAlign: 'center'},
                Cell: (row) => (
                    row.original.divisi.length > 0 ? row.original.divisi.map(item => (
                        <span key={item.code_jobdesk} className='badge badge-dark'>{item.jobdesk}</span>
                                                    )) : 'Kosong'
                )
            },
            {
                Header: <b>#</b>,
                id: 'delete',
                accessor: str => "delete",
                width:90,
                Cell: (row) => (
                    <>
                        <button  onClick={() => this.removeDetailBudget(row.original.id)} className="btn btn-danger btn-xs" type="button" ><i className="fa fa-remove"></i></button>
                    </>
                ),
                style: { textAlign: 'center' }
            }
        ]

        return(
            <>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div className='row m-2'>
                                    <div className='col-sm-3'>
                                        <button className='btn btn-success' onClick={this.handleForm}>Tambah</button>
                                    </div>
                                </div>
                                <div className='row m-2'>
                                    <div className='col-sm-3'>
                                        <select onChange={this.filterBudget} className='form-control' name='office_id' value={this.state.office_id}>
                                                <option value=''>All Office</option>
                                            { this.state.dataOffice.length > 0 ?
                                              this.state.dataOffice.map((item, index) => (
                                                <option key={index} value={item.id_kantor}>{item.nama_kantor}</option>
                                              ))
                                              : '' 
                                            }
                                        </select>
                                    </div>
                                    <div className='col-sm-3'>
                                        <select className='form-control' onChange={this.filterBudget} name='filter_indikator_id' value={this.state.filter_indikator_id}>
                                        <option key={0} value=''>--All Indikator--</option>
                                            {
                                                this.state.indiktorBudget.length > 0 ?
                                                this.state.indiktorBudget.map(item => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))
                                                :
                                                ''
                                            }
                                        </select>
                                    </div>
                                    <div className='col-sm-3'>
                                        <div className='form-group'>
                                                <input className='form-control' type='month' onChange={this.filterBudget} value={this.state.periode} name='periode' />
                                        </div>
                                    </div>
                                    <div className='col-sm-3'>
                                        <select onChange={this.filterBudget} className='form-control' name='budget_periode' value={this.state.budget_periode}>
                                            <option value='1'> Juli 2022 - Juni 2023</option>
                                            <option value='2'> Juli 2023 - Juni 2024</option>
                                            <option value='3'> Juli 2024 - Juni 2025</option>
                                        </select>
                                    </div>
                                </div>
                                <ReactTable
                                    data={this.state.dataBudget[0]}
                                    columns={column_budget}
                                    defaultPageSize={10}
                                    showPagination={true}
                                    className="-striped -highlight"
                                    SubComponent={row =>{
                                        return (
                                            <div style={{ padding: "20px"}}>
                                                <ReactTable
                                                    data={row.original.detail}
                                                    columns={detail_budget}
                                                    defaultPageSize={5}
                                                    className="-striped -highlight"
                                                    showPaginationBottom={true}
                                                />
                                            </div>
                                        )
                                    }}
                                />
                                {
                                this.state.dataBudget.length > 0 ? 
                                <table className='table'>
                                    <tr>
                                        <th colSpan={2}>#</th>
                                        <th>Total Budget : { this.formatRupiah(this.state.dataBudget[1].budget) }</th>
                                        <th>Total Terpakai : { this.formatRupiah(this.state.dataBudget[1].terpakai) }</th>
                                        <th>Total Sisa : { this.formatRupiah(this.state.dataBudget[1].sisa) }</th>
                                    </tr>
                                </table>
                                :
                                ''
                                }

                            </div>
                        </div>
                    </div>
                </div>
                {this.renderBudgetModal()}
                {this.renderDetailBudgetModal()}
                {this.renderDeleteModal()}
                {this.renderDeleteDetailModal()}
            </>
        )
    }
}

const reduxState = (state) => ({
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
  })

export default connect(reduxState)(DataBudgeting);