import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import moment from 'moment';
import API_URL from '../../../../ApiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress, CardHeader } from 'reactstrap';

class ProgressLog extends Component {    
    constructor(props) {
        super(props);
        this.state = {
            modal:false,
            id_delete:'',
            dataProgress: [],
            codeStudents:"",
            codeProgress:"",
            errors: {},
            display:this.props.dataUser.code_roles === 'RL012' ? '' : this.props.dataUser.code_roles === 'RL001' ? '': this.props.dataUser.code_roles === 'RL003' ? '' :this.props.dataUser.code_roles === 'RL005' ? '' :this.props.dataUser.code_roles === 'RL023' ? '' : 'none',
            btnDisabled: false,
            model: {
                code_students:"",
                code: "",
                code_users: "",
                code_lpt: ""
            },
        }
    }

    handleValidation(){
        let formIsValid = true;

        if(!this.state.errors.inputAddComment){
            formIsValid = false;
        }

        return formIsValid;
    }

    onChangeAddComment = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);  
            model.code = newData; 
            return { model };                   
        }, () => {
            this.validateAddComment();
        })
    }

    validateAddComment(){
        const data = this.state.model;
        if(data.code.length < 3){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.code = 'Comment is required'; 
                errors.inputAddComment = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.code = '';    
                errors.inputAddComment = true;                             
                return { errors };                   
            })
        }
    }

    saveRow() {
        if(this.handleValidation()){
            this.sendData();
        }else{
            this.validateAddComment();
        }
    }

    deleteRow(id){
        this.setState({ 
            id_delete: id, modal: true,
            btnDisabled: false
        })
    }

    saveDeleteRow(){
        axios.delete(API_URL.superStudent+'/student/progress-log/'+this.state.id_delete,{
            headers:{ 'Authorization': `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            //window.location.reload(false);
            toast.success('success delete data')
            this.props.handleReloadProgressData();
            this.closeModal();
        })
        .catch(error => console.log(error))
    }

    closeModal(){
        this.setState({modal : false})
    }

    sendData() {
        var formData = new FormData();
        formData.append('code_students', this.props.profile.code_students);
        formData.append('code', this.state.model.code);
        formData.append('code_users', this.props.codeUsers);
        formData.append('code_lpt', "CMNT");

        axios.post(API_URL.superStudent+'/student/progress-log',formData, { 
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }})
        .then((reply)=>{
            toast.success('success add data')
            this.closeModal();
            this.setState({ code: ''})
            this.props.handleReloadProgressData();
        })
    }

    renderDeleteDialog() {
          return (
            <Modal className="modal-dialog" isOpen={this.state.modal} toggle={() => this.closeModal()} size="md">
              <ModalHeader toggle={() => this.closeModal()}>Delete Data</ModalHeader>
                <ModalBody style={{
                    maxHeight: 'calc(100vh - 210px)',
                    overflowY: 'auto'
                }}>
                  Delete this data ?
                </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => this.saveDeleteRow()}>Delete</Button>
                <Button color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
              </ModalFooter>
            </Modal>
        )
      }

    render(){
        const data = this.props.data
        return (
            <Fragment>
                <div>
                <div className="card card-progress-log">
                    <CardHeader className='pb-0 d-flex justify-content-between mb-0'>
                        <h5>PROGRESS LOG</h5>
                    </CardHeader>

                        <div className="card-body scroll scrool recent-notification">
                            {data.map((dataProgress, index)=>(
                                <div className="media" key={index}>
                                    <div className=""><span>{moment(dataProgress.datetime_progress).format('DD MMM YY')}</span>
                                        <p>{moment(dataProgress.datetime_progress).format('HH:mm')}</p>
                                    </div>
                                    <div className="media-body">
                                        {
                                            dataProgress.code_lpt == 'CMNT' ? 
                                            <div>
                                                <span>{dataProgress.user != null ? dataProgress.user.username :''} has comment <h6>{dataProgress.code}</h6></span>
                                            </div> 
                                            :
                                            dataProgress.code_lpt == 'PGRS' ? 
                                            <div>
                                                <span>Progress has been changed to <p style={{fontSize:'1em', color:'#000', fontWeight:'bold'}}>{dataProgress.progress != null ? dataProgress.progress.name_progress : ''}</p></span>
                                                <p className="f-12">{dataProgress.user != null ? dataProgress.user.username :''}</p>
                                            </div> 
                                            :
                                            dataProgress.code_lpt == 'FLUP' ? 
                                            <div>
                                                <span>Already Follow Up with notes <p style={{fontSize:'1em', color:'#000', fontWeight:'bold'}}>{dataProgress.code}</p></span>
                                                <p className="f-12">{dataProgress.user != null ? dataProgress.user.username :''}</p>
                                            </div> 
                                            :
                                            ''
                                        }
                                    </div>
                                    <button onClick={() => this.deleteRow(dataProgress.id_log_progress)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none', display:this.state.display}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 5, color: '#e4566e' }}></i></button>
                                </div>
                            ))}
                            <div className="row">
                                <div className="col-md-9">
                                    <input className="form-control" name="code" type="text" id="code"
                                        onChange={(e) => this.onChangeAddComment(e)}
                                        onBlur={(e) => this.validateAddComment}
                                        ref={e => this.inputAddComment = e}
                                    />
                                    <span>{this.state.errors.code}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                                <div className="col-md-3">    
                                    <button 
                                        className="form-control btn btn-outline-primary btn-lg" 
                                        type="button" 
                                        style={{border:'none'}}
                                        disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>
                                        <i className="fa fa-paper-plane"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDeleteDialog()}
            </Fragment>
        );
    }
}

// export default Students;
const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
    codeUsers: state.Login.detailUser.code_users || state.Login.detailUser.code_students,
  })
  
  export default connect(reduxState, null)(withRouter(ProgressLog));