import React, { Fragment, Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import API_URL from '../../../ApiUrl';
import axios from 'axios';

class DatatableRoles extends Component {
   
    constructor (props){
        super(props)
        this.state = {
            roles:'',
            id_roles:'',
            modal:false,
            modalDelete:false,
            btnDisabled: false,
        }
    }

    addRow(){
      this.setState({
        modal:true
      })
    }

    editRow(row){
      this.setState({
        operation: 'edit',
        id_roles: row.id_roles,
        roles: row.roles,
        modal:true
      })
    }

    deleteRow(row){
      this.setState({
        id_roles: row.id_roles,
        modalDelete: true
      });
    }

    closeDialog(){
      this.setState({
        modal: false,
        modalDelete: false
      });
      this.resetState();
    }

    sendData(){
      this.setState({
        btnDisabled:true
      })
      var formData = new FormData()
      formData.append('roles', this.state.roles)

      let url = '/roles'

      if (this.state.operation === 'edit'){
          formData.append('_method','PATCH');
          url = '/roles/'+this.state.id_roles
      }

      axios({
          method: 'POST',
          url: API_URL.superStudent+url,
          data: formData,
          headers: {  Authorization: `Bearer ${this.props.dataToken}`  }
      })
      .then(() => {
        toast.success('Data Update Successfully')
        window.location.reload(false)
      })

    }

    resetState(){
      this.setState({
          roles:'',
          code_roles:''
      })
    }

    saveDeleteRow(){
      this.setState({
        btnDisabled: true
      })
      axios.delete(API_URL.superStudent+'/roles/'+this.state.id_roles,{
          headers: {  Authorization: `Bearer ${this.props.dataToken}`  }
      })
      .then(() => {
          toast.success('Data Deleted')
          window.location.reload(false)
      })
    }

    handleInput = (e)=> {
      this.setState({
        [e.target.name]: e.target.value
      })
    }

    renderDialog(){
      return (
        <div className="card-body btn-showcase">
          <Modal isOpen={this.state.modal} toggle={() => this.setState({ modal: false })}>
            <ModalHeader toggle={() => this.setState({ modal: false })}>Form Data</ModalHeader>
            <ModalBody>
            <form className="needs-validation" noValidate="" >
                <div className="col-md-12 mb-3"> 
                    <label htmlFor="roles">Role Name</label>
                    <input className="form-control" onChange={this.handleInput} value={this.state.roles} name="roles" type="text" />
                </div>
            </form>
            </ModalBody>
            <ModalFooter>
              <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>Save Data</Button>
              <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
            </ModalFooter>
          </Modal>
        </div>
      )
    }

    setDeleteRow() {
        return (
          <Modal isOpen={this.state.modalDelete} toggle={() => this.setState({ modalDelete: false })}>
            <ModalHeader toggle={() => this.setState({ modalDelete: false })}>Form Delete</ModalHeader>
            <ModalBody>
              Delete Role ?
            </ModalBody>
            <ModalFooter>
              <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveDeleteRow()}>Delete Data</Button>
              <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
            </ModalFooter>
          </Modal>
      )
    }

    render(){
        const { pageSize, myData } = this.props;
        const columns = [
        {
          Header: 'Roles',
          accessor: 'roles',
          style: {textAlign: 'center'},
          filterable: false
        },{
          Header: <b>Action</b>,
          id:'delete',
          accessor: str => 'delete',
          Cell:  (row) => (
            <div>                     
                  <button onClick={() => this.deleteRow(row.original)} className="btn btn-danger btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash"></i></button> &nbsp;
                  <button onClick={() => this.editRow(row.original)} className="btn btn-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" ></i></button>
            </div>
          ),
          style: {
            textAlign: 'center'
          },
          sortable: false,
          filterable: false
        }
      ]
        return (
            <Fragment>
                <div style={{marginBottom:'10px'}}>
                  <button onClick={() => this.addRow()} className="btn btn-success btn-xs" type="button" style={{border:'none'}} >
                    <div>
                      <span>
                        <i className="fa fa-plus fa-2x" ></i>
                      </span>
                    </div>
                  </button>
                </div>
                <ReactTable
                  data={myData}
                  columns={columns}
                  pageSize={pageSize}
                  showPagination={true}
                  defaultPageSize={10}
                  SubComponent={row => {
                    return(
                      <div style={{padding:'20px'}} className='row'>
                        {
                          row.original.modules.length > 0 ?
                          row.original.modules.map(item => (
                            <div key={item.id_modules} className='col-md-4'>
                                <li style={{fontSize:'12px'}}>{item.title}</li>
                            </div>
                          ))
                          :
                          ''
                        }

                      </div>
                    )
                  }}
                />
              <ToastContainer />
              {this.renderDialog()}
              {this.setDeleteRow()}
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
  dataToken: state.Login.lgnInitialize,
})


export default connect(reduxState, null)(DatatableRoles);