import React, { Fragment, Component } from 'react';
import ReactTable from 'react-table';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { matchSorter } from 'match-sorter';
import Moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ToastContainer, toast } from 'react-toastify';
import { 
    Button, Modal, ModalHeader, ModalBody, 
    ModalFooter, UncontrolledTooltip 
} from 'reactstrap';
import Breadcrumb from '../../components/common/breadcrumb';

class DataTaskListStatementLetter extends Component{

    constructor(props){
        super(props)
        this.state = {
            id_todo: '',
            noted: '',
            status: '',
            modal_todo: false,
            dateType: 'this_week',
            startDate: Moment().startOf('isoWeek'),
            endDate: Moment().endOf('isoWeek'),
            todoList: []
        }
    }

    componentDidMount(){
        this.loadTodoList()
    }

    loadTodoList = () =>{
        let formData = new FormData ()
        formData.append('code_jobdesk', 'JD017')
        formData.append('start_date', this.state.startDate.format('YYYY-MM-DD'))
        formData.append('end_date', this.state.endDate.format('YYYY-MM-DD'))

        axios.post(API_URL.superStudent+'/todo/list-todo', formData, {
            headers:{
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({todoList: result}))
        .catch(error => console.log(error))
    }

    setRangeDate(){   
        let startDate = Moment();
        let endDate  = Moment();     
        switch(this.state.dateType){
            case 'last_week':
                startDate.subtract(1, 'weeks').startOf('isoWeek')
                endDate.subtract(1, 'weeks').endOf('isoWeek')
                break;
            case 'this_month':
                startDate.startOf('month')
                endDate.endOf('month')
                break;
            case 'last_month':
                startDate.subtract(1, 'months').startOf('month')
                endDate.subtract(1, 'months').endOf('month')
                break;
            case 'this_year':
                startDate.startOf('year').add(-6,'months')
                endDate.endOf('year').add(-6,'months')
                break;
            case 'last_year':
                startDate.subtract(1, 'year').startOf('year').add(-6,'months')
                endDate.subtract(1, 'year').endOf('year').add(-6,'months')
                break;
            default:
                startDate.startOf('isoWeek')
                endDate.endOf('isoWeek')
        } 
        this.setState({ 
            startDate,
            endDate
        },() => this.filter())
    }

    handleDate = (e) => {
        this.setState({
            dateType: e.target.value
        }, () => this.setRangeDate())
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    filter = () => {
        this.loadTodoList()
    }

    finish(){
        let formData = new FormData()
        formData.append('id_todo', this.state.id_todo)
        formData.append('noted', this.state.noted)
        formData.append('status', this.state.status)
        axios.post(API_URL.superStudent+'/todo/finish-todo', formData, {
            headers:{
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => {
            toast.success('Task list updated successfully')
            this.setState({
                modal_todo:false
            })
            this.loadTodoList()
        })
        .catch(error => {
            toast.error('there is an error')
        })  
    }

    updateTask = (row) => {
        this.setState({
            id_todo: row.id,
            noted: row.noted,
            status: row.status,
            modal_todo:true
        })
    }

    renderModalTodo(){
        return(
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modal_todo} toggle={() => this.setState({ modal_todo: false })}>
                <ModalHeader toggle={() => this.setState({ modal_todo: false })}>Update Progress</ModalHeader>
                <ModalBody>
                    <div className='row'>
                        <div className='col-md-12'>
                            <form>
                                <div className='form-group'>
                                    <label className='control-label'>Noted</label>
                                    <textarea className='form-control' onChange={this.handleInput} value={this.state.noted} name='noted'></textarea>
                                </div>
                                <div className='form-group'>
                                    <label className='control-label'>Status</label>
                                    <select name='status' value={this.state.status} className='form-control' onChange={this.handleInput}>
                                        <option key={0} value='0'>Select Status</option>
                                        <option key={1} value='1'>Progress</option>
                                        <option key={3} value='3'>Done</option>
                                        <option key={4} value='2'>Pending</option>
                                        <option key={5} value='7'>Revisi</option>
                                        <option key={6} value='5'>Cancel</option>
                                    </select>
                                </div>
                            </form>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                  <Button disabled={this.state.btnAddDisabled} color="primary" onClick={(e) => this.finish()}>Yes</Button>
                  <Button  color="secondary" onClick={() => this.setState({ modal_todo: false })}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
        )
    }

    render() {
        const columns_todo = [
            {
                Header: '',
                accessor: 'created_at',
                style:{textAlign:'center'},
                width:90,
                Cell:(row) => (
                    <>
                        <span>{Moment(row.original.created_at).format('DD MMM YY')}</span><p className='f-12 text-center'>{Moment(row.original.created_at).format('HH:mm')}</p>
                    </>
                )
            },
            {
                Header: 'Code',
                accessor: 'student.code_students',
                width:100,
                style: {textAlign: 'center'},
                Cell: (row) => (
                    <div>                     
                        <button id="UncontrolledTooltipExample" style={{border:'none'}} className='btn btn-outline-primary btn-sm btn-pill text-center' onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/`+row.original.student.token_students)}>{row.original.code_students}</button>
                    </div>
                ),
            },
            {
                Header: 'Students',
                accessor: 'student.nama',
                width:150,
                style: {textAlign: 'center'},
            },
            {
                Header: 'Description',
                accessor: 'description',
                Cell:(row) => (
                    <>
                        <span id={'code'+row.original.id} data-placement="top">{row.original.description}</span>
                        <UncontrolledTooltip placement="left" target={'code'+row.original.id}>{row.original.description}</UncontrolledTooltip>
                    </>
                ),
                style: {textAlign: 'center'},
            },
            {
                Header: 'Type',
                accessor: 'category.name',
                width:160,
                style: {textAlign: 'center'}
            },
            {
                Header: 'User',
                width:90,
                accessor: 'user.username',
                style: {textAlign: 'center'},
            },
            {
                Header: 'Status',
                accessor: 'status',
                width:70,
                style: {textAlign: 'center'},
                Cell: (row) => (
                    <> <span id={`code_status`+row.original.id} className={'badge bg-'+row.original.status.label}>{row.original.status.name}</span> <UncontrolledTooltip placement="top" target={`code_status`+row.original.id}>
                        {row.original.noted}
                  </UncontrolledTooltip> </>
                  ),
            },
            {
                Header: 'University',
                accessor: 'code_university',
                style: {textAlign: 'center'},
                Cell:(row) => (
                   <>{row.original.code_university !== null ? row.original.university.nama_sekolah : ''}</> 
                )
            },
            {
                Header: 'Finish',
                accessor: 'date_finish',
                style:{textAlign:'center'},
                width:90,
                Cell:(row) => (
                    row.original.date_finish !== null ? 
                    <><span>{Moment(row.original.date_finish).format('DD MMM YY')}</span><p className='f-12 text-center'>{Moment(row.original.date_finish).format('HH:mm')}</p></> : ''
                )
            },
            {
                Header: '',
                accessor: 'action',
                width:50,
                Cell: (row) => (
                    row.original.status_id === 3 ? '' : row.original.status_id === 5 ? '' : <button onClick={() => this.updateTask(row.original)} style={{border:'none'}} className='btn btn-outline-success btn-xs'><i style={{fontSize:16}} className='fa fa-pencil '></i></button>
                  ),
                style: {textAlign: 'center'},
            },
        ]

        return (
            <Fragment>
                <Breadcrumb title='Statement Letter' />
                <div className='card'>
                    <div className='card-header'>
                        <div className='row justify-content-md-center'>
                            <div className='col-md-3'>
                                <select name='dateType' value={this.state.dateType} onChange={this.handleDate} className='form-control'>
                                    <option value='this_week'>This Week</option>
                                    <option value='last_week'>Last Week</option>
                                    <option value='this_month'>This Month</option>
                                    <option value='last_month'>Last Month</option>
                                    <option value='this_year'>This Year</option>
                                    <option value='last_year'>Last Year</option>
                                </select>
                            </div>
                        </div> 
                    </div>
                    <div className='card-body'>
                        <div className='row justify-content-center'>
                            <div className='col-md-12'>
                                <h4>Task List Statement Letter</h4>
                                <ReactTable
                                    data={this.state.todoList}
                                    columns={columns_todo}
                                    defaultPageSize={15}
                                    className="-striped -highlight"
                                    showPaginationBottom={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModalTodo()}
            </Fragment>
        )
    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize
})

export default connect(reduxState, null) (DataTaskListStatementLetter);