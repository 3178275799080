import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux'
import axios from 'axios'
import API_URL from '../../ApiUrl'
import { toast } from 'react-toastify';
import { saveAs } from "file-saver";

export class DetailKandidat extends Component {
    constructor(props){
        super(props)
        this.state = {
            dataKandidat: [],
            reviewModal: false,
            rating_kandidat: '',
            review_kandidat: '',
            btnLoading: ''
        }
    }

    openReviewModal(){
        this.setState({reviewModal: true});
    }

    closeModalReview(){
        this.setState({reviewModal: false});
    }

    handleChange(e){
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    componentDidMount(){
        this.getDataKandidat();
    }

    downloadFile = (e, url) => {
        saveAs(url,url.substring(url.lastIndexOf('/')));
    }

    saveReview(){
        this.setState({btnLoading: true})
        let formData = new FormData();

        formData.append('rating_kandidat', this.state.rating_kandidat)
        formData.append('review_kandidat', this.state.review_kandidat)
        formData.append('id_hire_employee', this.state.dataKandidat.id_hire_employee)
        axios.post(API_URL.superStudent + '/niec-career-candidate/add-review-candidate', formData, {
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            }
        })
        .then(res => {
            this.setState({btnLoading: false})
            toast.success("Candidate Review Saved")
            this.getDataKandidat();
            this.closeModalReview();
        })
        .catch(error => console.log(error))
    }

    getDataKandidat(){
        axios.get(API_URL.superStudent + '/niec-career-candidate/' + this.props.match.params.id_hire_employee, {
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            }
        })
        .then(response => response.data)
        .then(result => this.setState({ dataKandidat: result}))
        .catch(error => console.log(error))
    }

    renderReviewModal(){
        return(
            <Modal isOpen={this.state.reviewModal} toggle={() => this.closeModalReview()} size='lg'>
                <ModalHeader toggle={() => this.closeModalReview()}>Review Kandidat</ModalHeader>
                <ModalBody>
                    <form>  
                        <div class="form-group">
                            <label htmlFor="rating_kandidat">Nama Lengkap</label>
                            <select name="rating_kandidat" className='form-control' defaultValue={this.state.dataKandidat.rating_kandidat} id="rating_kandidat" onChange={(e) => this.handleChange(e)}>
                                <option value="">-- Pilih Rating --</option>
                                <option value="Bad">Bad</option>
                                <option value="Not Bad">Not Bad</option>
                                <option value="Good">Good</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label htmlFor="review_kandidat">Review</label>
                            <textarea name='review_kandidat' className="form-control" id="review_kandidat" rows="7" onChange={(e) => this.handleChange(e)}>{this.state.dataKandidat.review_kandidat}</textarea>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnLoading && true} color="primary" onClick={() => this.saveReview()}>Save Review</Button>
                    <Button color="secondary" onClick={() => this.closeModalReview()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

  render() {
    const {dataKandidat} = this.state;
    return (
        <>
        <div className="page-wrapper">
            <div className="container-fluid p-0">
                <button onClick={()=>this.props.history.goBack()} className='btn btn-danger mb-3'><i className="fa fa-arrow-left"></i> Back</button>
                <div className="row">
                    <div className=" col-lg-6 col-sm-12">
                    <div className="card mb-3 custom-card" style={{maxWidth: '600px', margin: 'auto'}}>
                        <div className='card-profile'>
                            {/* <img className="rounded-circle" src={this.state.photo !== null ? this.state.photo : seven} alt="" /> */}
                        </div>
                        <div className="text-center profile-details mb-3">
                            <h4>{dataKandidat.nama_lengkap}</h4>
                        </div>
                        <div className="table-responsive">
                            <table className="table">
                            <tbody>
                                <tr>
                                <td>Email</td>
                                <td>{dataKandidat.email}</td>
                                </tr>
                                <tr>
                                <td>No HP</td>
                                <td>{dataKandidat.no_hp}</td>
                                </tr>  
                                <tr>
                                <td>Lowongan di Apply</td>
                                <td>{dataKandidat.job_vacancy?.title}</td>
                                </tr>
                                <tr>
                                <td>About Kandidat</td>
                                <td><div dangerouslySetInnerHTML={{__html: dataKandidat.text_promosi_diri}} /></td>
                                </tr>
                            </tbody>
                            </table>
                            <div className="text-center profile-details m-3">
                                <h4><button className='btn btn-primary' onClick={(e)=>this.downloadFile(e, dataKandidat.file_cv)}>Download CV <i className='fa fa-download'></i></button></h4>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card" style={{maxWidth: '600px', margin: 'auto'}}>
                            <div className="card-header card-header-border d-flex justify-content-between">
                                <h5>Review Kandidat</h5>
                                <button onClick={()=> this.openReviewModal()} className='btn btn-secondary btn-xs'><i className="fa fa-comment"></i></button>
                            </div>
                            <div className="card-body scroll recent-notification">
                                <div className="media">
                                    {
                                        dataKandidat.review_kandidat ? (
                                            <>
                                            <div className=""><h6>{dataKandidat.rating_kandidat}</h6>
                                            </div>
                                            <div className="media-body">
                                                <div>
                                                    <span>Hasil review kandidat <h6 className='text-justify'>{dataKandidat.review_kandidat}</h6></span>
                                                </div>
                                            </div>
                                            </>
                                        ) :
                                        (<div className="text-center">
                                        <h5>Kandidat Belum Direview</h5>
                                    </div>)
                                    }
                                    
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>


                </div>
            </div>
            {this.renderReviewModal()}
        </>
      )
  }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser
  })
  
  export default connect(reduxState, null)(DetailKandidat)

