import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { DollarSign, Settings } from 'react-feather';
import { connect } from 'react-redux';
import { withRouter } from "react-router";

import { Switch, Route, Redirect} from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';

import "./styles/react-router-tabs.css";

const BisdevPerform = (dataUser) => {
    const roles = dataUser.dataUser.code_roles
    return (
        <Fragment>
            <Breadcrumb title="Bisdev Performance" parent="Bisdev Performance" />
            <div className="container-fluid">
                <div className="row theme-tab">
                    <div className="col-sm-12">
                        <RoutedTabs tabClassName="tab-link" activeTabClassName="active">
                            <div className="tabs tab-title">
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/bisdev-perform/tipe`}>
                                    Tipe
                                </NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/bisdev-perform/produk`}>
                                    Produk
                                </NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/bisdev-perform/perform`}>
                                    Performance
                                </NavTab>
                            </div>
                        </RoutedTabs>
                        <Switch>
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/pages/bisdev-perform/`}
                                render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/bisdev-perform/tipe`} />}
                            />
                        </Switch>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

// export default Students;
const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
})
  
export default connect(reduxState, null)(withRouter(BisdevPerform));