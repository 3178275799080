import React, { useEffect, useState } from "react"
import { Bar } from 'react-chartjs-2';
import Breadcrumb from '../../common/breadcrumb';
import { Progress } from 'reactstrap';
import CountUp from 'react-countup';
import setRangeDate from "../../../helper/general-function";
import axios from 'axios'
import API_URL from '../../../ApiUrl';
import { useSelector } from "react-redux"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import { color1,color2,color3,color4, barChartOptions } from "../../../constant/variable";

import DataKPI from "../partition/component/data-kpi";
import DetailKPI from "../partition/component/detail-kpi";
import IncomeConsuntant from '../partition/income-consultant';
import DataLeadsAll from '../../../pages/leads-all/index'
import LeadsStudentAmbassador from "./leads-student-ambassador";
import BadgeBeta from "../../../pages/components/badge-beta";

import DetailNewMember from "./detail/detail-new-member";
import DetailGoldenTicket from "./detail/detail-golden-ticket";
import DetailLeadsEvent from "./detail/detail-leads-event";
import DetailStudentTask from "./detail/detail-student-task";

const DashboardConsultant = () => {

    const[kpi,setKPI] = useState({
        result:0,
        granted:0,
        translate:0,
        school_fee:0,
        income:0,
        selisih:0,
        last_result:0
    });

    let data = setRangeDate('this_year');
    const[total,setTotal] = useState(0)
    const[indikator,setIndikator] = useState([]);
    const[master,setMaster] = useState([]);
    const[index,setIndex] = useState(0);
    const[report,setReport] = useState([index])
    const[date,setDate] = useState(Moment().endOf('month'))
    const[typeDate,setTypeDate] = useState('this_year')
    const[filterDate,setFilterDate] = useState({start_date:data.start, end_date:data.end})

    const[totalNewMember,setTotalNewMember] = useState(0);
    const[totalWefPotensial,setTotalWefPotensial] = useState(0);
    const[totalGoldenTicket,setTotalGoldenTicket] = useState(0);
    const[totalSessionPotensial,setTotalSessionPotensial] = useState(0);
    const[totalStudentTask,setTotalStudentTask] = useState(0);

    const[showDashboarConsultant,setShowDashboardConsultant] = useState(true);
    const[showDetailNewMember,setShowDetailNewMember] = useState(false);
    const[showDetailGoldenTicket,setShowDetailGoldenTicket] = useState(false);
    const[showDetailLeadsEventSession,setShowDetailLeadsEventSession] = useState(false);
    const[showDetailStudentTask,setShowDetailStudentTask] = useState(false);
    
    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    })); 

    const { dataUser } = useSelector(state => ({
        dataUser:state.Login.detailUser
    }));

    useEffect(() => {
        loadDataIndikator()
    },[]);
    
    useEffect(() => {
        loadDataPeformance()
    },[date]);

    useEffect(() => {
        rangeDate()
    },[typeDate])

    useEffect(() => {
        loadDashboarHelperConsultant()
    },[filterDate])

    const setParam = (e) => {
        setIndex(e)
        setTotal(report[e][1].reduce((a,b) => a + b,0))
    }

    const handlePickerMonth = (e) => {
        setDate(Moment(e).endOf('month'))
    }

    const rangeDate = () => {
        let data = setRangeDate(typeDate);
        setFilterDate({start_date:data.start, end_date: data.end });
    }

    const loadDataIndikator = () => {
        let formData = new FormData()
        formData.append('code_jobdesk','JD004')
        axios.post(API_URL.superStudent+'/kpi/list-indikator', formData,{
            headers: { 'Authorization': `Bearer ${dataToken}` }
        })
        .then(response => {
            setMaster(response.data.data)
        })
        .catch(error => console.log(error))
    }

    const loadDataPeformance = () => {
        let formData = new FormData()
        formData.append('code_konsultan',dataUser.code_konsultan)
        formData.append('end_date', date.format('YYYY-MM-DD'))
        formData.append('time_type', 2)
        formData.append('reference_type', 1)
        axios.post(API_URL.superStudent+'/consultant-peformance/recap-kpi', formData,{
            headers: { 'Authorization': `Bearer ${dataToken}` }
        })
        .then(response => {
            let result = response.data.data
            let report_kpi = result.kpi_data

            let kpi = report_kpi[0].bobot+report_kpi[1].bobot+report_kpi[2].bobot+report_kpi[3].bobot
            let last_kpi = report_kpi[0].last_bobot+report_kpi[1].last_bobot+report_kpi[2].last_bobot+report_kpi[3].last_bobot
            let selisih = 0
            if(kpi < 100){
                selisih = 100 - kpi
            }
            setKPI({
                result:kpi,
                translate: report_kpi[0].bobot,
                school_fee: report_kpi[1].bobot,
                granted: report_kpi[2].bobot,
                income: report_kpi[3].bobot,
                selisih,
                last_result: kpi - last_kpi
            })
            setIndikator(report_kpi)
            setReport(result.grafik)
            setTotal(result.grafik[index][1].reduce((a,b) => a + b,0))
        })
        .catch(error => console.log(error))
    }

    const loadDashboarHelperConsultant = () => {
        let formData = new FormData()
        formData.append('code_konsultan', dataUser.code_konsultan)
        formData.append('start_date', filterDate.start_date)
        formData.append('end_date', filterDate.end_date)
        axios.post(API_URL.superStudent+'/count-total-follow-up', formData, {
            headers: { 'Authorization': `Bearer ${dataToken}` }
        })
        .then(response => {
            setTotalNewMember(response.data.data.new_member.length)
            setTotalGoldenTicket(response.data.data.golden_tiket)
            setTotalSessionPotensial(response.data.data.potensial_infosession.length)
            setTotalStudentTask(response.data.data.student_task.length)
        })
        .catch(error => console.log(error))
    }

    var barChartData = {
        labels:report[index][0],
        datasets: [
            {
                label: 'y',
                lagend: 'y',
                data: report[index][1],
                borderColor: "#4466f2",
                backgroundColor: "rgba(68, 102, 242, 0.4)",
                highlightFill: "rgba(68, 102, 242, 0.95)",
                highlightStroke: "#4466f2",
                borderWidth: 2
            }
        ],
        plugins: {
            datalabels: {
                display: false,
                color: 'white'
            }
        }
    }

    const openNewMember = () => {
        setShowDashboardConsultant(false);
        setShowDetailNewMember(true);
        setShowDetailGoldenTicket(false);
        setShowDetailLeadsEventSession(false);
        setShowDetailStudentTask(false);
    }

    const openGoldenTicket = () => {
        setShowDashboardConsultant(false);
        setShowDetailNewMember(false);
        setShowDetailGoldenTicket(true);
        setShowDetailLeadsEventSession(false);
        setShowDetailStudentTask(false);
    }

    const openLeadsEventSession = () => {
        setShowDashboardConsultant(false);
        setShowDetailNewMember(false);
        setShowDetailGoldenTicket(false);
        setShowDetailLeadsEventSession(true);
        setShowDetailStudentTask(false);
    }

    const openStudentTask = () => {
        setShowDashboardConsultant(false);
        setShowDetailNewMember(false);
        setShowDetailGoldenTicket(false);
        setShowDetailLeadsEventSession(false);
        setShowDetailStudentTask(true);
    }

    return(
        <>
            <Breadcrumb parent="Home" title = "Report Consultant" />
            <div className="container-fluid">
                {/* <div className="row justify-content-center mb-2">
                    <div className="col-md-3">
                        <DatePicker dateFormat='MMMM yyyy' selected={date._d} showMonthYearPicker showFullMonthYearPicker className='form-control' onChange={handlePickerMonth} />
                    </div>
                </div> */}
                <div className="row justify-content-center mt-3">
                    <div className="col-sm-3">
                        <select name='date' value={typeDate} onChange={e => setTypeDate(e.target.value)} className='form-control mb-1'>
                            <option value='this_week'>This Week</option>
                            <option value='last_week'>Last Week</option>
                            <option value='this_month'>This Month</option>
                            <option value='last_month'>Last Month</option>
                            <option value='this_year'>This Year</option>
                            <optgroup label='Quartal'>
                                <option value='1'>Quartal I</option>
                                <option value='2'>Quartal II</option>
                                <option value='3'>Quartal III</option>
                                <option value='4'>Quartal IV</option>
                            </optgroup>
                        </select>
                    </div>
                </div>
                <div className="row justify-content-center mt-5">
                    <div className="col-sm-12">
                        <span style={{fontSize:'1.5em'}} className='f-w-600'>{Moment(filterDate.start_date).format('DD MMMM yyyy')} - {Moment(filterDate.end_date).format('DD MMMM yyyy')}</span>
                    </div>
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col-sm-12">
                        <div className="card" style={{padding:'20px'}}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <h4>Follow Up</h4>
                                    <ul className="mt-2">
                                        <li onClick={openNewMember} style={{color: 'blue', cursor: "pointer"}}>{totalNewMember} New Member</li>
                                        <li onClick={openGoldenTicket} style={{color: 'blue', cursor: "pointer"}}>{totalGoldenTicket} Golden Ticket</li>
                                        <li onClick={openLeadsEventSession} style={{color: 'blue', cursor: "pointer"}}>{totalSessionPotensial} Info Session Leads</li>
                                    </ul>
                                </div>
                                {/* <div className="col-sm-4">
                                    <div className="card" style={{padding:'15px'}}>
                                        <h4 className="text-center">Member Stuck</h4>
                                        <ul className="mt-2">
                                            <li><a href="">10 Member Stuck More Than 7 Days</a></li>
                                            <li><a href="">10 Member Stuck More Than 30 Days</a></li>
                                            <li><a href="">10 Member Stuck More Than 60 Days</a></li>
                                            <li><a href="">10 Member Stuck More Than 90 Days</a></li>
                                        </ul>
                                    </div>
                                </div> */}
                                <div className="col-sm-6">
                                    <h4>Student Task</h4>
                                    <ul className="mt-2">
                                        <li onClick={openStudentTask} style={{color: 'blue', cursor: "pointer"}}>{totalStudentTask} Student Task</li>
                                        <li>&nbsp;</li>
                                        <li>&nbsp;</li>
                                        <li>&nbsp;</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    showDashboarConsultant && (
                        <div>
                            <div className="row">
                                <div className="col-xl-4 col-sm-6">
                                    <div className="card">
                                        <div style={{padding:'11px'}}>
                                            <DataKPI kpi={kpi} />
                                            <div className="progress-showcase">
                                                <Progress multi style={{ height: "15px"}}>
                                                    <Progress animated style={{backgroundColor:color1}} bar value={kpi.translate}>{kpi.translate} %</Progress>
                                                    <Progress animated style={{backgroundColor:color2}} bar value={kpi.school_fee}>{kpi.school_fee} %</Progress>
                                                    <Progress animated style={{backgroundColor:color3}} bar value={kpi.granted}>
                                                    {kpi.granted} %
                                                    </Progress>
                                                    <Progress animated style={{backgroundColor:color4}} bar value={kpi.income}>
                                                    {kpi.income} %
                                                    </Progress>
                                                    <Progress animated bar style={{backgroundColor:'#d6d6c2'}} value={kpi.selisih} />
                                                </Progress>
                                            </div>
                                            <div style={{color:'white'}} className="mt-1">
                                                <span style={{fontSize:'8px',backgroundColor:color1}} className="badge badge-pill">Translate</span>
                                                <span style={{fontSize:'8px',backgroundColor:color2}} className="badge badge-pill">School Fee</span>
                                                <span style={{fontSize:'8px',backgroundColor:color3}} className="badge badge-pill">Granted</span>
                                                <span style={{fontSize:'8px',backgroundColor:color4}} className="badge badge-pill">Income</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-8 col-sm-12">
                                    <DetailKPI indikator={indikator} />
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-sm-8">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="row justify-content-between">
                                                <div className="col">
                                                <strong>Yearly Peformance Consultant <BadgeBeta/> </strong>
                                                </div>
                                                <div className="col">
                                                    <select name='index' value={index} onChange={e => setParam(e.target.value)} className='form-control'>
                                                    {
                                                    master.map((indikator, index) => (
                                                    <option key={indikator.id} value={index}>{indikator.name}</option>
                                                    ))
                                                    }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center font-primary font-weight-bold">
                                        {
                                            index == 3 ?
                                            <CountUp separator="." decimal="," prefix="Rp " decimals={2} className="counter" end={total} />
                                            :
                                            <CountUp separator="." className="counter" end={total} />
                                        }
                                        </div>
                                        <div className="card-body chart-block">
                                            <Bar height={170} data={barChartData} options={barChartOptions} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <IncomeConsuntant start_date={filterDate.start_date} end_date={filterDate.end_date} />
                                </div>
                                <div className="col-sm-12">
                                    <DataLeadsAll/>
                                </div>
                                <div className="col-sm-12">
                                    <LeadsStudentAmbassador/>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    showDetailNewMember && (
                        <div className="row">
                            <div className="col-lg-12">
                                <DetailNewMember code_konsultan={dataUser.code_konsultan} start_date={filterDate.start_date} end_date={filterDate.end_date} />
                            </div>
                        </div>
                    )
                }
                {
                    showDetailGoldenTicket && (
                        <div className="row">
                            <div className="col-lg-12">
                                <DetailGoldenTicket code_konsultan={dataUser.code_konsultan} start_date={filterDate.start_date} end_date={filterDate.end_date} />
                            </div>
                        </div>
                    )
                }
                {
                    showDetailLeadsEventSession && (
                        <div className="row">
                            <div className="col-lg-12">
                                <DetailLeadsEvent code_konsultan={dataUser.code_konsultan} start_date={filterDate.start_date} end_date={filterDate.end_date} />
                            </div>
                        </div>
                    )
                }
                {
                    showDetailStudentTask && (
                        <div className="row">
                            <div className="col-lg-12">
                                <DetailStudentTask code_konsultan={dataUser.code_konsultan} start_date={filterDate.start_date} end_date={filterDate.end_date} />
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default DashboardConsultant;