import React ,{ useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table'
import {matchSorter} from 'match-sorter'
import Breadcrumb from '../../common/breadcrumb'
import axios from 'axios'
import API_URL from '../../../ApiUrl';
import { useSelector } from "react-redux"

const LeadsStudentAmbassador = () => {

    const [data,setData] = useState([])
    const[modal,setModal] = useState(false)
    const[codeStudent,setCodeStudent] = useState('');
    const[codeConsultant,setCodeConsultant] = useState('');
    const[listConsultant,setListConsultant] = useState([])

    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    })); 

    const { dataUser } = useSelector(state => ({
        dataUser:state.Login.detailUser
    }));

    useEffect(() => {
        loadDataStudent()
    },[])

    const loadDataStudent = () => {
        let formData = new FormData()
        formData.append('code_konsultan',dataUser.code_konsultan)
        axios.post(API_URL.superStudent+'/student/new-ambassador-student', formData,{
            headers: { 'Authorization': `Bearer ${dataToken}` }
        })
        .then(response => {
            setData(response.data.data)
        })
        .catch(error => console.log(error))
    }

    const setConsultant = async (row) => {
        setModal(true)
        setCodeStudent(row.code_students)
        await loadDataConsultant()
    }

    const loadDataConsultant = async () => {
        const url = API_URL.superStudent+'/consultant/jobdesk';
        let formData = new FormData ()
        formData.append('jobdesk', JSON.stringify(['JD004']))
        await axios.post(url, formData, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${dataToken}`
          }
        })
        .then(response => response.data.data)
        .then(result => {
            setListConsultant(result)
        })
        .catch(error => console.log(error))
    }

    const handleSubmit = () =>{
        var formData = new FormData();
        formData.append('code_konsultan', codeConsultant)
        formData.append('code_students', codeStudent)
      
        axios.post(API_URL.superStudent+'/student/set-consultant', formData, {
          headers: {
            'Authorization': `Bearer ${dataToken}`
          }
        })
        .then(response => {
            loadDataStudent()
            setModal(false)
        })
        .catch(error => {
        })
      }

    const renderModalEditConsultant = () =>{
        return(
          <Modal className="modal-dialog" isOpen={modal} toggle={() => setModal(false)} size="lg">
            <ModalHeader toggle={() => setModal(false)}><h5 className='mb-3'>Select Consultant</h5></ModalHeader>
            <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>
              <form className="needs-validation" noValidate="">
                <div className="form-row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="id_kabkota">Pilih Consultant<span className='text-danger'>*</span> </label>
                    <select className="form-control" onChange={(e) => setCodeConsultant(e.target.value)}  type="text" value={codeConsultant}>
                      <option value="0">--Pilih Consultant--</option>
                      {listConsultant.map((data)=>(
                        <option key={data.code_konsultan} value={data.code_konsultan}>{data.nama}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => handleSubmit()} >Save</Button>
              <Button color="secondary" onClick={() => setModal(false)}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )
      }
  
    const columns = [
        {
            Header: 'Code',
            accessor: 'code_students',
            style: {textAlign: 'center'},
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["code_students"] }),
            filterAll: true
        },
        {
            Header: 'Name',
            accessor: 'nama',
            style: {textAlign: 'center'},
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["nama"] }),
            filterAll: true
        },
        {
            Header: 'Email',
            accessor: 'email',
            style: {textAlign: 'center'},
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["email"] }),
            filterAll: true
        },
        {
            Header: 'Phone',
            accessor: 'no_hp',
            style: {textAlign: 'center'},
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["no_hp"] }),
            filterAll: true
        },
        {
            Header: 'Action',
            accessor: 'action',
            style: {textAlign: 'center'},
            filterable: false,
            Cell:(row) => (
                <button onClick={() => setConsultant(row.original)} className="btn btn-outline-success btn-xs" type="button" style={{border:'none'}}><i className="fa fa-pencil" style={{ fontSize: 16}}></i></button>
            )
        },
    ]

    return(
        <>
            <Breadcrumb title='Students Ambassador' />
            <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body datatable-react">
                                        <ReactTable
                                            data={data}
                                            columns={columns}
                                            filterable
                                            className="-striped -highlight"
                                            showPaginationBottom={false}
                                            showPageSizeOptions={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {renderModalEditConsultant()}
        </>
    );
}

export default LeadsStudentAmbassador;