import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import logo from '../assets/images/logo/Logo_NIEC_large.png';
import man from '../assets/images/dashboard/user.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { withRouter } from "react-router";

import {loginUserAPI} from '../actions/login.action';
import { Link } from 'react-router-dom';

import authVideo from '../assets/video/walpaper.jpg';
import comingsoon from '../assets/images/other-images/coming-soon-bg.jpg';

const Signin = ({ dataUser, loginStatus, loginUserAPI, history }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnTextSubmit, setBtnTextSubmit] = useState('Login');

    const [value] = useState(
        localStorage.getItem('profileURL' || man)
    );

    useEffect(() => {
        if (value !== null)
            localStorage.setItem('profileURL', value);
        else
            localStorage.setItem('profileURL', man);
    }, [value]);

    const loginAuth = (e) => {
        e.preventDefault()

        setBtnDisabled(true);
        setBtnTextSubmit('Sending ...');

        var formData = new FormData();
        formData.append('email',email);
        formData.append('password',password);

        const res = loginUserAPI(formData)
        .then((res) => {
            if(res.loggedIn) {
                setBtnDisabled(false);
                setBtnTextSubmit('Login');
                if (res.userable_type == 'App\\Models\\Consultant') {
                    window.location.href = `${process.env.PUBLIC_URL}/pages/otpPwd`;
                } else {
                    if(res.roles ==='RL008') {
                        window.location.href = `${process.env.PUBLIC_URL}/pages/students/consultant`;
                    } else {
                        window.location.href = `${process.env.PUBLIC_URL}/`;
                    }
                }
            } else {
                setTimeout(() => {
                    toast.error("Oppss...");
                }, 200);
            }
        })
        .catch(function(error){
            setTimeout(() => {
                toast.error("Oppss.. The password is invalid or the user does not have a password.");
            }, 200);
        })
    }

    return (
        <div>
            <div className="page-wrapper">
                <div className="auth-bg-video">
                        <video id="bgvid" poster={authVideo} playsInline="" autoPlay={true} muted="true" loop={true}>
                            {/* <source src={authVideo} type="video/mp4" /> */}
                        </video>
                    <div className="container-fluid p-0">
                        <div className="">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">
                                            <div className="text-center">
                                                <img width='100' src={logo} alt="" /></div>
                                            <div className="card mt-4">
                                                <div className="card-body">
                                                    <div className="text-center">
                                                        <h4>LOGIN</h4>
                                                        <h6>Enter your Email and Password </h6>
                                                    </div>
                                                    <form onSubmit={(e) => loginAuth(e)} className="theme-form" >
                                                        <div className="form-group">
                                                            <label className="col-form-label pt-0">Your Email</label>
                                                            <input className="form-control" type="email" name="email"
                                                                value={email}
                                                                onChange={e => setEmail(e.target.value)}
                                                                placeholder="Email address"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="col-form-label">Password</label>
                                                            <input className="form-control" type="password" name="password"
                                                                value={password}
                                                                onChange={e => setPassword(e.target.value)} />

                                                        </div>
                                                        <div className="form-group form-row mt-3 mb-0">
                                                            <button disabled={btnDisabled} className="btn btn-primary btn-block" type="submit">{btnTextSubmit}</button>
                                                        </div>
                                                        <div className="form-group text-center">
                                                            <div className="text-center mt-2 m-l-20">Lost your password?  
                                                                <Link className="btn-link text-capitalize" to={`${process.env.PUBLIC_URL}/pages/forgetPwd`}>
                                                                    Reset     
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ToastContainer />
                    </div>
                </div>
            </div>
        </div>
    );
};


const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
})
  

const reduxDispatch = { loginUserAPI };
  
export default connect(reduxState, reduxDispatch)(withRouter(Signin));