import React, { Component,Fragment } from 'react'
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTablesPotential from './dataTablesPotential';

class UniversityPotential extends Component {

    render(){
        return(
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row m-1">
                            <div className='col-md-12'>
                                <DataTablesPotential id_event={this.props.match.params.idEvents} />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default UniversityPotential;