import React, { Component } from 'react'
import JSZip from "jszip";
import { saveAs } from "file-saver";

class ZipDownloadSimBiaya extends Component {

    constructor(props){
      super(props)
      this.state = {
        downloadText:'Download All',
        disabledBtn:false
      }
    }
    
    download = (urls,name) =>{
        this.setState({
          downloadText:'Downloading...',
          disabledBtn:true
        })

        const zip = new JSZip();
        const folder = zip.folder(name)
        const zipFilename = name+".zip";

        urls.forEach((url)=> {
            const blobPromise =  fetch(url)  
            .then(function (response) {            
                return Promise.resolve(response.blob());
            })
            .catch(error => {
                return Promise.reject(new Error(error.statusText));
            }) 

            const name = url.substring(url.lastIndexOf('/'))
            folder.file(name, blobPromise)
        })
       
        zip.generateAsync({type:"blob"})
        .then(blob => {
            this.setState({
                downloadText:'Download All',
                disabledBtn:false
            })
            saveAs(blob, zipFilename)
        })
        .catch(e =>{
            this.setState({
                downloadText:'Download All',
                disabledBtn:false
            })
            console.log(e)
        })
    }

    render(){
        return (
            <button disabled={this.state.disabledBtn} className='btn btn-success' onClick={(e) => this.download(this.props.urlDownload, this.props.name)}>
                {this.state.downloadText}
            </button>
        );
    }

}

export default ZipDownloadSimBiaya;