import React, { Component } from 'react';
import CKEditors from "react-ckeditor-component";
import { connect } from 'react-redux';
import API_URL from '../../../ApiUrl';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { updataDataUserable } from '../../../actions/login.action'

class ProfileUniversity extends Component{

    constructor(props) {
        super(props);
        this.state = {
            code_users: '',
            id_users:'',
            id_school_rep:'',
            about: '',
            name:'',
            website:'',
            email:'',
            phone:'',
            postal_code:'',
            country:'',
            province:'',
            city:'',
            address:'',
            provider_id:'',
            application_fee:0,
            living_cost:0,
            dataCountry:[],
            providerType:[],
            btnUpdate: 'Update university data',
            disabledBtn: false,
            errors:[]
        }
    }

    componentDidMount(){
        this.setUniversityData()
        this.getCountry()
        this.loadProviderType()
    }

    onChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            about: newContent
        })
    }

    loadProviderType = () => {
        axios.get(API_URL.superStudent+'/master/provider-type',{
            headers:{
                Authorization: `Bearer `+this.props.dataToken
            }
        })
        .then(response => this.setState({providerType:response.data.data}))
        .catch(error => console.log(error))
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
 
    getCountry = async () => {
        await axios.get(API_URL.superStudent+'/master/country')
        .then(response => response.data.data)
        .then(result => {
            this.setState({ dataCountry: result })
        })
    }

    setUniversityData = () => {
        let result = this.props.userableData.userable
            this.setState({
                code_users: this.props.dataUsers.code_users,
                id_users: this.props.userableData.id,
                id_school_rep: result.id || '',
                about: result.about || '',
                name: result.name || '',
                website: result.website || '',
                email: result.email || '',
                phone: result.phone || '',
                postal_code: result.zip_code || '',
                country: result.country.code || '',
                province: result.province || '',
                city: result.city || '',
                address: result.address || '',
                provider_id: result.provider_id ||'',
                application_fee: result.application_fee || 0,
                living_cost: result.living_cost || 0
            })
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        var formData = new FormData();
        formData.append('name', this.state.name);
        formData.append('country', this.state.country);
        formData.append('city', this.state.city);
        formData.append('province', this.state.province);
        formData.append('address', this.state.address);
        formData.append('website', this.state.website);
        formData.append('email', this.state.email);
        formData.append('phone', this.state.phone);
        formData.append('postal_code', this.state.postal_code);
        formData.append('provider_id',this.state.provider_id)
        formData.append('application_fee', this.state.application_fee)
        formData.append('living_cost', this.state.living_cost)
        formData.append('about', this.state.about);
        formData.append('_method','PATCH');
        this.setState({
            btnUpdate: 'Updating...',
            disabledBtn:true
        })
        await this.props.updateUniversity(formData, this.state.id_school_rep ,this.props.dataToken)
        .then(result => {
            this.setState({
                btnUpdate: 'Update university data',
                disabledBtn:false
            })
            toast.success("University data changed successfully...");
        })
        .catch(error => {
            if(error.response.status === 422){
                this.setState({
                    btnUpdate: 'Update university data',
                    disabledBtn:false,
                    errors:error.response.data.data
                })
            }
            toast.error("There is an error...");
        })
    }

    
    render(){
        return (
            <>
            <form onSubmit={this.handleSubmit} className="card">
                <div className="card-header">
                    <h4 className="card-title mb-0">Profile University</h4>
                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label className='f-w-600'>University name<span className="text-danger">*</span></label>
                                <input className="form-control" onChange={this.handleInput} value={this.state.name} name="name" type="text" placeholder="example" />
                                <div className="text-danger">{this.state.errors.name}</div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label className='f-w-600'>Website</label>
                                <input className="form-control" name='website' value={this.state.website} onChange={this.handleInput} type="text" placeholder="http://example.com" />
                            </div>
                        </div> 
                        <div className="col-md-5 col-sm-12">
                            <div className="form-group">
                                <label className='f-w-600'>Email<span className="text-danger">*</span></label>
                                <input className="form-control" type="email" name="email" value={this.state.email} onChange={this.handleInput} placeholder="example@university.com" />
                                <div className="text-danger">{this.state.errors.name}</div>
                            </div>
                        </div> 
                        <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                                <label className='f-w-600'>Phone</label>
                                <input className="form-control" name='phone' value={this.state.phone} onChange={this.handleInput} type="text" placeholder="(021) 8080 8080" />
                            </div>
                        </div> 
                        <div className="col-sm-4 col-md-3">
                            <div className="form-group">
                                <label className='f-w-600'>Postal Code</label>
                                <input className="form-control" name='postal_code' value={this.state.postal_code} onChange={this.handleInput} type="number" placeholder="123456" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label className='f-w-600'>Country<span className="text-danger">*</span></label>
                                <select name='country' value={this.state.country} onChange={this.handleInput} className="form-control btn-square">
                                    <option value="0">--Select Country--</option>
                                    {
                                        this.state.dataCountry.length > 0 ?
                                        this.state.dataCountry.map(country => {
                                            return <option key={country.code} value={country.code}>{country.name}</option>
                                        })
                                        :
                                        ''
                                    }
                                </select>
                                <div className="text-danger">{this.state.errors.name}</div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                                <label className='f-w-600'>Province</label>
                                <input className="form-control" name='province' value={this.state.province} onChange={this.handleInput} type="text" placeholder="Bali" />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                                <label className='f-w-600'>City</label>
                                <input className="form-control" name='city' value={this.state.city} onChange={this.handleInput} type="text" placeholder="Denpasar" />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                <label className='f-w-600'>Address</label>
                                <input className="form-control" name='address' value={this.state.address} onChange={this.handleInput} type="text" placeholder="Pemecutan Kaja" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label className='f-w-600'>Provider Type</label>
                                <select name='provider_id' value={this.state.provider_id} onChange={this.handleInput} className='form-control'>
                                    <option key={0} value=''>--Select</option>
                                    {
                                    this.state.providerType.length > 0 ?
                                    this.state.providerType.map(item => (
                                        <option key={item.id} value={item.id}>{item.provider_name}</option>
                                    ))
                                    :
                                    ''
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label className='f-w-600'>Application Fee</label>
                                <input className="form-control" name='application_fee' value={this.state.application_fee} onChange={this.handleInput} type="text" placeholder="100" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label className='f-w-600'>Living Cost</label>
                                <input className="form-control" name='living_cost' value={this.state.living_cost} onChange={this.handleInput} type="text" placeholder="10" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group mb-0">
                                <label className='f-w-600'>About University</label>
                                <CKEditors
                                    activeclassName="p10"
                                    content={this.state.about}
                                    events={{
                                            "change": this.onChange.bind(this),
                                    }}
                                    config={{
                                    toolbar:[
                                                ["Bold","Italic",'Strike','RemoveFormat'],
                                                ['NumberedList','BulletedList','Indent','Outdent'],
                                                ['Link','Unlink'],
                                                ['Styles','Format','Table','Blockquote','Maximize'],
                                                ["Cut","Copy","Paste","Pasteasplaintext","Undo","Redo"]
                                            ]
                                        }}     
                                />
                            </div>
                        </div>
                    </div>
                </div>
            <div className="card-footer text-right">
                <button disabled={this.state.disabledBtn} className="btn btn-primary btn-pill btn-air-info" type="submit">{this.state.btnUpdate}</button>
            </div>
        </form>
        <ToastContainer/>
        </>
        )
    }
}

const reduxState = (state) => ({
    dataUsers: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
    userableData: state.Login.userableData
})

const reduxDispatch = (dispatch) => ({
    updateUniversity:(data,id,headers) => dispatch(updataDataUserable(data,id,headers))
})

export default connect(reduxState, reduxDispatch) (ProfileUniversity);