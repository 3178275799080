import React, { Fragment, Component } from 'react';
import DataTableCurrency from '../common/datatable/datatableCurrency';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { resettoken } from '../../actions/login.action';

class DataTablesCurrency extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listCurrency: [],
            listMataUang: [],
            listCountry: [],
        }
    }

    componentDidMount() {
        this.getDataCurrency();
        this.getMataUang();
        this.getCountry();
    }

    getDataCurrency(){
        const url = API_URL.superStudent+'/finance/currency-all';
        axios.get(url, 
        { 
            headers: { 
                'Accept': 'application/json', 
                'Authorization': `Bearer ${this.props.dataToken}` 
            } 
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({listCurrency:data});
        });
    }

    getMataUang(){
        const url = API_URL.superStudent+'/master/mata-uang';
        axios.get(url, { headers: { 'Accept': 'application/json','Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data.data)
        .then((data) => {
            if(data.code === 400) {
                const config = {
                    headers: { Authorization: `Bearer `+this.props.dataToken }
                };
                var formData = new FormData();
                formData.append('id_users',this.props.dataId);
                formData.append('api_token',this.props.dataToken);
                this.props.resettoken(formData, config);
            }else{
            this.setState({listMataUang:data});
            }
        });
    }

    getCountry(){
        const url = API_URL.superStudent+'/master/country';
          axios.get(url, { headers: { 'Accept': 'application/json','Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data.data)
          .then((data) => {
              if(data.code === 400) {
                    const config = {
                        headers: { Authorization: `Bearer `+this.props.dataToken }
                    };
                    var formData = new FormData();
                    formData.append('id_users',this.props.dataId);
                    formData.append('api_token',this.props.dataToken);
                    this.props.resettoken(formData, config);
              }else{
                this.setState({listCountry:data});
              }
          });
    }

    render() {
        const data = this.state.listCurrency;
        const dataMataUang = this.state.listMataUang; 
        const dataCountry = this.state.listCountry; 

        const pgsize = data.length;
        
        return(
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body datatable-react">
                                    <DataTableCurrency
                                        multiSelectOption={false}
                                        myData={data}
                                        mataUang={dataMataUang}
                                        country={dataCountry}
                                        title="Currency Data"
                                        pageSize={pgsize}
                                        pagination={true}
                                        class="-striped -highlight"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
    dataId: state.Login.detailUser.id_users,
})

const reduxDispatch = (dispatch) => ({
    resettoken: (data, headers) => dispatch(resettoken(data, headers))
})

export default connect(reduxState, reduxDispatch)(withRouter(DataTablesCurrency));