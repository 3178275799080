import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import ReactTable from 'react-table';
import API_URL from '../../../../ApiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Moment from 'moment';

class VisaData extends Component {    
  constructor(props) {
    super(props);
    this.state = {
      dataVisaData: [],
      code_students: "",
      modal: false,
      modalDelete:false,
      id_students_visa: '',
      date_visa_expired: '',
      date_visa_granted: '',
      notes: '',
      operation: '',
      btnDisabled: false,
    }
  }

  addVisa() {
    this.setState({
      operation: 'add',
      modal: true,
      btnDisabled: false,
    })
  }

  editVisa(row) {
    this.setState({
      modal: true,
      operation:'update',
      date_visa_expired: row.date_visa_expired,
      date_visa_granted: row.date_visa_granted,
      notes: row.notes,
      id_students_visa: row.id_students_visa,
      btnDisabled: false,
    })
  }

  deleteRow(row) {
    this.setState({
      modalDelete: true,
      id_students_visa: row.id_students_visa,
      btnDisabled: false,
    })
  }

  closeModal(){
    this.setState({
      modal: false,
      modalDelete: false,
    });
    this.resetState();
  }

  resetState(){
    this.setState({
      id_students_visa: '',
      date_visa_expired: '',
      date_visa_granted: '',
      notes: '',
    })
  }

  sendData() {
    this.setState({
      btnDisabled: true
    });

    var formData = new FormData();
    formData.append('code_students',this.props.codeStudents);
    formData.append('date_visa_expired',this.state.date_visa_expired);
    formData.append('date_visa_granted',this.state.date_visa_granted);
    formData.append('notes',this.state.notes);

    let url = '/visa'

    if (this.state.operation === 'update'){
      formData.append('_method','PATCH');
      url = '/visa/'+this.state.id_students_visa
    }

    axios({
      method: 'POST',
      url: API_URL.superStudent+url,
      data: formData,
      headers: {  Authorization: `Bearer ${this.props.dataToken}`  }
    })
    .then(() => {
      toast.success('Data Update Successfully')
      this.closeModal();
      this.props.onReloadVisa();
    })
  }

  saveDeleteRow() {
    axios.delete(API_URL.superStudent+'/visa/'+this.state.id_students_visa,{
      headers: {  Authorization: `Bearer ${this.props.dataToken}`  }
    })
    .then(() => {
      toast.success('Data Update Successfully')
      this.closeModal();
      this.props.onReloadVisa();
    })
    .catch(error =>console.log(error))
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

    renderEditDialog() {
        return(
          <Modal className="modal-dialog" isOpen={this.state.modal} toggle={() => this.closeModal()} size="lg">
            <ModalHeader toggle={() => this.closeModal()}>Form Visa</ModalHeader>
            <ModalBody style={{
                  maxHeight: 'calc(100vh - 210px)',
                  overflowY: 'auto'
              }}>
              <form className="needs-validation"  >
                <div className="form-row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="date_visa_expired">Tanggal Expired</label>
                    <input className='form-control' type='date' name='date_visa_expired' value={this.state.date_visa_expired} onChange={this.handleInput} />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-12 mb-3">
                      <label htmlFor="date_visa_granted">Tanggal Granted</label>
                      <input className='form-control' type='date' name='date_visa_granted' value={this.state.date_visa_granted} onChange={this.handleInput} />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="notes">Catatan</label>
                    <input className="form-control" name="notes" type="text"  value={this.state.notes} onChange={this.handleInput}/>
                  </div>
                </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>Save Changes</Button>
              <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )
    }

    renderDeleteDialog() {
        return (
          <Modal className="modal-dialog" isOpen={this.state.modalDelete} toggle={() => this.closeModal()} size="lg">
            <ModalHeader toggle={() => this.closeModal()}>Delete data</ModalHeader>
              <ModalBody style={{
                  maxHeight: 'calc(100vh - 210px)',
                  overflowY: 'auto'
              }}>
                Delete this data ?
              </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => this.saveDeleteRow()}>Save Changes</Button>
              <Button color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
            </ModalFooter>
          </Modal>
      )
    }

    render(){
        const columns = [
            {
              Header: 'Code',
              accessor: 'code_students_visa',
              style: {textAlign: 'center'},
            },
            {
              Header: 'Granted Date',
              accessor: 'date_visa_granted',
              Cell: (row) => (
                <span>{Moment(row.original.date_visa_granted).format('DD/MM/yyyy')}</span>
              ),
              style: {textAlign: 'center'},
              filterable: false
            },
            {
              Header: 'Expired Date',
              accessor: 'date_visa_expired',
              style: {textAlign: 'center'},
              filterable: false,
              Cell: (row) => (
                <span>{Moment(row.original.date_visa_expired).format('DD/MM/yyyy')}</span>
              ),
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div  style={{display: this.props.display}}>                   
                      <button onClick={() => this.deleteRow(row.original)} className="btn btn-danger btn-xs" type="button" ><i className="fa fa-trash"></i></button> &nbsp;
                      <button onClick={() => this.editVisa(row.original)} className="btn btn-primary btn-xs" type="button" style={{border:'none', display: this.state.display}} ><i className="fa fa-pencil"></i></button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                width:150,
                sortable: false,
                filterable: false
            }
          ]
        return (
            <Fragment>
                <div>
                    <div className="card">
                        <div className="card-header bg-red-color">
                            <div className="row">
                                <div className="col-10">
                                <h5 style={{fontSize:15}}>Visa</h5>
                                </div>
                                  <div className="col-2">
                                      <button onClick={() => this.addVisa()} className="btn btn-light btn-xs" type="button" style={{marginLeft: 'auto', border:'none', display: this.props.display}} ><i className="fa fa-plus color-red"></i></button>
                                  </div>
                            </div>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <ReactTable
                                        data={this.props.data}
                                        columns={columns}
                                        defaultPageSize={5}
                                        className="-striped -highlight"
                                        showPaginationBottom={false}
                                        style={{
                                          height: "300px"
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderEditDialog()}
                {this.renderDeleteDialog()}
            </Fragment>
        );
    }
}

const reduxState = (state) => ({
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
  })
  
  export default connect(reduxState, null)(withRouter(VisaData));