import React, { Component, Fragment } from 'react'
import ReactTable from 'react-table';
import {matchSorter} from 'match-sorter';

export class DataTableTraining extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filtered:[],
        }
    }
    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
  
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
  
              insertNewFilter = 0;
            }
          });
        }
        if (insertNewFilter) {
          filtered.push({ id: accessor, value: value });
        }
        this.setState({ filtered: filtered });
    }

  render() {
    const {myData} = this.props
    const columns = [
        {
          Header: 'Training Category',
          accessor: 'category',
          style: {textAlign: 'center'},
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["category"] }),
            filterAll: true
        },
        {
          Header: <b>#</b>,
          id: 'action',
          accessor: str => "action",
          width:90,
          filterable: false,
          Cell: (row) => (
              <>
                  {
                  <>
                    {/* <button style={{border:'none', fontSize: 15 }} onClick={() => this.props.editSubTraining(row.original)} className="btn btn-outline-success btn-xs" id="TooltipTop" data-placement="top"><i className='fa fa-edit'></i></button> */}
                    <button style={{border:'none', fontSize: 15 }} onClick={() => this.props.addSubTraining(row.original)} className="btn btn-outline-primary btn-xs" id="TooltipTop" data-placement="top"><i className='fa fa-plus'></i></button>
                  </>
                  }
              </>
          ),
          style: { textAlign: 'center' }
      }
    ]
    return (
        <Fragment>
            <ReactTable
            data={myData}
            filtered={this.state.filtered}
            columns={columns}
            pageSize={15}
            filterable
            showPagination={true}
            onFilteredChange={(filtered, column, value) => {
                this.onFilteredChangeCustom(value, column.id || column.accessor);
            }}
            defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value}
            SubComponent={row => {
                return (
                <div className="p-5">
                    <div className="table-responsive">
                        <table className="table">
                        <tbody>
                            {row.original.sub_training.map((m, i)=>{
                                return (
                                  <tr key={i}>
                                      <td>{m.sub_category}</td>
                                      <td align='right'><button style={{border:'none', fontSize: 15 }} onClick={() => this.props.editSubTraining(m)} className="btn btn-outline-success btn-xs" id="TooltipTop" data-placement="top"><i className='fa fa-pencil'></i></button></td>
                                  </tr>
                                )
                            })}
                        </tbody>
                        </table>
                    </div>
                </div>
                )
            }}
            />
      </Fragment>
    )
  }
}

export default DataTableTraining