import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { DollarSign, Settings } from 'react-feather';
// import { Tabs as ReactTabs, TabList as ReactTablist } from 'react-tabs';
import { connect } from 'react-redux';
import { withRouter } from "react-router";

import { Switch, Route, Redirect} from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';

import "./styles/react-router-tabs.css";

const SimulasiBiaya = (dataUser) => {
    const roles = dataUser.dataUser.code_roles
    return (
        <Fragment>
            <Breadcrumb title="Finance" parent="Finance" />
            <div className="container-fluid">
                <div>
                  <button onClick={() => this.addRow()} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} >
                    <div>
                      <span>
                        {/* <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i> */}
                      </span>
                    </div>
                  </button>
                </div>
                <div className="row theme-tab">
                    <div className="col-sm-12">
                        <RoutedTabs
                            tabClassName="tab-link"
                            activeTabClassName="active">
                            <div className="tabs tab-title">
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/simulasibiaya/currency`}>
                                    <DollarSign /> Currency
                                </NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/simulasibiaya/tipepembayaran`}>
                                    <Settings /> Tipe Pembayaran
                                </NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/simulasibiaya/detailpembayaran`}>
                                    <Settings /> Detail Pembayaran
                                </NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/simulasibiaya/simbiayasetting`}>
                                    <Settings /> Simulasi Pembayaran
                                </NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/simulasibiaya/simbiayastorage`}>
                                    <Settings /> Storage
                                </NavTab>
                            </div>
                        </RoutedTabs>
                        <Switch>
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/pages/simulasibiaya/`}
                                render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/simulasibiaya/simbiayastorage`} />}
                            />
                        </Switch>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

// export default Students;
const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
})
  
export default connect(reduxState, null)(withRouter(SimulasiBiaya));