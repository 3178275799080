import React from "react";

const BadgeBeta = () => {

    return(
        <>
            <span style={{fontSize:'8px'}} className='badge badge-secondary'>BETA</span>
        </>
    )
}

export default BadgeBeta;