import React, { Fragment, Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Container, Form } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../../components/common/loader';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Moment from 'moment';

class BisdevTipe extends Component {

    constructor(props) {
        super(props);
        this.state = {
            btnDisabled: false,
            operation: "",
            modal: false,
            modal_date: false,
            modal_attandance: false,
            modal_nilai: false,
            modal_edit_att: false,
            modal_edit_nilai: false,
            modal_show_nilai: false,
            modal_delete_nilai: false,
            errors: {},
            row: [],
            row_att: [],
            row_nilai: [],
            dataGrup: [],
            dateAttandance: [],
            dataNilai: [],
            dataRegNilai: [],
            margin: "",
            start_date: '',
            end_date: '',
            date: "",
            status_kehadiran: "1",
            kode_nilai: "NL0001",
            total_nilai: "",
            kode_reg_nilai: ""
        }
    }

    componentDidMount() {
        this.getDataEnglishGrup();
        this.getDataJenisNilai();
    }

    getDataEnglishGrup(){
        let formData = new FormData()
        formData.append('start_date', this.state.start_date)
        formData.append('end_date', this.state.end_date)

        const url = API_URL.superStudent+'/english-course/group-english-course';
        axios.post(url, formData, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({ dataGrup: data })
        })
    }
    
    getDataJenisNilai() {
        const url = API_URL.superStudent+'/english-course/nilai-english-course';
        axios.get(url, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({ dataNilai: data })
        })
    }

    getDataAttandanceByKode(kode_register) {
        let formData = new FormData()
        formData.append('kode_register', kode_register);

        const url = API_URL.superStudent+'/english-course/attandance-english-course';
        axios.post(url, formData, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({ dateAttandance: data })
        })
    }

    getDataNilaiByKode(kode_register) {
        let formData = new FormData();
        formData.append('kode_register', kode_register);

        const url = API_URL.superStudent+'/english-course/reg-nilai-english-course';
        axios.post(url, formData, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({ dataRegNilai: data })
        })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    closeDialog() {
        this.setState({
            modal: false,
            modal_date: false,
            modal_attandance: false,
        })
    }

    closeDialogEditAtt() {
        this.setState({
            modal_edit_att: false
        })
    }

    closeDialogEditNilai() {
        this.setState({
            modal_edit_nilai: false,
            modal_delete_nilai: false,
        })
    }

    editRow(row) {
        this.setState({
            operation: 'edit',
            modal: true,
            row: row
        })
    }

    attandanceRow(row) {
        this.setState({
            modal_attandance: true,
            row: row
        }, () => {
            this.getDataAttandanceByKode(this.state.row.kode_register);
        })
    }

    nilaiRow(row) {
        this.setState({
            modal_show_nilai: true,
            row: row
        }, () => {
            this.getDataNilaiByKode(this.state.row.kode_register);
        })
    }

    addRowRegNilai() {
        this.setState({
            modal_edit_nilai: true,
            operation: 'add'
        })
    }

    editRowNilai(row) {
        this.setState({
            modal_edit_nilai: true,
            operation: 'edit',
            kode_reg_nilai: row.kode_reg_nilai,
            kode_nilai: row.kode_nilai,
            total_nilai: row.total_nilai
        })
    }

    deleteRowNilai(row) {
        this.setState({
            modal_delete_nilai: true,
            operation: 'delete',
            kode_reg_nilai: row.kode_reg_nilai,
            kode_nilai: row.code_ket_nilai,
            total_nilai: row.total_nilai
        })
    }

    editAttandance(row) {
        this.setState({
            modal_edit_att: true,
            row_att: row
        })
    }

    editDate(row) {
        this.setState({
            modal_date: true,
            row: row
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        }, () => {
            this.setRangeDate()
        })
    }

    setRangeDate() {   
        let start_date = Moment();
        let end_date  = Moment();
        switch(this.state.date) {
            case 'all_data':
                start_date = ''
                end_date = ''
                break;
            case 'last_week':
                start_date.subtract(1, 'weeks').startOf('isoWeek')
                end_date.subtract(1, 'weeks').endOf('isoWeek')
                break;
            case 'this_month':
                start_date.startOf('month')
                end_date.endOf('month')
                break;
            case 'last_month':
                start_date.subtract(1, 'months').startOf('month')
                end_date.subtract(1, 'months').endOf('month')
                break;
            case 'this_year':
                start_date.startOf('year').add(-6,'months')
                end_date.endOf('year').add(-6,'months')
                break;
            case 'last_year':
                start_date.subtract(1, 'year').startOf('year').add(-6,'months')
                end_date.subtract(1, 'year').endOf('year').add(-6,'months')
                break;
            case '1':
            case '2':
            case '3':
            case '4':
                start_date.quarter(this.state.date).add(2, 'quarter').startOf('quarter')
                end_date.quarter(this.state.date).add(2, 'quarter').endOf('quarter')
                break;
            default:
                start_date.startOf('isoWeek')
                end_date.endOf('isoWeek')
        }
        
        this.setState({ 
            start_date: start_date !== '' ? Moment(start_date).format('YYYY-MM-DD') : '',
            end_date: end_date !== '' ? Moment(end_date).format('YYYY-MM-DD') : ''
        }, () => {
            this.getDataEnglishGrup()
        })
    }

    sendDataDate() {
        this.setState({
            btnDisabled: true
        });

        let formData = new FormData();
        formData.append('code_grup', this.state.row.code_register_grup);
        formData.append('start_date', this.state.start_date);
        formData.append('end_date', this.state.end_date);
        formData.append('register', JSON.stringify(this.state.row.register));

        axios.post(API_URL.superStudent+'/english-course/update-start-date',formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            this.closeDialog();
            toast.success("Update Sucsessfully");
            this.setState({
                btnDisabled: false
            }, () => {
                this.getDataEnglishGrup();
            })
        }).catch(err => {
            this.closeDialog();
            toast.error("Update Failed");
        });
    }

    sendDataEditAtt() {
        this.setState({
            btnDisabled: true
        });
        
        let formData = new FormData();
        formData.append('kode_reg_att', this.state.row_att.code_reg_attandance);
        formData.append('status', this.state.status_kehadiran);

        axios.post(API_URL.superStudent+'/english-course/update-attandance',formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            this.closeDialogEditAtt();
            toast.success("Update Sucsessfully");
            this.setState({
                btnDisabled: false
            }, () => {
                this.getDataAttandanceByKode(this.state.row.kode_register);
            })
        }).catch(err => {
            this.closeDialogEditAtt();
            toast.error("Update Failed");
        });
    }

    sendDataEditNilai() {
        let formData = new FormData();
        formData.append('kode_reg_nilai', this.state.kode_reg_nilai);
        formData.append('kode_register', this.state.row.kode_register);
        formData.append('kode_nilai', this.state.kode_nilai);
        formData.append('total_nilai', this.state.total_nilai);

        let url = '/english-course/create-reg-nilai-english-course';

        if (this.state.operation == 'edit') {
            url = '/english-course/update-reg-nilai-english-course';
        } else if (this.state.operation == 'delete') {
            url = '/english-course/delete-reg-nilai-english-course';
        } 

        axios.post(API_URL.superStudent+url, formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            this.closeDialogEditNilai();
            toast.success("Update Sucsessfully");
            this.setState({
                btnDisabled: false
            }, () => {
                this.getDataNilaiByKode(this.state.row.kode_register);
            })
        }).catch(err => {
            this.closeDialogEditNilai();
            toast.error("Update Failed");
        });
    }

    renderDialogDate() {
        const { modal_date } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal_date} toggle={() => this.setState({ modal_date: false })}>
                    <ModalHeader toggle={() => this.setState({ modal_date: false })}>{this.state.row.code_register_grup}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Start Date</label>
                                    <input className="form-control" name="start_date" type="date" id="start_date" onChange={(e) => this.handleInput(e)} value={this.state.start_date} />
                                    <span>{this.state.errors.start_date}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">End Date</label>
                                    <input className="form-control" name="end_date" type="date" id="end_date" onChange={(e) => this.handleInput(e)} value={this.state.end_date} />
                                    <span>{this.state.errors.end_date}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendDataDate()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDialogAttandance() {
        const { modal_attandance } = this.state;

        const columns_att = [
            {
                Header: 'Pertemuan',
                accessor: 'attandance_name',
                filterable: false
            },
            {
                Header: 'Status',
                accessor: 'status_attandance',
                filterable: false,
                Cell: (row) => (
                    <div>
                        {
                            row.original.status_attandance == 1 ?
                            'Hadir'
                            :
                            row.original.status_attandance == 2 ?
                            'Tidak Hadir'
                            :
                            ''
                        }
                    </div>
                )
            },
            {
                Header: <b>Action</b>,
                id: 'update',
                filterable: false,
                accessor: str => "update",
                Cell: (row) => (
                    <div>
                        <div className='row justify-content-center'>
                            <div className='col-lg-3'>
                                <button onClick={() => this.editAttandance(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 30, fontSize: 16, padding: 5, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                            </div>
                        </div>
                    </div>
                )
            },
        ]

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal_attandance} toggle={() => this.setState({ modal_attandance: false })}>
                    <ModalHeader toggle={() => this.setState({ modal_attandance: false })}>{this.state.row.nama_lengkap}</ModalHeader>
                    <ModalBody>
                        <ReactTable
                            data={this.state.dateAttandance}
                            columns={columns_att}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}
                            defaultPageSize={20}
                            className="-striped -highlight"
                        />
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    renderDialogEditAtt() {
        const { modal_edit_att } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal_edit_att} toggle={() => this.setState({ modal_edit_att: false })}>
                    <ModalHeader toggle={() => this.setState({ modal_edit_att: false })}>{this.state.row_att.attandance_name}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Status Kehadiran</label>
                                    <select name="status_kehadiran" className='form-control' onChange={this.handleInput}>
                                        <option value="1" >Hadir</option>
                                        <option value="2" >Tidak Hadir</option>
                                    </select>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendDataEditAtt()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialogEditAtt()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

    renderDialogShowNilai() {
        const { modal_show_nilai } = this.state;

        const columns = [
            {
                Header: 'Nilai',
                accessor: 'nilai.keterangan_nilai',
                filterable: false
            },
            {
                Header: 'Keterangan Nilai',
                accessor: 'total_nilai',
                filterable: false
            },
            {
                Header: <b>Action</b>,
                id: 'update',
                accessor: str => "update",
                Cell: (row) => (
                    <div>
                        <div className='row justify-content-center'>
                            <div className='col-lg-3'>
                                <button onClick={() => this.editRowNilai(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 30, fontSize: 16, padding: 5, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                            </div>
                            <div className='col-lg-3'>
                                <button onClick={() => this.deleteRowNilai(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash" style={{ width: 30, fontSize: 16, padding: 5, color: 'red' }}></i>{row.id}</button>
                            </div>
                        </div>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal_show_nilai} toggle={() => this.setState({ modal_show_nilai: false })}>
                    <ModalHeader toggle={() => this.setState({ modal_show_nilai: false })}>{this.state.row.nama_lengkap}</ModalHeader>
                    <ModalBody>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <button onClick={() => this.addRowRegNilai()} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} >
                                    <div>
                                        <span>
                                            <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i>
                                        </span>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <ReactTable
                            data={this.state.dataRegNilai}
                            columns={columns}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}
                            defaultPageSize={20}
                            className="-striped -highlight" />
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendDataEditNilai()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialogEditNilai()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

    renderDialogEditNilai() {
        const { modal_edit_nilai } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal_edit_nilai} toggle={() => this.setState({ modal_edit_nilai: false })}>
                    <ModalHeader toggle={() => this.setState({ modal_edit_nilai: false })}>{this.state.row.nama_lengkap}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Keterangan Nilai</label>
                                    <select name="kode_nilai" className='form-control' onChange={this.handleInput} value={this.state.kode_nilai}>
                                        {
                                            this.state.dataNilai.map((nilai) => (
                                                <option value={nilai.code_ket_nilai}>{nilai.keterangan_nilai}</option>
                                            ))
                                        }
                                    </select>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Total Nilai</label>
                                    <input className='form-control' name="total_nilai" type="number" onChange={this.handleChange} value={this.state.total_nilai} />
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendDataEditNilai()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialogEditNilai()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

    renderDialogDeleteNilai() {
        const { modal_delete_nilai } = this.state;

        return (
            <Modal isOpen={modal_delete_nilai} toggle={() => this.setState({ modal_delete_nilai: false })}>
                <ModalHeader toggle={() => this.setState({ modal_delete_nilai: false })}>{this.state.row.nama_lengkap}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.sendDataEditNilai()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialogEditNilai()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        let idr = Intl.NumberFormat('us-US');

        const columns = [
            {
                Header: 'Kode Grup',
                accessor: 'code_register_grup',
                filterable: false
            },
            {
                Header: 'Start Date',
                accessor: 'start_date',
                filterable: false,
                Cell: (row) => (
                    <div>
                        <div>{row.original.start_date == null ? '' : Moment(row.original.start_date).format('DD MMMM YYYY')}</div>
                    </div>
                )
            },
            {
                Header: 'End Date',
                accessor: 'end_date',
                filterable: false,
                Cell: (row) => (
                    <div>
                        <div>{row.original.end_date == null ? '' : Moment(row.original.end_date).format('DD MMMM YYYY')}</div>
                    </div>
                )
            },
            {
                Header: <b>Action</b>,
                id: 'update',
                accessor: str => "update",
                Cell: (row) => (
                    <div>                     
                        <button onClick={() => this.editDate(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        const columns_detail = [
            {
                Header: 'Nama Lengkap',
                accessor: 'nama_lengkap',
                filterable: false
            },
            {
                Header: 'Email',
                accessor: 'email',
                filterable: false
            },
            {
                Header: 'No. WA.',
                accessor: 'no_wa',
                filterable: false
            },
            {
                Header: 'Schedule',
                accessor: 'schedule', 
                filterable: false
            },
            {
                Header: 'Rekomender',
                accessor: 'consultant.nama', 
                filterable: false
            },
            {
                Header: 'Total Payment',
                accessor: 'payment.nominal', 
                filterable: false,
                style: {
                    textAlign: 'end'
                },
                Cell: (row) => (
                    <div>
                        {row.original.payment.code_mata_uang} {idr.format(Math.ceil(row.original.payment.nominal))}
                    </div>
                )
            },
            {
                Header: 'Margin',
                accessor: 'payment.total_rupiah', 
                filterable: false,
                style: {
                    textAlign: 'end'
                },
                Cell: (row) => (
                    <div>
                        {row.original.payment.code_mata_uang} {idr.format(Math.ceil(row.original.payment.total_rupiah))}
                    </div>
                )
            },
            {
                Header: <b>Action</b>,
                id: 'update',
                accessor: str => "update",
                Cell: (row) => (
                    <div>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <button onClick={() => this.attandanceRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-list" style={{ width: 30, fontSize: 16, padding: 5, color: 'blue' }}></i>{row.id}</button>
                            </div>
                            <div className='col-lg-3'>
                                <button onClick={() => this.nilaiRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-address-book" style={{ width: 30, fontSize: 16, padding: 5, color: 'red' }}></i>{row.id}</button>
                            </div>
                        </div>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className='row justify-content-center'>
                                            <div className='col-md-3'>
                                                <select name='date' value={this.state.date} onChange={this.handleChange} className='form-control mb-5'>
                                                    <option value='all_data'>All Data</option>
                                                    <option value='this_week'>This Week</option>
                                                    <option value='last_week'>Last Week</option>
                                                    <option value='this_month'>This Month</option>
                                                    <option value='last_month'>Last Month</option>
                                                    <option value='this_year'>This Year</option>
                                                    <option value='last_year'>Last Year</option>
                                                    <optgroup label='Quartal'>
                                                        <option value='1'>Quartal I</option>
                                                        <option value='2'>Quartal II</option>
                                                        <option value='3'>Quartal III</option>
                                                        <option value='4'>Quartal IV</option>
                                                    </optgroup>
                                                </select>
                                            </div>
                                        </div>
                                        {
                                            this.state.dataGrup.length > 0 ?
                                            <ReactTable
                                                data={this.state.dataGrup}
                                                columns={columns}
                                                filterable
                                                defaultFilterMethod={(filter, row) =>
                                                    String(row[filter.id]) === filter.value}
                                                defaultPageSize={50}
                                                className="-striped -highlight"
                                                SubComponent={row => {
                                                    const data = row.original;
                                                    return(
                                                        <div className='container m-5'>
                                                            <Container>
                                                                <ReactTable
                                                                    data={data.register}
                                                                    columns={columns_detail}
                                                                    filterable
                                                                    defaultFilterMethod={(filter, row) =>
                                                                        String(row[filter.id]) === filter.value}
                                                                    defaultPageSize={5}
                                                                    className="-striped -highlight"
                                                                />
                                                            </Container>
                                                        </div>
                                                    )
                                                }}
                                            /> 
                                            : 
                                            <Loader showLoading={this.state.showLoading} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialogDate()}
                {this.renderDialogAttandance()}
                {this.renderDialogEditAtt()}
                {this.renderDialogShowNilai()}
                {this.renderDialogEditNilai()}
                {this.renderDialogDeleteNilai()}
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(BisdevTipe));