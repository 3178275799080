import React, { Fragment, Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../../components/common/loader';
import Breadcrumb from '../../../components/common/breadcrumb';
import Moment from 'moment';

class UniversityListEventCart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            title: "",
            operation: "",
            logName: "",
            errors: "",
            modal: false,
            modalDelete: false,
            btnDisabled: false,
            dataListEventCart: [],
            total_amount: 0,
            total_diskon: 0,
            total_harga: 0,
            tipe_diskon: "",
            message: ""
        }
    }

    componentDidMount() {
        this.getDataEventListCart();
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    deleteRow(row) {
        this.setState({
            id: row.id_event_cart,
            modalDelete: true,
            title: 'Delete Event From Cart',
            operation: 'delete',
            logName: 'DELETE-CART'
        })
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });

        var formData = new FormData();
        formData.append('id_event_cart', this.state.id);

        let url = '/event/cart';

        if (this.state.operation == 'edit') {
            formData.append('_method', 'PATCH');
            url = '/event/cart/'+this.state.id
        } else if (this.state.operation == 'delete') {
            formData.append('_method', 'DELETE');
            url = '/event/cart/'+this.state.id
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            this.closeDialog();
            this.getDataEventListCart();
            this.setState({ btnDisabled: false });
            if (this.state.operation == "add") {
                toast.success("Save Sucsessfully");
            } else {
                toast.success("Delete Sucsessfully");
            }
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    addOrderEvent() {
        var formData = new FormData();
        formData.append('code_school_rep', this.props.userableData.userable.code);
        formData.append('amount', this.state.total_amount);
        formData.append('tipe_diskon', this.state.tipe_diskon);
        formData.append('total_diskon', this.state.total_diskon);
        formData.append('total_harga', this.state.total_harga);
        formData.append('order_data', JSON.stringify(this.state.dataListEventCart));

        let url = '/event/order';

        axios.post(API_URL.superStudent+url,formData, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then((reply)=>{
            this.getDataEventListCart();
            this.setState({ btnDisabled: false });
            if (reply.status == 200) {
                toast.success(reply.data.message);
            } else {
                toast.success(reply.data.message);
            }
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    closeDialog() {
        this.setState({
            modal: false,
            modalDelete: false,
            id: "",
            name: "",
            description: "",
            zoom_link: "",
            image: "",
            harga: "",
            jenis_event: "",
            start_date: "",
            end_date: ""
        });
    }

    getDataEventListCart() {
        this.setState({
            showLoading:true
        });

        const url = API_URL.superStudent+'/event/cart/'+this.props.userableData.userable.code;
        axios.get(url, { headers: 
            { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            this.setState({
                showLoading:false,
                dataListEventCart: data.data.cart,
                total_amount: data.data.total_amount,
                total_harga: data.data.total_amount
            });
        });
    }
    
    updateTotal() {
        var formData = new FormData();
        formData.append('kode_promo', this.state.kode_promo);
        formData.append('order_data', JSON.stringify(this.state.dataListEventCart));
        formData.append('total_harga', this.state.total_amount);
        
        axios.post(API_URL.superStudent+'/event/check-promo', formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            if (reply.data.data.status == 1) {
                toast.success(reply.data.data.message);
            } else {
                toast.error(reply.data.data.message);
            }
            this.setState({
                total_amount: reply.data.data.total_amount,
                tipe_diskon: reply.data.data.tipe,
                total_diskon: reply.data.data.total_diskon,
                total_harga: reply.data.data.total_harga,
                message: reply.data.data.message
            });
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    renderDeleteDialog() {
        const { title, modalDelete } = this.state;

        return (
            <Modal isOpen={modalDelete} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{title}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.sendData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        let dollarUSLocale = Intl.NumberFormat('en-US');

        const columns = [
            {
                Header: 'Event Name',
                accessor: 'event.name',
                filterable: false
            },
            {
                Header: 'Description',
                accessor: 'event.description',
                filterable: false
            },
            {
                Header: 'Category Event',
                Cell: (row) => (
                    <div>
                        { row.original.event.jenis_event == 1 ? row.original.event.jenis_event == 2 ? 'Info Session' : 'Education Expo' : 'Education Festival' }
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: true,
                filterable: false
            },
            {
                Header: 'Images',
                Cell: (row) => (
                    <div>                     
                        <img style={{width: '100px'}} src={row.original.event.image}/>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: true,
                filterable: false
            },
            {
                Header: 'City',
                filterable: false,
                Cell: (row) => (
                    <p>
                        {
                            row.original.harga.length > 0 ?
                            row.original.harga[0].kota[0].kabkota.nama_kabkota
                            :
                            ''
                        }
                    </p>
                )
            },
            {
                Header: 'Price',
                filterable: false,
                Cell: (row) => (
                    <p>
                        AUD { row.original.harga.length > 0 ? dollarUSLocale.format(row.original.harga[0].kota[0].harga) : dollarUSLocale.format(row.original.harga) }
                    </p>
                )
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>
                        <button onClick={() => this.deleteRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <Breadcrumb title="Cart Event" parent="Cart Event" />
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        {
                                            this.state.dataListEventCart.length > 0 ?
                                            <ReactTable
                                                data={this.state.dataListEventCart}
                                                columns={columns}
                                                filterable
                                                defaultFilterMethod={(filter, row) =>
                                                    String(row[filter.id]) === filter.value}
                                                defaultPageSize={10}
                                                className="-striped -highlight"
                                                showPaginationBottom={false}
                                            />
                                            : 
                                            <Loader showLoading={this.state.showLoading} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row mb-5">
                                    <div className='col-md-3'>
                                        <h3>Promo Code</h3>
                                    </div>
                                    <div className="col-md-3">
                                        <input className='form-control' name="kode_promo" placeholder='Kode Promo' type="text" id="kode_promo" onChange={(e) => this.handleInput(e)} />
                                    </div>
                                    <div className="col-md-3">
                                        <button className='btn btn-success' onClick={() => this.updateTotal()}>Apply</button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-md-12'>
                                        <form className="theme-form">
                                            <div className="form-group row">
                                                <h5 className="col-sm-6" htmlFor="inputEmail3">Amount</h5>
                                                <div className="col-sm-6">
                                                    <h5>AUD {this.state.total_amount > 0 ? dollarUSLocale.format(this.state.total_amount) : 0}</h5>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <h5 className="col-sm-6" htmlFor="inputEmail3">Total Discount</h5>
                                                <div className="col-sm-6">
                                                    <h5>{this.state.tipe_diskon} {this.state.total_diskon > 0 ? dollarUSLocale.format(this.state.total_diskon) : 0}</h5>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <h5 className="col-sm-6" htmlFor="inputEmail3">Grand Total</h5>
                                                <div className="col-sm-6">
                                                    <h5>AUD {this.state.total_harga > 0 ? dollarUSLocale.format(this.state.total_harga) : 0}</h5>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-md-6'></div>
                                    <div className='col-md-6'>
                                        <button className='btn btn-success' onClick={() => this.addOrderEvent()}>Checkout</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDeleteDialog()}
            </Fragment>
        )
    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    userableData: state.Login.userableData
})

export default connect(reduxState)(withRouter(UniversityListEventCart));