import { GET_LGNSTS, GET_USERSDATA, GET_LISTMENU, LOGOUT, INITIALIZE, RESET_TOKEN, USERABLE_DATA, TOTAL_LAST} from "../constant/actionTypes";
const initialState = {
    userableData:{},
    detailUser: {},
    lgnsts:false,
    lgnInitialize:{},
    listMenu:{}
}

const Login = (state=initialState , action) => {

    if(action.type === USERABLE_DATA){
        return {
            ...state,
            userableData:action.value
        }
    }

    if(action.type === GET_USERSDATA){
        return {
            ...state,
            detailUser: action.value
        }
    }
   
    if(action.type === GET_LGNSTS){
        return {
            ...state,
            lgnsts: action.value
        }
    }

    if(action.type === INITIALIZE){
        return {
            ...state,
            lgnInitialize: action.value
        }
    }

    if(action.type === GET_LISTMENU){
        return {
            ...state,
            listMenu: action.value
        }
    }

    if(action.type === LOGOUT){
        return {
            ...state,
            detailUser: {},
            lgnsts:{},
            listMenu:{},
            lgnInitialize: {},
            userableData:{}
        }
    }

    if(action.type === RESET_TOKEN){
        return {
            ...state,
            detailUser: {},
            lgnsts:{},
            lgnInitialize: {}
        }
    }

    if(action.type === TOTAL_LAST){
        return {
            ...state,
            totalLast: action.value
        }
    }

    return state;
}

export default Login;