import React, { Fragment, Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../components/common/loader';
import {matchSorter} from 'match-sorter';
import Moment from 'moment';
import CKEditors from "react-ckeditor-component";

class RewardPoint extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            title: "",
            operation: "",
            logName: "",
            errors: "",
            modal: false,
            modalDelete: false,
            filtered: [],
            dataPoint: [],
        }
    }

    componentDidMount() {
        this.getDataPoint()
    }

    getDataPoint() {
        const url = API_URL.superStudent+'/reward/point';
        axios.get(url, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            } 
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({
                dataPoint:data.data
            });
        });
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
    
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
    
        if (insertNewFilter) {
          filtered.push({ id: accessor, value: value });
        }
    
        this.setState({ filtered: filtered });
    }

    render() {

        const columns = [
            {
                Header: 'Employee',
                accessor: 'consultant.nama',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["consultant.nama"] }),
                filterAll: true
            },
            {
                Header: 'Total Submission',
                accessor: 'total_submission',
                style: {textAlign: "center"},
                filterable: false
            },
            {
                Header: 'Total Redeem',
                accessor: 'total_redeem',
                style: {textAlign: "center"},
                filterable: false
            },
            {
                Header: 'Rest Point',
                accessor: 'total_point',
                style: {textAlign: "center"},
                filterable: false
            },
        ]

        return(
            <>
                <Fragment>
                    <div className="page-wrapper">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <ReactTable
                                                data={this.state.dataPoint}
                                                filtered={this.state.filtered}
                                                columns={columns}
                                                filterable
                                                defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                                onFilteredChange={(filtered, column, value) => {
                                                    this.onFilteredChangeCustom(value, column.id || column.accessor);
                                                }}
                                                defaultPageSize={10}
                                                className="-striped -highlight"
                                            /> 
                                            : 
                                            <Loader showLoading={this.state.showLoading} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            </>
        )

    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(RewardPoint));