import React, { Component } from 'react';
import Moment from 'moment';
import axios from 'axios'
import { connect } from 'react-redux';
import { Chart } from "react-google-charts"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import englishCoursePromo from './english-course-promo';

import API_URL from '../../../ApiUrl';
import EnglishCoursePromo from './english-course-promo';

class EnglishCourseHome extends Component {

    constructor(props) {
        super(props)
        this.state = {
            startDate: Moment().startOf('year').add(-6, 'months'),
            endDate: Moment().endOf('year').add(-6, 'months'),
            dataIncome: []
        }
    }

    componentDidMount() {
        this.loadDataIncome()
    }

    loadDataIncome() {
        const url = API_URL.superStudent + '/consultant-peformance/income-by-type';
        let formData = new FormData();
        formData.append('start_date', this.state.startDate.format('YYYY-MM-DD'))
        formData.append('end_date', this.state.endDate.format('YYYY-MM-DD'))
        formData.append('jenis_pembayaran', 'JPB012')
        axios.post(url, formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        }).then(response => response.data.data).then((data) => {
            this.setState({ dataIncome: data })
        });
    }

    handlePickerStartMonth = (e) => {
        this.setState({ startDate: Moment(e).startOf('month') }, () => this.loadDataIncome())
    }

    handlePickerEndMonth = (e) => {
        this.setState({ endDate: Moment(e).endOf('month') }, () => this.loadDataIncome())
    }

    render() {
        return (
            <>
                <div className="tab-content active default" id="tab-1">
                    <div className="row m-1">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className='col-md-3 col-sm-6'>
                                            <h5>Income Consultant</h5>
                                        </div>
                                        <div className='col-md-6 col-sm-12'>
                                            <div className='form-group'>
                                                <div className='input-group'>
                                                    <DatePicker dateFormat='MMMM yyyy' dateFormatCalendar='MMM' selected={this.state.startDate._d} showMonthYearPicker className='form-control' onChange={this.handlePickerStartMonth} />
                                                    <DatePicker dateFormat='MMMM yyyy' selected={this.state.endDate._d} showMonthYearPicker className='form-control' onChange={this.handlePickerEndMonth} />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='col-md-2'>
                                            <button style={{color:'white'}} selected={this.state.end_date._d} className='btn btn-success btn-md' type='button'><i className='fa fa-refresh' onClick={this.changeFilterType} style={{color:'white'}}></i></button>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="card-body">
                                    <Chart
                                        height={'500px'}
                                        chartType="Bar"
                                        loader={<div>Loading Chart</div>}
                                        data={this.state.dataIncome}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <EnglishCoursePromo/>
                </div>
            </>
        );
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(EnglishCourseHome);