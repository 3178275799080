import React, { Component,Fragment } from 'react'

export class HargaBooking extends Component {
  render() {
    return (
        <Fragment>
        <div className="page-wrapper">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-md-12">
                        {/* <DataTablesStudentsData /> */}
                        <p>HARGA BOOKING</p>
                    </div>
                </div>
            </div>
        </div>
        </Fragment> 
    )
  }
}

export default HargaBooking