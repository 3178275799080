import React from 'react'
import DataTablesProduct from '../../components/tables/dataTablesProduct'

const DataProduct = () => {
  return (
    <>
    <div className="page-wrapper">
        <div className="container-fluid p-0">
            <div className="row">
                <div className="col-md-12">
                    <DataTablesProduct />
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default DataProduct