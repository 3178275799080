import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import API_URL from '../../../../ApiUrl';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
 
class StudentProfileBar extends Component {    
    constructor(props) {
        super(props);
        this.state = {
            codeStudents:"",
            codeStatus:"",

            modalEdit: false,
            title: '',
            editingData: null,
            model: {
                status:"",
              },
            errors: {},
            operation: '',
            toastId: null,
            btnDisabled: false,
            statusList:[],
            modules:"Students"
        }
    }

    componentDidMount(){
        this.setState({
            codeStudents: this.props.data.code_students,
            codeStatus: this.props.status,
        });
        this.getStatusList();
    }

    getStatusList(){
        const url = API_URL.superStudent+'/master/status';
        axios.get(url,{
            headers: { 'Authorization': `Bearer ${API_URL.niecsisKey}`}
        }).then(response => response.data.data)
        .then((data) => {
            this.setState({ statusList: data })
        })
    }

    editStatus() {
        const status = this.props.status
        setTimeout(() => {
          this.setState({
            editingData: {status:status},
            model: Object.assign({}, {status:status}),
            operation: 'edit',
            title:"Edit Status",
            modalEdit: true,
            codeStudents: this.props.data.code_students,
            btnDisabled: false
          });
          this.validateStatus();
        }, 100);
        this.handleValidation();
    }

    closeModal(){
        this.setState({
            modalEdit: false,
        });
        this.resetState();
      }

    // handle on change   
    setStatus = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
        model.status = newData; 
        return { model };                   
        }, () => {
        this.validateStatus();
        })
    }
    // end handle on change

    // handle validation per field
    validateStatus = () => {
        const data = this.state.model;
        if(data.status === "" || data.status === 0 ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.status = 'Status Harus Diisi'; 
                errors.statusStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.status = '';    
                errors.statusStatus = true;                             
                return { errors };                   
            })
        }
    }
    // ennd handle validation per field

    resetState(){
        this.setState({
          editingData: null,
          model: {
            status:"",
          },
          errors: {},
        });
    }

    // validasi submit form
    handleValidation(){
        let formIsValid = true;
        if(!this.state.errors.statusStatus){
            formIsValid = false;
        }
        return formIsValid;
    }
    // validasi submit form

    saveData(){
        if(this.handleValidation()){
          this.sendData();
        }else{
          this.validateStatus();
        }
    }

    sendData() {
        this.setState({
          btnDisabled: true
        });

        let data = this.state.model;

        var formData = new FormData();
        formData.append('code_students',this.props.code_students);
        formData.append('code_status',data.status);

        axios.post(API_URL.superStudent+'/student/update-status',formData, 
        { 
            headers: 
            { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            },
        }).then((reply)=>{
            // toast.update(this.state.toastId, {
            //     render: "Input Success !",
            //     type: toast.TYPE.SUCCESS,
            //     autoClose: true,
            //     className: 'rotateY animated'
            // })
            toast.success('Kategori updated successfully');
            this.closeModal();
            this.props.onReloadStatus();
            this.props.onReloadProfil();
            this.props.handleReloadProgressData();
        }).catch(function(reply){
            console.log(reply)
        });
    }

    renderEditDialog() {
        const row = this.state.model;
        const { startDate } = this.state
          const modal = this.state.modalEdit;
          const { title } = this.state;
          const statusValue = row.status || "0";
          const {data, status} = this.props
          return(
            <Modal className="modal-dialog" isOpen={modal} toggle={() => this.closeModal()} size="lg">
              <ModalHeader toggle={() => this.closeModal()}>Edit Kategori</ModalHeader>
              <ModalBody style={{
                    maxHeight: 'calc(100vh - 210px)',
                    overflowY: 'auto'
                }}>
                <div className="container">
                <form className="needs-validation">
                    <div className="col">
                        <div className="form-group m-t-15 m-b-15 custom-radio-ml">
                        {status.map((m,i)=>{
                        return (
                        <div className="radio radio-danger" key={i}>
                            <input id={`radio${i}`} type="radio" defaultChecked={data.status?.code_status === m.code ? true : false} name="radio" value={m.code} onChange={(e) => this.setStatus(e)}/>
                            <label htmlFor={`radio${i}`}><b>{m.name}</b></label>
                        </div>
                        )
                        })}
                        </div>
                    </div>
                </form>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveData()}>Save Changes</Button>
                <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
              </ModalFooter>
            </Modal>
          )
      }
      
    render(){
        const {data, display, displayBtn} = this.props
        return (
            <>
            <p className='mb-0'>Kategori: <b>{data?.status?.name_status}</b>
              <i onClick={()=>this.editStatus()} className="fa fa-pencil ml-2 color-red" style={{display: displayBtn}}></i>
            </p>
            
            {this.renderEditDialog()}
            </>
        );
    }
}

// export default Students;
const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    dataToken: state.Login.lgnInitialize,
})
  
  export default connect(reduxState, null)(withRouter(StudentProfileBar));