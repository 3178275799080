import React, { Fragment, Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../components/common/loader';
import {matchSorter} from 'match-sorter';
import Moment from 'moment';
import CKEditors from "react-ckeditor-component";

class RewardGift extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            title: "",
            operation: "",
            logName: "",
            errors: "",
            modal: false,
            modalDelete: false,
            filtered: [],
            dataGift: [],
            id_gift: "",
            gift_name: "",
            harga: ""
        }
    }

    componentDidMount() {
        this.getDataGift()
    }

    getDataGift() {
        this.setState({
            showLoading:true
        });

        const url = API_URL.superStudent+'/reward/gift';
        axios.get(url, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            } 
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({
                showLoading:false,
                dataGift:data.data
            });
        });
    }

    addRow() {
        this.setState({
            modal: true,
            title: 'Add Gift Reward',
            operation: 'add',
            logName: 'ADD-GIFT-REWARD',
            id_gift: "",
            gift_name: "",
            harga: ""
        })
    }

    editRow(row) {
        this.setState({
            modal: true,
            title: 'Edit Gift Reward',
            operation: 'edit',
            logName: 'EDIT-GIFT-REWARD',
            id_gift: row.id_gift,
            gift_name: row.gift_name,
            harga: row.harga
        })
    }

    deleteRow(row) {
        this.setState({
            modalDelete: true,
            title: 'Delete Gift Reward',
            operation: 'delete',
            logName: 'DELETE-GIFT-REWARD',
            id_gift: row.id_gift,
        })
    }

    closeDialog() {
        this.setState({
            modal: false,
            modalDelete: false,
            kategori: "",
        });
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
    
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
    
        if (insertNewFilter) {
          filtered.push({ id: accessor, value: value });
        }
    
        this.setState({ filtered: filtered });
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });

        var formData = new FormData();
        formData.append('id_gift', this.state.id_gift);
        formData.append('gift_name', this.state.gift_name);
        formData.append('harga', this.state.harga);
        
        formData.append('user',this.props.dataId);
        formData.append('token',this.props.dataToken);

        let url = '/reward/gift';

        if (this.state.operation == 'edit') {
            formData.append('_method', 'PATCH');
            url = '/reward/gift/'+this.state.id_gift
        } else if (this.state.operation == 'delete') {
            formData.append('_method', 'DELETE');
            url = '/reward/gift/'+this.state.id_gift
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            this.closeDialog();
            this.getDataGift();
            this.setState({ 
                btnDisabled: false,
            });
            if (this.state.operation == "add") {
                toast.success("Save Sucsessfully");
            } else {
                toast.success("Update Sucsessfully");
            }
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    renderDialog() {
        const { title, modal } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Gift Name</label>
                                    <input className="form-control" name="gift_name" type="text" id="gift_name" value={this.state.gift_name}
                                        onChange={(e) => this.handleInput(e)}
                                        ref={e => this.inputgift_name = e}
                                    />
                                    <span>{this.state.errors.gift_name}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Harga</label>
                                    <input className="form-control" name="harga" type="text" id="harga" value={this.state.harga}
                                        onChange={(e) => this.handleInput(e)}
                                        ref={e => this.inputharga = e}
                                    />
                                    <span>{this.state.errors.harga}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDeleteDialog() {
        const { title, modalDelete } = this.state;

        return (
            <Modal isOpen={modalDelete} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{title}</ModalHeader>
                <ModalBody>
                    Delete this data {this.state.gift_name} ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.sendData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        const columns = [
            {
                Header: 'Code Gift',
                accessor: 'code_gift',
                filterable: false
            },
            {
                Header: 'Gift Name',
                accessor: 'gift_name',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["gift_name"] }),
                filterAll: true
            },
            {
                Header: 'Harga',
                accessor: 'harga',
                style: {textAlign: "center"},
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["harga"] }),
                filterAll: true
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>                     
                        <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                        <button onClick={() => this.deleteRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return(
            <>
                <Fragment>
                    <div className="page-wrapper">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <button onClick={() => this.addRow()} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} >
                                                <div>
                                                    <span>
                                                        <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i>
                                                    </span>
                                                </div>
                                            </button>
                                            <br/>
                                            {
                                                this.state.dataGift.length > 0 ?
                                                <ReactTable
                                                    data={this.state.dataGift}
                                                    filtered={this.state.filtered}
                                                    columns={columns}
                                                    filterable
                                                    defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                                    onFilteredChange={(filtered, column, value) => {
                                                        this.onFilteredChangeCustom(value, column.id || column.accessor);
                                                    }}
                                                    defaultPageSize={100}
                                                    className="-striped -highlight"
                                                /> 
                                                : 
                                                <Loader showLoading={this.state.showLoading} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderDialog()}
                    {this.renderDeleteDialog()}
                </Fragment>
            </>
        )

    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(RewardGift));