import React, { Component,Fragment } from 'react'
import { connect } from 'react-redux';
import { getDataUserable } from '../../../actions/login.action';
import { Box } from 'react-feather';
import Moment from 'moment';
import { Line } from 'react-chartjs-2';
import API_URL from '../../../ApiUrl';
import axios from 'axios';

class TranslateProfile extends Component {

    constructor(props){
        super(props)
        this.state = {
            transaction:'',
            graphic:'',
            showComponent:false,
            staticFilter:'',
            dinamicFilter:'none',
            date:'',
            start_date: Moment().startOf('year'),
            end_date: Moment().endOf('year'),
        }
    }

    componentDidMount(){
        this.props.getUserableData(this.props.dataToken)
        .then(response => {
            if(this.props.userableData.userable !== undefined){
                this.setState({
                    showComponent:true
                },() => { 
                    this.loadDataCard()
                    this.loadDataGraphic()
                })
            }
        })
        .catch(error => {
            console.log(error)
        })
    }

    formatRupiah = (nominal) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        }).format(nominal);
    }


    loadDataCard(){
        let formData = new FormData()
        formData.append('code_vendor', this.props.userableData.userable.code_vendor_translate)
        formData.append('start_date', this.state.start_date.format('YYYY-MM-DD'))
        formData.append('end_date', this.state.end_date.format('YYYY-MM-DD'))
        axios.post(API_URL.superStudent+'/vendor-translate/card-count', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            // console.log(response.data.data)
            this.setState({ transaction: response.data.data })
        })
    }

    loadDataGraphic(){
        let formData = new FormData()
        formData.append('code_vendor', this.props.userableData.userable.code_vendor_translate)
        formData.append('start_date', this.state.start_date.format('YYYY-MM-DD'))
        formData.append('end_date', this.state.end_date.format('YYYY-MM-DD'))
        axios.post(API_URL.superStudent+'/vendor-translate/graphic-translate', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            //console.log(response.data.data)
            this.setState({ graphic: response.data.data })
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        }, () => {
            this.setRangeDate()
        })
    }

    setRangeDate(){   
        let start_date = Moment();
        let end_date  = Moment();     
        switch(this.state.date){
            case 'this_year':
                start_date.startOf('year')
                end_date.endOf('year')
                break;
            case 'last_year':
                start_date.subtract(1, 'year').startOf('year').add(-6,'months')
                end_date.subtract(1, 'year').endOf('year').add(-6,'months')
                break;
            case '1':
            case '2':
            case '3':
            case '4':
                start_date.quarter(this.state.date).startOf('quarter')
                end_date.quarter(this.state.date).endOf('quarter')
                break;
            default:
                start_date.startOf('year')
                end_date.endOf('year')
        }
        this.setState({ 
            start_date,
            end_date
        }, () => this.loadDataCard())
    }



    render(){
        return(
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid">
                        <div className='row'>
                        <div className="col-sm-12">
                    <div style={{marginBottom:'10px'}} className="row justify-content-md-center justify-content-xs-center">
                        <div className='col-md-3 col-sm-4 mb-2'>
                            <select  name='date' value={this.state.date} onChange={this.handleChange} className='form-control'>
                                <option value='this_year'>This Year</option>
                                    {/* <optgroup label='Quartal'>
                                        <option value='1'>Quartal I</option>
                                        <option value='2'>Quartal II</option>
                                        <option value='3'>Quartal III</option>
                                        <option value='4'>Quartal IV</option>
                                    </optgroup> */}
                            </select>
                        </div>
                        <div className='col-sm-12 mb-3'>
                            <span style={{fontSize:'1.5em'}} className='f-w-600'>{this.state.start_date.format('DD MMMM yyyy')} - {this.state.end_date.format('DD MMMM yyyy')}</span>
                        </div>          
                    </div>
                </div>
                        </div>
                        <div className='row justify-content-center'>
                            <div className="col-sm-6 col-xl-4 col-lg-6">
                                <div className="card o-hidden">
                                    <div className="bg-success b-r-4 card-body">
                                        <div className="media static-top-widget">
                                        <div className="align-self-center text-center">
                                            Paid
                                        </div>
                                        <div className="media-body"><span className="m-0">{ this.state.transaction.total_paid }</span>
                                            <h4 className="mb-0 counter">
                                            <span className="m-0">{ this.formatRupiah(this.state.transaction.sum_paid ) }</span>
                                            </h4>
                                            <Box className="icon-bg" />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-4 col-lg-6">
                                <div className="card o-hidden">
                                    <div className="bg-primary b-r-4 card-body">
                                        <div className="media static-top-widget">
                                        <div className="align-self-center text-center">
                                            Pending
                                        </div>
                                        <div className="media-body"><span className="m-0">{this.state.transaction.total_pending}</span>
                                            <h4 className="mb-0 counter">
                                            <span className="m-0">{ this.formatRupiah(this.state.transaction.sum_pending) }</span>
                                            </h4>
                                            <Box className="icon-bg" />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-4 col-lg-6">
                                <div className="card o-hidden">
                                    <div className="bg-danger b-r-4 card-body">
                                        <div className="media static-top-widget">
                                        <div className="align-self-center text-center">
                                            Failure
                                        </div>
                                        <div className="media-body"><span className="m-0">{this.state.transaction.total_failure}</span>
                                            <h4 className="mb-0 counter">
                                            <span className="m-0">{ this.formatRupiah(this.state.transaction.sum_failure) }</span>
                                            </h4>
                                            <Box className="icon-bg" />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                        <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h5>Transaction Paid</h5>
                    </div>
                    <div className="card-body charts-box">
                      <div className="flot-chart-container">
                        <div className="flot-chart-placeholder" id="graph123">
                          <Line data={
                            {
                                labels: this.state.graphic[0],
                                datasets: [
                                    {
                                        lagend: 'none',
                                        data: this.state.graphic[1],
                                        borderColor: 'blue',
                                        pointBackgroundColor: 'lightblue',
                                        backgroundColor: "transparent",
                                        fill: 'origin',
                                    }
                                ]
                            }
                          } 
                          
                          options={{
                                maintainAspectRatio: false,
                                height: 45,
                                width: 500,
                                legend: {
                                    display: false,
                                },
                                scales: {
                                    xAxes: [{
                                        gridLines: {
                                            color: "rgba(0, 0, 0, 0)",
                                        },
                                        display: true
                                    }]
                                },
                                plugins: {
                                    datalabels: {
                                        display: false,
                                    }
                                }
                          }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    userableData: state.Login.userableData
})

const reduxDispatch = (dispatch) =>{
    return {
        getUserableData:(token) => dispatch(getDataUserable(token))
    }
}

export default connect(reduxState, reduxDispatch)(TranslateProfile)