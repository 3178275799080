import React, { Fragment, Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../components/common/loader';
import Moment from 'moment';
import CKEditors from "react-ckeditor-component";
import { file } from 'jszip';

class StudyTourList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            title: "",
            operation: "",
            logName: "",
            errors: "",
            modal: false,
            modalDelete: false,
            dataStudyTour: [],
            id_web_study_tour: "",
            code_study_tour: "",
            city: "",
            country: "",
            harga: "",
            durasi: "",
            min_pax: "",
            itenary: "",
            image: "",
            flyer: "",
            flyerFile: "", 
            description: "",
            highlight: "",
            full_description: "",
            termasuk: "",
            important: "",
            btnDisabled: false
        }
    }

    componentDidMount() {
        this.getDataStudyTour();
    }

    addRow() {
        this.setState({
            modal: true,
            title: 'Add Study Tour Data',
            operation: 'add',
            logName: 'ADD-STUDYTOUR',
            code_study_tour: "",
            city: "",
            country: "",
            harga: "",
            durasi: "",
            min_pax: "",
            image: "",
            itenary: "",
            flyer: "",
            flyerFile: "", 
            description: "",
            highlight: "",
            full_description: "",
            termasuk: "",
            important: "",
        });
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    } 

    formatRupiah = (nominal) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        }).format(nominal);
    }

    onChangeFileName = (e) => { 
        this.setState({
            image: e.target.files[0],
            filename: e.target.value
        })
    }

    onChangeFlyer = (e) => {
        this.setState({
            flyerFile: e.target.files[0],
            flyer: e.target.value
        })
    }

    onChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            itenary: newContent
        })
    }

    onChangeDescription(evt) {
        var newDescription = evt.editor.getData();
        this.setState({
            description: newDescription
        })
    }

    onChangeHighlight(evt) {
        var newHighlight = evt.editor.getData();
        this.setState({
            highlight: newHighlight
        })
    }

    onChangeImportant(evt) {
        var newImportant = evt.editor.getData();
        this.setState({
            important: newImportant
        })
    }

    onChangeInclude(evt) {
        var newInclude = evt.editor.getData();
        this.setState({
            termasuk: newInclude
        })
    }

    editRow(row) {
        axios.get(API_URL.superStudent+'/website/study-tour/'+row.id_study_tour,{
            headers: {
              'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({
                modal: true,
                title: 'Edit Study Tour Data',
                operation: 'edit',
                logName: 'EDIT-STUDYTOUR',
                id_web_study_tour: result.id_web_study_tour,
                code_study_tour: result.code_study_tour,
                city: result.city,
                country: result.country,
                harga: result.harga,
                durasi: result.durasi,
                min_pax: result.min_pax,
                itenary: result.itenary,
                description: result.description,
                highlight: result.highlight,
                termasuk: result.include,
                important: result.important,
            })
        })
    }

    deleteRow(row) {
        this.setState({
            id_web_study_tour: row.id_study_tour,
            modalDelete: true,
            title: 'Delete Study Tour Data',
            operation: 'delete',
            logName: 'DELETE-STUDYTOUR'
        })
    }

    saveRow() {
        this.sendData();
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });

        let datetime = Moment(Date()).format('DDMMYYYY');

        let fileExtension = '';
        let newFileName = '';
        let nama = '';

        let fileExtensionFlyer = '';
        let newFileNameFlyer = '';
        let namaFlyer = '';

        if (this.state.image.name != undefined) {
            fileExtension = this.state.image.name.split('.').pop();
            newFileName = "studytour"+"_"+datetime+"."+fileExtension;
            nama = "studytour"+"_"+datetime;
        }

        if (this.state.flyerFile.name != undefined) {
            fileExtensionFlyer = this.state.flyerFile.name.split('.').pop();
            newFileNameFlyer = "flyer"+"_"+datetime+"."+fileExtensionFlyer;
            namaFlyer = "flyer"+"_"+datetime;
        }

        var formData = new FormData();
        formData.append('code_study_tour', this.state.code_study_tour);
        formData.append('city', this.state.city);
        formData.append('country', this.state.country);
        formData.append('harga', this.state.harga);
        formData.append('durasi', this.state.durasi);
        formData.append('itenary', this.state.itenary);
        formData.append('description', this.state.description);
        formData.append('highlight', this.state.highlight);
        formData.append('include', this.state.termasuk);
        formData.append('important', this.state.important);
        formData.append('min_pax', this.state.min_pax);
        formData.append('image', nama);
        formData.append('file', namaFlyer);
        formData.append('file_image', "");
        formData.append('file_flyer', "");

        if(this.state.image.name != undefined) {
            formData.append('file_image', this.state.image, newFileName)
        }

        if(this.state.flyerFile.name != undefined) {
            formData.append('file_flyer', this.state.flyerFile, newFileNameFlyer)
        }
        
        formData.append('user',this.props.dataId);
        formData.append('token',this.props.dataToken);

        let url = '/website/study-tour';

        if (this.state.operation == 'edit') {
            formData.append('_method', 'PATCH');
            url = '/website/study-tour/'+this.state.id_web_study_tour
        } else if (this.state.operation == 'delete') {
            formData.append('_method', 'DELETE');
            url = '/website/study-tour/'+this.state.id_web_study_tour
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            this.closeDialog();
            this.getDataStudyTour();
            this.setState({ 
                btnDisabled: false,
                image: "",
                flyerFile: ""
            });
            if (this.state.operation == "add") {
                toast.success("Save Sucsessfully");
            } else {
                toast.success("Update Sucsessfully");
            }
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    closeDialog() {
        this.setState({
            modal: false,
            modalDelete: false,
            city: "",
            country: "",
            harga: "",
            itenary: "",
            min_pax: "",
        });
    }

    getDataStudyTour() {
        this.setState({
            showLoading:true
        });

        const url = API_URL.superStudent+'/website/study-tour';
        axios.get(url, { headers: 
            { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            this.setState({
                showLoading:false,
                dataStudyTour:data.data
            });
        });
    }

    renderDialog() {
        const { title, modal } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">City</label>
                                    <input className="form-control" name="city" type="text" id="city"  value={this.state.city}
                                        onChange={(e) => this.handleInput(e)}
                                        ref={e => this.inputCity = e}
                                    />
                                    <span>{this.state.errors.city}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Country</label>
                                    <input className="form-control" name="country" type="text" id="country"  value={this.state.country}
                                        onChange={(e) => this.handleInput(e)}
                                        ref={e => this.inputCountry = e}
                                    />
                                    <span>{this.state.errors.country}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Harga</label>
                                    <input className="form-control" name="harga" type="text" id="harga"  value={this.state.harga}
                                        onChange={(e) => this.handleInput(e)}
                                        ref={e => this.inputHarga = e}
                                    />
                                    <span>{this.state.errors.harga}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Durasi</label>
                                    <input className="form-control" name="durasi" type="text" id="durasi"  value={this.state.durasi}
                                        onChange={(e) => this.handleInput(e)}
                                        ref={e => this.inputDurasi = e}
                                    />
                                    <span>{this.state.errors.durasi}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Min. Pax</label>
                                    <input className="form-control" name="min_pax" type="text" id="min_pax"  value={this.state.min_pax}
                                        onChange={(e) => this.handleInput(e)}
                                        ref={e => this.inputMinPax = e}
                                    />
                                    <span>{this.state.errors.min_pax}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className='col-md-12 mb-3'>
                                    <label htmlFor='itenary'>Itenary</label>
                                    <CKEditors
                                        activeclassName="p10"
                                        content={this.state.itenary}
                                        events={{ "change": this.onChange.bind(this) }}
                                        config={'Full'}
                                    />
                                    <span>{this.state.errors.itenary}</span>
                                    <div className='valid-feedback'>Looks good!</div>
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className='col-md-12 mb-3'>
                                    <label htmlFor='description'>Description</label>
                                    <CKEditors
                                        activeclassName="p10"
                                        content={this.state.description}
                                        events={{ "change": this.onChangeDescription.bind(this) }}
                                        config={'Full'}
                                    />
                                    <span>{this.state.errors.description}</span>
                                    <div className='valid-feedback'>Looks good!</div>
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className='col-md-12 mb-3'>
                                    <label htmlFor='highlight'>Highlight</label>
                                    <CKEditors
                                        activeclassName="p10"
                                        content={this.state.highlight}
                                        events={{ "change": this.onChangeHighlight.bind(this) }}
                                        config={'Full'}
                                    />
                                    <span>{this.state.errors.highlight}</span>
                                    <div className='valid-feedback'>Looks good!</div>
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className='col-md-12 mb-3'>
                                    <label htmlFor='important'>Important Information</label>
                                    <CKEditors
                                        activeclassName="p10"
                                        content={this.state.important}
                                        events={{ "change": this.onChangeImportant.bind(this) }}
                                        config={'Full'}
                                    />
                                    <span>{this.state.errors.important}</span>
                                    <div className='valid-feedback'>Looks good!</div>
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className='col-md-12 mb-3'>
                                    <label htmlFor='include'>Include</label>
                                    <CKEditors
                                        activeclassName="p10"
                                        content={this.state.termasuk}
                                        events={{ "change": this.onChangeInclude.bind(this) }}
                                        config={'Full'}
                                    />
                                    <span>{this.state.errors.termasuk}</span>
                                    <div className='valid-feedback'>Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Image</label>
                                    <input className="form-control" name="image" type="file" id="image"
                                        onChange={(e) => this.onChangeFileName(e)}
                                        ref={e => this.inputFileName = e}
                                    />
                                    <span>{this.state.errors.image}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Document / Flyer</label>
                                    <input className="form-control" name="flyer" type="file" id="flyer"
                                        onChange={(e) => this.onChangeFlyer(e)}
                                        ref={e => this.inputFileNameFlyer = e}
                                    />
                                    <span>{this.state.errors.flyer}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDeleteDialog() {
        const { title, modalDelete } = this.state;

        return (
            <Modal isOpen={modalDelete} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{title}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.sendData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        const columns = [
            {
                Header: 'City',
                accessor: 'city',
                filterable: false
            },
            {
                Header: 'Country',
                accessor: 'country',
                filterable: false
            },
            {
                Header: 'Harga',
                accessor: 'harga',
                filterable: false,
                Cell:(row) => (
                    <>{this.formatRupiah(row.original.harga)}</>
                  )
            },
            {
                Header: 'Durasi',
                accessor: 'durasi',
                filterable: false
            },
            {
                Header: 'Min. Pax',
                accessor: 'min_pax',
                filterable: false
            },
            {
                Header: 'Images',
                Cell: (row) => (
                    <div>                     
                        <img style={{width: '100px'}} src={API_URL.baseSuperStudent+"/website/studytour/"+row.original.image}/>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: true,
                filterable: false
            },
            {
                Header: 'Images',
                Cell: (row) => (
                    <div>                     
                        <img style={{width: '100px'}} src={API_URL.baseSuperStudent+"/website/studytour/"+row.original.file}/>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: true,
                filterable: false
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>                     
                        <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                        <button onClick={() => this.deleteRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <button onClick={() => this.addRow()} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} >
                                            <div>
                                            <span>
                                                <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i>
                                            </span>
                                            </div>
                                        </button>
                                        <br/>
                                        {
                                            this.state.dataStudyTour.length > 0 ?
                                            <ReactTable
                                                data={this.state.dataStudyTour}
                                                columns={columns}
                                                filterable
                                                defaultFilterMethod={(filter, row) =>
                                                    String(row[filter.id]) === filter.value}
                                                defaultPageSize={100}
                                                className="-striped -highlight"
                                             /> 
                                            : 
                                            <Loader showLoading={this.state.showLoading} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialog()}
                {this.renderDeleteDialog()}
            </Fragment>
        )
    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(StudyTourList));