import React, { Component, Fragment } from 'react'
import ReactTable from 'react-table';
import {matchSorter} from 'match-sorter';

export class DataTableTrainings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filtered:[],
            filterDate: ''
        }
    }
    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
  
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
  
              insertNewFilter = 0;
            }
          });
        }
  
        if (insertNewFilter) {
          filtered.push({ id: accessor, value: value });
        }
        this.setState({ filtered: filtered });
    }

    // setFilterDate = (e) => {        
    //   const newData = e.target.value;
    //   this.setState(prevState => {
    //     let filterDate = newData;
    //     return { filterDate };                   
    //     }, () => {
    //       this.onFilteredChangeCustom(newData,"training_date");
    //     })
    // }

  render() {
    const {myData} = this.props
    const columns = [
        {
          Header: 'Training Name',
          accessor: 'training_name',
          style: {textAlign: 'center'},
          width: 500,
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["training_name"] }),
          filterAll: true,
          Cell: (row) => (<p>{`${row.original.training_name} (${row.original.sub_training?.sub_category})`}</p>)
        },
        {
          Header: 'Nama PIC',
          accessor: 'trainer.nama_lengkap',
          width: 200,
          style: {textAlign: 'center'},
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["trainer.nama_lengkap"] }),
            filterAll: true          
        },
        {
          Header: 'Nama Trainer',
          accessor: 'trainer_name',
          width: 200,
          style: {textAlign: 'center'},
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["trainer_name"] }),
            filterAll: true          
        },
        {
          Header: 'Length of Hours',
          accessor: 'length_of_training',
          style: {textAlign: 'center'},
          filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["length_of_training"] }),
          filterAll: true,
          Cell: (row) => (<><p>{row.original.length_of_training} Jam</p></>)
        },
        {
          Header: 'Jumlah Participants',
          accessor: 'participant_count',
          style: {textAlign: 'center'},
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["participant_count"] }),
            filterAll: true          
        },
        {
          Header: 'Date of Training',
          accessor: 'training_date',
          style: {textAlign: 'center'},
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["training_date"] }),
            filterAll: true
        },
        {
          Header: <b>#</b>,
          id: 'action',
          accessor: str => "action",
          width:90,
          filterable: false,
          Cell: (row) => (
              <>
                  {
                  <>
                    <button style={{border:'none', fontSize: 15 }} onClick={() => this.props.editTraining(row.original)} className="btn btn-outline-primary btn-xs" id="TooltipTop" data-placement="top"><i className='fa fa-edit'></i></button>
                    <button style={{border:'none', fontSize: 15 }} onClick={() => this.props.addParticipants(row.original)} className="btn btn-outline-success btn-xs" id="TooltipTop" data-placement="top"><i className='fa fa-plus'></i></button>
                  </>
                  }
              </>
          ),
          style: { textAlign: 'center' }
      }
      ]
    return (
        <Fragment>
            <ReactTable
            data={myData}
            filtered={this.state.filtered}
            columns={columns}
            pageSize={15}
            filterable
            showPagination={true}
            onFilteredChange={(filtered, column, value) => {
                this.onFilteredChangeCustom(value, column.id || column.accessor);
            }}
            defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value}
            SubComponent={row => {
              const {participant, length_of_training, participant_count} = row.original;
              let hours = length_of_training * participant_count
                return (
                <div className="p-5">
                    <h5 className='text-right'>Total Hours : {hours} hours</h5>
                    <div className="table-responsive">
                        <table className="table">
                        <tbody>
                            {participant.map((m, i)=>{
                                return (
                                    <tr key={i}>
                                        <td>{m.participant.nama_lengkap}</td>
                                        <td><strong>{m.participant.job_title}</strong></td>
                                        <td align='right'><button style={{border:'none', fontSize: 15 }} onClick={() => this.props.deleteParticipant(m.id_training_list)} className="btn btn-outline-danger btn-xs" id="TooltipTop" data-placement="top"><i className='fa fa-trash'></i></button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        </table>
                    </div>
                </div>
                )
            }}
            />
      </Fragment>
    )
  }
}

export default DataTableTrainings