import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import API_URL from '../../../../ApiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import { Typeahead } from 'react-bootstrap-typeahead';
import { Card, CardBody, CardHeader } from 'reactstrap';

export class StudentProfileData extends Component {
  _isMounted = false;
  constructor(props, data) {
    super(props);
    this.state = {
      dataStudent: [],
      modalEdit: false,
      nama:"",
      alamat:"",
      id_propinsi:"",
      id_propinsi_sekolah: "",
      id_kabkota:"",
      no_hp:"",
      email:"",
      kota_lahir:"",
      tgl_lahir:"",
      jenis_kelamin:"",
      code_students: "",
      code_jenpen:"",
      code_kelas:"",
      code_leads_source:"",
      code_konsultan:"",
      code_ambassador:"",
      code_students_category:"",
      ket_kampus: "",
      code_pilihan_kampus: "",
      code_sekolah_asal: "",
      date_intake:"",
      profile_pic:'',
      instagram_field:'',
      toastId: null,
      btnDisabled: false,
      dataConsultant:[],
      dataProvinsi:[],
      dataKabKota:[],
      dataKabKotaFilter:[],
      dataJenPen:[],
      dataKelasSemester: [],
      dataKelasSemesterFilter:[],
      dataLeadsSource:[],
      dataAmbassador:[],
      dataCategory:[],
      dataCampus:[],
      dataSekolah: [],
      dataKampus: []
    }
  }

  componentDidMount() {
    this.getCategory();
    this.getDataCampus();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  getDataJenPen(){
    const url = API_URL.superStudent+'/master/jenjang';
    axios.get(url,{
        headers: {
            'Accept': 'application/json'
        }
    }).then(response => response.data.data)
    .then((data) => {
        this.setState({ dataJenPen: data })
    })
  }

  getDataKelasSemester(){
    const url = API_URL.superStudent+'/master/kelas';
    axios.get(url,{
        headers: {
            'Accept': 'application/json'
        }
    }).then(response => response.data.data)
    .then((data) => {
        this.setState({ dataKelasSemester: data })
        const id = this.props.data.code_jenpen
          if(id !== null || id !== ''){
            const data = Object.values(this.state.dataKelasSemester);              
            const filterData = data.filter(dataFilter => {
                return dataFilter.code_jenjang == id;
                });
              this.setState({dataKelasSemesterFilter:filterData})
          }
    })
  }
  
  getCategory(){
    const url = API_URL.superStudent+'/master/student-category';
    axios.get(url,{
        headers: {
            'Accept': 'application/json'
        }
    }).then(response => response.data.data)
    .then((data) => {
        this.setState({ dataCategory: data })
    })
  }

  getProvinsi(){
      const url = API_URL.superStudent+'/master/provinces';
      axios.get(url,{
          headers: {
              'Accept': 'application/json'
          }
      }).then(response => response.data.data)
      .then((data) => {
          this.setState({ dataProvinsi: data })
      })
  }
  
  getKabKota(){
      const url = API_URL.superStudent+'/master/kabupaten';
      axios.get(url,{
          headers: {
              'Accept': 'application/json'
          }
      }).then(response => response.data.data)
      .then((data) => {
        this.setState({ dataKabKota: data })
        const id = this.props.data.id_propinsi
          if(id > 0){
            const data = Object.values(this.state.dataKabKota);              
            const filterData = data.filter(dataFilter => {
                return dataFilter.province_id == id;
                });
              this.setState({dataKabKotaFilter:filterData})
          }
      })
  }

  getConsultant(){
      const url = API_URL.superStudent+'/consultant/list-all';
      axios.get(url,{
          headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${this.props.dataToken}`
          }
      }).then(response => response.data.data)
      .then((data) => {
          this.setState({ dataConsultant: data })
      })
  }
  
  getDataLeadsSource(){
      const url = API_URL.superStudent+'/master/lead-source';
      axios.get(url,{
          headers: {
              'Accept': 'application/json'
          }
      }).then(response => response.data.data)
      .then((data) => {
          this.setState({ dataLeadsSource: data })
      })
  }
  
  getDataAmbassador(){
      const url = API_URL.superStudent+'/ambassadors?category=all';
      axios.get(url,{
          headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${this.props.dataToken}`
          }
      }).then(response => response.data.data)
      .then((data) => {
          this.setState({ dataAmbassador: data })
      })
  }

  getDataCampus(){
    const url = API_URL.superStudent+'/universities';
    axios.get(url,{
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.props.dataToken}`
        }
    }).then(response => response.data.data)
    .then((data) => {
        this.setState({ dataCampus: data })
    })
  }

  editProfile(row){
    this.setState({btnDisabled: false});
      this.getConsultant();
      this.getProvinsi();
      this.getKabKota();
      this.getDataJenPen();
      this.getDataKelasSemester();
      this.getDataLeadsSource();
      this.getDataAmbassador();
      this.getDataSekolah(row.id_propinsi)
      this.setState({
          modalEdit:true,
          nama: row.nama,
          alamat: row.alamat,
          id_propinsi: row.id_propinsi,
          id_kabkota: row.id_kabkota,
          no_hp: row.no_hp,
          email: row.email,
          kota_lahir: row.kota_lahir,
          tgl_lahir: row.tgl_lahir,
          jenis_kelamin: row.jenis_kelamin,
          code_jenpen: row.code_jenpen,
          code_kelas: row.code_kelas,
          code_leads_source: row.code_leads_source,
          code_sekolah_asal: row.code_school,
          code_konsultan: row.code_konsultan,
          code_ambassador: row.code_ambassador,
          code_students_category: row.code_students_category,
          date_intake: row.date_intake,
          instagram_field: row.instagram_field
    })
  }

  getDataSekolah(province_id){
    const url = API_URL.superStudent+'/sekolah-indonesia?province_id='+province_id;
    axios.get(url,{
        headers: { 'Accept': 'application/json'}
    }).then(response => response.data.data)
    .then((data) => {
        this.setState({ dataSekolah: data })
    })
  }

  closeModal(){
      this.setState({
          modalEdit: false,
      });
    }

  setProvinsi = (e) => { 
    const newData = e.target.value;
    const data = Object.values(this.state.dataKabKota);
    const filterData = data.filter(dataFilter => {
      return dataFilter.province_id == newData;
      });
    this.setState({dataKabKotaFilter:filterData})

    this.setState(prevState => {
      let id_propinsi = Object.assign({}, prevState.id_propinsi);  
      id_propinsi = newData; 
      return { id_propinsi };                   
    })
    this.getDataSekolah(e.target.value)
  }

  setProvinsiSekolah = (e) => {
    const newData = e.target.value;
    const data = Object.values(this.state.dataKabKota);
    const filterData = data.filter(dataFilter => {
      return dataFilter.province_id == newData;
      });
    this.getDataSekolah(e.target.value)
  }


  setJenjang = (e) =>{
    const newData = e.target.value;
    const data = Object.values(this.state.dataKelasSemester);
    const filterData = data.filter(dataFilter => {
      return dataFilter.code_jenjang == newData;
      });
    this.setState({dataKelasSemesterFilter:filterData})

    this.setState(prevState => {
      let code_jenpen = Object.assign({}, prevState.code_jenpen);  
      code_jenpen = newData; 
      return { code_jenpen };                   
    })
  }

  setSekolahAsal = (e) => {
    const newData = e.length > 0 ? e[0].kode_sekolah : "";
    this.setState(prevState => {
      let code_sekolah_asal = Object.assign({}, prevState.code_sekolah_asal);  
      code_sekolah_asal = newData; 
      return { code_sekolah_asal };                   
    })
  }

  sendData() {
    this.setState({
      btnDisabled: true
    });
    let formData = new FormData()
    formData.append('code_students', this.props.data.code_students)
    formData.append('nama', this.state.nama)
    formData.append('email', this.state.email)
    formData.append('id_propinsi',this.state.id_propinsi);
    formData.append('id_kabkota',this.state.id_kabkota);
    formData.append('no_hp',this.state.no_hp);
    formData.append('alamat', this.state.alamat)
    formData.append('kota_lahir',this.state.kota_lahir);
    formData.append('tgl_lahir',this.state.tgl_lahir);
    formData.append('jenis_kelamin',this.state.jenis_kelamin);
    formData.append('code_jenpen',this.state.code_jenpen);
    formData.append('code_kelas',this.state.code_kelas);
    formData.append('code_leads_source',this.state.code_leads_source);
    formData.append('code_konsultan',this.state.code_konsultan);
    formData.append('code_ambassador',this.state.code_ambassador);
    formData.append('code_students_category',this.state.code_students_category);
    formData.append('code_sekolah_asal',this.state.code_sekolah_asal);
    formData.append('ket_kampus',this.state.ket_kampus);
    formData.append('date_intake', this.state.date_intake)
    formData.append('instagram_field', this.state.instagram_field)
    axios.post(API_URL.studentUrl+'update-profile-student', formData, {
      headers: { 'Authorization': `Bearer ${API_URL.studentToken}`}
    })
    .then(response => {
      toast.success('student data update succesfully');
      this.props.onReloadProfil();
      this.closeModal()
    })
    .catch(error => {
      toast.error('there is an error')
      this.props.onReloadProfil();
      this.closeModal()
    })
  }

  renderEditDialog() {
    return(
      <Modal className="modal-dialog" isOpen={this.state.modalEdit} toggle={() => this.closeModal()} size="md">
        <ModalHeader toggle={() => this.closeModal()}>Edit Profile</ModalHeader>
        <ModalBody style={{
          maxHeight: 'calc(100vh - 210px)',
          overflowY: 'auto'
        }}>
          <form className="needs-validation" noValidate="" >
            {
              this.props.dataUser.code_roles == 'RL023' || this.props.dataUser.code_roles == 'RL003' ?
              <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="nama">Nama</label>
                  <input className="form-control" name="nama" value={this.state.nama} onChange={this.handleInput} type="text"
                  />
              </div>
            </div>
            : ''
            }
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="alamat">Alamat</label>
                  <input className="form-control" name="alamat" value={this.state.alamat} onChange={this.handleInput} type="text"
                  />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label>Provinsi</label>
                  <select className="form-control" value={this.state.id_propinsi} name="id_propinsi" type="text"
                    onChange={this.setProvinsi}
                  >
                  <option value="0">--Pilih Provinsi--</option>
                          {this.state.dataProvinsi.map((data2)=>(
                              <option key={data2.id} value={data2.id}>{data2.province_name}</option>
                          ))}
                  </select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="id_kabkota">Kab / Kota</label>
                  <select className="form-control" value={this.state.id_kabkota} name="id_kabkota" type="text"
                    onChange={this.handleInput}
                  >
                    <option value="0">--Pilih Kab/Kota--</option>
                          {this.state.dataKabKotaFilter.map((data3)=>(
                              <option key={data3.id} value={data3.id}>{data3.kabupaten_name}</option>
                          ))} 
                  </select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="no_hp">No HP / WA</label>
                  <input className="form-control" name="no_hp" onChange={this.handleInput} value={this.state.no_hp} type="text"
                  />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="email">Email</label>
                  <input className="form-control" name="email" value={this.state.email} onChange={this.handleInput} type="text"/>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="kota_lahir">Kota Lahir</label>
                  <input className="form-control" name="kota_lahir" value={this.state.kota_lahir} onChange={this.handleInput} type="text"/>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                  <label htmlFor="tgl_lahir">Tanggal Lahir</label>
                  <input type='date' name='tgl_lahir' value={this.state.tgl_lahir} onChange={this.handleInput} className='form-control'/>  
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="jenis_kelamin">Jenis Kelamin</label>
                  <select className="form-control" value={this.state.jenis_kelamin} onChange={this.handleInput} name="jenis_kelamin" type="text"
                  >
                    <option value="0">--Pilih Jenis Kelamin--</option>
                    <option key="L" value="L">Laki-laki</option>
                    <option key="P" value="P">Perempuan</option>
                  </select>
              </div>
            </div>
            <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="code_jenpen">Jenjang Pendidikan</label>
                    <select className="form-control" value={this.state.code_jenpen} onChange={this.setJenjang} name="code_jenpen" type="text" 
                    >
                      <option value="0">--Pilih Jenjang Pendidikan--</option>
                          {this.state.dataJenPen.map((data4)=>(
                                <option key={data4.code_jenjang} value={data4.code_jenjang}>{data4.jenjang_pendidikan}</option>
                            ))} 
                    </select>
                </div>
            </div>
            <div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="code_kelas">Kelas / Semester</label>
                    <select className="form-control" name="code_kelas" type="text" value={this.state.code_kelas} onChange={this.handleInput}
                    >
                      <option value="0">--Pilih Kelas / Semester--</option>
                            {this.state.dataKelasSemesterFilter.map((data5)=>(
                                <option key={data5.code} value={data5.code}>{data5.name}</option>
                            ))} 
                    </select>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="code_leads_source">Sumber</label>
                  <select md="3" className="form-control" value={this.state.code_leads_source} onChange={this.handleInput} name="code_leads_source"
                    >
                    <option value="0">--Pilih Sumber Leads--</option>
                        {this.state.dataLeadsSource.map((data1)=>(
                            <option key={data1.code_leads_source} value={data1.code_leads_source}>{data1.source}</option>
                        ))}
                    </select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label>Provinsi Sekolah</label>
                  <select className="form-control" value={this.state.id_propinsi_sekolah} name="id_propinsi_sekolah" type="text"
                    onChange={this.setProvinsiSekolah}
                  >
                    <option value="0">--Pilih Provinsi--</option>
                    {this.state.dataProvinsi.map((data2)=>(
                      <option key={data2.id} value={data2.id}>{data2.province_name}</option>
                    ))}
                  </select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="code_jenpen">Sekolah Asal</label>
                <Typeahead
                  id="basic-typeahead"
                  labelKey="nama_sekolah"
                  name="code_sekolah_asal"
                  options={this.state.dataSekolah}
                  onChange={(e) => this.setSekolahAsal(e)}
                  placeholder="--Sekolah Asal--"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="code_konsultan">Konsultan</label>
                    <select md="3" className="form-control" value={this.state.code_konsultan} onChange={this.handleInput} name="code_konsultan"
                    >
                    <option value="0">--Pilih Consultant--</option>
                        {this.state.dataConsultant.map((data1)=>(
                            <option key={data1.code} value={data1.code}>{data1.name}</option>
                        ))}
                    </select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="code_ambassador">Ambassador</label>
                  <select md="3" className="form-control" value={this.state.code_ambassador} onChange={this.handleInput} name="code_ambassador"
                    >
                    <option value="0">--Pilih Ambassador--</option>
                        {this.state.dataAmbassador.map((data1)=>(
                            <option key={data1.code_ambassador} value={data1.code_ambassador}>{data1.name}</option>
                        ))}
                    </select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="code_students_category">Category</label>
                  <select md="3" className="form-control" name="code_students_category" value={this.state.code_students_category} onChange={this.handleInput}
                    >
                    <option value="0">--Category--</option>
                        {this.state.dataCategory.map((data1)=>(
                            <option key={data1.code_students_category} value={data1.code_students_category}>{data1.category}</option>
                        ))}
                    </select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="date_intake">Estimasi Intake</label>
                <input className="form-control" onChange={this.handleInput} required value={this.state.date_intake} name="date_intake" type="date"
                />
              </div>
            </div>
            <strong>Social Media</strong>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label htmlFor="code_students_category">Instargram</label>
                <input className="form-control" value={this.state.instagram_field} onChange={this.handleInput} name="instagram_field" type="text"
                  />
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>Save Changes</Button>
          <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }

  render() {
    const data = this.props.data
    const profile = [
        {label: 'Nama Lengkap', value: data?.nama},
        {label: 'Code', value: data?.code_students},
        {label: 'Code Member', value: data?.code_member},
        {label: 'Email', value: data?.email},
        {label: 'No Telepon', value: data?.no_hp},
        {label: 'Tempat Lahir', value: data?.kota_lahir},
        {label: 'Tanggal Lahir', value: data?.tgl_lahir},
        {label: 'Jenis Kelamin', value: data?.jenis_kelamin},
        {label: 'Alamat', value: data?.alamat},
        {label: 'Provinsi', value: data?.province?.nama_propinsi},
        {label: 'Kab/Kota', value: data?.kabupaten?.nama_kabkota},
        {label: 'Jenjang', value: data?.jenjang?.jenjang_pendidikan},
        {label: 'Sekolah Asal', value: data?.sekolah?.nama_sekolah},
        {label: 'Kelas / Semester', value: data?.kelas_semester?.kelas},
        {label: 'Konsultan', value: data.consultant?.nama},
        {label: 'Estimasi Intake', value: data?.date_intake},
        {label: 'Sosmed', value: data?.instagram_field}
    ];
    return (
      <>
      <Card className='card-student'>
        <CardHeader className='card-header pb-0 d-flex justify-content-between mb-0'>
            <h5>Student Profile</h5>
            <i onClick={() => this.editProfile(data)} className='fa fa-pencil color-red'></i>
        </CardHeader>
        <CardBody className='scroll scrool'>
            <div className="table-responsive">
            <table className='table text-left'>
              <tbody>
          {
              profile.map((m,i)=>{
                  return(
                  <tr key={i}>
                      <th scope="col">{m.label}</th>
                      <td scope="col">{m.value}</td>
                  </tr>
                  )
              })
          }
              </tbody>
            </table>
            </div>
        </CardBody>
      </Card>
      {this.renderEditDialog()}
      </>
    )
  }
}
const reduxState = (state) => ({
  loginStatus: state.Login.lgnsts,
  dataUser: state.Login.detailUser,
  dataId: state.Login.detailUser.id_users,
  dataToken: state.Login.lgnInitialize,
  codeUsers: state.Login.detailUser.code_users,
})

export default connect(reduxState, null)(withRouter(StudentProfileData));