import React, { Fragment, Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../../components/common/loader';
import { saveAs } from "file-saver";
import Breadcrumb from '../../../components/common/breadcrumb';
import Moment from 'moment';

class MasterEvent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            title: "",
            operation: "",
            logName: "",
            errors: "",
            modal: false,
            modalDelete: false,
            btnDisabled: false,
            dataListEventOrder: [],
            id: "",
            status_order: ""
        }
    }

    componentDidMount() {
        this.getDataEventListOrder();
    }

    addRow() {
        this.setState({
            modal: true,
            title: 'Add Master Event Data',
            operation: 'add',
            logName: 'ADD-MASTER-EVENT',
            id: "",
        });
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    validateStatusOrder = () => {
        const status_order = this.state.status_order;
        if(status_order === "" || status_order === 0 ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.status_order = 'Status Order Harus Diisi'; 
                errors.status_orderStatus = false;                                 
                return { errors };                   
            })
        } else {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.status_order = '';    
                errors.status_orderStatus = true;                             
                return { errors };                   
            })
        }
    }

    handleValidation() {
        let formIsValid = true; 
        if(!this.state.errors.status_orderStatus){
            formIsValid = false;
            alert('Status Order Masih Kosong');
        }
        return formIsValid;
    }

    editRow(row) {
        this.setState({
            modal: true,
            title: 'Edit Order Event Data',
            operation: 'edit',
            logName: 'EDIT-ORDER-EVENT',
            id: row.id_event_order,
        })
    }

    deleteRow(row) {
        this.setState({
            id: row.id,
            modalDelete: true,
            title: 'Delete Master Event Data',
            operation: 'delete',
            logName: 'DELETE-MASTEREVENT'
        })
    }

    saveRow() {
        if(this.handleValidation()) {
          this.sendData();
        } else {
            this.validateStatusOrder();
        }
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });

        var formData = new FormData();
        formData.append('id_event_order', this.state.id); 
        formData.append('status_order', this.state.status_order);

        formData.append('user',this.props.dataId);
        formData.append('token',this.props.dataToken);

        let url = '/event/order';

        if (this.state.operation == 'edit') {
            formData.append('_method', 'PATCH');
            url = '/event/order/'+this.state.id
        } else if (this.state.operation == 'delete') {
            formData.append('_method', 'DELETE');
            url = '/event/order/'+this.state.id
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            this.closeDialog();
            this.getDataEventListOrder();
            this.setState({ btnDisabled: false });
            if (this.state.operation == "add") {
                toast.success("Save Sucsessfully");
            } else {
                toast.success("Update Sucsessfully");
            }
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    downloadFile = (row) => {
        saveAs(row,row);
    }

    closeDialog() {
        this.setState({
            modal: false,
            modalDelete: false,
            id: "",
            name: "",
            description: "",
            zoom_link: "",
            image: "",
            harga: "",
            jenis_event: "",
            start_date: "",
            end_date: ""
        });
    }

    getDataEventListOrder() {
        this.setState({
            showLoading:true
        });

        const url = API_URL.superStudent+'/event/order';
        axios.get(url, { headers: 
            { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            this.setState({
                showLoading:false,
                dataListEventOrder:data.data
            });
        });
    }

    renderDialog() {
        const { title, modal } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Status Order</label>
                                    <select name='status_order' id='status_order' value={this.state.status_order} onChange={(e) => this.handleInput(e)} onBlur={(e) => this.validateStatusOrder(e)} className='form-control'>
                                        <option value='2'>Success</option>
                                        <option value='3'>Cancel</option>
                                    </select>
                                    <span>{this.state.errors.status_order}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDeleteDialog() {
        const { title, modalDelete } = this.state;

        return (
            <Modal isOpen={modalDelete} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{title}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.sendData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        let dollarUSLocale = Intl.NumberFormat('en-US');

        const columns = [
            {
                Header: 'Code Order',
                accessor: 'code_event_order',
                filterable: false
            },
            {
                Header: 'School Name',
                accessor: 'school_rep.nama_sekolah',
                filterable: false
            },
            {
                Header: 'Total Amount',
                accessor: 'amount',
                filterable: false
            },
            {
                Header: 'Diskon',
                filterable: false,
                Cell: (row) => (
                    <p>
                        {row.original.tipe_diskon} {row.original.diskon}
                    </p>
                )
            },
            {
                Header: 'Grand Total',
                filterable: false,
                Cell: (row) => (
                    <p>
                        AUD {row.original.total_amount != null ? dollarUSLocale.format(row.original.total_amount) : ''}
                    </p>
                )
            },
            {
                Header: 'Status Order',
                Cell: (row) => (
                    <div>
                        { row.original.order_status == 0 ? <span className={'badge bg-info'}>Waiting Payment</span> : row.original.order_status == 1 ? <span className={'badge bg-secondary'}>Payment Confirmed</span> : <span className={'badge bg-success'}>Success</span>}
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: true,
                filterable: false
            },
            {
                Header: <b>Confirmation</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>                     
                        <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary" type="button" style={{border:'none'}} ><i className='fa fa-bars'></i></button> 
                        {
                            row.original.image === null ? '' : 
                            <button onClick={()=> this.downloadFile(row.original.image)} className="btn btn-outline-success btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-download fa-2x"></i></button>
                        }
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        const columns_event = [
            {
                Header: 'Event Name',
                accessor: 'event.name',
                filterable: false
            },
            {
                Header: 'Category Event',
                Cell: (row) => (
                    <div>
                        { row.original.event.jenis_event == 1 ? row.original.event.jenis_event == 2 ? 'Info Session' : 'Education Expo' : 'Education Festival' }
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: true,
                filterable: false
            },
            {
                Header: 'Online Link',
                accessor: 'event.zoom_link',
                filterable: false
            },
            {
                Header: 'City',
                Cell: (row) => (
                    <div>                     
                        { 
                            row.original.kabkota != null ? row.original.kabkota.kota[0].nama_kabkota : ''
                        }
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
            },
            {
                Header: 'Images',
                Cell: (row) => (
                    <div>                     
                        <img style={{width: '100px'}} src={row.original.event.image}/>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: true,
                filterable: false
            },
            {
                Header: 'Price',
                filterable: false,
                Cell: (row) => (
                    <p>
                        AUD {row.original.event.harga != null ? dollarUSLocale.format(row.original.event.harga) : ''}
                    </p>
                )
            },

            
        ]

        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        {
                                            this.state.dataListEventOrder.length > 0 ?
                                            <ReactTable
                                                data={this.state.dataListEventOrder}
                                                columns={columns}
                                                filterable
                                                defaultFilterMethod={(filter, row) =>
                                                    String(row[filter.id]) === filter.value}
                                                defaultPageSize={10}
                                                className="-striped -highlight"
                                                showPaginationBottom={false}
                                                SubComponent={ row => {
                                                    return(
                                                        <div className='container'>
                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                <ReactTable
                                                                    data={row.original.detail}
                                                                    columns={columns_event}
                                                                    defaultPageSize={4}
                                                                    className="-striped -highlight"
                                                                    showPaginationBottom={false}
                                                                />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }}
                                             /> 
                                            : 
                                            <Loader showLoading={this.state.showLoading} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialog()}
                {this.renderDeleteDialog()}
            </Fragment>
        )
    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(MasterEvent));