import React, { Component } from 'react';
import { Switch, Route, Redirect} from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';
import { User, FileText, List } from 'react-feather';
import Breadcrumb from '../../components/common/breadcrumb';
class IndexEmployee extends Component{

    constructor(props){
        super(props)
        this.state = {
            
        }
    }

    render(){
        return(
            <>
            <Breadcrumb title="Employee Data" parent="Employee Data" />
                <div className='container-fluid'>
                    <div className='row theme-tab'>
                        <div className='col-sm-12'>
                            <RoutedTabs tabClassName="tab-link" activeTabClassName="active" >
                                <div className='tabs tab-title'>
                                    <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/employee/profile`}>
                                        <User /> Profile
                                    </NavTab>
                                    <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/employee/index-kpi`}>
                                        <FileText /> KPI Report
                                    </NavTab>
                                    <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/employee/cuti-data`}>
                                        <List /> Cuti
                                    </NavTab>
                                </div>
                            </RoutedTabs>
                            <Switch>
                                <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/pages/employee`}
                                    render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/employee/profile`} />}
                                />
                                <Route path={`${process.env.PUBLIC_URL}/pages/employee/profile`}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/employee/kpi-report`} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/employee/cuti-data`} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default IndexEmployee