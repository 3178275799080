import React ,{ useState, useEffect } from 'react'
import { useSelector } from "react-redux"
import API_URL from '../../../ApiUrl'
import axios from 'axios'
import Moment from 'moment'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import SweetAlert from 'react-bootstrap-sweetalert'

const EventReport = ({start_date, end_date}) => {

    const[data, setData] = useState([]);
    const[loader,setLoader] = useState(false)
    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    }));

    const loadEventReport = () => {
        let formData = new FormData ()
        formData.append('start_date', start_date)
        formData.append('end_date', end_date)

        axios.post(API_URL.superStudent+'/event/event-by-range', formData, {
            headers: { 'Authorization': `Bearer ${dataToken}` }
        })
        .then(response => {
            setData(response.data.data);
        })
        .catch(error => console.log(error))
    }

    useEffect(() => {
        loadEventReport()
    },[start_date, end_date])

    return (
        <>
            <SweetAlert title="Loading..." showConfirm={false} showCancel={false} show={loader} />
            <div className='card'>
                <div className='card-header'>
                    <h6 className='f-w-600'>Event Report</h6>
                </div>
                <div className='card-body'>
                    <div className='table-responsive'>
                        <table style={{fontSize:'12px'}} className='table table-striped table-hover table-borderedfor'>
                            <thead className='tbl-strip-thad-bdr'>
                                <tr className='table-secondary'>
                                    <th scope='col'>#</th>
                                    <th scope="col">Nama Event</th>
                                    <th scope="col">Jenis Event</th>
                                    <th scope="col">Category Event</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Register Target</th>
                                    <th scope="col">Register Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.length > 0 ?
                                    data.map((items,index) => (
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{items.name}</td>
                                            <td>
                                                {
                                                    items.jenis_event == 1 ? 
                                                    "Global Youth"
                                                    :
                                                    items.jenis_event == 2 ? 
                                                    "Event Session"
                                                    :
                                                    items.jenis_event == 3 ?
                                                    "Edufest"
                                                    :
                                                    items.jenis_event == 4 ?
                                                    "Event Partner"
                                                    :
                                                    "None"
                                                }
                                            </td>
                                            <td>{items.category_event}</td>
                                            <td>
                                                {Moment(items.start_date).format('DD MMM YYYY')}
                                            </td>
                                            <td>{items.target_peserta}</td>
                                            <td>{items.register_count}</td>
                                        </tr>
                                    ))
                                    :
                                    <td colSpan={7}>Data Kosong</td>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EventReport;