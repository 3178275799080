import React, { Component,Fragment } from 'react'
import Breadcrumb from '../../../components/common/breadcrumb';

class UniversityExpoNone extends Component {

    render(){
        return(
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row m-1">
                            <div className='col-md-12'>
                                <div className="card">
                                    <div className="card-body">
                                        <Breadcrumb title="You Are Not Join In This Expo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default UniversityExpoNone;
