import React, { Component } from 'react';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import ReactTable from 'react-table'
import Moment from 'moment'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class DataIncome extends Component{

    constructor(props){
        super(props)
        this.state = {
            date: '',
            amount:0,
            id_area:'',
            description:'',
            filterArea:'',
            incomeData:[],
            filtered:[],
            dataArea:[],
            textSave:'Save',
            btnDisabled:false,
            modalAdd:false
        }
    }

    componentDidMount(){
        this.loadIncomeData()
        // this.loadArea()
    }

    loadIncomeData = () => {
        axios.get(API_URL.superStudent+'/accounting/income',{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({incomeData: response.data.data}))
        .catch(error => console.log(error));
    }

    loadArea = () => {
        axios.get(API_URL.superStudent+'/master/area',{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({ dataArea: response.data.data}))
        .catch(error => console.log(error))
        console.log(this.state.dataArea)
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    handleSubmit = (e) => {
        this.setState({
            textSave:'Saving...',
            btnDisabled:true,
        })
        let formData = new FormData ()
        formData.append('id_area', this.state.id_area)
        formData.append('keterangan', this.state.description)
        formData.append('date_income', this.state.date)
        formData.append('nominal', this.state.amount)
        axios.post(API_URL.superStudent+'/accounting/income', formData, {
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(() => {
            this.closeModal()
            this.setState({
                textSave:'Save',
                btnDisabled:false,
            })
            this.loadIncomeData()
            toast.success('successfully add data')
        })
        .catch(() => {
            this.closeModal()
               this.setState({
                textSave:'Save',
                btnDisabled:false,
            })
            toast.error('there is an error')
        })

    }

    addData = () => {
        this.setState({
            date: '',
            amount:0,
            id_area:'',
            description:'',
            modalAdd: true
        })
    }

    closeModal(){
        this.setState({
            modalAdd:false
        })
    }

    SetFilterArea = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
          let filterArea = newData; 
          return { filterArea };                   
          }, () => {
            this.onFilteredChangeCustom(newData,"id_area");
          })
    }

    formatRupiah = (nominal) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currencyDisplay:'narrowSymbol',
            currency: 'IDR',
        }).format(nominal);
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
    
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
    
        if (insertNewFilter) {
          filtered.push({ id: accessor, value: value });
        }
    
        this.setState({ filtered: filtered });
  }

  
  deletePost = (id) => {
    axios.delete(API_URL.superStudent+`/accounting/income/${id}`, {
        headers: {
            Authorization: `Bearer `+this.props.dataToken
        }
    })
    .then(result => {
        this.setState({
            btnDisabled:false,
            textSave:'Save Data',
            modal: false,
        })
        toast.success("Data Berhasil di perbaharui")
        this.loadIncomeData()
    })
    .catch(error => console.log(error))
  }

  formatRupiah = (nominal) => {
    return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currencyDisplay:'narrowSymbol',
        currency: 'IDR',
    }).format(nominal);
  }


    renderModalAdd(){
        return(
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modalAdd} toggle={() => this.closeModal()}>
                <ModalHeader toggle={() => this.closeModal()}>Form Income</ModalHeader>
                <ModalBody>
                <form >
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label className='control-label'>Description</label>
                                <input type='text' className='form-control' onChange={this.handleInput} name='description' value={this.state.description} />
                            </div>
                            <div className='form-group'>
                                <label className='control-label'>Branch</label>
                                    <select className='form-control' name='id_area' onChange={this.handleInput} value={this.state.id_area}>
                                            <option>--Select Branch--</option>
                                            {
                                                this.state.dataArea.length > 0 ?
                                                this.state.dataArea.map(item => (
                                                    <option key={item.id_area} value={item.id_area}>{item.area}</option>
                                                ))
                                                :
                                                ''
                                            }
                                    </select>
                            </div>
                            <div className='form-group'>
                                <label className='control-label'>Nominal</label>
                                <input type='number' className='form-control' onChange={this.handleInput} name='amount' value={this.state.amount} />
                            </div>
                            <div className='form-group'>
                                <label className='control-label'>Date</label>
                                <input type='date' className='form-control' value={this.state.date} onChange={this.handleInput} name='date'/>
                            </div>
                        </div>
                    </div>
                </form>
                </ModalBody>
                <ModalFooter>
                  <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.handleSubmit()}>{this.state.textSave}</Button>
                  <Button  color="secondary" onClick={() => this.closeModal}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
        )
    }

    render(){
        const columns = [
            {
                Header: 'Nama Pembeli',
                accessor: 'merc_order.nama_lengkap',
                filterable:false,
                style: {textAlign: 'center'},
            },
            // {
            //     Header: 'Area',
            //     accessor: 'id_area',
            //     style: {textAlign: 'center'},
            //     Filter: () => 
            //     <select value={this.state.filterArea || ''} onChange={(e) => this.SetFilterArea(e)}>
            //         <option key={0} value=''>Show All</option>
            //         {
            //             this.state.dataArea.map( item => {
            //                 return <option key={item.id_area} value={item.id_area}>{item.area}</option>
            //             })
            //         } 
            //     </select>,
            //     Cell:(row) => (
            //         <>{row.original.area.area}</>
            //     )
            // },
            {
                Header: 'Income',
                accessor: 'total_income',
                style: {textAlign: 'center'},
                filterable:false,
                Cell:(row) => (
                   <>{this.formatRupiah(row.original.total_income)}</>
                )
            },
            {
                Header: 'Date',
                accessor: 'date_income',
                style: {textAlign: 'center'},
                filterable:false,
                Cell: (row) =>(
                    <>{Moment(row.original.date_income).format('DD MMM YYYY')}</>
                )
            },
        ]
        return(
            <>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <ReactTable
                                        data={this.state.incomeData}
                                        columns={columns}
                                        filterable
                                        filtered={this.state.filtered}
                                        defaultPageSize={15}
                                        className="-striped -highlight"
                                        showPaginationBottom={true}
                                        onFilteredChange={(filtered, column, value) => {
                                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                                        }}
                                        SubComponent={row => {
                                            const data = row.original
                                            return (
                                            <div className='p-4'>
                                            <h5>{row.original.keterangan}</h5>
                                            <div className="table-responsive">
                                                <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Item</th>
                                                        <th>Harga Jual</th>
                                                        <th>Profit</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                            {
                                                data.merc_order.item.map((d) => {  
                                                    return(
                                                    <tr key={d.id_item_order}>
                                                        <td>{d.name} x {d.quantity}</td>
                                                        <td>{this.formatRupiah(d.value)}</td>
                                                        <td className='text-success'>{this.formatRupiah(d.profit_order)}</td>
                                                    </tr>
                                                    )
                                                })
                                            }                                                            
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th></th>
                                                        <th className='text-right'>Total</th>
                                                        <th>{this.formatRupiah(data.total_income)}</th>
                                                    </tr>
                                                </tfoot>
                                                </table>
                                                <div className="text-center profile-details m-3">
                                                    {/* <h4><button className='btn btn-primary' onClick={()=>this.openPreviewModal(data.file_cv)}>Lihat CV <i className='fa fa-eye'></i></button></h4> */}
                                                    {/* <button className='btn btn-primary' onClick={(e)=>this.downloadFile(e, data.file_cv)}>Lihat CV <i className='fa fa-eye'></i></button> */}
                                                </div>
                                            </div>
                                            </div>
                                            )
                                          }} 
                                    /> 
                                </div>
                            </div>
                        </div>        
                    </div>
                </div>
                {this.renderModalAdd()}
            </>
        )
    }
}

const reduxState = (state) => ({
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
  })

export default connect(reduxState)(DataIncome);