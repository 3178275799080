import API from '../services';
// import * as types from '../constant/actionTypes'

import {
    GET_STUDENTS,
    ADD_ROWS_STUDENTS,
    GET_STUDENTS_AMBASSADOR
} from '../constant/actionTypes';

export const dataStudents = () => (dispatch) => {
    return (
        API.getStudents()
        .then((res) => {
                const datastudent = res
                dispatch({type: GET_STUDENTS, value: datastudent })
        })
    )
}

export const dataStudentsAmbassador = (data) => (dispatch) => {
    return (
        API.getStudentsAmbassador(data)
        .then((res) => {
                const datastudentambassador = res
                dispatch({type: GET_STUDENTS_AMBASSADOR, value: datastudentambassador })
        })
    )
}

export const addRowStudents = (data) => (dispatch, commit) => {
    return (
        API.getNewStudents(data)
        .then((res) => {
            const addRowStudents = res.data[0];
            console.log('cel row'. addRowStudents)
            dispatch({type: ADD_ROWS_STUDENTS, value: addRowStudents })
            commit(dataStudents())
        })
    )
}