import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import {matchSorter} from 'match-sorter';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import axios from 'axios'
import API_URL from '../../../ApiUrl';

class DataTableLowongan extends Component {
  constructor(props) {
      super(props);
      this.state = {
          data: [],
          filtered:[],
          filterArea:'',
      }
  }

  SetFilterArea = (e) => {        
    const newData = e.target.value;
    this.setState(prevState => {
      let filterArea = newData;
      return { filterArea };                   
      }, () => {
        this.onFilteredChangeCustom(newData,"internal_area.area");
      })
  }

  onFilteredChangeCustom = (value, accessor) => {
      let filtered = this.state.filtered;
      let insertNewFilter = 1;

      if (filtered.length) {
        filtered.forEach((filter, i) => {
          if (filter["id"] === accessor) {
            if (value === "" || !value.length) filtered.splice(i, 1);
            else filter["value"] = value;

            insertNewFilter = 0;
          }
        });
      }

      if (insertNewFilter) {
        filtered.push({ id: accessor, value: value });
      }
      this.setState({ filtered: filtered });
  }

  render() {
    const {myData, area } = this.props;
    const columns = [
      {
        Header: 'Job Title',
        accessor: 'title',
        style: {textAlign: 'center'},
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["title"] }),
          filterAll: true
      },
      {
        Header: 'Department',
        accessor: 'department',
        style: {textAlign: 'center'},
        filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["department"] }),
        filterAll: true
      },
      {
        Header: 'Pendidikan Minimal',
        accessor: 'min_pendidikan',
        style: {textAlign: 'center'},
        filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["min_pendidikan"] }),
        filterAll: true
      },
      {
        Header: 'Wilayah/Area',
        accessor: 'internal_area.area',
        style: {textAlign: 'center'},
        filterable: true,
        Cell:(row) => (
            <>{row.original.internal_area.area}</>
        ),
        Filter: () => 
        <select value={this.state.filterArea || ''} onChange={(e) => this.SetFilterArea(e)}>
            <option key={0} value=''>Show All</option>
            {
                area.map( item => {
                    return <option key={item.id_area} value={item.area}>{item.area}</option>
                })
            } 
        </select>
      },      
      {
        Header: 'Jumlah Kandidat',
        accessor: 'employee_candidate_count',
        style: {textAlign: 'center'},
        filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["employee_candidate_count"] }),
        filterable: false
      },
      {
        Header: <b>#</b>,
        id: 'action',
        accessor: str => "action",
        width:90,
        filterable: false,
        Cell: (row) => (
            <>
                {
                <>
                  <button style={{border:'none', fontSize: 15 }} onClick={() => this.props.editLowongan(row.original)} className="btn btn-outline-primary btn-xs" id="TooltipTop" data-placement="top"><i className="fa fa-pencil success-icon"></i></button> 
                  <Link className='btn btn-outline-primary btn-xs' style={{border:'none', fontSize: 15}} to={`${process.env.PUBLIC_URL}/pages/job-vacancy/list-kandidat/${row.original.id_job_vacancy}`}><i className='fa fa-user'></i></Link>
                  <button style={{border:'none', fontSize: 15 }} onClick={() => this.props.changeStatusLowongan(row.original)} className="btn btn-outline-primary btn-xs" id="TooltipTop" data-placement="top"><i className={`fa ${row.original.status !== '1' ? 'fa-times danger-icon' : 'fa-check success-icon'} `}></i></button>
                </>
                }
            </>
        ),
        style: { textAlign: 'center' }
    }
    ]

    return (
      <Fragment>
        <ReactTable
          data={myData}
          filtered={this.state.filtered}
          columns={columns}
          pageSize={15}
          filterable
          showPagination={true}
          onFilteredChange={(filtered, column, value) => {
            this.onFilteredChangeCustom(value, column.id || column.accessor);
        }}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value}
          />
      </Fragment>
    );
  }
};

// export default Datatable;

const reduxState = (state) => ({
  dataUser: state.Login.detailUser,
  dataToken: state.Login.lgnInitialize,
})


export default connect(reduxState, null) (withRouter(DataTableLowongan));