import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import Breadcrumb from '../../../components/common/breadcrumb';
import Moment from 'moment';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';

class EventDetailCard extends Component {

    _isMounted = false; 
    constructor(props) {
        super(props);
        this.state = {
            totalKota: 0,
            dataListEvent: [],
            kota: [],
            selectKota: []
        }
    }

    componentDidMount() {
        this.getDetailEvent();
    }

    getDetailEvent() {
        const url = API_URL.superStudent+'/event/detail/'+this.props.match.params.id;
        axios.get(url, { headers: 
            { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            this.setState({
                showLoading:false,
                dataListEvent:data.data[0],
                kota: data.data[0].kota
            });
        });
    }

    pilihKota = (e, item, j) => {
        if (e.target.checked) {
            this.setState(prevState => ({
                selectKota: [...prevState.selectKota, item]
            }), () => {
                this.setState({
                    totalKota: this.state.selectKota.reduce((total, currentValue) => total = total + currentValue.pivot.harga,0)
                })
            })
        } else {
            let selectKota = [...this.state.selectKota];
            selectKota.splice(j, 1);
            this.setState({
                selectKota 
            }, () => {
                this.setState({
                    totalKota: this.state.selectKota.reduce((total, currentValue) => total = total + currentValue.pivot.harga,0)
                })
            });
        }
    }

    addCartEvent(row) {
        var formData = new FormData();
        formData.append('code_events', row.code_event);
        formData.append('code_school_rep', this.props.userableData.userable.code);

        let url = '/event/cart';

        axios.post(API_URL.superStudent+url,formData, {
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then((reply)=>{
            if (reply.status == 200) {
                toast.success(reply.data.message);
            } else {
                toast.success(reply.data.message);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    cart() {
        this.props.history.push({
            pathname: `${process.env.PUBLIC_URL}/pages/university/event/cart`
        })
    }

    render() {

        let dollarUSLocale = Intl.NumberFormat('en-US');

        return (
            <Fragment>
                <div className='row'>
                    <div className='col-lg-6'>
                        <Breadcrumb title="Detail Event" />
                    </div>
                    <div className='col-lg-6 text-right'>
                        <button className="btn btn-outline-primary btn-lg mt-3" onClick={() => this.cart()} type="button" style={{border:'none'}}>
                            <i className="fa fa-shopping-cart fa-2xl" style={{ width: 35, fontSize: 32}}></i>
                        </button>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="card">
                        <div className="row product-page-main">
                            <div className="col-xl-4">
                                <img src={this.state.dataListEvent.image} className="img-fluid" />
                            </div>
                            <div className="col-xl-8">
                                <div className="product-page-details">
                                    <h5>{this.state.dataListEvent.name}</h5>
                                </div>
                                <hr />
                                <p>{this.state.dataListEvent.description}</p>
                                <hr />
                                <div>
                                    <table className="product-page-width">
                                        <tbody>
                                            {
                                                this.state.kota !== null ?
                                                this.state.kota.map((detail, j) => (
                                                    <tr>
                                                        <td>{detail.nama_kabkota}</td>
                                                        <td>:</td>
                                                        <td>AUD {detail.pivot.harga}</td>
                                                        <td><input id={detail.pivot.id_kabkota} type="checkbox" name="hargaKota" value="Bike" onChange={(e) => this.pilihKota(e, detail, j)} /></td>
                                                    </tr>
                                                ))
                                                :
                                                ''
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <hr />
                                <div>
                                {
                                    this.state.kota.length !== null ? 
                                    <div className='row card-promo-campus-middle'>
                                        <div className='col-lg-5 text-left'>
                                            <h6><strong>Total</strong></h6>
                                        </div>
                                        <div className='col-lg-7 text-left'>
                                            <h6><strong>{this.state.totalKota != null ? dollarUSLocale.format(this.state.totalKota) : ''}</strong></h6>
                                        </div>
                                    </div>
                                    :
                                    <div className='row card-promo-campus-middle'>
                                        <div className='col-lg-5 text-left'>
                                            <h6><strong>AUD</strong></h6>
                                        </div>
                                        <div className='col-lg-7 text-left'>
                                            <h6><strong>{this.state.dataListEvent.harga != null ? dollarUSLocale.format(this.state.dataListEvent.harga) : ''}</strong></h6>
                                        </div>
                                    </div>
                                }
                                </div>
                                <div className="m-t-15">
                                    <button className="btn btn-primary-gradien m-r-10" type="button" data-original-title="btn btn-info-gradien" title="" onClick={() => this.addCartEvent(this.state.dataListEvent)}>
                                        Add To Cart
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(withRouter(EventDetailCard))