import React, {useState } from 'react'
import { Collapse } from 'reactstrap';

const DataCollapse = ({list,index,type}) => {
    
    const [collapse, setCollapse] = useState(false);

    return (
        <>
            <li onClick={() => setCollapse(!collapse)} className="list-group-item d-flex justify-content-between align-items-center">
                <strong> { index+1 +'. '+ list.label} </strong>
                <span className="badge badge-primary counter digits">{list.members_count}</span>
            </li>
            <Collapse isOpen={collapse}>
                <div className='m-10'>
                    <ul>
                        {list.members.length > 0 ?
                            list.members.map(member => (
                                <li key={member.code_member}><i className="fa fa-caret-right txt-secondary m-r-10"></i> ({member.kode_member}) {member.nama_lengkap}</li>
                            ))
                            :
                            ''
                        }
                    </ul>
                </div>
            </Collapse>
        </> 
    )
}

export default DataCollapse