import React, { Component } from "react"
import { connect } from 'react-redux'
import ReactTable from 'react-table'
import Moment from 'moment'
import API_URL from '../../ApiUrl'
import { matchSorter } from 'match-sorter';
import axios from 'axios'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ListDataMapping extends Component {

    constructor(props) {
        super(props)
        this.state = {
            listData: [],
            code_sekolah:'',
            modalEdit:false,
            btnDisabled:false,
            limit:0,
            status:0
        }
    }

    componentDidMount() {
        this.loadData()
    }

    loadData = () => {
        axios.get(API_URL.superStudent + '/mapping-sekolah/list-sekolah', {
            headers: { Authorization: `Bearer ` + this.props.dataToken }
        })
            .then(response => this.setState({ listData: response.data.data }))
            .catch(error => console.log(error))
    }

    saveList = () => {
        this.setState({
            btnDisabled:true,
        })
        var formData = new FormData();
        formData.append('kode_sekolah', this.state.code_sekolah);
        formData.append('limit_user', this.state.limit);
        formData.append('status', this.state.status);
        axios.post(API_URL.superStudent + '/mapping-sekolah/update-sekolah', formData, {
            headers: { Authorization: `Bearer ` + this.props.dataToken }
        })
            .then(() => {
                toast.success('data updated succesfully')
                this.loadData()
                this.closeModal()
            })
            .catch(error => {
                toast.error('something error')
                this.loadData()
                this.closeModal()
            })
    }

    editData = (data) => {
        this.setState({
            code_sekolah:data.kode_sekolah,
            limit:data.limit_user,
            status:data.status,
            modalEdit:true,
        });
    }

    handleInput = (e) => {
        this.setState({
          [e.target.name]: e.target.value
        })
      }

    closeModal(){
        this.setState({
            modalEdit:false,
            btnDisabled:false,
        })
    }

    renderEditModal() {
        return (
          <Modal className="modal-dialog" isOpen={this.state.modalEdit} toggle={() => this.closeModal()} size="md">
            <ModalHeader toggle={() => this.closeModal()}>Update Data</ModalHeader>
            <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
              <form className="needs-validation tooltip-validation" noValidate="" >
                <div className='form-group'>
                  <label className='control-label'>Status</label>
                  <select onChange={this.handleInput} className='form-control' value={this.state.status} name='status'>
                    <option key={0} value='0'>Inactive</option>
                    <option key={1} value='1'>Active</option>
                  </select>
                </div>
                <div className='form-group'>
                  <label className='control-label'>Limit User</label>
                  <input className="form-control" name="limit" value={this.state.limit} onChange={this.handleInput} type="text" />
             
                </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveList()}>Save</Button>
              <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )
     }

    render() {
        const columns = [
            {
                Header: ' Sekolah',
                accessor: 'nama_sekolah',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["nama_sekolah"] }),
                filterAll: true
            },
            {
                Header: 'Email',
                accessor: 'email_sekolah',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["email_sekolah"] }),
                filterAll: true
            },
            {
                Header: 'Telepon',
                accessor: 'no_telp_sekolah',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["no_telp_sekolah"] }),
                filterAll: true,
            },
            {
                Header: 'PIC Telepon',
                accessor: 'no_wa_pic',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["no_wa_pic"] }),
                filterAll: true
            },
            {
                Header: 'Handler',
                accessor: 'employee.nama',
                filterable: false,
                style: { textAlign: 'center' }
            },
            {
                Header: 'Status',
                accessor: 'status',
                filterable: false,
                style: { textAlign: 'center' },
                Cell: (row) => (
                    row.original.status == '1' ? <span className="badge bg-success">Active</span> : <span className="badge bg-dark">Inactive</span>
                )
            },
            {
                Header: 'Limit User',
                accessor: 'limit_user',
                filterable: false,
                style: { textAlign: 'center' },
            },
            {
                id: 'created_at',
                Header: 'Tanggal',
                accessor: a => {
                    return Moment(a.date_created)
                        .local()
                        .format("DD MMM YYYY")
                },
                filterable: false,
                style: { textAlign: 'center' }
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                style: { textAlign: 'center' },
                width: 80,
                filterable:false,
                accessor: str => "delete",
                Cell: (row) => (
                    <button onClick={() => this.editData(row.original)} style={{ border: 'none' }} className="btn btn-outline-info btn-xs"><i className='fa fa-edit' style={{ fontSize: 16 }}></i></button>
                ),
            }

        ]

        const child_columns = [
            {
                Header: ' Nama Siswa',
                accessor: 'nama_siswa',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["nama_siswa"] }),
                filterAll: true
            },
            {
                Header: 'NIS',
                accessor: 'nis',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["nis"] }),
                filterAll: true
            },
            {
                Header: 'Telepon',
                accessor: 'no_wa_siswa',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["no_wa_siswa"] }),
                filterAll: true,
            },
            {
                Header: 'Email ',
                accessor: 'email_siswa',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["email_siswa"] }),
                filterAll: true
            }
        ]

        return (
            <>
                <div className="card">
                    <div className="card-body">
                        <ReactTable
                            data={this.state.listData}
                            columns={columns}
                            defaultPageSize={10}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}
                            className="-striped -highlight"
                            SubComponent={row => {
                                return (
                                    <ReactTable
                                        data={row.original.siswa}
                                        columns={child_columns}
                                        defaultPageSize={10}
                                        className="-striped -highlight"
                                        filterable
                                        defaultFilterMethod={(filter, row) =>
                                            String(row[filter.id]) === filter.value}
                                        showPaginationBottom={true}
                                    />
                                )
                            }}
                        />
                    </div>
                </div>
                {this.renderEditModal()}
            </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState)(ListDataMapping)