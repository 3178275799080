import React, { Component } from 'react'
import API_URL from '../../ApiUrl'
import axios from 'axios'
import ReactTable from 'react-table'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import Moment from 'moment'
import {matchSorter} from 'match-sorter'
import { ExportCSV } from '../../services/ExportCSV';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

class CutiList extends Component{

    constructor(props){
        super(props)
        this.state = {
            HistoryCuti:[],
            excelCuti:[],
            filtered:[],
            filterStatus:'',
            filterDuration:'',
            filterType:'',
            DataEmployee:[],
            modelDP:[
                {
                    code_konsultan:'',
                    description:'',
                    start_date:'',
                    duration:8,
                }
            ],
            modalDP:false,
            textSave: 'Create',
            disableBtn:false,
            startDate:'',
            endDate:'',
        }
    }

    componentDidMount(){
        this.loadHistoryCuti()
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })  
      }
    
      filter = () => {
        var formData = new FormData();
        formData.append('start_date', this.state.startDate)
        formData.append('end_date', this.state.endDate)
        this.loadHistoryCuti(formData)
     }

    onChangeModelDP = (index, e) => {
        let data = [...this.state.modelDP]
        data[index][e.target.name] = e.target.value
        this.setState({ modelDP: data }, () => console.log(this.state.modelDP))
    }

    loadHistoryCuti = async (data = null) => {
        axios.post(API_URL.superStudent+'/cuti/history', data, {
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => {
            this.setState({
                HistoryCuti: response.data.data
            },() => this.formatExcel())
        })
        .catch(error => {

        })
    }

    formatExcel = () => {
        let data = [];
        this.state.HistoryCuti.map(item => {
            let obj = {
                date:item.start_date,
                name: item.nama_lengkap,
                type: this.getType(item),
                duration: item.duration === 8 ? '1 Hari' : '1/2 Hari',
                description: item.description,
                status: item.status === 1 ? 'Approved' : item.status === 0 ? 'Rejected' : item.status === 2 ? 'Used' : item.status === 3 ? 'Expired' : 'Waiting' 
            }
            data.push(obj)
        })
        this.setState({
            excelCuti: data
        })
    }

    loadDataActiveEmployee = () => {
        axios.get(API_URL.superStudent+'/consultant/list-all',{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({ DataEmployee: response.data.data }))
        .catch(error => console.log(error))
    }
 
    getType = (row) => {
        let type = '';
        if(row.type === 1){
            switch (row.cuti_type) {
                case 1:
                    type = 'Cuti Tahunan'
                    break;
                case 2:
                    type = 'Cuti Khusus'
                    break;
                case 3: 
                    type = 'Permission Leave'
                    break;
                case 4: 
                    type = 'Unpaid Leave'
                    break;
                case 5: 
                    type = 'Cuti DP'
                    break;
                case 6: 
                    type = 'Cuti Sakit'
                    break;
                default:
                    break;
            }
        }
        else
            type = 'Day Payment';
        return type;
    }

    handleCuti = (id,status) => {
        this.setState({
            disableBtn: true
        });
        let formData = new FormData()
        formData.append('id', id)
        formData.append('status', status)
        axios.post(API_URL.superStudent+'/cuti/handle-cuti', formData,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(() => {
            this.loadHistoryCuti()
            this.setState({ disableBtn: false });
            toast.success('data updated successfully')
        })
        .catch(error => {
            toast.error('there is an error')
        })
    }

    closeModal = () => {
        this.setState({
            modalDP: false
        })
    }

    addFiledData = () => {
        this.setState(prevState => ({
            modelDP:[
                ...prevState.modelDP,
                {
                    code_konsultan:'',
                    description:'',
                    start_date:'',
                    duration:8,
                }
            ]
        }))
    }

    handleAddDayPayment = (e) => {
        this.loadDataActiveEmployee()
        this.setState({
            modalDP:true,
            textSave: 'Create',
            disableBtn: false,
            modelDP:[
                {
                    code_konsultan:'',
                    type: 2,
                    description:'',
                    start_date:'',
                    duration:8,
                }
            ],
        })
    }

    createData = (e) => {
        this.setState({
            textSave: 'Creating...',
            disableBtn: true
        })
        let formData = new FormData()
        formData.append('modelDP',JSON.stringify(this.state.modelDP))
        axios.post(API_URL.superStudent+'/cuti/create-dp',formData,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        }) 
        .then(() => {
            this.closeModal()
            this.loadHistoryCuti()
            toast.success('data added successfully')
        })
        .catch(error => {
            this.closeModal()
            toast.error('there is an error')
        })
    }

    renderModalDP() {
        return(
          <Modal className="modal-dialog" isOpen={this.state.modalDP} toggle={() => this.closeModal()} size="lg">
            <ModalHeader toggle={() => this.closeModal()}>Create Day Payment </ModalHeader>
            <ModalBody style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto'
            }}>
                {
                    this.state.modelDP.map((item,index) => (
                        <div key={index} className='row mb-2'>
                            <div className='col-md-3'>
                                <select className='form-control' name='code_konsultan' value={item.code_konsultan} onChange={e => this.onChangeModelDP(index, e)}>
                                    <option key={0} value=''> --Pilih-- </option>
                                    {
                                    this.state.DataEmployee.length > 0 ?
                                    this.state.DataEmployee.map(employee => (
                                        <option key={employee.code} value={employee.code}>{employee.name}</option>
                                    ))
                                    :
                                    ''
                                    }
                                </select>
                            </div>
                            {/* <div className='col-md-2'>
                                <select className='form-control' name='duration' value={item.duration} onChange={e => this.onChangeModelDP(index, e)} >
                                    <option>--Pilih--</option>
                                    <option value={4}>&#189; Hari</option>
                                    <option value={8}>1 Hari</option>
                                </select>
                            </div> */}
                            <div className='col-md-3'>
                                <input type='date' className='form-control' value={item.start_date} name='start_date' onChange={e => this.onChangeModelDP(index, e)} />
                            </div>
                            <div className='col-md-6'>
                                <input type='text' placeholder='keterangan' className='form-control' value={item.description} name='description' onChange={e => this.onChangeModelDP(index, e)} />
                            </div>
                        </div>
                    ))
                }
                <button style={{float:'right'}} onClick={this.addFiledData} className='btn btn-pill btn-outline-success'><i className='fa fa-plus'></i></button>
            </ModalBody>
            <ModalFooter>
              <Button disabled={this.state.disableBtn} color="primary" onClick={(e) => this.createData()}>{this.state.textSave}</Button>
              <Button color="secondary" onClick={e => this.closeModal()}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )
    }

    SetFilterStatus = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
          let filterStatus = newData; 
          return { filterStatus };                   
          }, () => {
            this.onFilteredChangeCustom(newData,"status");
          })
    }

    SetFilterDuration = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
          let filterDuration = newData; 
          return { filterDuration};                   
          }, () => {
            this.onFilteredChangeCustom(newData,"duration");
          })
    }

    SetFilterType = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
          let filterType = newData; 
          return { filterType};                   
          }, () => {
            this.onFilteredChangeCustom(newData,"type");
          })
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
    
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
    
        if (insertNewFilter) {
          filtered.push({ id: accessor, value: value });
        }
    
        this.setState({ filtered: filtered });
  }

    render(){
        const columns = [
            {
                Header: 'Nama',
                accessor: 'nama_lengkap',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["nama_lengkap"] }),
                    filterAll: true
            },
            {
                Header: 'Type',
                accessor: 'type',
                style: {textAlign: 'center'},
                Cell: (row) => (
                  <>{this.getType(row.original)}</>
                ),
                filterable: true,
                Filter: () => 
                <select value={this.state.filterType || ''} onChange={(e) => this.SetFilterType(e)}>
                    <option key={0} value=''>Show All</option>
                    <option key={1} value='1'>Cuti</option>
                    <option key={2} value='2'>Day Payment</option>
                </select>,
            },
            {
                Header: 'Durasi',
                accessor: 'duration',
                style: {textAlign: 'center'},
                Cell: (row) => (
                  <>{row.original.duration === 8 ? '1' : <>&#189;</> } Hari</>
                ),
                filterable: true,
                Filter: () => 
                <select value={this.state.filterDuration || ''} onChange={(e) => this.SetFilterDuration(e)}>
                    <option key={0} value=''>Show All</option>
                    <option key={8} value='8'>1 Hari</option>
                    <option key={4} value='4'>&#189; Hari</option>
                </select>,
            },
            {
                Header: 'Description',
                accessor: 'description',
                style: {textAlign: 'center'},
                filterable: false
            },
            {
                Header: 'Tanggal',
                accessor: 'start_date',
                style: {textAlign: 'center'},
                Cell: (row) =>(
                    <>{Moment(row.original.start_date).format('DD/MM/YYYY')}</>
                ),
                filterable: false,
            },
            {
                Header: <b>Status</b>,
                accessor: 'status',
                Cell: (row) => (
                    row.original.status === 1 ? <span className='badge bg-success'>Approved</span> : row.original.status === 0 ? <span className='badge bg-danger'>Rejected</span> : row.original.status === 2 ? <span className='badge bg-info'>Used</span> : row.original.status === 3 ? <span className='badge bg-warning'>Expired</span> : <span className='badge bg-primary'>Waiting</span>
                ),
                style: { textAlign: 'center' },
                filterable: true,
                Filter: () => 
                <select value={this.state.filterStatus || ''} onChange={(e) => this.SetFilterStatus(e)}>
                    <option key={0} value=''>Show All</option>
                    <option key={1} value={1}>Approved </option>
                    <option key={2} value='0'>Rejected</option>
                    <option key={3} value='2'>Used</option>
                    <option key={4} value='3'>Expired</option>
                    <option key={5} value='null'>Waiting</option>
                </select>,
            },
            {
                Header: <b>Action</b>,
                id: 'action',
                filterable: false,
                style: { textAlign: 'center' },
                width:100,
                accessor: str => "action",
                Cell: (row) => (
                    row.original.status === null && row.original.type === 1 ? 
                    <div style={{display:row.original.consultant.code_superior === this.props.dataUser.code_konsultan ? '' : 'none'}}>
                        <button disabled={this.state.disableBtn} onClick={() => this.handleCuti(row.original.id,1)} className='btn btn-info btn-xs'><i className='fa fa-check'></i></button> &nbsp;
                        <button onClick={() => this.handleCuti(row.original.id,0)} className='btn btn-dark btn-xs' ><i className='fa fa-remove'></i></button>
                    </div>
                    :
                    ''
                )
            }
        ]
        return(
            <>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div style={{display: this.props.dataUser.code_roles ==='RL004'? '' : 'none'}} className='card-header'>
                                    <button className='btn btn-success' onClick={this.handleAddDayPayment}> Add Data</button>
                                    <div style={{float:'right'}}>
                                        <ExportCSV csvData={this.state.excelCuti} fileName={"data-history-cuti"} />
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div style={{marginBottom:'10px'}} className="row">
                                        <div className="col-md-3 col-sm-4 mb-2">
                                            <input className="form-control" type="date" onChange={this.handleInput} name="startDate"  value ={this.state.startDate} />
                                        </div>
                                        <div className="col-md-3 col-sm-4 mb-2">
                                            <input className="form-control" type="date" onChange={this.handleInput} name="endDate"  value ={this.state.endDate} />
                                        </div>
                                        <div className="col-md-2 col-sm-6"><Button onClick={() => this.filter()}  color="primary"><i className="fa fa-filter"></i></Button></div>
                                    </div>
                                    <ReactTable
                                        data={this.state.HistoryCuti}
                                        filterable
                                        columns={columns}
                                        defaultPageSize={10}
                                        className="-striped -highlight"
                                        showPaginationBottom={true}
                                        onFilteredChange={(filtered, column, value) => {
                                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                                        }}
                                        filtered={this.state.filtered}
                                        defaultFilterMethod={(filter, row) =>
                                            String(row[filter.id]) === filter.value}
                                    /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModalDP()}
            </>
        )
    }
}

const reduxState = (state) => ({
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(CutiList);