import React, { Fragment } from 'react';

const Loader = (props) => {
    return (
        <Fragment>
          <div className={`loader-wrapper2 ${props.showLoading ? '' : 'loderhide2'}`} >
              <div className="loader-box">
                  <div className="loader">
                      <div className="line bg-primary"></div>
                      <div className="line bg-primary"></div>
                      <div className="line bg-primary"></div>
                      <div className="line bg-primary"></div>
                  </div>
              </div>
          </div>
      </Fragment>
    );
};

export default Loader;