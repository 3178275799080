import React, { Component,Fragment } from 'react'
import axios from 'axios';
import ReactTable from 'react-table';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { getDataUserable } from '../../actions/login.action';
import API_URL from '../../ApiUrl';
import Loader from '../../components/common/loader';
import { connect } from 'react-redux';
import {matchSorter} from 'match-sorter';

class StudentFollowUp extends Component {
    constructor(props){
        super(props)
        this.state = {
            title: '',
            modal: false,
            students: [],
            dataCategory: [],
            row: [],
            status: '',
            code_students_category: '',
            keterangan: '',
            showLoading: false,
            errors: '',
            filtered:[],
        }
    }

    componentDidMount() {
        this.getStudentFollowUp()
        this.getCategory()
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered.length) {
        filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
            if (value === "" || !value.length) filtered.splice(i, 1);
            else filter["value"] = value;

            insertNewFilter = 0;
            }
        });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });
    }

    getStudentFollowUp() {
        this.setState({ showLoading: true })
        let formData = new FormData();
        formData.append('code_roles', this.props.dataUser.code_roles)
        formData.append('code_consultant', this.props.dataUser.code_konsultan)

        const url = API_URL.superStudent+'/student/follow-up-membership';
        axios.post(url, formData, { 
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        }).then(response => response.data.data).then((data) => {
            this.setState({ 
                students: data,
                showLoading: true 
            })
        })
        .catch(error => console.log(error))
    }

    getCategory(){
        const url = API_URL.superStudent+'/master/student-category';
        axios.get(url,{
            headers: {
                'Accept': 'application/json'
            }
        }).then(response => response.data.data)
        .then((data) => {
            this.setState({ dataCategory: data })
        })
    }

    edit(row, status) {
        this.setState({
            modal: true,
            title: 'Update Follow Up',
            row: row,
            status: status
        })
    }

    handleValidation() {
        let formIsValid = true;

        if (!this.state.errors.keteranganStatus){
            formIsValid = false;
        }
        if (!this.state.errors.code_students_categoryStatus){
            formIsValid = false;
        }

        return formIsValid;
    }

    validateKeterangan = () => {
        const data = this.state.keterangan;
        if(data === "" || data === 0 || data === null ) {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);
                errors.keterangan = 'Pilihan Keterangan Harus Diisi';
                errors.keteranganStatus = false;
                return { errors };
            })
        } else {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);
                errors.keterangan = '';
                errors.keteranganStatus = true;
                return { errors };                   
            })
        }
    }

    validateKategori = () => {
        const data = this.state.code_students_category;
        if(data === "" || data === 0 || data === null ) {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.code_students_category = 'Pilihan Kategori Harus Diisi'; 
                errors.code_students_categoryStatus = false;                                 
                return { errors };                   
            })
        } else {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.code_students_category = '';    
                errors.code_students_categoryStatus = true;                             
                return { errors };                   
            })
        }
    }

    handleChange = (e) => {
        this.setState({
          [e.target.name]: e.target.value
        })
    }

    sentData() {
        let formData = new FormData()
        formData.append('code_students', this.state.row.code_students)
        formData.append('code_students_category', this.state.code_students_category)
        formData.append('status_follow_up', this.state.status)
        formData.append('keterangan_follow_up', this.state.keterangan)

        axios.post(API_URL.superStudent+'/student/update-status-follow-up', formData, {
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        })
        .then(() => {
            this.getStudentFollowUp()
            this.setState({ modal: false })
            toast.success("Data Student Berhasil Di Update");
        })
        .catch((error) => {
            console.log(error)
        })
    }

    saveRow() {
        if (this.handleValidation()) {
            this.sentData();
        } else {
            this.validateKeterangan();
            this.validateKategori();
        }
    }

    renderDialog() {
        const { title, modal } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className='form-group'>
                                <label className='f-w-600'>Keterangan</label>
                                <textarea name='keterangan' value={this.state.keterangan} className='form-control' onChange={this.handleChange} onBlur={() => this.validateKeterangan()}/>
                                <span style={{color:"#FF5370"}}>{this.state.errors.keterangan}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="form-row">
                                <label className='f-w-600'>Category</label>
                                <select md="3" className="form-control" name="code_students_category" value={this.state.code_students_category} onChange={this.handleChange} onBlur={() => this.validateKategori()}>
                                    <option value="0">--Category--</option>
                                    {this.state.dataCategory.map((data1)=>(
                                        <option key={data1.code_students_category} value={data1.code_students_category}>{data1.category}</option>
                                    ))}
                                </select>
                                <span style={{color:"#FF5370"}}>{this.state.errors.code_students_category}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveRow()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    render() {
        const columns = [
            {
                Header: 'Code Student',
                accessor: 'code_students',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["code_students"] }),
                filterAll: true,
                Cell: (row) => (
                    <div className="row">
                      <div className="col-md-3">
                        <button style={{border:'none'}} className='btn btn-outline-primary btn-sm btn-pill' type='button' onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/`+row.original.token_students)}>{row.original.code_students}</button>
                      </div>
                    </div>
                ),
            },
            {
                Header: 'Code Member',
                accessor: 'code_member',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["code_member"] }),
                filterAll: true,
            },
            {
                Header: 'Nama',
                accessor: 'nama',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["nama"] }),
                filterAll: true,
            },
            {
                Header: 'Email',
                accessor: 'email',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["email"] }),
                filterAll: true,
            },
            {
                Header: 'No. Whatsapp',
                accessor: 'no_hp',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["no_hp"] }),
                filterAll: true,
            },
            {
                Header: 'Consultant',
                accessor: 'consultant.nama',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["consultant.nama"] }),
                filterAll: true,
            },
            {
                Header: 'University',
                accessor: 'university.nama_sekolah',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["university.nama_sekolah"] }),
                filterAll: true,
            },
            {
                Header: 'Progress',
                accessor: 'last_progress_log.progress.name_progress',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["last_progress_log.progress.name_progress"] }),
                filterAll: true,
            },
            {
                Header: 'Total Day Progress',
                accessor: 'last_progress_log.total_date',
                filterable: false
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>
                        {
                            row.original.status_follow_up == 0 ?
                            <label className='badge bg-danger' onClick={() => this.edit(row.original, 1)}>Follow Up</label>
                            :
                            <label className='badge bg-success'>Done</label>
                        }
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                width:100,
                sortable: false,
                filterable: false
            }
        ]

        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        {
                                            this.state.students.length > 0 ? 
                                            <ReactTable
                                                data={this.state.students}
                                                columns={columns}
                                                collapseOnDataChange={false}
                                                filtered={this.state.filtered}
                                                onFilteredChange={(filtered, column, value) => {
                                                    this.onFilteredChangeCustom(value, column.id || column.accessor);
                                                }}
                                                filterable
                                                defaultPageSize={25}
                                                className="-striped -highlight"
                                            />
                                            :
                                            <Loader showLoading={this.state.showLoading}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialog()}
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
})

const reduxDispatch = (dispatch) =>{
    return {
        getUserableData:(token) => dispatch(getDataUserable(token))
    }
}

export default connect(reduxState, reduxDispatch)(StudentFollowUp)