import axios from 'axios';
import React, { Component, Fragment } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import API_URL from '../../ApiUrl';
import Moment from 'moment';
import DataTableTrainings from '../common/datatable/dataTableTrainings';
import { toast } from 'react-toastify';
import DataTableReportTraining from '../common/datatable/dataTableReportTraining';
import { connect } from 'react-redux';

export class DataTablesTrainings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list_training: [],
            list_training_master: [],
            list_training_master_report: [],
            sub_training: [],
            list_employee: [],
            list_all_employee: [],
            list_employee_training: [],
            addModal: false,
            addParticipant: false,
            editModal: false,
            btnLoading: false,
            training_name: '',
            participants: [],
            participant_check: [],
            category: '',
            id_training_category: '',
            id_sub_training: '',
            code_konsultan_trainer: '',
            trainer_name: '',
            length_of_training: '',
            id_training_employee: '',
            month: Moment().format('MM'),
            checked: [],
            count_all_participant: '',
            count_entire_hour: '',
            count_employee: '',
            avg_hour_per_employee: '',
            report_modal: false,
            row: [],
            count_sub_training: '', 
            count_employee_training: '',
            persentase: ''
        }
    }

    openAddModal() {
        this.setState({addModal: true})
    }
    openAddParticipant = (row) => {
        this.setState({addParticipant: true, id_training_employee: row.id_training_employee, participant_check: row.participant})
    }
    openEditModal = (row) => {
        console.log(row)
        const {list_training_master} = this.state
        const id = row.sub_training.id_training_category;
        const data = list_training_master.filter(m => {
            return m.id_training_category == id
        })
        this.setState({
            editModal: true,
            training_name: row.training_name,
            category: row.category,
            id_training_category: id,
            id_sub_training: row.id_sub_training,
            code_konsultan_trainer: row.code_konsultan_trainer,
            length_of_training: row.length_of_training,
            id_training_employee: row.id_training_employee,
            sub_training: data[0].sub_training,
            participant_check: row.participant
        });
    }
    openDetailReport = (row) => {
        const data = {code_jobdesk: row.code_jobdesk, code_konsultan: row.code_konsultan}
        this.setState({
            report_modal: true,
        })
        this.getDataTrainingMasterByRole(data)
    }
    closeModalAdd(){
        this.setState({addModal: false})
    }
    closeModalParticipant(){
        this.setState({addParticipant: false, id_training_employee: ''})
    }
    closeModalEdit(){
        this.setState({editModal: false})
    }
    closeModalReport(){
        this.setState({report_modal: false})
    }

    setSubKatTraining(e){
        const {list_training_master} = this.state
        const id = e.target.value;
        const data = list_training_master.filter(m => {
            return m.id_training_category == id
        })
        this.setState({category: id, sub_training: data[0].sub_training})
    }

    handleChange(e){
        const {name, value} = e.target
        this.setState({[name]: value});
    }

    handleCheckBox(e){
        const {participants, id_training_employee} = this.state
        const {name, value, checked} = e.target;
        if (checked) {
            this.setState({participants: [...participants, {id_training_employee: id_training_employee, [name]: value}]})
        } else {
            this.setState({participants: participants.filter(el => el.code_konsultan !== value)})
        }
    }

    handleMonth(e){
        this.setState({month: e.target.value})
        this.getDataTrainingPerMonth(e.target.value);
        this.getEmployeeTraining(e.target.value);
    }

    componentDidMount(){
        this.getDataTrainingPerMonth();
        this.getDataTrainingMaster();
        this.getDataEmployee();
        this.getEmployeeTraining();
    }

    countAvgHour(hour, employee){
        const avg = hour / employee
        const avg_hour_per_employee = (Math.round(avg * 100) / 100).toFixed(2)
        return avg_hour_per_employee;
    }

    saveData(method){
        this.setState({btnLoading: true})
        const {training_name, category, id_sub_training, trainer_name, length_of_training, code_konsultan_trainer, id_training_employee, participants} = this.state
        const data = {
            training_name: training_name,
            category: category,
            id_sub_training: id_sub_training,
            category: category,
            code_konsultan_trainer: code_konsultan_trainer,
            trainer_name: trainer_name,
            length_of_training: length_of_training,
            method: method,
            participants: this.state.participants,
            id_training_employee: id_training_employee
        }
        
        axios.post(API_URL.superStudent + '/training/add-training-list', data,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(res => {
            this.setState({btnLoading: false})
            if (method === 'add') {
                let id = res.data.data.id_training_employee;
                let temp = [];
                for (let i = 0; i < participants.length; i++) {
                   temp.push({id_training_employee: id, code_konsultan: participants[i].code_konsultan})
                }
                    axios.post(API_URL.superStudent + '/training/add-participants', temp)
                    .then(res => {
                        this.setState({btnLoading: false})
                        this.clearState()
                        toast.success(`Training Added Successfully`)
                        this.closeModalAdd();
                        this.getDataTrainingPerMonth(this.state.month);
                        this.getEmployeeTraining(this.state.month);
                    })
                    .catch(err => console.log(err))
            } else {
                toast.success(`Training Edited Successfully`)
                this.getDataTrainingPerMonth(this.state.month);
                this.getEmployeeTraining(this.state.month);
                this.closeModalEdit();
            }
        })
        .catch(err => console.log(err))
    }

    clearState(){
        this.setState({
            training_name: '',
            category: '',
            id_sub_training: '',
            category: '',
            code_konsultan_trainer: '',
            length_of_training: '',
            method: '',
            participants: [],
        })
    }

    saveParticipants(){
        this.setState({btnLoading: true})
        const {participants} = this.state
        axios.post(API_URL.superStudent + '/training/add-participants', participants,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(res => {
            this.setState({btnLoading: false})
            toast.success(`Participants Added Successfully`)
            this.closeModalParticipant();
            this.getDataTrainingPerMonth(this.state.month);
            this.getEmployeeTraining(this.state.month);
            this.clearState();
        })
        .catch(err => console.log(err))
    }

    deleteParticipant = (id) => {
        axios.post(API_URL.superStudent + '/training-list/participant/delete', {id_training_list: id},{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(res => {
            toast.success(`Participants Added Successfully`)
            this.getDataTrainingPerMonth(this.state.month);
            this.getEmployeeTraining(this.state.month);
        })
        .catch(err => console.log(err))
    }

    getDataEmployee(){
        axios.get(API_URL.superStudent+'/consultant/all-employee',{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => response.data.data)
        .then(result => {
            const employee = result.filter((m) => m.code_jobdesk !== 'JD001');
            this.setState({ list_all_employee: result, list_employee: employee, count_employee: employee.length})
        })
        .catch(error => console.log(error))
    }

    getDataTrainingMasterByRole(data){
        axios.post(API_URL.superStudent + '/training/training-by-role', data,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
          .then(response => response.data)
          .then(result => {
            let sub_training = [];
            let list_training = [];
            for (let i = 0; i < result.length; i++) {
              sub_training = result[i].sub_training;
              for (let j = 0; j < sub_training.length; j++) {
                list_training.push(sub_training[j])            
              }
            }
          const sub = list_training.filter((m) => m.is_training === true)
          const count_sub_training = list_training.length
          const count_employee_training = list_training.reduce((n, {training_list_count}) => n + training_list_count, 0);
          const persentase = Math.round((sub.length / count_sub_training) * 100)
    
          this.setState({ 
            list_training_master_report: result,
            count_sub_training: count_sub_training, 
            count_employee_training: count_employee_training,
            persentase: persentase
          })
            
        })
          .catch(error => console.log(error))
      }

    getEmployeeTraining(month){
        axios.post(API_URL.superStudent + '/training-list/employee-training', {month: month ?? this.state.month},{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
          .then(response => response.data)
          .then(result => {
            const employee = result.filter((m) => m.code_jobdesk !== 'JD001');
            let training = []
            for (let i = 0; i < employee.length; i++) {
                for (let j = 0; j < training.length; j++) {
                    training = [...training, employee[i].training[j]]   
                }              
            }
            this.setState({ list_employee_training: employee})
        })
          .catch(error => console.log(error))
    }

    getDataTrainingPerMonth(month){
        axios.post(API_URL.superStudent + '/training-list/month', {month: month ?? this.state.month},{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
          .then(response => response.data)
          .then(result => {
            const count_all_participant = result.reduce((n, {participant_count}) => n + participant_count, 0)
            for (let i = 0; i < result.length; i++) {
                let sum_hour = result[i].length_of_training * result[i].participant_count
                 result[i].total_hours = sum_hour;
            }
            const count_entire_hour = result.reduce((n, {total_hours}) => n + total_hours, 0)
            this.setState({
                list_training: result, 
                count_all_participant: count_all_participant, 
                count_entire_hour: count_entire_hour,
            })
        })
          .catch(error => console.log(error))
    }

    getDataTrainingMaster(){
        axios.get(API_URL.superStudent + '/training',{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
          .then(response => response.data)
          .then(result => {
            this.setState({ list_training_master: result})
        })
          .catch(error => console.log(error))
    }

    renderAddModal(){
        return(
            <Modal isOpen={this.state.addModal} toggle={() => this.closeModalAdd()} size='lg'>
                <ModalHeader toggle={() => this.closeModalAdd()}>Training</ModalHeader>
                <ModalBody>
                    <form>  
                        <div class="form-group">
                            <label htmlFor="title">Judul Training</label>
                            <input autoFocus type="text" name='training_name' onChange={(e) => this.handleChange(e)} class="form-control" id="training_name" placeholder='Judul Training' />
                        </div>
                        <div class="form-group">
                            <label htmlFor="department">Training Category</label>
                            <select name='category' className='form-control' id='department' onChange={(e) => this.setSubKatTraining(e)}>
                                <option value="">Pilih Kategori Training</option>
                            {
                                this.state.list_training_master.map((m, i)=>{
                                   return (
                                    <option key={i} value={m.id_training_category}>{m.category}</option>
                                   ) 
                                })
                            }
                            </select>
                        </div>
                        <div class="form-group">
                            <label htmlFor="pendidikan">Jenis Training</label>
                            <select name='id_sub_training' className='form-control' id='pendidikan' onChange={(e) => this.handleChange(e)}>
                                <option value="">Pilih Jenis Training</option>
                                {
                                this.state.sub_training?.map((m, i)=>{
                                   return (
                                    <option key={i} value={m.id_sub_category}>{m.sub_category}</option>
                                   ) 
                                })
                            }
                            </select>
                        </div>
                        <div class="form-group">
                            <label htmlFor="trainer">Pilih PIC</label>
                            <select name='code_konsultan_trainer' className='form-control' id='trainer' onChange={(e) => this.handleChange(e)}>
                                <option value="">Pilih PIC</option>
                                {
                                this.state.list_all_employee?.map((m, i)=>{
                                   return (
                                    <option key={i} value={m.code_konsultan}>{m.nama}</option>
                                   ) 
                                })
                            }
                            </select>
                        </div>
                        <div class="form-group">
                            <label htmlFor="trainer_name">Nama Trainer</label>
                            <input autoFocus type="text" name='trainer_name' onChange={(e) => this.handleChange(e)} class="form-control" id="trainer_name" placeholder='Judul Training' />
                        </div>
                        <div class="form-group">
                            <label htmlFor="duration">Durasi Training</label>
                            <select name='length_of_training' className='form-control' id='duration' onChange={(e) => this.handleChange(e)}>
                                <option value="">Pilih Durasi Training</option>
                                <option value="1">1 Jam</option>
                                <option value="1.5">1.5 Jam</option>
                                <option value="2">2 Jam</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="none">Participants</label>
                            <div className="row">
                            {
                                this.state.list_employee?.map((m,i)=>{
                                    return (
                                    <div key={i} className="col-3">
                                        <div className="form-check">
                                            <input className="form-check-input" name='code_konsultan' onChange={(e) => this.handleCheckBox(e)} type="checkbox" value={m.code_konsultan} id={m.code_konsultan} />
                                            <label className="form-check-label" htmlFor={m.code_konsultan}>
                                                {m.nama}
                                            </label>
                                        </div>
                                    </div>
                                    )
                                })
                            }
                            </div>
                        </div>

                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnLoading && true} color="primary" onClick={() => this.saveData('add')}>Add Training</Button>
                    <Button color="secondary" onClick={() => this.closeModalAdd()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderEditModal(){
        return(
            <Modal isOpen={this.state.editModal} toggle={() => this.closeModalEdit()} size='lg'>
                <ModalHeader toggle={() => this.closeModalEdit()}>Training</ModalHeader>
                <ModalBody>
                    <form>  
                        <div class="form-group">
                            <label htmlFor="title">Judul Training</label>
                            <input autoFocus type="text" name='training_name' value={this.state.training_name} onChange={(e) => this.handleChange(e)} class="form-control" id="training_name" placeholder='Judul Training' />
                        </div>
                        <div class="form-group">
                            <label htmlFor="department">Kategori Training</label>
                            <select name='category' className='form-control' defaultValue={this.state.id_training_category} id='department' onChange={(e) => this.setSubKatTraining(e)}>
                                <option value="">Pilih Kategori Training</option>
                            {
                                this.state.list_training_master.map((m, i)=>{
                                   return (
                                    <option key={i} value={m.id_training_category}>{m.category}</option>
                                   ) 
                                })
                            }
                            </select>
                        </div>
                        <div class="form-group">
                            <label htmlFor="pendidikan">Jenis Training</label>
                            <select name='id_sub_training' className='form-control' defaultValue={this.state.id_sub_training} id='pendidikan' onChange={(e) => this.handleChange(e)}>
                                <option value="">Pilih Jenis Training</option>
                                {
                                this.state.sub_training?.map((m, i)=>{
                                   return (
                                    <option key={i} value={m.id_sub_category}>{m.sub_category}</option>
                                   ) 
                                })
                            }
                            </select>
                        </div>
                        <div class="form-group">
                            <label htmlFor="trainer">Pilih Trainer</label>
                            <select name='code_konsultan_trainer' defaultValue={this.state.code_konsultan_trainer} className='form-control' id='trainer' onChange={(e) => this.handleChange(e)}>
                                <option value="">Pilih Trainer</option>
                                {
                                this.state.list_employee?.map((m, i)=>{
                                   return (
                                    <option key={i} value={m.code_konsultan}>{m.nama}</option>
                                   ) 
                                })
                            }
                            </select>
                        </div>
                        <div class="form-group">
                            <label htmlFor="duration">Durasi Training</label>
                            <select name='length_of_training' defaultValue={this.state.length_of_training} className='form-control' id='duration' onChange={(e) => this.handleChange(e)}>
                                <option value="">Pilih Durasi Training</option>
                                <option value="1">1 Jam</option>
                                <option value="1.5">1.5 Jam</option>
                                <option value="2">2 Jam</option>
                            </select>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnLoading && true} color="primary" onClick={() => this.saveData('edit')}>Update Training</Button>
                    <Button color="secondary" onClick={() => this.closeModalEdit()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderAddParticipants(){
        let {list_employee, participant_check} = this.state
        const checked = list_employee.filter((item1) => !participant_check.some(item2 => item1.code_konsultan === item2.code_konsultan));
        return (
            <Modal isOpen={this.state.addParticipant} toggle={() => this.closeModalParticipant()} size='lg'>
                <ModalHeader toggle={() => this.closeModalParticipant()}>Add Participants</ModalHeader>
                <ModalBody>
                    <form>
                        <div className="form-group">
                            <label htmlFor="none">Participants</label>
                            <div className="row">
                            {
                                checked?.map((m,i)=>{
                                    return (
                                    <div key={i} className="col-3">
                                        <div className="form-check">
                                            <input className="form-check-input" name='code_konsultan' onChange={(e) => this.handleCheckBox(e)} type="checkbox" value={m.code_konsultan} id="defaultCheck1" />
                                            <label className="form-check-label" htmlFor="defaultCheck1">
                                                {m.nama}
                                            </label>
                                        </div>
                                    </div>
                                    )
                                })
                            }
                            </div>
                        </div>    
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnLoading && true} color="primary" onClick={() => this.saveParticipants()}>Add Participants</Button>
                    <Button color="secondary" onClick={() => this.closeModalParticipant()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderReportModal(){
        const {count_employee_training, count_sub_training, persentase} = this.state
        return (
            <>
            <Modal isOpen={this.state.report_modal} toggle={() => this.closeModalReport()} size='lg'>
                <ModalHeader toggle={() => this.closeModalReport()}>Report All Training</ModalHeader>
                <ModalBody>
                <div className='card-body'>
                    <h5>Total Training Participated : {count_employee_training}/{count_sub_training} ({persentase}%)</h5>
                    <div className="table-responsive mb-5">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            {this.state.list_training_master_report.map((m,i)=>{
                              return (<th key={i} scope="col" className='text-center' style={{backgroundColor: 'red', color: '#fff'}} colSpan={m.sub_training_count}>{m.category}</th>)
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {this.state.list_training_master_report.map((m,i)=>{
                              return m.sub_training.map((j, o)=>{
                                return (<td key={o} width={200}>{j.sub_category}</td>)
                              })
                            })}
                          </tr>
                          <tr>
                          {this.state.list_training_master_report.map((m,i)=>{
                              return m.sub_training.map((j, o)=>{
                                return (<td key={o} className='text-center'>{j.is_training === false ? <i className='fa fa-times text-danger'></i> 
                                : 
                                  j.training_list?.map((t, m) => {
                                    return (
                                    <>
                                      <i key={m} className='fa fa-star text-warning mr-3' id={`UncontrolledTooltipExample1${m}`}></i>
                                      {/* <UncontrolledTooltip placement="top" target={`UncontrolledTooltipExample1${m}`}>
                                          <p>{t.training_name} ({t.training_date})</p>
                                      </UncontrolledTooltip>  */}
                                    </>
                                    )
                                  })
                                }</td>)
                              })
                            })}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                </div>
                </ModalBody>
                <ModalFooter>
                    {/* <Button disabled={this.state.btnLoading && true} color="primary" onClick={() => this.saveParticipants()}>Add Participants</Button> */}
                    <Button color="secondary" onClick={() => this.closeModalReport()}>Cancel</Button>
                </ModalFooter>
            </Modal>
            </>
        )
    }

  render() {
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body datatable-react">
                                {/* <h3 className='text-center'>{listKandidat[0]?.job_vacancy.title}</h3> */}
                                <div className="row">
                                    <div className="col-lg-2">
                                        <button onClick={() => this.openAddModal()} className='btn btn-success mb-4'>Add Training +</button>
                                    </div>
                                    <div className='col-lg-2'>
                                        <div className="form-group">
                                            <select name='id_sub_training' defaultValue={this.state.month} className='form-control' id='pendidikan' onChange={(e) => this.handleMonth(e)}>
                                                <option value="">All Month</option>
                                                <option value="1">Januari</option>
                                                <option value="2">Februari</option>
                                                <option value="3">Mare</option>
                                                <option value="4">April</option>
                                                <option value="5">Mei</option>
                                                <option value="6">Juni</option>
                                                <option value="7">Juli</option>
                                                <option value="8">Agustust</option>
                                                <option value="9">September</option>
                                                <option value="10">Oktober</option>
                                                <option value="11">November</option>
                                                <option value="12">Desember</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <DataTableTrainings 
                                    myData={this.state.list_training}
                                    addParticipants={this.openAddParticipant}
                                    deleteParticipant={this.deleteParticipant}
                                    editTraining={this.openEditModal}
                                    title="Kategori Training" 
                                    pageSize={15}
                                    pagination={true}
                                    class="-striped -highlight"
                                /> 

                                <div className="col-8">
                                    <table className='table mt-5 table-bordered text-center'>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>General Information</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='text-left fw-bold'><strong>Number of Employee</strong></td>
                                                <td>{this.state.count_employee}</td>
                                            </tr>
                                            <tr>
                                                <td className='text-left fw-bold'><strong>Total Participant</strong></td>
                                                <td>{this.state.count_all_participant}</td>
                                            </tr>
                                            <tr>
                                                <td className='text-left fw-bold'><strong>Total Hours All Training</strong></td>
                                                <td>{this.state.count_entire_hour} hours</td>
                                            </tr>
                                            <tr>
                                                <td className='text-left fw-bold'><strong>Total number of training hours per employee</strong></td>
                                                <td>{this.countAvgHour(this.state.count_entire_hour, this.state.count_employee)} hours</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h4>Employee Training Reports</h4>
                            </div>
                            <div className="card-body">
                                <DataTableReportTraining 
                                    myData={this.state.list_employee_training}
                                    openDetailReport={this.openDetailReport}
                                    title="Kategori Training"
                                    pageSize={15}
                                    pagination={true}
                                    class="-striped -highlight"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {this.renderAddModal()}
            {this.renderEditModal()}
            {this.renderAddParticipants()}
            {this.renderReportModal()}
        </Fragment>
    )
  }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
  })
  
export default connect(reduxState, null)(DataTablesTrainings);