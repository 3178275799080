import React, { useState, useEffect } from 'react'
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import Moment from 'moment';
import { useSelector } from "react-redux"

const AdmissionTastkBranch = ({start_date, end_date}) => {

    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    }));

    const { dataUser } = useSelector(state => ({
        dataUser:state.Login.detailUser
    }))

    const[todoList,setTodoList] = useState([])

    const loadTodoList = () =>{
        let formData = new FormData ()
        formData.append('code_jobdesk','JD006')
        formData.append('start_date', start_date)
        formData.append('end_date', end_date)
        formData.append('limit',true)
        formData.append('status',true)
        formData.append('code_konsultan',dataUser.code_konsultan)
        axios.post(API_URL.superStudent+'/todo/admission-task-branch', formData, {
            headers:{ Authorization: `Bearer ${dataToken}` }
        })
        .then(response => response.data.data)
        .then(result => {
            setTodoList(result)
        })
        .catch(error => console.log(error))
    }

    useEffect(() => {
        loadTodoList()
    },[start_date, end_date])

    return(
        <>
            <div className="card height-equal">
                <div className="card-header">
                    <h6 className='f-w-600'>Admission Task Branch</h6>
                </div>
                <div style={{height:430}} className="card-body scroll recent-notification">
                    {
                        todoList.length > 0 ?
                        todoList.map((item,index) => (
                        <div style={{marginBottom:10}} key={index} className="media">
                            <strong>{Moment(item.created_at).format('DD MMM')}</strong>
                            <div style={{cursor:'pointer'}} onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/`+item.student.token_students)} className="media-body">
                                <span style={{fontSize:11}}>{item.student != null ? item.student.nama : ''}</span>
                               <div></div>
                                <span style={{color:'black', fontSize:12}}>by {item.user.username}, Request <strong>{item.category != null ? item.category.name : 'general'} {item.category.id === 10 ? `ke ${item.university.nama_sekolah}`:''}</strong></span> 
                            </div>
                            <div style={{float:'right'}}><span className={'badge bg-'+item.status.label}>{item.status.name}</span></div>
                        </div> 
                        ))
                        :
                        'Data Kosong'
                    }             
                </div>
                <div onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/visa-admission/}`)} className="card-footer btn-more text-center"><a  href="#javascript">MORE...</a></div>
            </div>
        </>
    )

}

export default AdmissionTastkBranch

