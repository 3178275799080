import React, { Component } from 'react'
import ReactTable from 'react-table'
import API_URL from '../../ApiUrl'
import axios from 'axios'
import {matchSorter} from 'match-sorter'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { toast } from 'react-toastify';
import Moment from 'moment';
import Loader from '../../components/common/loader'
import SweetAlert from 'react-bootstrap-sweetalert'
import setRangeDate from '../../helper/general-function'
import StudentGrantedLocation from '../../components/dashboard/partition/student-granted-location'

class HomeAdmission extends Component{

    constructor(props){
        super(props)
        this.state = {
            dataPrecetasi:[],
            detailPresentasi:[],
            taskListAdmission:[],
            filtered:[],
            std_apply:[],
            std_loa:[],
            std_school_fe:[],
            std_lodge_visa:[],
            std_granted:[],
            std_departure:[],
            std_start_study:[],
            std_reject:[],
            filterEdugroup:'',
            startDate: '',
            endDate: '',
            code_school_rep:'',
            target:'',
            dateType: 'this_year',
            modal_update:false,
            modal_detail:false,
            buttonText:'Update',
            disableBtn:false,
            showLoading: false,
            staticFilter:'',
            loader:false,
            dinamicFilter:'none',
        }
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    handleDate = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        },() => {
            if(this.state.dateType === 'dinammic_date'){
                this.setState({
                    dinamicFilter:'',
                    staticFilter:'none'
                })
            }
            else{
                this.setDate()
            }
        })
    }

    setDate(){   
        let data = setRangeDate(this.state.dateType)    
        this.setState({ 
            startDate : data.start,
            endDate : data.end
        },() => this.filterData())
    }

    filterData(){
        this.countTaskListConsultant()
        this.countPrecetageGranted()
    }

    countPrecetageGranted = async () => {
        var formData = new FormData();
        formData.append('start_date', this.state.startDate)
        formData.append('end_date', this.state.endDate)
        this.setState({ showLoading: true, dataPrecetasi:[] })
        await axios.post(API_URL.superStudent+'/granted-task', formData,{
            headers:{ Authorization: `Bearer `+this.props.dataToken }
        })
        .then(response => this.setState({dataPrecetasi: response.data.data, showLoading:false}))
        .catch(error => {
            this.setState({showLoading:false})
        })
    }

    countTaskListConsultant = async () => {
        var formData = new FormData();
        formData.append('start_date', this.state.startDate)
        formData.append('end_date', this.state.endDate)
        this.setState({ showLoading: true, taskListAdmission:[] })
        await axios.post(API_URL.superStudent+'/auth/report-consultant-task', formData,{
            headers:{ Authorization: `Bearer `+this.props.dataToken }
        })
        .then(response => {
            this.setState({taskListAdmission: response.data.data, showLoading:false})
        })
        .catch(error => {
            this.setState({showLoading:false})
        })
    }

    editTarget = (row) => {
        this.setState({
            modal_update: true,
            code_school_rep:row.code_school_rep,
            target: row.target_application
        })
    }

    saveUpdate = async () => {
        this.setState({
            buttonText:'Updating...',
            disableBtn:true,
        })
        let formData = new FormData()
        formData.append('code_school_rep', this.state.code_school_rep)
        formData.append('target', this.state.target)
        await axios.post(API_URL.superStudent+'/update-target-application', formData, {
            headers:{ Authorization: `Bearer `+this.props.dataToken }
        })
        .then(response => {
            this.countPrecetageGranted()
            toast.success('Target Application successfully updated')
            this.setState({
                buttonText:'Update',
                disableBtn:false,
                modal_update:false
            })
        })
        .catch(error => {
            toast.error('something Error')
            this.setState({
                buttonText:'Update',
                disableBtn:false,
                modal_update:false
            })
        })
    }

    showDetailStudent = (code_school_rep) => {
        this.setState({
            loader: true,
            std_apply:[],
            std_loa:[],
            std_school_fe:[],
            std_lodge_visa:[],
            std_granted:[],
            std_departure:[],
            std_start_study:[],
            std_reject:[],
        })

        let form = new FormData()
        form.append('code_school_rep', code_school_rep)
        form.append('start_date', this.state.startDate)
        form.append('end_date', this.state.endDate)
        axios.post(API_URL.superStudent+'/detail-student-granted', form,{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            this.setState({
                modal_detail: true,
                loader:false,
                detailPresentasi:response.data.data
            }, () => {
                this.state.detailPresentasi[0].todo.map((detail) => {
                    if (detail.student != null) {
                        if (detail.student.code_progress == 'PG006') {
                            this.setState(prevState => ({
                                std_apply: [...prevState.std_apply, detail.student]
                            }));
                        } else if(detail.student.code_progress == 'PG008') {
                            detail.student.programs.map((program) => {
                                if (program.school_rep_id == this.state.detailPresentasi[0].id_school_rep && program.pivot.status == 3) {
                                    this.setState(prevState => ({
                                        std_loa: [...prevState.std_loa, detail.student]
                                    }));
                                }
                            })
                        } else if(detail.student.code_progress == 'PG009') {
                            detail.student.programs.map((program) => {
                                if (program.school_rep_id == this.state.detailPresentasi[0].id_school_rep && program.pivot.status == 1) {
                                    this.setState(prevState => ({
                                        std_school_fe: [...prevState.std_school_fe, detail.student]
                                    }));
                                }
                            })
                        } else if(detail.student.code_progress == 'PG010') {
                            detail.student.programs.map((program) => {
                                if (program.school_rep_id == this.state.detailPresentasi[0].id_school_rep && program.pivot.status == 1) {
                                    this.setState(prevState => ({
                                        std_lodge_visa: [...prevState.std_lodge_visa, detail.student]
                                    }));
                                }
                            })
                        } else if(detail.student.code_progress == 'PG015') {
                            detail.student.programs.map((program) => {
                                if (program.school_rep_id == this.state.detailPresentasi[0].id_school_rep && program.pivot.status == 1) {
                                    this.setState(prevState => ({
                                        std_granted: [...prevState.std_granted, detail.student]
                                    }));
                                }
                            })
                        } else if(detail.student.code_progress == 'PG013') {
                            detail.student.programs.map((program) => {
                                if (program.school_rep_id == this.state.detailPresentasi[0].id_school_rep && program.pivot.status == 1) {
                                    this.setState(prevState => ({
                                        std_departure: [...prevState.std_departure, detail.student]
                                    }));
                                }
                            })
                        } else if(detail.student.code_progress == 'PG016') {
                            detail.student.programs.map((program) => {
                                if (program.school_rep_id == this.state.detailPresentasi[0].id_school_rep && program.pivot.status == 1) {
                                    this.setState(prevState => ({
                                        std_start_study: [...prevState.std_start_study, detail.student]
                                    }));
                                }
                            })
                        } else if(detail.student.code_progress == 'PG018') {
                            detail.student.programs.map((program) => {
                                if (program.school_rep_id == this.state.detailPresentasi[0].id_school_rep && program.pivot.status == 1) {
                                    this.setState(prevState => ({
                                        std_reject: [...prevState.std_reject, detail.student]
                                    }));
                                }
                            })
                        } 
                    }
                });
            })
        })
        .catch(error => console.log(error))

    }

    componentDidMount(){
        this.setDate()
        this.countPrecetageGranted()
        this.countTaskListConsultant()
    }

    normalisasi = (value) => {
        return value.toFixed(2);
    }

    renderModalUpdate(){
        return(
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modal_update} toggle={() => this.setState({ modal_update: false })}>
                <ModalHeader toggle={() => this.setState({ modal_update: false })}>Update Data</ModalHeader>
                <ModalBody>
                <form >
                    <div className='row'>
                        <div className='col-md-12'>
                            <h5>{this.state.title}</h5>
                            <div className='form-group'>
                                <label className='control-label'>Target Application</label>
                                <input type='text' className='form-control' name='target' value={this.state.target} onChange={this.handleInput} />
                            </div>
                        </div>
                    </div>
                </form>
                </ModalBody>
                <ModalFooter>
                  <Button disabled={this.state.disableBtn} color="primary" onClick={(e) => this.saveUpdate()}>{this.state.buttonText}</Button>
                  <Button  color="secondary" onClick={() => this.setState({ modal_update: false })}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
        )
    }

    renderModalDetail() {
        return(
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modal_detail} toggle={() => this.setState({ modal_detail: false })} size="lg">
                <ModalHeader toggle={() => this.setState({ modal_detail: false })}>Detail Data</ModalHeader>
                <ModalBody>
                <div className='row'>
                    {
                        this.state.std_apply.length > 0 ?
                        <div className='col-lg-6 col-md-12 mt-2'>
                            <ul>
                                <li className='f-w-600'>Apply Campus</li>
                                {
                                    this.state.std_apply.map((items, index) => (
                                        <li key={index}><i className='fa fa-caret-right txt-secondary m-r-10'></i> <span style={{cursor:'pointer'}} onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/` + items.token_students)}>({items.code_students})</span> {items.nama} - {items.consultant.nama}</li>
                                    ))
                                }
                            </ul>
                        </div>
                        :
                        ''
                    }
                    <br/>
                    {
                        this.state.std_loa.length > 0 ?
                        <div className='col-lg-6 col-md-12 mt-2'>
                            <ul>
                                <li className='f-w-600'>LoA</li>
                                {
                                    this.state.std_loa.map((items, index) => (
                                        <li key={index}><i className='fa fa-caret-right txt-secondary m-r-10'></i> <span style={{cursor:'pointer'}} onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/` + items.token_students)}>({items.code_students})</span> {items.nama} - {items.consultant.nama}</li>
                                    ))
                                }
                            </ul>
                        </div>
                        :
                        ''
                    }
                    <br/>
                    {
                        this.state.std_school_fe.length > 0 ?
                        <div className='col-lg-6 col-md-12 mt-2'>
                            <ul>
                                <li className='f-w-600'>School Fee</li>
                                {
                                    this.state.std_school_fe.map((items, index) => (
                                        <li key={index}><i className='fa fa-caret-right txt-secondary m-r-10'></i> <span style={{cursor:'pointer'}} onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/` + items.token_students)}>({items.code_students})</span> {items.nama} - {items.consultant.nama}</li>
                                    ))
                                }
                            </ul>
                        </div>
                        :
                        ''
                    }
                    <br/>
                    {
                        this.state.std_lodge_visa.length > 0 ?
                        <div className='col-lg-6 col-md-12 mt-2'>
                            <ul>
                                <li className='f-w-600'>Lodge Visa</li>
                                {
                                    this.state.std_lodge_visa.map((items, index) => (
                                        <li key={index}><i className='fa fa-caret-right txt-secondary m-r-10'></i> <span style={{cursor:'pointer'}} onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/` + items.token_students)}>({items.code_students})</span> {items.nama} - {items.consultant.nama}</li>
                                    ))
                                }
                            </ul>
                        </div>
                        :
                        ''
                    }
                    <br/>
                    {
                        this.state.std_granted.length > 0 ?
                        <div className='col-lg-6 col-md-12 mt-2'>
                            <ul>
                                <li className='f-w-600'>Granted</li>
                                {
                                    this.state.std_granted.map((items, index) => (
                                        <li key={index}><i className='fa fa-caret-right txt-secondary m-r-10'></i> <span style={{cursor:'pointer'}} onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/` + items.token_students)}>({items.code_students})</span> {items.nama} - {items.consultant.nama}</li>
                                    ))
                                }
                            </ul>
                        </div>
                        :
                        ''
                    }
                    <br/>
                    {
                        this.state.std_departure.length > 0 ?
                        <div className='col-lg-6 col-md-12 mt-2'>
                            <ul>
                                <li className='f-w-600'>Departure</li>
                                {
                                    this.state.std_departure.map((items, index) => (
                                        <li key={index}><i className='fa fa-caret-right txt-secondary m-r-10'></i> <span style={{cursor:'pointer'}} onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/` + items.token_students)}>({items.code_students})</span> {items.nama} - {items.consultant.nama}</li>
                                    ))
                                }
                            </ul>
                        </div>
                        :
                        ''
                    }
                    <br/>
                    {
                        this.state.std_start_study.length > 0 ?
                        <div className='col-lg-6 col-md-12 mt-2'>
                            <ul>
                                <li className='f-w-600'>Start Study</li>
                                {
                                    this.state.std_start_study.map((items, index) => (
                                        <li key={index}><i className='fa fa-caret-right txt-secondary m-r-10'></i> <span style={{cursor:'pointer'}} onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/` + items.token_students)}>({items.code_students})</span> {items.nama} - {items.consultant.nama}</li>
                                    ))
                                }
                            </ul>
                        </div>
                        :
                        ''
                    }
                    <br/>
                    {
                        this.state.std_reject.length > 0 ?
                        <div className='col-lg-6 col-md-12 mt-2'>
                            <ul>
                                <li className='f-w-600'>Rejected</li>
                                {
                                    this.state.std_reject.map((items, index) => (
                                        <li key={index}><i className='fa fa-caret-right txt-secondary m-r-10'></i> <span style={{cursor:'pointer'}} onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/` + items.token_students)}>({items.code_students})</span> {items.nama} - {items.consultant.nama}</li>
                                    ))
                                }
                            </ul>
                        </div>
                        :
                        ''
                    }
                </div>
                </ModalBody>
                <ModalFooter>
                  <Button  color="secondary" onClick={() => this.setState({ modal_detail: false })}>Close</Button>
                </ModalFooter>
              </Modal>
            </div>
        )
    }

    render(){
        const columns = [
            {
                Header: 'Instansi',
                accessor: 'nama_sekolah',
                width:200,
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["nama_sekolah"] }),
                filterAll: true
            },
            {
                Header: 'Edu Group',
                accessor:'edu_group',
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["edu_group"] }),
                filterAll: true,
                style: {textAlign: 'center'},
            },
            {
                Header: 'Application',
                accessor: 'total_todo',
                width:100,
                style: {textAlign: 'center'},
                filterable: false,
                Cell:(row) => (
                    <>
                        <span style={{color:'#7c9ed9'}}>{row.original.total_todo}</span> | <span style={{color:'red'}}>{row.original.target_application}</span>
                    </>
                )
            },
            {
                Header: 'Apply Campus',
                accessor: 'apply_campus',
                width:100,
                style: {textAlign: 'center'},
                filterable: false,
            },
            {
                Header: 'LoA',
                accessor: 'LoA',
                width:80,
                style: {textAlign: 'center'},
                filterable: false
            },
            {
                Header: 'School Fee',
                accessor: 'school_fee',
                width:100,
                style: {textAlign: 'center'},
                filterable: false
            },
            {
                Header: 'Lodge Visa',
                accessor: 'lodge_visa',
                width:100,
                style: {textAlign: 'center'},
                filterable: false
            },
            {
                Header: 'Granted',
                accessor: 'granted',
                width:90,
                style: {textAlign: 'center'},
                filterable: false
            },
            {
                Header: 'Student Departure',
                accessor: 'student_departure',
                width:100,
                style: {textAlign: 'center'},
                filterable: false
            },
            {
                Header: 'Start Study',
                accessor: 'start_study',
                width:90,
                style: {textAlign: 'center'},
                filterable: false
            },
            {
                Header: 'Rejected',
                accessor: 'rejected',
                width:90,
                style: {textAlign: 'center'},
                filterable: false
            },
            {
                Header: 'Percentage',
                accessor: 'peformance',
                style: {textAlign: 'center'},
                filterable: false,
                width:100,
                Cell:(row) => (
                    this.normalisasi((row.original.granted / row.original.total_todo) * 100) + ' %'
                )
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                width:70,
                style: {textAlign: 'center'},
                filterable: false,
                accessor: str => "delete",
                Cell: (row) => (
                    <>
                        <button
                        onClick={()=> this.showDetailStudent(row.original.code_school_rep)} 
                          className="btn btn-success btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-eye"></i>
                        </button> &nbsp;
                    <button
                        onClick={()=> this.editTarget(row.original)} 
                          className="btn btn-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil"></i>
                        </button>
                    </>
                )
            }
        ];

        const columns_task = [
            {
                Header: 'Nama',
                accessor: 'username',
                width:120,
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["username"] }),
                filterAll: true
            },
            {
                Header: 'Pendaftaran Sekolah',
                accessor: 'task_pendaftaran_sekolah',
                style: {textAlign: 'center'},
                filterable: false,
                 Cell:(row) => (
                    <>
                        <span>{row.original.task_pendaftaran_sekolah.length}</span> 
                    </>
                )
            },
            {
                Header: 'Lodge Visa',
                accessor: 'task_visa',
                style: {textAlign: 'center'},
                filterable: false,
                Cell:(row) => (
                    <>
                        <span>{row.original.task_visa.length}</span> 
                    </>
                )
            },
        ];

        const columns_sub_task = [
            {
                Header: 'Nama',
                accessor: 'student.nama',
                style: {textAlign: 'center'},
                filterable: false,
                Cell:(row) => (
                    <>
                       <span>({row.original.student.code_students})</span> <span>{row.original.student.nama}</span> 
                    </>
                )
            },
            {
                Header: 'University',
                accessor: 'university.nama_sekolah',
                style: {textAlign: 'center'},
                filterable: false,
                Cell:(row) => (
                    <>
                        <span>{row.original.university == null ? '' : row.original.university.nama_sekolah }</span> 
                    </>
                )
            },
        ];

        return(
            <>
            <SweetAlert title="Loading..." showConfirm={false} showCancel={false} show={this.state.loader} />
                <div className='container-fluid'>
                    <div className='row justify-content-center'>
                        <div className='col-sm-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    <div className='row'>
                                        <div className='col-sm-5'>
                                            <h5>Campus Application Peformance</h5>
                                        </div>
                                        <div style={{display:this.state.staticFilter}} className='col-md-3'>
                                            <select name='dateType' value={this.state.dateType} onChange={this.handleDate} className='form-control'>
                                                <option value='this_week'>This Week</option>
                                                <option value='last_week'>Last Week</option>
                                                <option value='this_month'>This Month</option>
                                                <option value='last_month'>Last Month</option>
                                                <option value='this_year'>This Year</option>
                                                <option value='last_year'>Last Year</option> 
                                                <option value='dinammic_date'> --Dinammic Date-- </option>
                                            </select>
                                        </div>
                                        <div  style={{display:this.state.dinamicFilter}} className="col-md-2 mb-2">
                                            <input className="form-control" type="date" onChange={(e) => this.setState({ startDate: Moment(e.target.value).format('YYYY-MM-DD')})} name="startDate"  value ={this.state.startDate} />
                                        </div>
                                        <div  style={{display:this.state.dinamicFilter}} className="col-md-2 mb-2">
                                            <input className="form-control" type="date" onChange={(e) => this.setState({ endDate: Moment(e.target.value).format('YYYY-MM-DD')})} name="endDate"  value ={this.state.endDate} />
                                        </div>
                                        <div style={{display:this.state.dinamicFilter}} className="col-md-2 btn-group" role='group'>
                                            <button style={{color:'white'}} type='button' className='btn btn-primary btn-md' onClick={() => {
                                                this.countPrecetageGranted()
                                                this.countTaskListConsultant()
                                            }} ><i className='fa fa-filter'></i> Filter</button>
                                            <button style={{color:'white'}} className='btn btn-success btn-md' type='button' onClick={() => this.setState({dinamicFilter:'none', staticFilter:''})}><i className='fa fa-refresh'></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='col-lg-12'>
                                        <span style={{fontSize:'1.5em'}} className='f-w-600'>{Moment(this.state.startDate).format('DD MMMM yyyy')} - {Moment(this.state.endDate).format('DD MMMM yyyy')}</span>
                                    </div>
                                    <ReactTable
                                        data={this.state.dataPrecetasi}
                                        columns={columns} 
                                        defaultPageSize={20}
                                        filterable
                                        className="-striped -highlight"
                                        showPaginationBottom={true}
                                    />
                                 <Loader showLoading={this.state.showLoading}/>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-12'>
                            <div className='card'>
                                <div className='card-body'>
                                <ReactTable
                                    data={this.state.taskListAdmission}
                                    columns={columns_task} 
                                    defaultPageSize={10}
                                    filterable
                                    className="-striped -highlight"
                                    showPaginationBottom={true}
                                    SubComponent={ 
                                        row => {
                                            return <div className='row'>
                                                <div className='col-xl-6'>
                                                    <h6 className='m-2'>Pendaftaran Sekolah</h6>
                                                    <ReactTable
                                                        data={row.original.task_pendaftaran_sekolah}
                                                        columns={columns_sub_task}
                                                        filterable
                                                        defaultPageSize={5}
                                                        className="-striped -highlight"
                                                        showPaginationBottom={true}
                                                    />
                                                </div>
                                                <div className='col-xl-6'>
                                                    <h6 className='m-2'>Visa</h6>
                                                    <ReactTable
                                                        data={row.original.task_visa}
                                                        columns={columns_sub_task}
                                                        filterable
                                                        defaultPageSize={5}
                                                        className="-striped -highlight"
                                                        showPaginationBottom={true}
                                                    />
                                                </div>
                                            </div> 
                                        }}
                                />
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-6'>
                            <StudentGrantedLocation start_date={this.state.startDate} end_date={this.state.endDate}/>
                        </div>  
                    </div>
                </div>
                {this.renderModalUpdate()}
                {this.renderModalDetail()}
            </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(HomeAdmission);