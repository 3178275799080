import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { resettoken } from '../../actions/login.action';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import ZipDownloadSimBiaya from '../../services/jszipsimulasi-biaya';
import {matchSorter} from 'match-sorter';

class SimulasiBiayaStorage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listStorage: [],
            title: "",
            modalPreview:false,
            student_doc: [],
            data_document: [{}],
            urls: [],
            filtered: [],
        }
    }

    componentDidMount() {
        this.getDataStorage();
    }

    closePreview(){
        this.setState({
            modalPreview: false,
        });
    }

    getDataStorage(){
        const url = API_URL.superStudent+'/finance/storage-simulasi-biaya';
        axios.get(url, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            } 
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({
                listStorage: data
            })
        });
    }

    getStudentDocByCode(row) {
        var formData = new FormData();
        formData.append('code_students_document', row.code_students_document);

        const url = API_URL.studentUrl+'students-documents-by-code';
        axios.post(url, formData, {
            headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
        })
        .then(response => response.data.data)
        .then((result) => {
            this.setState({ 
                title:"Preview",
                modalPreview: true,
                student_doc: result
            })
        })
    }

    pilihSimulasiBiaya(row) {
        this.setState(prevState => ({
            listStorage:prevState.listStorage.map(checkbox =>
                checkbox.id_storage_simulasi_biaya === row.id_storage_simulasi_biaya ? {...checkbox, isChecked: !checkbox.isChecked} :checkbox )
        }), () => {
            var filtered = this.state.listStorage.filter(item => item.isChecked === true);

            var formData = new FormData();
            formData.append('code_student_documents', JSON.stringify(filtered));
    
            axios.post(API_URL.studentUrl+'students-documents/sim-biaya-storage', formData, {
                headers:{  Authorization: `Bearer ${API_URL.studentToken}` }
            })
            .then(response => response.data)
            .then(results => {
                let urls = results.map(function (result){
                    return result.file_name
                })
                this.setState({
                    urls
                })
            })
            .catch(error => {
                console.log(error)
            })
        })
    }

    renderPreview(){
        const modalPreview = this.state.modalPreview;
        const { title } = this.state;
        return(
            <Modal className="modal-dialog" isOpen={modalPreview} toggle={() => this.closePreview()} size="lg">
              <ModalHeader toggle={() => this.closePreview()}>{title}</ModalHeader>
              <ModalBody style={{maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>
                {this.state.student_doc.map((doc, i) => {
                    const url_doc = [{ uri:doc.file_name }]
                    const url_download = doc.file_name;
                    return  <div key={i}>
                                <h5>{doc.code_students_document}</h5>
                                <DocViewer 
                                    pluginRenderers={DocViewerRenderers} 
                                    documents={url_doc}
                                    config={{
                                        header: {
                                            disableHeader: true,
                                            disableFileName: false,
                                            retainURLParams: false
                                        }
                                    }}
                                />
                                <div className="form-row" style={{textAlign: "end"}}>
                                    <div className="col-md-12">
                                        <button onClick={(e) => this.downloadFile(e, url_download, doc.file_name)} className="btn btn-outline-success" type="button"><i className="fa fa-download"></i></button>
                                    </div>
                                </div>
                            </div>
                })}
              </ModalBody>
            </Modal>
        )
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
    
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }
    
        this.setState({ filtered: filtered });
    }

    render() {
        const columns = [
            {
                Header: 'Code Student',
                accessor: 'students.code_students',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["students.code_students"] }),
                filterAll: true
            },
            {
                Header: 'Nama Student',
                accessor: 'students.nama',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["students.nama"] }),
                filterAll: true
            },
            {
                Header: 'University',
                accessor: 'university.nama_sekolah',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["university.nama_sekolah"] }),
                filterAll: true
            },
            {
                Header: 'Program',
                accessor: 'program.program_name',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["program.program_name"] }),
                filterAll: true
            },
            {
                Header: 'Total',
                accessor: 'sub_total[0].total_nominal',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["sub_total[0].total_nominal"] }),
                filterable: false,
                // Cell:(row) => (
                //     <div>
                //         {row.original.sub_total[0].code_mata_uang} {row.original.sub_total[0].total_nominal}
                //     </div>
                // )
            },
            {
                Header: 'Rate',
                accessor: 'sub_total[0].rate',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["sub_total[0].rate"] }),
                filterable: false,
                // Cell:(row) => (
                //     <div>
                //         Rp {row.original.sub_total[0].rate}
                //     </div>
                // )
            },
            {
                Header: 'Action',
                style: {textAlign: 'center'},
                filterable: false,
                Cell:(row) => (
                    <div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3">
                                <button onClick={() => this.getStudentDocByCode(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}}><i className="fa fa-list" style={{ fontSize: 16}}></i></button>
                            </div>
                            <div className="col-md-3">
                                {/* <input type="checkbox" name='code_student_document' onChange={(e) => this.pilihSimulasiBiaya(e, row.index, row.original.code_students_document)} /> */}
                                <input checked={row.original.isChecked} onChange={() => this.pilihSimulasiBiaya(row.original)}  type='checkbox' />
                            </div>
                        </div>
                    </div>
                )
            },
        ]
        return(
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <Row className='mb-3'>
                                        <Col lg={10}>

                                        </Col>
                                        <Col lg={2} className='d-flex justify-content-end'>
                                            { 
                                                this.state.data_document.length > 0 ?
                                                <ZipDownloadSimBiaya urlDownload={this.state.urls} name={'storage-simulasi-biaya'} />
                                                :
                                                ''
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col col={12}>
                                            <div className='table-responsive'>
                                                {
                                                    this.state.listStorage.length > 0 ?
                                                    <ReactTable
                                                        data={this.state.listStorage}
                                                        filtered={this.state.filtered}
                                                        filterable
                                                        columns={columns}
                                                        pageSize={25}
                                                        className="-striped -highlight"
                                                        showPaginationBottom={true}
                                                        showPageSizeOptions={true}
                                                        onFilteredChange={(filtered, column, value) => {
                                                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                                                        }}
                                                    />
                                                    :
                                                    ""
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderPreview()}
            </Fragment>
        );

    }
};

const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
    dataId: state.Login.detailUser.id_users,
})

const reduxDispatch = (dispatch) => ({
    resettoken: (data, headers) => dispatch(resettoken(data, headers))
})

export default connect(reduxState, reduxDispatch)(withRouter(SimulasiBiayaStorage));