import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { Card,CardHeader,CardBody,Nav,NavItem,NavLink,TabContent,TabPane} from 'reactstrap'
import StructureTest from './pages/structure-test';
import ReadingTest from './pages/reading-test';
import ListeningTest from './pages/listening-test';
import ReactTable from 'react-table';
import {matchSorter} from 'match-sorter';
import axios from 'axios';
import API_URL from '../../../ApiUrl';
import Moment from 'moment';

export class TestResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // hashid: param,
          userResult: [],
          detailResult: [],
          writingResult: [],
          user: [],
          row: [],
          filtered: [],
          showTableResult: true,
          showDetailResult: false,
          showWritingResult: false
        }
    }

    componentDidMount(){
        this.getUserResult();

    }

    detailResult(row){
        this.setState({showTableResult: false, showDetailResult: true, showWritingResult: false})
        this.getDetailResult(row.tb_user.hash_id)
    }

    writingResult(row){
        this.setState({showTableResult: false, showDetailResult: false, showWritingResult:true})
        this.getDetailResult(row.tb_user.hash_id)
    }
    
    backButton(){
        this.setState({showTableResult: true, showDetailResult: false, showWritingResult:false})
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
    
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }
    
        this.setState({ filtered: filtered });
    }

    getUserResult(){
        axios.get(API_URL.placementTest + 'result', { headers: { Authorization: `Bearer ${API_URL.placementTestToken}` }})
        .then(res=>{
            // console.log(res.data)
            this.setState({userResult: res.data})
        })
        .catch(err => console.log(err))
    }

    getDetailResult(hashid){
        axios.get(API_URL.placementTest + 'result/' + hashid, { headers: { Authorization: `Bearer ${API_URL.placementTestToken}` }})
        .then(res=>{
            let data = res.data
            let writingResult = data.pop()
            this.setState({detailResult: data, user: data[0].tb_user, writingResult: writingResult})
        })
        .catch(err => console.log(err))
    }

    renderDetailResult(detailResult, user){
        return(
            <>
            <div className="p-5">
                <h4>Name : {user.name}</h4>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                {/* <th scope="col">#</th> */}
                                <th scope="col">Question</th>
                                <th scope="col">User Answers</th>
                                <th scope="col">Correct Answers</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            detailResult?.map((s)=>{
                                return(
                                    <tr>
                                        {/* <th scope="row"></th> */}
                                        {/* <td>{s.tb_question.question}</td> */}
                                        <td><div dangerouslySetInnerHTML={{__html: s.tb_question.question}} /></td>
                                        <td>{(s.answer == 0 || s.answer === '') ? 'No Answer' : s.answer}</td>
                                        <td>{s.tb_question.opt_a}</td>
                                        <td>{s.answer === s.tb_question.opt_a ? (<i className='fa fa-check text-success'></i>) : (<i className='fa fa-times text-danger'></i>)}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            </>
        )
    }

    renderWritingResult(writingResult, user){
        // console.log(writingResult);
        return(
            <>
            <div className="p-5">
                <h3>Name : {user.name}</h3>
                <hr />
                <div className="mt-2">
                    <h4>Writing Answer</h4>
                    <h6>{writingResult?.tb_question?.question}</h6>
                    <p>{writingResult.answer}</p>
                </div>
            </div>
            </>
        )
    }

  render() {
    const {showTableResult, detailResult, writingResult, showDetailResult, showWritingResult, user} = this.state
    const test_result = [
        {
            Header: 'Nama',
            accessor: 'tb_user.name',
            filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["tb_user.name"] }),
            filterAll: true,
            style: {textAlign: 'center'}
        },
        {
            Header: 'Email',
            accessor: 'tb_user.email',
            filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["tb_user.email"] }),
            filterAll: true,
            style: {textAlign: 'center'},
        },
        {
            Header: 'Phone',
            accessor: 'tb_user.phone',
            filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["tb_user.phone"] }),
            filterAll: true,
            style: {textAlign: 'center'},
        },
        {
            Header: 'Structure Score',
            accessor: 'structure_score',
            filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["structure_score"] }),
            // filterAll: true,
            filterable:false,
            style: {textAlign: 'center'},
            Cell:(row) => (
                <span>{row.original.structure_score}%</span>
            )
        },
        {
            Header: 'Reading Score',
            accessor: 'reading_score',
            filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["reading_score"] }),
            // filterAll: true,
            filterable:false,
            style: {textAlign: 'center'},
            Cell:(row) => (
                <span>{row.original.reading_score}%</span>
            )
        },
        {
            Header: 'Listening Score',
            accessor: 'listening_score',
            filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["listening_score"] }),
            // filterAll: true,
            filterable:false,
            style: {textAlign: 'center'},
            Cell:(row) => (
                <span>{row.original.listening_score}%</span>
            )
        },
        {
            Header: 'Total Score',
            accessor: 'score_total',
            filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["score_total"] }),
            // filterAll: true,
            filterable:false,
            style: {textAlign: 'center'},
            Cell:(row) => (
                <span>{row.original.score_total}%</span>
            )
        },
        {
            Header: 'Tanggal',
            accessor: 'created_at',
            // filterAll: true,
            filterable:false,
            style: {textAlign: 'center'},
            Cell:(row) => (
                <span>{Moment(row.original.created_at).format('DD/MM/YYYY')}</span>
            )
        },
        {
            Header: <b>Detail</b>,
            id: 'action',
            accessor: str => "action",
            width:90,
            filterable:false,
            Cell: (row) => (
                <>
                    {/* <button onClick={(e) => this.detailResult(row.original)} class="btn btn-outline-warning btn-xs" type="button" style={{border:'none', fontSize: 15}}><i className="fa fa-book"></i></button> */}
                    <button onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/placement-test/detail-result/` + row.original.tb_user.hash_id)} class="btn btn-outline-warning btn-xs" type="button" style={{border:'none', fontSize: 15}}><i className="fa fa-book"></i></button>
                    <button onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/placement-test/detail-writing/` + row.original.tb_user.hash_id)} class="btn btn-outline-primary btn-xs" type="button" style={{border:'none', fontSize: 15}}><i className="fa fa-file"></i></button>
                    {/* <button onClick={(e) => this.writingResult(row.original)} class="btn btn-outline-primary btn-xs" type="button" style={{border:'none', fontSize: 15}}><i className="fa fa-file"></i></button> */}
                </>
            ),
            style: { textAlign: 'center' }
        }

    ]

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12'>
                {
                    (showDetailResult || showWritingResult) && 
                    (<button onClick={() => this.backButton()} className='btn btn-danger mb-3'><i className='fa fa-arrow'></i> Back</button>)
                }
                    <div className='card'>
                {
                    showTableResult &&
                    <ReactTable
                        data={this.state.userResult}
                        columns={test_result}
                        filtered={this.state.filtered}
                        defaultPageSize={15}
                        filterable  
                        className="-striped -highlight"
                        showPaginationBottom={true}
                        onFilteredChange={(filtered, column, value) => {
                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                        }}
                    /> 
                }
                {
                    showDetailResult && 
                    this.renderDetailResult(detailResult, user)
                }
                {
                    showWritingResult && 
                    this.renderWritingResult(writingResult, user)
                }

                    </div>
                </div>
            </div>
        </div>
    )
  }
}

// export default TestResult
const reduxState = (state) => ({
  dataToken: state.Login.lgnInitialize,
  dataUser: state.Login.detailUser,
  codeUsers: state.Login.detailUser.code_users,
  codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(TestResult));