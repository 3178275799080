import axios from 'axios';
import React, { Component, Fragment } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import API_URL from '../../ApiUrl';
import DataTableTraining from '../common/datatable/dataTableTraining';

export class DataTablesReportTraining extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list_training: [],
            addModal: false,
            editModal: false,
            addSubModal: false,
            editSubModal: false,
            id_training_category: '',
            sub_category: '',
            id_sub_category: '',
            category: ''
        }
    }

    render(){
        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body datatable-react">
                                    <table className='table table-bordered text-center'>
                                    <thead>
                                        <tr>
                                            <th>Subject</th>
                                            <th>Trainer</th>
                                            <th>Date</th>
                                            <th>No. of Participant</th>
                                            <th>Length of Training (Hours)</th>
                                            <th>Total Hours</th>
                                            <th>Cost</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Generic</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Finance</td>
                                            <td></td>
                                            <td>20/10/22</td>
                                            <td>2</td>
                                            <td>1.5</td>
                                            <td>3</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default DataTablesReportTraining;