import React, { Fragment, Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { resettoken } from '../../../actions/login.action';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {matchSorter} from 'match-sorter'
import Moment from 'moment';


class DatatablePromoMember extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operation: '',
            modal:false,
            modalDelete:false,
            id_harga:'',
            nama:'',
            finish:'',
            kode_harga:'',
            harga:'',
            status:'',
            btnDisabled: false,
        }
    }

    selectRow = (e, i) => {
      if (!e.target.checked) {
          this.setState({
              checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
          });
      } else {
          this.state.checkedValues.push(i);
          this.setState({
              checkedValues: this.state.checkedValues
          })
      }
    }
    
    formatRupiah = (nominal) => {
      return new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
      }).format(nominal);
    }

    componentDidMount(){
    }

    resetState(){
      this.setState({
          nama:"",
          kode_harga:"",
          harga:"",
          status:"",
      });
    }

    addRow() {
      this.setState({
        title: 'Add Promo Data',
        operation: 'add',
        modal: true
      });
    }

    editRow(row) {
      this.setState({
        operation: 'edit',
        modal: true,
        id_harga:row.id_harga,
        nama: row.nama,
        kode_harga: row.kode_harga,
        harga: row.harga,
        status:row.status,
        finish:row.finish
      });
    }

    deleteRow(row) {
      this.setState({
        operation: 'delete',
        modalDelete: true,
        id_harga:row.id_harga,
        nama: row.nama,
        kode_harga: row.kode_harga,
        harga: row.harga,
        status:row.status,
        finish:row.finish
      })
    }

    closeDialog(){
      this.setState({
        modal: false,
        modalDelete: false
      })
    }

    handleInput = (e) => {
      this.setState({
        [e.target.name]:e.target.value
      })
    }

    sendData() {
      this.setState({
        btnDisabled:true
      })
      var formData = new FormData();
      formData.append('nama', this.state.nama);
      formData.append('kode_harga', this.state.kode_harga);
      formData.append('harga',this.state.harga);
      formData.append('status', this.state.status)
      formData.append('finish', this.state.finish)

      let url = '/harga'

      if (this.state.operation === 'edit'){
          formData.append('_method','PATCH');
          url = '/harga/'+this.state.id_harga
      } else if (this.state.operation === 'delete') {
          formData.append('_method', 'DELETE');
          url = '/harga/'+this.state.id_harga
      }

      axios({
          method: 'POST',
          url: API_URL.superStudent+url,
          data: formData,
          headers: {  Authorization: `Bearer ${this.props.dataToken}`  }
      })
      .then(() => {
        toast.success('Data Update Successfully')
        window.location.reload(false)
      })

    }

    renderDialog() {
      return (
        <div className="card-body btn-showcase">
          <Modal isOpen={this.state.modal} toggle={() => this.setState({ modal: false })}>
            <ModalHeader toggle={() => this.setState({ modal: false })}>Form Kode Promo</ModalHeader>
            <ModalBody>
            <form className="needs-validation" >
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="name">Kode Promo</label>
                    <input className="form-control" required name="kode_harga" type="text"   value={this.state.kode_harga} onChange={this.handleInput} />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="name">Nama Promo</label>
                    <input className="form-control" name="nama" required type="text" id="nama"  value={this.state.nama} onChange={this.handleInput}/>
                </div>
              </div>
              {
                this.state.operation == 'add' ?
                <div className="form-row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="name">Harga</label>
                      <input className="form-control" required name="harga" type="number"  value={this.state.harga} onChange={this.handleInput}/>
                  </div>
                </div>
              :
              ''
              }
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="name">Status</label>
                    <select className="form-control" required name="status" type="text" id="status"  value={this.state.status}
                      onChange={this.handleInput}>
                    <option value="0">--Pilih Status--</option>
                    <option key="Y" value="Y">Active</option>
                    <option key="N" value="N">Non Active</option>
                    </select>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="name">Periode</label>
                    <input className='form-control' type='datetime-local' name='finish' value={this.state.finish} onChange={this.handleInput} />
                </div>
              </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button disabled={this.state.btnDisabled} onClick={(e) => this.sendData()} color="primary">Save Changes</Button>
              <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
            </ModalFooter>
          </Modal>
        </div>
      )
    }

    renderDeleteDialog() {
      const { modalDelete } = this.state;

      return (
          <Modal isOpen={modalDelete} toggle={() => this.setState({ modalDelete: false })}>
              <ModalHeader toggle={() => this.setState({ modalDelete: false })}>Delete Kode Promo</ModalHeader>
              <ModalBody>
                  Delete this data ? &nbsp; {this.state.nama} {this.state.kode_harga}
              </ModalBody>
              <ModalFooter>
                  <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.sendData()}>Save Changes</Button>
                  <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
              </ModalFooter>
          </Modal>
      )
  }

    render() {
      const { title } = this.state;
      const { myData } = this.props;
      const columns = [
        {
          Header: 'Name',
          accessor: 'nama',
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["nama"] }),
            filterAll: true
        },
        {
          Header: 'Price Code',
          accessor: 'kode_harga',
          style: {textAlign: 'center'},
          filterable: false
        },
        {
          Header: 'Price',
          accessor: 'harga',
          style: {textAlign: 'center'},
          filterable: false,
          Cell:(row) => (
            <>{this.formatRupiah(row.original.harga)}</>
          )
        },
        {
          Header: 'Status',
          accessor: 'status',
          style: {textAlign: 'center'},
          filterable: false,
          Cell:(row) => (
            row.original.status === 'Y' ? <label className='badge bg-success'>Active</label> : <label className='badge bg-danger'>In Active</label> 
          )
        },
        {
          Header: 'Finish',
          accessor: 'finish',
          style: {textAlign: 'center'},
          filterable: false,
          Cell: (row) => (
            row.original.finish !== null ? 
            <><span>{Moment(row.original.finish).format('DD MMM YY')}</span><p className='f-12 text-center'>{Moment(row.original.finish).format('HH:mm')}</p></> : 'Infinite'
          )
        }
      ]      
        if(this.props.dataUser.code_roles == 'RL001' || this.props.dataUser.code_roles == 'RL005') {
            columns.push(
                {
                    Header: <b>Action</b>,
                    id: 'delete',
                    accessor: str => "delete",
                    Cell: (row) => (
                        <div>                     
                          <button onClick={() => this.editRow(row.original)} className="btn btn-primary btn-xs mr-2" type="button" style={{border:'none'}} ><i className="fa fa-pencil" ></i></button>
                          <button onClick={() => this.deleteRow(row.original)} className="btn btn-danger btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-remove" ></i></button>
                        </div>
                    ),
                    style: {
                        textAlign: 'center'
                    },
                    sortable: false,
                    filterable: false
                }
            )
        }
        return (
            <Fragment>
              <div>
                <h5>{title}</h5>
              </div>

                <div style={{display:this.props.dataUser.code_roles == 'RL001' || this.props.dataUser.code_roles == 'RL005' ? '': 'none'}}>
                  <button onClick={() => this.addRow()} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} >
                    <div>
                      <span>
                        <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i>
                      </span>
                    </div>
                  </button>
                </div>

                <div>
                  <div className="row">
                    <div className="col-lg-12">
                    <ReactTable
                      data={myData}
                      columns={columns}
                      className="-striped -highlight"
                      showPagination={true}
                      filterable
                      defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value}
                      
                    />
                      </div>
                    </div>
                  </div>
              <ToastContainer />
              {this.renderDialog()}
              {this.renderDeleteDialog()}
            </Fragment>
        );
    }
};

// export default Datatable;

const reduxState = (state) => ({
  codeUser: state.Login.detailUser.code_users,
  dataUser: state.Login.detailUser,
  dataToken: state.Login.lgnInitialize,
})

const reduxDispatch = (dispatch) => ({
  resettoken: (data, headers) => dispatch(resettoken(data, headers))
})

export default connect(reduxState, reduxDispatch)(DatatablePromoMember);