import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux';
import DatatableRoles from '../common/datatable/datatableRoles';
import { withRouter } from "react-router";
import API_URL from '../../ApiUrl';
import axios from 'axios';

class dataTableRoles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listRoles: []
        }
    }

    componentDidMount(){
        this.getDataRoles();
    }

    getDataRoles(){
        axios.get(API_URL.superStudent+'/roles',{
            headers: {  Authorization: `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            this.setState({
                listRoles:response.data.data
            })
        })
        .catch(error => console.log(error))
    }

    render() {
        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body datatable-react">
                                    <DatatableRoles myData={this.state.listRoles}  title="Roles Data" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
  })

  
export default connect(reduxState, null) (withRouter(dataTableRoles));