import React, { Component } from 'react'
import { connect } from 'react-redux'
import API_URL from '../../ApiUrl'
import axios from 'axios'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { toast } from 'react-toastify';

class Appraisal extends Component{

    constructor(props){
        super(props);
        this.state = {
            modalAddAppraisal:false,
            DataEmployee:[],
            disableBtn:false,
            textSave:'Save',
            code_konsultan:'',
            file:'',
            category:'',
            start_date:''
        }
    }

    componentDidMount(){
    }

    handleAddAppraisal  = (e) =>{
        this.loadDataActiveEmployee()
        this.setState({
            modalAddAppraisal:true,
            code_konsultan:'',
            file:'',
            category:'',
            start_date:''
        })
    }

    loadDataActiveEmployee = () => {
        axios.get(API_URL.superStudent+'/consultant/list-all',{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({ DataEmployee: response.data.data }))
        .catch(error => console.log(error))
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })  
    }

    handleChangeFile = (e) => {
        this.setState({
            file:e.target.files[0]
        })
    }
    closeModal(){
        this.setState({
            modalAddAppraisal:false
        })
    }

    saveData(){
        let formData = new FormData();
        formData.append('code_konsultan', this.state.code_konsultan)
        formData.append('file', this.state.file)
        formData.append('category', this.state.category)
        formData.append('start_date', this.state.start_date)
        this.setState({
            disableBtn:true,
            textSave: 'Saving...'
        })
        axios.post(API_URL.superStudent+'/employee', formData,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(() => {
            toast.success('Data Added Successfully')
            this.setState({
                modalAddAppraisal:false,
                textSave: 'Save',
                disableBtn:false,
            })
        })
        .catch(error => console.log(error))
    }

    renderModalAppraisal(){
        return(
            <Modal className="modal-dialog" isOpen={this.state.modalAddAppraisal} toggle={() => this.closeModal()}>
            <ModalHeader toggle={() => this.closeModal()}>Create Data Appraisal </ModalHeader>
            <ModalBody style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto'
            }}>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label className='f-w-600'>Nama Karyawan</label>
                            <select className='form-control' name='code_konsultan' value={this.state.code_konsultan} onChange={this.handleInput}>
                                <option key={0} value=''> --Pilih-- </option>
                                {
                                this.state.DataEmployee.length > 0 ?
                                this.state.DataEmployee.map(employee => (
                                    <option key={employee.code} value={employee.code}>{employee.name}</option>
                                ))
                                :
                                ''
                                }
                            </select>
                        </div>
                        <div className='form-group'>
                            <label className='f-w-600'>File Attandance</label>
                            <input type='file'  className='form-control' onChange={this.handleChangeFile} />
                            <span>Download Template <a href='#'> here</a></span>
                        </div>
                        <div className='form-group'>
                            <label className='f-w-600'>Jenis Appraisal</label>
                            <select className='form-control' value={this.state.category} onChange={this.handleInput} name='category'>
                                <option key={0} value=''> --Pilih-- </option>
                                <option key={1} value='1'> 3 Bulan </option>
                                <option key={2} value='2'> 10 Bulan </option>
                            </select>
                        </div>
                        <div className='form-group'>
                            <label className='f-w-600'>Bulan Awal</label>
                            <input type='month' className='form-control' name='start_date' value={this.state.start_date} onChange={this.handleInput} />
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
              <Button disabled={this.state.disableBtn} color="primary" onClick={(e) => this.saveData()}>{this.state.textSave}</Button>
              <Button color="secondary" onClick={e => this.closeModal()}>Cancel</Button>
            </ModalFooter>
          </Modal>
        );
    }

    render(){
        return (
            <>
             <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div className='card-header'>
                                <button className='btn btn-success' onClick={this.handleAddAppraisal}> Add Data</button>
                                </div>
                                <div className='card-body'>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModalAppraisal()}
            </>
        );
    }
}

const reduxState = (state) => ({
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(Appraisal);