import React, {useState}  from 'react';
import StepZilla from "react-stepzilla";
import StepOne from './step-one';
import StepTwo from './step-two';
import Breadcrumb from '../../../components/common/breadcrumb';

const FormCourse = () => {

    const[navigation,setNavigation] = useState(false)
    const [data,setData] = useState();

    const SaveFormOne = (data) => {
        setData(data)
        setNavigation(true)
    }

    const steps =
        [
            { name: 'Step 1', component: <StepOne  SaveFormOne={SaveFormOne} /> },
            { name: 'Step 2', component: <StepTwo data={data} /> },
        ]

    return(
        <>
            <Breadcrumb parent="User" title="Form Course" />
            <div className='row'>
                <div className='col-md-12'>
                    <div className='card'>
                        <div className='card-body'>
                            <StepZilla steps={steps} showSteps={true} showNavigation={navigation} stepsNavigation={false}prevBtnOnLastStep={false} nextButtonText='Next'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FormCourse