import React, { Component,Fragment } from 'react'
import Breadcrumb from '../../../components/common/breadcrumb';
import CKEditors from "react-ckeditor-component";
import { withRouter } from "react-router";
import API_URL from '../../../ApiUrl';
import { connect } from 'react-redux'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class DetailProgram extends Component{
 
    constructor(props){
        super(props)
        this.state = {
            id: '',
            school_rep_id:'',
            code_users: '',
            program_name: '',
            program_length:'',
            program_level:'',
            tuition_cost:'',
            bidang_pendidikan_id:'',
            commision:'',
            content: '',
            action:'',
            errors:[],
            universityStyle:'',
            btnText: 'Save data',
            listSchoolRep:[],
            programType:[],
            bidang:[],
            disabledBtn: false
        }
    }

    getDataSchoolRep(){
        axios.get(API_URL.superStudent+'/universities?education_group_id='+this.props.dataUsers.userable_id,{
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`
            }
        }) 
        .then(response => {
            this.setState({
                listSchoolRep:response.data.data
            })
        })
        .catch(err => { })
    }

    componentDidMount(){
        this.loadProgramType()
        this.loadBidangPendidikan()
        if(this.props.location.state === undefined){
            this.getDataSchoolRep()
            this.setState({ 
                action: 'add',
                code_users:this.props.dataUsers.code_users
         })
        }
        else{
            let data = this.props.location.state
            console.log('data program', data)
            this.setState({
                action: 'update',
                universityStyle:'none',
                id: data.id,
                program_name: data.program_name,
                code_users:this.props.dataUsers.code_users,
                program_length: data.program_length,
                tuition_cost:  data.tuition_cost,
                commision:data.commision,
                program_level: data.program_level,
                bidang_pendidikan_id: data.bidang_pendidikan_id,
                content: data.about

            })
        }
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            content: newContent
        })
    }

    loadProgramType = () => {
        axios.get(API_URL.superStudent+'/master/jenjang-tujuan',{
            headers: { Authorization: `Bearer `+this.props.dataToken }
        })
        .then(response => this.setState({programType:response.data.data}))
        .catch(error => console.log(error))
    }

    loadBidangPendidikan = () => {
        axios.get(API_URL.superStudent+'/master/bidang-pendidikan',{
            headers: { Authorization: `Bearer `+this.props.dataToken }
        })
        .then(response => this.setState({ bidang: response.data.data}))
        .catch(error => console.log(error))
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        this.setState({
            btnText: 'Saving...',
            disabledBtn: true
        })

        var formData = new FormData();
        formData.append('university_id', this.state.school_rep_id);
        formData.append('program_name', this.state.program_name);
        formData.append('program_length', this.state.program_length)
        formData.append('tuition_cost', this.state.tuition_cost);
        formData.append('program_level', this.state.program_level)
        formData.append('bidang_pendidikan_id', this.state.bidang_pendidikan_id)
        formData.append('commision', this.state.commision);
        formData.append('content', this.state.content);

        let url = '/university-programs'
        let method = 'post'

        if (this.state.action === 'update'){
            formData.append('_method','PATCH');
            url = '/university-programs/'+this.state.id
        }

        axios({
            method,
            url: API_URL.superStudent+url,
            data: formData,
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data',
            }
        })
        .then(response => {
            this.setState({
                btnText: 'Save data',
                disabledBtn: false
            })
            toast.success("University program successfully updated...")
            this.props.history.push('/pages/education-group/program')
        })
        .catch(error => {
            this.setState({
                btnText: 'Save data',
                errors: error.response.data.data,
                disabledBtn: false
            })
            toast.error("Opps...");
        })
    }

    render(){
        return (
            <Fragment>
                <Breadcrumb parent="User" title="Form University Program" />
                <div className='row m-1'>
                    <div className='col-md-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <form onSubmit={this.handleSubmit}>
                                    <div className='row'>
                                        <div className='col-md-4 col-sm-12'>
                                            <div className="form-group">
                                                <label className='f-w-600'>Program name<span className='text-danger'>*</span> </label>
                                                <input className="form-control" onChange={this.handleInput} value={this.state.program_name} name='program_name' type="text" placeholder="example" />
                                                <div className="text-danger">{this.state.errors.program_name}</div>
                                            </div>
                                        </div>
                                        <div style={{display:this.state.universityStyle}} className='col-md-4'>
                                            <div className='form-group'>
                                                <label className='f-w-600'>University<span className='text-danger'>*</span> </label>
                                                <select value={this.state.school_rep_id} onChange={this.handleInput} className='form-control' name='school_rep_id'>
                                                    <option>--Select--</option>
                                                    {
                                                        this.state.listSchoolRep.length > 0 ?
                                                        this.state.listSchoolRep.map(item => (
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        ))
                                                        :
                                                        '' 
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-sm-12'>
                                            <div className="form-group">
                                                <label className='f-w-600'>Program Level<span className='text-danger'>*</span> </label>
                                                <select name='program_level' value={this.state.program_level} onChange={this.handleInput} className='form-control'>
                                                    <option value=''> --Select-- </option>
                                                    {
                                                        this.state.programType.length > 0 ?
                                                        this.state.programType.map(program => (
                                                            <option key={program.code_jenjang} value={program.code_jenjang}>{program.jenjang_tujuan}</option>
                                                        ))
                                                        :
                                                        ''
                                                    }
                                                </select>
                                                <div className="text-danger">{this.state.errors.program_level}</div>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-sm-12'>
                                            <div className="form-group">
                                                <label className='f-w-600'>Bidang Pendidikan</label>
                                                <select name='bidang_pendidikan_id' value={this.state.bidang_pendidikan_id} onChange={this.handleInput} className='form-control'>
                                                    <option value=''> --Select-- </option>
                                                    {
                                                        this.state.bidang.length > 0 ?
                                                        this.state.bidang.map(bidang => (
                                                            <option key={bidang.id} value={bidang.id}>{bidang.name}</option>
                                                        ))
                                                        :
                                                        ''
                                                    }
                                                </select>
                                                <div className="text-danger">{this.state.errors.program_level}</div>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-sm-12'>
                                            <div className="form-group">
                                                <label className='f-w-600'>Program Length<span className='text-danger'>*</span></label>
                                                <input className="form-control" onChange={this.handleInput} value={this.state.program_length} name='program_length' type="text" placeholder="4 -16 months" />
                                                <div className="text-danger">{this.state.errors.program_length}</div>
                                            </div>
                                        </div>
                                        <div className='col-md-3 col-sm-6'>
                                            <div className="form-group">
                                                <label className='f-w-600'>Tuition Cost<span className='text-danger'>*</span></label>
                                                <input className="form-control" onChange={this.handleInput} value={this.state.tuition_cost} name='tuition_cost' type="text" placeholder="10000" />
                                                <div className="text-danger">{this.state.errors.tuition_cost}</div>
                                            </div>
                                        </div>
                                        <div className='col-md-3 col-sm-6'>
                                            <div className="form-group">
                                                <label className='f-w-600'>Commision<span className='text-danger'>*</span></label>
                                                <input className="form-control" onChange={this.handleInput} value={this.state.commision} name='commision' type="text" placeholder="10%" />
                                                <div className="text-danger">{this.state.errors.commision}</div>
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <div className="form-group mb-0">
                                            <label className='f-w-600'>Description<span className='text-danger'>*</span></label>
                                                <CKEditors
                                                    activeclassName="p10"
                                                    content={this.state.content}
                                                    events={{
                                                            "change": this.onChange.bind(this),
                                                    }}
                                                    config={{
                                                    toolbar:[
                                                                ["Bold","Italic",'Strike','RemoveFormat'],
                                                                ['NumberedList','BulletedList','Indent','Outdent'],
                                                                ['Link','Unlink'],
                                                                ['Styles','Format','Table','Blockquote','Maximize'],
                                                                ["Cut","Copy","Paste","Pasteasplaintext","Undo","Redo"]
                                                            ]
                                                        }}     
                                                />
                                                <div className="text-danger">{this.state.errors.content}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type='submit' disabled={this.state.disabledBtn} className='btn btn-primary btn-air-info mt-4'>{this.state.btnText}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer/>
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataUsers: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
    userableData: state.Login.userableData
})

export default connect(reduxState,null)(withRouter(DetailProgram));