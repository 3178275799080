import React, { Component } from 'react'
import AccordionPlacementTest from '../components/accordion-placement-test'
import axios from 'axios'
import API_URL from '../../../../ApiUrl'
import StructureTable1 from '../tables/structure-table1'
import StructureTable2 from '../tables/structure-table2'
import StructureTable3 from '../tables/structure-table3'

export class StructureTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      structure1: [],
      structure2: [],
      structure3: [],
    }
}

  componentDidMount(){
    this.getStructureQuestion();
  }

  loadStructure = () => {
    this.getStructureQuestion()
  }


  getStructureQuestion(){
    axios.get(API_URL.placementTest + 'question', { headers: { Authorization: `Bearer ${API_URL.placementTestToken}` }})
        // .then(res => console.log(res.data))
        .then(res => {
          const data = res.data;
          const structure1 = [];
          const structure2 = [];
          const structure3 = [];
          for (let i = 0; i < data.length; i++) {
              if (data[i].id_structure_type === 1) {
                  structure1.push({
                      id: data[i].id,
                      question: data[i].question,
                      opt_a: data[i].opt_a,
                      opt_b: data[i].opt_b,
                      opt_c: data[i].opt_c,
                      opt_d: data[i].opt_d,
                  })
              } else if(data[i].id_structure_type === 1){
                  structure1.push({
                      id: data[i].id,
                      question: data[i].question,
                      opt_a: data[i].opt_a,
                      opt_b: data[i].opt_b,
                      opt_c: data[i].opt_c,
                      opt_d: data[i].opt_d,
                  })
              }else if(data[i].id_structure_type === 2){
                  structure2.push({
                      id: data[i].id,
                      question: data[i].question,
                      opt_a: data[i].opt_a,
                      opt_b: data[i].opt_b,
                      opt_c: data[i].opt_c,
                      opt_d: data[i].opt_d,
                  })
              }else if(data[i].id_structure_type === 3){
                  structure3.push({
                      id: data[i].id,
                      question: data[i].question,
                      opt_a: data[i].opt_a,
                      opt_b: data[i].opt_b,
                      opt_c: data[i].opt_c,
                      opt_d: data[i].opt_d,
                  })
              }
          }
          this.setState({structure1: structure1})
          this.setState({structure2: structure2})
          this.setState({structure3: structure3})
      })
      .catch(err => console.log(err))
    }

  render() {
    return (
        <>
        <AccordionPlacementTest title={'Structure Test Part 1'} component={<StructureTable1 data={this.state.structure1} loadStructure={this.loadStructure}/>} />
        <AccordionPlacementTest title={'Structure Test Part 2'} component={<StructureTable2 data={this.state.structure2} loadStructure={this.loadStructure}/>} />
        <AccordionPlacementTest title={'Structure Test Part 3'} component={<StructureTable3 data={this.state.structure3} loadStructure={this.loadStructure}/>} />
        </>
    )
  }
}

export default StructureTest