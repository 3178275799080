import {
    EXPO_QUESTION_2021,
} from '../constant/actionTypes';

const initialState = {
    questionData2021: {}
}

const EXPO2021 = (state = initialState, action) => {
    switch (action.type) {
        case EXPO_QUESTION_2021:
            return {
                ...state,
                questionData2021: action.value
            }
        default:
            return state;
    }
}

export default EXPO2021

