import React, { Component } from 'react'
import API_URL from '../../../ApiUrl';
import axios from 'axios'
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';

class DataTablesMedia extends Component{

    constructor(props){
        super(props)
        this.state = {
            disableBtn:false,
            btnSave: 'Save',
            showInput:'none',
            showVideo:'none',
            textAddData: 'Add Media',
            type:'',
            input_image:'',
            video_url:'',
            errors:[],
            images:[],
            videos:[],
            photoIndex: 0,
            isOpen: false
        }
    }

    componentDidMount(){
        this.getUniversityMedia()
    }

    getUniversityMedia(){
        axios.get(API_URL.superStudent+'/media-by-university/'+this.props.userableData.userable.id,{
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`,
            }
        })
        .then(response => response.data.data)
        .then(result => {
            let images = result.filter(function(item) {
                return item.type === 'image'
            })
            let videos = result.filter(function (item) {
                return item.type === 'video'
            })
            this.setState({
                images,
                videos
            })
        })
        .catch(error => {
            console.log(error)
        })
    }

    deleteUniversityMedia = async (id) => {
        await axios.delete(API_URL.superStudent+'/university-medias/'+id, {
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data',
            }
        })
        .then(response => {
            this.getUniversityMedia()
            toast.success("University media successfully deleted...")
        })
        .catch(error => {
            console.log(error)
            toast.error("Opps..")
        })
    }

    showInputForm = () => {
        let showInput = this.state.showInput === 'none' ? '' : 'none'
        let textAddData = this.state.textAddData === 'Add Media' ? 'Cancel' : 'Add Media'
        this.setState({
            showInput,
            textAddData
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            let showVideo = this.state.type === 'video' ? '' : 'none'
            this.setState({
                showVideo
            })
        })
    }

    handleChangeImage = (e) => {
        this.setState({
            input_image:e.target.files[0]
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        var formData = new FormData()
        formData.append('type',this.state.type)
        formData.append('university_id', this.props.userableData.userable.id)
        formData.append('code_university', this.props.userableData.userable.code)
        formData.append('image', this.state.input_image)
        formData.append('video_url', this.state.video_url)
        this.setState({
            disableBtn:true,
            btnSave:'Saving..'
        })
        await axios.post(API_URL.superStudent+'/university-medias',formData, {
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data',
            }
        })
        .then(response => {
            this.showInputForm()
            this.setState({
                errors:[],
                type:'',
                input_image:'',
                video_url:'',
                btnSave:'Save',
                disableBtn:false
            })
            this.getUniversityMedia()
            toast.success("University media successfully updated...")
        })
        .catch(error => {
            console.log(error)
            this.setState({
                btnSave: 'Save',
                errors: error.response.data.data,
                disableBtn: false
            })
            toast.error("Opps..")
        })
    }

    render(){
        const { photoIndex, isOpen } = this.state
        return(
            <>
                <div className='card'>
                    <div className='card-header'>
                        <button onClick={this.showInputForm} className='btn btn-success'>{this.state.textAddData}</button>
                    </div>
                    <div className='card-body'>
                        <div className='row justify-content-md-center'>
                            <div style={{display:this.state.showInput}} className='col-sm-6 mb-4'>
                                <form onSubmit={this.handleSubmit}>
                                    <div className='form-group'>
                                        <label className='control-label'>Media Type<span className='text-danger'>*</span></label>
                                        <select value={this.state.type} name='type' onChange={this.handleChange} className='form-control'>
                                            <option value=''>--Select media type--</option>
                                            <option value='image'>Image</option>
                                            <option value='video'>Video</option>
                                        </select>
                                        <div className="text-danger">{this.state.errors.type}</div>
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Image Data<span className='text-danger'>*</span></label>
                                        <input type='file' name='input_image' className='form-control' onChange={this.handleChangeImage}/>
                                        <div className="text-danger">{this.state.errors.image}</div>
                                    </div>
                                    <div style={{display:this.state.showVideo}} className='form-group'>
                                        <label className='control-label'>Link of a Youtube Video<span className='text-danger'>*</span></label>
                                        <input type='text' name='video_url' value={this.state.video_url} className='form-control' onChange={this.handleChange}/>
                                        <div className="text-danger">{this.state.errors.video_url}</div>
                                    </div>
                                    <div >
                                        <button type='submit'disabled={this.state.disableBtn} className='btn btn-primary float-right'>{this.state.btnSave}</button>
                                    </div>
                                </form>
                            </div>

                            <div className='col-sm-12'>
                                <div className="my-gallery row gallery-with-description">
                                    <h4 style={{display:this.state.images.length !== 0 ? '' :'none'}} className='col-sm-12'>Images</h4>
                                     {
                                         this.state.images.length > 0 ?
                                          this.state.images.map((image, index) => {
                                              return <figure key={index} className="col-xl-3 col-md-4 col-sm-6 col-xs-12">
                                                        <img
                                                            src={image.image}
                                                            style={{height:'200px',width:'100%'}}
                                                            alt="Gallery"
                                                            className="img-thumbnail"
                                                            onClick={() =>
                                                                this.setState({ photoIndex: index, isOpen: true })
                                                            }
                                                        />
                                                        <div className="caption">
                                                            <button onClick={() => this.deleteUniversityMedia(image.id)} className='btn btn-danger btn-block'>Delete</button>
                                                        </div>
                                                    </figure>
                                          })
                                          :
                                          ''
                                     }
                                </div>
                                <div className='row mt-4'>
                                    <h4 style={{display:this.state.videos.length !== 0 ? '' :'none'}}  className='col-md-12'>Videos</h4>
                                    {
                                        this.state.videos.length > 0 ?
                                        this.state.videos.map((video, index) => {
                                            return <div key={index} className="col-xl-4 col-md-6 col-sm-12 mb-3">
                                                        <div className="embed-responsive embed-responsive-21by9">
                                                                <iframe title="media" height="315" src={video.video_url} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                        </div>
                                                        <div className="caption">
                                                            <button onClick={() => this.deleteUniversityMedia(video.id)} className='btn btn-danger btn-block'>Delete</button>
                                                        </div>
                                                    </div>
                                        })
                                        :
                                        ''
                                    }
                                    
                                </div>

                            </div>
                        </div>
                    </div>
                    {isOpen && (
                    <Lightbox
                        mainSrc={this.state.images[photoIndex].image}
                        nextSrc={this.state.images[(photoIndex + 1) % this.state.images.length].image}
                        prevSrc={this.state.images[(photoIndex + this.state.images.length - 1) % this.state.images.length].image}
                        imageTitle={photoIndex + 1 + "/" + this.state.images.length}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + this.state.images.length - 1) % this.state.images.length
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % this.state.images.length
                            })
                        }
                    />
                )}
                </div>
                <ToastContainer/>
            </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    userableData: state.Login.userableData
})

export default connect(reduxState, null)(DataTablesMedia);