import React, { Fragment, Component } from 'react';
import DatatableSchedulle from '../common/datatable/dataTableSchedulleInvoice';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { resettoken } from '../../actions/login.action';
import { Button } from 'reactstrap';
import Moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import setRangeDate from '../../helper/general-function';

class DataTablesDocument extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listInvoice: [],
            listSchoolRep: [],
            staticFilter:'',
            dinamicFilter:'none',
            month: Moment().format('YYYY-MM'),
            startDate: Moment().subtract(1, 'weeks').startOf('isoWeek'),
            endDate: Moment().subtract(1, 'weeks').endOf('isoWeek'),
            date:'this_year',
            status_payment: '',
            code_school_rep: ''
        }
    }

    componentDidMount() {
        this.getStudentsInvoice(); 
        this.getSchoolRep(); 
    }

    filter = () => {        
        this.getStudentsInvoice()
    }

    getStudentsInvoice() {
        let formData = new FormData();
        formData.append('start_date', this.state.date != "all_time" ? Moment(this.state.startDate).format('YYYY-MM-DD') : '')
        formData.append('end_date', this.state.date != "all_time" ? Moment(this.state.endDate).format('YYYY-MM-DD') : '')
        formData.append('status_payment', this.state.status_payment) 
        formData.append('code_school_rep', this.state.code_school_rep) 

        const url = API_URL.superStudent+'/student/invoice-all';
        axios.post(url, formData, { 
            headers: { 'Accept': 'application/json','Authorization': `Bearer ${this.props.dataToken}` } 
        }).then(response => response.data).then((data) => {
            this.setState({ listInvoice: data.data })
        });
    }

    getSchoolRep() {
        const url = API_URL.superStudent+'/universities';
        axios.get(url, { 
            headers: { 'Accept': 'application/json','Authorization': `Bearer ${this.props.dataToken}` } 
        }).then(response => response.data).then((data) => {
            this.setState({ listSchoolRep: data.data })
        });
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        },() => {
            if(this.state.date === 'dinammic_date'){
                this.setState({
                    dinamicFilter:'',
                    staticFilter:'none'
                })
            }
            else{
                this.setRangeDates()
            }
        })
    }

    handleChangeMonth = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        }, () => {
            this.setRangeMonth()
        })
    }

    setKampusTujuan = (e) => {
        const newData = e.length > 0 ? e[0].code : "";
        this.setState({
            code_school_rep: newData
        })
    }

    setRangeDates() {
        let data = setRangeDate(this.state.date);
        this.setState({ 
            startDate: data.start,
            endDate: data.end
        },() => this.filter())
    }

    setRangeMonth() {
        let startDate = Moment(this.state.month+"-01");
        let endDate  = Moment(startDate).endOf('month');

        this.setState({ 
            startDate,
            endDate
        })
    }

    render() {
        const data = this.state.listInvoice;
        const pgsize = data.length;

        return(
            <Fragment>
                <div className="container-fluid">
                    <div className='row'>
                        <div className="col-sm-12">
                            <div style={{marginBottom:'10px'}} className="row justify-content-md-left justify-content-xs-left">
                                <div className='col-sm-12 mb-5'>
                                    <span style={{fontSize:'1.5em'}} className='f-w-600'>{this.state.date != "all_time" ? Moment(this.state.startDate).format('DD MMMM yyyy') : "ALL"} - {this.state.date != "all_time" ? Moment(this.state.endDate).format('DD MMMM yyyy') : "TIME"}</span> 
                                </div>
                                <div style={{display:this.state.staticFilter}} className="col-md-3 col-sm-3 mb-2">
                                    <input className='form-control digits' name='month' type='month' defaultValue={this.state.month} onChange={this.handleChangeMonth}></input>
                                </div>
                                <div style={{display:this.state.staticFilter}} className='col-md-2 col-sm-2 mb-2'>
                                    <select name='date' value={this.state.date} onChange={this.handleInput} className='form-control'>
                                        <option value='all_time'>All Time</option>
                                        <option value='this_week'>This Week</option>
                                        <option value='last_week'>Last Week</option>
                                        <option value='this_month'>This Month</option>
                                        <option value='last_month'>Last Month</option>
                                        <option value='this_year'>This Year</option>
                                        <option value='last_year'>Last Year</option>
                                            <optgroup label='Quartal'>
                                                <option value='1'>Quartal I</option>
                                                <option value='2'>Quartal II</option>
                                                <option value='3'>Quartal III</option>
                                                <option value='4'>Quartal IV</option>
                                        </optgroup>
                                        <option value='dinammic_date'> --Dinammic Date-- </option>
                                    </select>
                                </div>
                                <div style={{display:this.state.dinamicFilter}} className='col-md-5 col-sm-5 mb-2 mr-2 row'>
                                    <div className="col-md-5 mb-2">
                                        <input className="form-control" type="date" onChange={(e) => this.setState({ startDate: Moment(e.target.value)})} name="startDate"  value ={this.state.date != "all_time" ? Moment(this.state.startDate).format('YYYY-MM-DD') : ""} />
                                    </div>
                                    <div className="col-md-5 mb-2">
                                        <input className="form-control" type="date" onChange={(e) => this.setState({ endDate: Moment(e.target.value)})} name="endDate"  value ={this.state.date != "all_time" ? Moment(this.state.endDate).format('YYYY-MM-DD') : ""} />
                                    </div>
                                    <div className="col-md-2 btn-group" role='group'>
                                        <button style={{color:'white'}} className='btn btn-success btn-md' type='button' onClick={() => this.setState({dinamicFilter:'none', staticFilter:''})}><i className='fa fa-refresh'></i></button>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-2">
                                    <select className="form-control" name="status_payment" type="text" id="status_payment"  value={this.state.status_payment} onChange={(e) => this.handleInput(e)}>
                                        <option value="">--Pilih Status--</option>
                                        <option value="0">New</option>
                                        <option value="4">Sent</option>
                                        <option value="5">Outstanding</option>
                                        <option value="1">Paid</option>
                                        <option value="2">Pending</option>
                                        <option value="3">Cancel</option>
                                    </select>
                                </div>
                                <div className="col-md-3 col-sm-3">
                                    <Typeahead
                                        id="basic-typeahead"
                                        labelKey="name"
                                        name="code_school_rep"  
                                        value={this.state.listSchoolRep.code}
                                        options={this.state.listSchoolRep}
                                        onChange={(e) => this.setKampusTujuan(e)}
                                        placeholder="--School Rep--"
                                    />
                                </div>
                                <div className="col-md-1 col-sm-1"><Button onClick={() => this.filter()}  color="primary"><i className="fa fa-filter"></i> Filter</Button></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body datatable-react">
                                    <DatatableSchedulle
                                        multiSelectOption={false}
                                        myData={data}
                                        title="Document Data"
                                        pageSize={pgsize}
                                        pagination={true}
                                        class="-striped -highlight"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
    dataId: state.Login.detailUser.id_users,
})

const reduxDispatch = (dispatch) => ({
    resettoken: (data, headers) => dispatch(resettoken(data, headers))
})

export default connect(reduxState, reduxDispatch)(withRouter(DataTablesDocument));