import React, { Component } from 'react';
import { Chart } from "react-google-charts";

class PeformanceTable extends Component {

    constructor(props){
        super(props)
        this.state = {
        }
    }

    formatRupiah = (nominal) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        }).format(nominal);
    }

   render(){
    let series = 0;
    if(this.props.data.length === 2)
        series = {2 : {type: 'line'}}
    else if(this.props.data.length === 3)
        series = {3 : {type: 'line'}}
    else if(this.props.data.length === 4)
        series = {4 : {type: 'line'}}
    else if(this.props.data.length === 5)
        series = {5 : {type: 'line'}}
    else if(this.props.data.length === 6)
        series = {6 : {type: 'line'}}

    return(
        <>
            <div className='card'>
                <div className='table-responsive'>
                    <table className='table table-bordered table-stripped'>
                        <thead>
                            <tr>
                                <th>Indikator</th>
                                <th style={{width:'100px'}}>Bobot</th>
                                <th>Target</th>
                                <th>Achieved</th>
                                <th style={{width:'130px'}}>Achivement</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.data.length > 0 ?
                                this.props.data.map(item => (
                                    <tr key={item.id}>
                                        <td>{item.indikator.name}</td>
                                        <td>{item.bobot}</td>
                                        <td>{
                                            item.indikator.id === 21 || item.indikator.id === 5 ? this.formatRupiah(item.total_target) : item.total_target  
                                        }</td>
                                        <td>
                                            {
                                                item.indikator.id === 21 || item.indikator.id === 5 ? this.formatRupiah(item.kpi_data_value) : item.kpi_data_value  
                                            }
                                        </td>
                                        <td>{item.nilai_bobot}</td>
                                    </tr>
                                ))
                                :
                                <tr>
                                    <td colSpan={5}>Data Kosong</td>
                                </tr>
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <th style={{textAlign:'center'}} colSpan={4}>Total Peformance Index</th>
                                <th>{this.props.totalBobot.toFixed(2)}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div style={{display:this.props.showGrafik}} className="card">
                <Chart
                    width={'100%'}
                    height={'300px'}
                    chartType="ComboChart"
                    loader={<div>Loading Chart</div>}
                    data={this.props.grafikKPI}
                    options={{
                        title: 'Monthly KPI by Employee',
                        vAxis: { title: 'Score' },
                        hAxis: { title: 'Periode' },
                        seriesType: 'bars',
                        series,
                    }}
                    rootProps={{ 'data-testid': '1' }}
                />    
            </div>
        </>
    )
   }
}

export default PeformanceTable