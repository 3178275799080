import React ,{ useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import API_URL from '../../../ApiUrl';
import axios from 'axios'
import DataCollapseMember from './component/data-collapse-member';


const MemberLocation = ({start_date, end_date}) => {

    const[params,setParams] = useState('province')
    const[data,setData]= useState([]);

    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    }));

    const loadGraphic = () => {
        let formData = new FormData()
        formData.append('start_date', start_date)
        formData.append('end_date', end_date)
        axios.post(API_URL.superStudent+'/graphic-location-member/'+params, formData,{
            headers: {
                'Authorization': `Bearer ${dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => setData(result))
        .catch(error => console.log(error))
    }

    useEffect(() => {
        loadGraphic()
    },[params, start_date, end_date])

    return(
        <>
            <div className="card">
                <div className="card-header">
                    <div className='row'>
                        <div className='col-sm-7'>
                                <h6 className='f-w-600'>Total Members</h6>
                        </div>
                        <div className='col-sm-5'>
                            <div className="select2-drpdwn-project select-options">
                                <select onChange={e => setParams(e.target.value)} value={params} className="form-control form-control-primary btn-square" name="params">
                                    <option value="province">Provinsi</option>
                                    <option value="kabupaten">Kabupaten</option>
                                    </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body scroll" style={{height:450}}>
                    <ul className="list-group">
                        {
                            data.length > 0 ?
                            data.map((list, index) => (
                                <DataCollapseMember key={index} list={list} index={index} type={params} />
                            ))
                            :''
                        }
                    </ul>
                </div>
            </div>
        </>
    )
}

export default MemberLocation;
