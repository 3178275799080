import React, { Fragment, Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URL from "../../../ApiUrl";
import axios from "axios";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { matchSorter } from "match-sorter";

class DataTableProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name_product: "",
      code_product: "",
      price: "",
      price_modal: "",
      description: "",
      file_pic: "",
      file_pic1: "",
      file_pic2: "",
      file_pic3: "",
      file_pic_name: "",
      file_pic_name1: "",
      file_pic_name2: "",
      file_pic_name3: "",
      is_with_bundle: "0",
      status_product: "1",
      modal: false,
      edit_modal: false,
      delete_modal: false,
      btnDisabled: false,
    };
  }

  addProduct() {
    this.setState({ modal: true });
  }
  closeModal() {
    this.setState({ modal: false });
  }
  editProduct(row) {
    this.setState({
      edit_modal: true,
      name_product: row.name_product,
      code_product: row.code_product,
      price: row.price,
      price_modal: row.price_modal,
      description: row.description,
      file_pic: row.file_pic,
      file_pic1: row.file_pic1,
      file_pic2: row.file_pic2,
      file_pic3: row.file_pic3,
    });
  }
  deleteProduct(row) {
    this.setState({
      delete_modal: true,
      name_product: row.name_product,
      code_product: row.code_product,
      price: row.price,
      price_modal: row.price_modal,
      description: row.description,
      file_pic: row.file_pic,
      file_pic1: row.file_pic1,
      file_pic2: row.file_pic2,
      file_pic3: row.file_pic3,
      status_product: 0,
    });
  }
  closeEditModal() {
    this.setState({ edit_modal: false });
  }
  closeDeleteModal() {
    this.setState({ delete_modal: false });
  }

  handlePic = (e) => {
    const { files } = e.target;
    this.setState({ file_pic: files[0], file_pic_name: files[0].name });
  };
  handlePic1 = (e) => {
    const { files } = e.target;
    this.setState({ file_pic1: files[0], file_pic_name1: files[0].name });
  };
  handlePic2 = (e) => {
    const { files } = e.target;
    this.setState({ file_pic2: files[0], file_pic_name2: files[0].name });
    // console.log(e.target.files);
  };
  handlePic3 = (e) => {
    const { files } = e.target;
    this.setState({ file_pic3: files[0], file_pic_name3: files[0].name });
    // console.log(e.target.files);
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  formatRupiah = (nominal) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currencyDisplay: "narrowSymbol",
      currency: "IDR",
    }).format(nominal);
  };

  sendData() {
    this.setState({ btnDisabled: true });

    var formData = new FormData();
    formData.append("name_product", this.state.name_product);
    formData.append("is_with_bundle", 0);
    formData.append("price", this.state.price);
    formData.append("price_modal", this.state.price_modal);
    formData.append("description", this.state.description);
    formData.append("file_pic", this.state.file_pic);
    formData.append("file_pic1", this.state.file_pic1);
    formData.append("file_pic2", this.state.file_pic2);
    formData.append("file_pic3", this.state.file_pic3);
    formData.append("file_pic_name", this.state.file_pic_name);
    formData.append("file_pic_name1", this.state.file_pic_name1);
    formData.append("file_pic_name2", this.state.file_pic_name2);
    formData.append("file_pic_name3", this.state.file_pic_name3);

    axios
      .post(API_URL.superStudent + "/niec-merchandise/add-produk", formData, {
        headers: {
          Authorization: `Bearer ${this.props.dataToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        // console.log(res.data)
        this.setState({ btnDisabled: false, modal: false });
        this.props.loadProduct();
        toast.success(`Data Added Successfully`);
      })
      .catch((error) => console.log(error));
  }

  updateData() {
    this.setState({ btnDisabled: true });
    const {
      name_product,
      code_product,
      price_modal,
      price,
      description,
      file_pic,
      file_pic1,
      file_pic2,
      file_pic3,
      file_pic_name,
      file_pic_name1,
      file_pic_name2,
      file_pic_name3,
      status_product,
    } = this.state;

    var formData = new FormData();

    formData.append("name_product", name_product);
    formData.append("code_product", code_product);
    formData.append("is_with_bundle", 0);
    formData.append("price", price);
    formData.append("price_modal", price_modal);
    formData.append("description", description);
    // formData.append("file_pic", file_pic);
    // formData.append("file_pic", file_pic1);
    // formData.append("file_pic", file_pic2);
    // formData.append("file_pic", file_pic3);
    if (file_pic) formData.append("file_pic", file_pic);
    if (file_pic1) formData.append("file_pic1", file_pic1);
    if (file_pic2) formData.append("file_pic2", file_pic2);
    if (file_pic3) formData.append("file_pic3", file_pic3);

    formData.append("file_pic_name", file_pic_name);
    formData.append("file_pic_name", file_pic_name1);
    formData.append("file_pic_name", file_pic_name2);
    formData.append("file_pic_name", file_pic_name3);
    formData.append("status", status_product);

    axios
      .post(
        API_URL.superStudent + "/niec-merchandise/update-produk",
        formData,
        {
          headers: {
            Authorization: `Bearer ${this.props.dataToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        // console.log(res.data)
        this.setState({
          btnDisabled: false,
          edit_modal: false,
          delete_modal: false,
        });
        toast.success(`Data Updated Successfully`);
        this.props.loadProduct();
      })
      .catch((error) => console.log(error));
  }

  renderAddModal() {
    return (
      <div className="card-body btn-showcase">
        <Modal
          isOpen={this.state.modal}
          toggle={() => this.setState({ modal: false })}
        >
          <ModalHeader toggle={() => this.setState({ modal: false })}>
            Add Product
          </ModalHeader>
          <ModalBody>
            <form className="needs-validation" noValidate="">
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="name">Nama Product</label>
                  <input
                    className="form-control"
                    name="name_product"
                    type="text"
                    onChange={this.handleChange}
                  />
                </div>
                {/* <div className="col-md-12 mb-3">
                    <label htmlFor="name">Bundle</label>
                      <select className="form-control" defaultValue={this.state.is_with_bundle} name="is_with_bundle" onChange={this.handleChange} >
                        <option value="0">Tanpa Member</option>
                        <option value="1">Dengan Member</option>
                      </select>
                  </div> */}
                <div className="col-md-12 mb-3">
                  <label htmlFor="name">Harga Modal</label>
                  <input
                    className="form-control"
                    name="price_modal"
                    type="text"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="name">Harga Jual</label>
                  <input
                    className="form-control"
                    name="price"
                    type="text"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="name">Deskripsi</label>
                  <textarea
                    className="form-control"
                    name="description"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="name">Gambar 1</label>
                  <input
                    className="form-control"
                    name="file_pic"
                    type="file"
                    onChange={this.handlePic}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="name">Gambar 2</label>
                  <input
                    className="form-control"
                    name="file_pic1"
                    type="file"
                    onChange={this.handlePic1}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="name">Gambar 3</label>
                  <input
                    className="form-control"
                    name="file_pic2"
                    type="file"
                    onChange={this.handlePic2}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="name">Gambar 4</label>
                  <input
                    className="form-control"
                    name="file_pic3"
                    type="file"
                    onChange={this.handlePic3}
                  />
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={this.state.btnDisabled}
              color="primary"
              onClick={(e) => this.sendData()}
            >
              Save
            </Button>
            <Button
              disabled={this.state.btnDisabled}
              color="secondary"
              onClick={() => this.closeModal()}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  renderEditModal() {
    const { name_product, price_modal, price, description } = this.state;
    return (
      <div className="card-body btn-showcase">
        <Modal
          isOpen={this.state.edit_modal}
          toggle={() => this.setState({ edit_modal: false })}
        >
          <ModalHeader toggle={() => this.setState({ edit_modal: false })}>
            Edit Product
          </ModalHeader>
          <ModalBody>
            <form className="needs-validation" noValidate="">
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="name">Nama Product</label>
                  <input
                    className="form-control"
                    name="name_product"
                    type="text"
                    onChange={this.handleChange}
                    value={name_product}
                  />
                </div>
                {/* <div className="col-md-12 mb-3">
                  <label htmlFor="name">Bundle</label>
                    <select className="form-control" defaultValue={this.state.is_with_bundle} name="is_with_bundle" onChange={this.handleChange} >
                      <option value="0">Tanpa Member</option>
                      <option value="1">Dengan Member</option>
                    </select>
                </div> */}
                <div className="col-md-12 mb-3">
                  <label htmlFor="name">Harga Modal</label>
                  <input
                    className="form-control"
                    name="price_modal"
                    type="text"
                    onChange={this.handleChange}
                    defaultValue={price_modal}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="name">Harga Jual</label>
                  <input
                    className="form-control"
                    name="price"
                    type="text"
                    onChange={this.handleChange}
                    defaultValue={price}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="name">Deskripsi</label>
                  <textarea
                    className="form-control"
                    name="description"
                    onChange={this.handleChange}
                    defaultValue={description ?? ""}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="name">Gambar 1</label>
                  <input
                    className="form-control"
                    name="file_pic"
                    type="file"
                    onChange={this.handlePic}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="name">Gambar 2</label>
                  <input
                    className="form-control"
                    name="file_pic1"
                    type="file"
                    onChange={this.handlePic1}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="name">Gambar 3</label>
                  <input
                    className="form-control"
                    name="file_pic2"
                    type="file"
                    onChange={this.handlePic2}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="name">Gambar 4</label>
                  <input
                    className="form-control"
                    name="file_pic3"
                    type="file"
                    onChange={this.handlePic3}
                  />
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={this.state.btnDisabled}
              color="primary"
              onClick={(e) => this.updateData()}
            >
              Save
            </Button>
            <Button
              disabled={this.state.btnDisabled}
              color="secondary"
              onClick={() => this.closeEditModal()}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
  renderDeleteModal() {
    return (
      <div className="card-body btn-showcase">
        <Modal
          isOpen={this.state.delete_modal}
          toggle={() => this.setState({ delete_modal: false })}
        >
          <ModalHeader toggle={() => this.setState({ delete_modal: false })}>
            Delete Product
          </ModalHeader>
          <ModalBody>
            <h4>Apakah yakin ingin menghapus?</h4>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={this.state.btnDisabled}
              color="danger"
              onClick={(e) => this.updateData()}
            >
              Ya
            </Button>
            <Button
              disabled={this.state.btnDisabled}
              color="secondary"
              onClick={() => this.closeDeleteModal()}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  render() {
    const { pageSize, myData } = this.props;
    const columns = [
      {
        Header: "Kode Produk",
        accessor: "code_product",
        width: 180,
        style: { textAlign: "center" },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["code_product"] }),
        filterAll: true,
      },
      {
        Header: "Nama Produk",
        accessor: "name_product",
        style: { textAlign: "center" },
        filterable: false,
      },
      {
        Header: "Harga Modal",
        accessor: "price",
        style: { textAlign: "center" },
        filterable: false,
        Cell: (row) => this.formatRupiah(row.original.price_modal),
      },
      {
        Header: "Harga Jual",
        accessor: "price",
        style: { textAlign: "center" },
        filterable: false,
        Cell: (row) => this.formatRupiah(row.original.price),
      },
      {
        Header: <b>Action</b>,
        id: "delete",
        accessor: (str) => "delete",
        Cell: (row) => (
          <div>
            <button
              onClick={() => this.editProduct(row.original)}
              className="btn btn-primary btn-xs"
              type="button"
              style={{ border: "none" }}
            >
              <i className="fa fa-pencil"></i>
            </button>{" "}
            &nbsp;
            <button
              onClick={() => this.deleteProduct(row.original)}
              className="btn btn-danger btn-xs"
              type="button"
              style={{ border: "none" }}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        ),
        style: {
          textAlign: "center",
        },
        sortable: false,
        filterable: false,
      },
    ];

    return (
      <Fragment>
        <div style={{ marginBottom: "5px" }}>
          <button
            onClick={() => this.addProduct()}
            className="btn btn-success btn-xs"
            type="button"
            style={{ border: "none" }}
          >
            <div>
              <span>
                <i className="fa fa-plus fa-2x"></i>
              </span>
            </div>
          </button>
        </div>

        <ReactTable
          data={myData}
          columns={columns}
          pageSize={pageSize}
          showPagination={false}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
        />
        <ToastContainer />
        {this.renderAddModal()}
        {this.renderEditModal()}
        {this.renderDeleteModal()}
      </Fragment>
    );
  }
}

// export default Datatable;

const reduxState = (state) => ({
  dataId: state.Login.detailUser.id_users,
  dataToken: state.Login.lgnInitialize,
});

export default connect(reduxState, null)(DataTableProduct);
