import React, { Fragment } from 'react';
import man from '../../../assets/images/logo/compact-logo.png'
import { User,LogOut,Key } from 'react-feather';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

const UserMenu = ({ dataToken, dataUser, dataId, history }) => {
   
    const handleLogout = () => {
        window.location.href=`${process.env.PUBLIC_URL}/logout`;
    }

    return (
        <Fragment>
            <li className="onhover-dropdown">
                <div className="media align-items-center">
                    <img style={{height:'50px'}} className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={man} alt="header-user" />
                    <div className="dotted-animation">
                        <span className="animate-circle"></span>
                        <span className="main-circle"></span>
                    </div>
                </div>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                    {
                        dataUser.userable_type === "App\\Models\\Consultant" ? <li><Link to={`${process.env.PUBLIC_URL}/pages/employee`} ><User/> Profile</Link> </li> :''
                    }
                    <li><Link to={`${process.env.PUBLIC_URL}/resetpwd`}><Key />Password</Link></li>
                    <li><Link to='' onClick={handleLogout} ><LogOut /> Log out</Link></li>
                </ul>
            </li>
        </Fragment>
    );
};


const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataToken: state.Login.lgnInitialize,
    dataId: state.Login.detailUser.id_users,
    dataUser: state.Login.detailUser,
  })

  
  export default connect(reduxState, null)(withRouter(UserMenu));