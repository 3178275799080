import React, { Component,Fragment } from 'react'
import Breadcrumb from '../../../components/common/breadcrumb';
import { connect } from 'react-redux';
import { getDataUserable } from '../../../actions/login.action';

class VendorCourseProfile extends Component {

    constructor(props){
        super(props)
        this.state = {
            showComponent:false
        }
    }

    componentDidMount(){
        this.props.getUserableData(this.props.dataToken)
        .then(() => {
            if(this.props.userableData.userable !== undefined){
                this.setState({
                    showComponent:true
                })
            }
        })
        .catch(error => {
            console.log(error)
        })
    }


    render(){
        return(
            <Fragment>
                <Breadcrumb parent="User" title="Vendor Course" />
                <div className="page-wrapper">
                    <div className="container-fluid">
                        <div className="edit-profile">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className='card'>
                                        <div className='card-header'>
                                            <h4>Vendor Info</h4>
                                        </div>
                                        <div className='card-body'>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='card'>
                                        <div className='card-header'>
                                            <h4>Purchase History</h4>
                                        </div>
                                        <div className='card-body'>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    userableData: state.Login.userableData
})

const reduxDispatch = (dispatch) =>{
    return {
        getUserableData:(token) => dispatch(getDataUserable(token))
    }
}



export default connect(reduxState, reduxDispatch)(VendorCourseProfile)