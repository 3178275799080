import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import axios from 'axios';
import API_URL from '../../../ApiUrl';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Switch, Route, Redirect} from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';
import { DollarSign, TrendingDown, Home } from 'react-feather';

export class PlacementTestWriting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailResult: [],
            user: "",
            writingResult: "",
            hash: this.props.match.params.hashid
        }
    }

    componentDidMount() {
        this.getDetailResult(this.state.hash)
    }

    getDetailResult(hashid){
        axios.get(API_URL.placementTest + 'result/' + hashid, { headers: { Authorization: `Bearer ${API_URL.placementTestToken}` }})
        .then(res=>{
            let data = res.data
            let writingResult = data.pop()
            this.setState({detailResult: data, user: data[0].tb_user, writingResult: writingResult})
        })
        .catch(err => console.log(err))
    }

    render() {
        return(
            <>
                <div className="p-5">
                    <h3>Name : {this.state.user.name}</h3>
                    <hr />
                    <div className="mt-2">
                        <h4>Writing Answer</h4>
                        <h6>{this.state.writingResult?.tb_question?.question}</h6>
                        <p>{this.state.writingResult.answer}</p>
                    </div>
                </div>
            </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(PlacementTestWriting));