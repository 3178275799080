import React, { Fragment, Component } from 'react';
import DatatablePromoMember from '../common/datatable/datatablePromoMember';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { resettoken } from '../../actions/login.action';

class DataTablesPromoMember extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listPromoMember: []
        }
    }

    componentDidMount() {
        this.getDataPromoMember();
    }
 
    getDataPromoMember(){
        const url = API_URL.superStudent+'/promo-member/active';

        axios({
            method: 'get',
            url: url,
            headers: {
              'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({ listPromoMember: result}))
    }

    render() {
        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body datatable-react">
                                    <DatatablePromoMember
                                        multiSelectOption={false}
                                        myData={this.state.listPromoMember}
                                        title="Members Promo"
                                        pageSize={15}
                                        pagination={true}
                                        class="-striped -highlight"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
    dataId: state.Login.detailUser.id_users,
  })

const reduxDispatch = (dispatch) => ({
    resettoken: (data, headers) => dispatch(resettoken(data, headers))
   })
  
export default connect(reduxState, reduxDispatch)(withRouter(DataTablesPromoMember));