import React, { Fragment, Component } from 'react';
import DatatableModules from '../common/datatable/datatableModules';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import API_URL from '../../ApiUrl';
import axios from 'axios';

class dataTableModules extends Component{   

    constructor(props){
        super(props);
        this.state = {
            listModules:[]
        }
    }

    componentDidMount(){
        this.getDataModules()
    }

    getDataModules(){
        axios.get(API_URL.superStudent+'/modules',{
            headers: {  Authorization: `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            this.setState({
                listModules:response.data.data
            })
        })
        .catch(error => console.log(error))
    }

    render(){
        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body datatable-react">
                                    <DatatableModules myData={this.state.listModules} title="Modules Data" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
  })

export default connect(reduxState, null) (withRouter(dataTableModules))