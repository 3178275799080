import {
    GET_PROVINCES,
} from '../constant/actionTypes';

const initialState = {
    provinceData: {}
}

const Master = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROVINCES:
            return {
                ...state,
                provinceData: action.value
            }
        default:
            return state;
    }
}

export default Master

