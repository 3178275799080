import React from 'react'
import PodiumStep from './podium-step'

export default function Podium({ winners }) {
  const podium = [32,30,28,26,24,22,20,18,16,14,12,10,8,6,4,2,0,1,3,5,7,9,11,13,15,17,19,21,23,25,27,29,31,33]
    .reduce((podiumOrder, position) => [...podiumOrder, winners[position]], [])
    .filter(Boolean)
  return (
    <div
      style={{
        alignContent: 'flex-end',
        alignItems: 'flex-end',
        borderBottom: '1px solid #e5e7eb',
        display: 'grid',
        gap: '.2rem',
        gridAutoFlow: 'column dense',
        justifyContent: 'center',
        justifyItems: 'center',
        height: 350,
        marginTop: '2rem',
        overflowY:'scroll'
      }}
    >
      {podium.map((winner,index) => (
        <PodiumStep key={index} podium={podium} winner={winner} />
      ))}
    </div>
  )
}