import React, { Fragment, useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { Clipboard} from 'react-feather';
import Moment from 'moment';
import { useSelector } from "react-redux";
import excel from '../../assets/images/document-logo/excel-logo.png';
import word from '../../assets/images/document-logo/word-logo.png';
import pdf from '../../assets/images/document-logo/pdf-logo.png';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import 'react-vertical-timeline-component/style.min.css';

const ListAnnouncement = ({data,deleteData}) => {

    const[modal,setModal] = useState(false)
    const[url,setUrl] = useState('')

    const { dataUser } = useSelector(state => ({
        dataUser:state.Login.lgnInitialize
    }));

    const handleDelete = (id) => {
        deleteData(id)
    }

    const handleModal = (url) => {
        setModal(true)
        setUrl(url)
    }
 
    const renderPreview = () => {
        return(
            <Modal className="modal-dialog" isOpen={modal} size="lg">
              <ModalHeader toggle={() => setModal(false)}>File Preview</ModalHeader>
              <ModalBody style={{maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>
                    <DocViewer 
                        pluginRenderers={DocViewerRenderers} 
                        documents={ [{ uri:url }]} 
                        config={{
                                header: {
                                disableHeader: false,
                                disableFileName: false,
                                retainURLParams: false
                                }
                            }}
                        />                
              </ModalBody>
            </Modal>
        )
    }

    return (
        <Fragment>
            <VerticalTimeline>
                {
                    data.map(item => (
                        <VerticalTimelineElement
                            key={item.id}
                            className="vertical-timeline-element--work"
                            animate={true}
                            date={Moment(item.created_at).format('DD MMM YYYY')}
                            icon={<Clipboard />}
                        >
                            <h4 className="vertical-timeline-element-subtitle">{item.title}</h4>
                        {
                            item.image !== null ?
                            <>
                                {
                                    item.image.split('.').pop() == 'pdf' ?
                                    <img style={{cursor:'pointer',width:'100px'}} onClick={() => handleModal(item.image)} src={pdf} alt="pdf" />
                                    :
                                    item.image.split('.').pop() == 'xlsx' ?
                                    <img style={{cursor:'pointer',width:'100px'}} onClick={() => handleModal(item.image)} src={excel} alt="excel" />
                                    :
                                    item.image.split('.').pop() == 'docx' ?
                                    <img style={{cursor:'pointer',width:'100px'}} onClick={() => handleModal(item.image)} src={word} alt="timelineImg2" />
                                    :
                                    <img style={{cursor:'pointer',width:'100px'}} onClick={() => handleModal(item.image)} src={item.image} alt="timelineImg2" />
                                }
                            </>
                            :
                            ''
                        }
                        <p dangerouslySetInnerHTML={{ __html: item.description }} /> 
                        <button style={{display:dataUser.code_roles === 'RL003' || dataUser.code_roles === 'RL004' ? '' : 'none'}} onClick={() => handleDelete(item.id)} className='btn btn-danger float-right btn-sm'>Delete</button> 
                    </VerticalTimelineElement>
                    ))
                }
            </VerticalTimeline>
            {renderPreview()}
        </Fragment>
    );
};

export default ListAnnouncement;