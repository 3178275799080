import React, { Component } from 'react'
import axios from 'axios'
import API_URL from '../../../../ApiUrl'
import AccordionPlacementTest from '../components/accordion-placement-test';
import ReadingTable from '../tables/reading-table';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CKEditors from "react-ckeditor-component";
import { toast } from 'react-toastify';

export class ReadingTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text_paragraph: [],
            question: [],
            editModal: false,
            editParagraphModal: false,
            title: 'Reading Question',
            data: {
                question: '',
                opt_a: '',
                opt_b: '',
                opt_c: '',
                opt_d: '',
                id_question_type: 2,
                id_question: 'null'
            },
            id_text_paragraph: '',
            text_question: '',
            img_file: [],
            img_file_name: ''
        }
    }

    componentDidMount(){
        this.getReadingQuestion()
    }
    onChange(evt){
        var newQuestion = evt.editor.getData();
        this.setState({text_question : newQuestion})
    }
    handleChange(e){
        const {name, value} = e.target
        this.setState({data:{
            ...this.state.data, [name]: value
        }})
    }
    handleFile(e){
        const {files} = e.target
        console.log('dd', files[0])
        this.setState({img_file: files[0], img_file_name: files[0].name})
    }

    openEditParagraph = (row) => {
        const img_file_name = row.img_file 
        const str = img_file_name.substring(img_file_name.lastIndexOf('/'))
        const file_name = str.replace('/', '');
        this.setState({
            editParagraphModal: true,
            id_text_paragraph: row.id,
            text_question: row.text_question,
            img_file_name: file_name,
        })
    }
    closeEditParagraphModal(){
        this.setState({editParagraphModal: false})
    }

    editSoal(row){
        this.setState({
            editModal: true, 
            data:{...this.state.data, 
                question: row.question,
                opt_a: row.opt_a,
                opt_b: row.opt_b,
                opt_c: row.opt_c,
                opt_d: row.opt_d,
                id_question: row.id
            }
        })
    }

    closeModal(){
        this.setState({editModal: false})
    }

    sendData(){
        axios({
            method: 'POST',
            url: API_URL.placementTest + 'updateStructure',
            data: this.state.data,
            headers: {
                Authorization: `Bearer ${API_URL.placementTestToken}`
            }
        }).then(res=>{
            toast.success("Question Added")
            this.getReadingQuestion();
            this.closeModal()  
        }).catch(err => console.log(err))
    }

    saveImg(){
        let formData = new FormData();

        formData.append('img_file', this.state.img_file)
        formData.append('img_file_name', this.state.img_file_name)
        formData.append('text_question', this.state.text_question)
        formData.append('id_text_paragraph', this.state.id_text_paragraph)
        
        axios.post(API_URL.placementTest + 'updateTextQuestion', formData, { headers: { Authorization: `Bearer ${API_URL.placementTestToken}` }})
        .then(res => {
            toast.success("Paragraph Edit Success")
            this.getReadingQuestion();
            this.closeEditParagraphModal();
        })
        .catch(err => console.log(err))
    }

    getReadingQuestion(){
        axios.get(API_URL.placementTest + 'readingQuestion', { headers: { Authorization: `Bearer ${API_URL.placementTestToken}` }})
        .then(res => {
            const data = res.data
            let arr = []
            let text = []
            for (let i = 0; i < data.length; i++) {
                text = data[i].text_question
                arr = data[i].tb_question
            }
            this.setState({text_paragraph: data, question: arr})
        })
        .catch(err => console.log(err))
    }

    renderEditParagraphModal(){
        const {img_file_name} = this.state;
        return(
            <Modal isOpen={this.state.editParagraphModal} toggle={() => this.closeEditParagraphModal()} size='lg'>
                <ModalHeader toggle={() => this.closeEditParagraphModal()}>Add Paragraph Image</ModalHeader>
                <ModalBody>
                    <form>
                        {/* <div class="form-group">
                            <label htmlFor="exampleFormControlFile1">Image</label>
                            <input type="file" className='form-control' name='img_file' onChange={(e) => this.handleFile(e)} class="form-control-file" id="exampleFormControlFile1" />
                        </div> */}
                        <div class="input-group mb-3">
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" name='img_file' onChange={(e) => this.handleFile(e)} id="inputGroupFile01"/>
                            <label class="custom-file-label" for="inputGroupFile01">{img_file_name}</label>
                        </div>
                        </div>
                        <div className="form-group">
                        <label className=''>Paragraph</label>
                        <CKEditors
                            activeclassName="p10"
                            content={this.state.text_question}
                            events={{
                                    "change": this.onChange.bind(this),
                            }}
                            config={{
                            toolbar:[
                                        ["Bold","Italic",'Strike','RemoveFormat'],
                                        ['NumberedList','BulletedList','Indent','Outdent'],
                                        ['Link','Unlink'],
                                        ['Styles','Format','Table','Blockquote','Maximize'],
                                        ["Cut","Copy","Paste","Pasteasplaintext","Undo","Redo"] 
                                    ]
                                }}     
                        />
                    </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    {/* <Button color="primary" onClick={saveAudio}>{loading ? 'Add Audio Question....' : 'Add Audio Question'}</Button> */}
                    <Button disabled={this.state.btnLoading && true} color="primary" onClick={() => this.saveImg()}>Update Text Paragraph</Button>
                    <Button color="secondary" onClick={() => this.closeEditParagraphModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderEditQuestionModal(){
        const {editModal,title, data} = this.state
        return (
            <>
            <Modal isOpen={editModal} toggle={() => this.closeModal()} size='lg'>
                <ModalHeader toggle={() => this.closeModal()}>{title}</ModalHeader>
                <ModalBody>
                <form>
                <div className="form-group">
                        <label for="exampleFormControlTextarea1">Question</label>
                        <textarea name='question' className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e)=>this.handleChange(e)}>{data?.question}</textarea>
                    </div>
                    <div class="form-group">
                        <label htmlFor="exampleInputEmail1">Correct Answer</label>
                        <input type="text" name='opt_a' className="form-control" id="exampleInputEmail1" placeholder="Enter correct answer" value={data?.opt_a} onChange={(e)=>this.handleChange(e)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Other Option</label>
                        <input type="text" name='opt_b' className="form-control" id="exampleInputEmail1" placeholder="Enter other answer" value={data?.opt_b} onChange={(e)=>this.handleChange(e)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Other Option</label>
                        <input type="text" name='opt_c' className="form-control" id="exampleInputEmail1" placeholder="Enter other answer" value={data?.opt_c} onChange={(e)=>this.handleChange(e)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Other Option</label>
                        <input type="text" name='opt_d' className="form-control" id="exampleInputEmail1" placeholder="Enter other answer" value={data?.opt_d} onChange={(e)=>this.handleChange(e)} />
                    </div>
                    
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => this.sendData()}>Add</Button>
                    <Button color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
            </>
        )
    }
  render() {
    const {text_paragraph, question} = this.state;
    return (
        <>
        <AccordionPlacementTest title={'Paragraph'} component={<ReadingTable data={text_paragraph} editParagraph={this.openEditParagraph} />} />
        <div className="table-responsive mt-5">
            <h4>Question</h4>
            <table className="table">
                <thead>
                    <tr>
                        {/* <th scope="col">#</th> */}
                        <th scope="col">Question</th>
                        <th scope="col">Correct Answer</th>
                        <th scope="col">Other Options</th>
                        <th scope="col">Other Options</th>
                        <th scope="col">Other Options</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                {
                    question.map((s, i)=>{
                        return(
                            <tr key={i}>
                                <td>{s.question}</td>
                                <td>{s.opt_a}</td>
                                <td>{s.opt_b}</td>
                                <td>{s.opt_c}</td>
                                <td>{s.opt_d}</td>
                                <td><button onClick={() => this.editSoal(s)} class="btn btn-outline-success btn-xs" type="button" style={{border:'none', fontSize: 15}}><i className="fa fa-pencil"></i></button></td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
        </div>
        {this.renderEditParagraphModal()}
        {this.renderEditQuestionModal()}
        </>
    )
  }
}

export default ReadingTest