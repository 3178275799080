import React, { Component,Fragment } from 'react'
import axios from 'axios';
import ReactTable from 'react-table';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import Moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import Breadcrumb from '../../../components/common/breadcrumb';
import { getDataUserable } from '../../../actions/login.action';
import API_URL from '../../../ApiUrl';
import Loader from '../../../components/common/loader';
import { connect } from 'react-redux';

class VendorTranslateIncome extends Component {
    constructor(props){
        super(props)
        this.state = {
            staticFilter: '',
            dinamicFilter: 'none',
            startDate: Moment().startOf('isoWeek'),
            endDate: Moment().endOf('isoWeek'),
            vendor_income: []
        }
    }

    componentDidMount() {
        this.getVendorTranslateIncome()
    }

    getVendorTranslateIncome() {
        const url = API_URL.superStudent+'/vendor-translate/income';
        let formData = new FormData();
        formData.append('start_date', this.state.startDate.format('YYYY-MM-DD'))
        formData.append('end_date', this.state.endDate.format('YYYY-MM-DD'))
        axios.post(url, formData, { 
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        }).then(response => response.data.data).then((data) => {
            this.setState({ vendor_income: data })
        })
        .catch(error => console.log(error))
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        },() => {
            if(this.state.date === 'dinammic_date'){
                this.setState({
                    dinamicFilter:'',
                    staticFilter:'none'
                })
            }
            else{
                this.setRangeDate()
            }
        })
    }

    setRangeDate(){   
        let startDate = Moment();
        let endDate  = Moment();     
        switch(this.state.date){
            case 'this_week':
                startDate.startOf('isoWeek')
                endDate.endOf('isoWeek')
                break;
            case 'last_week':
                startDate.subtract(1, 'weeks').startOf('isoWeek')
                endDate.subtract(1, 'weeks').endOf('isoWeek')
                break;
            case 'this_month':
                startDate.startOf('month')
                endDate.endOf('month')
                break;
            case 'last_month':
                startDate.subtract(1, 'months').startOf('month')
                endDate.subtract(1, 'months').endOf('month')
                break;
            case 'last_year':
                startDate.subtract(1, 'year').startOf('year').add(-6,'months')
                endDate.subtract(1, 'year').endOf('year').add(-6,'months')
                break;
            case '1':
            case '2':
            case '3':
            case '4':
                startDate.quarter(this.state.date).add(2,'quarter').startOf('quarter')
                endDate.quarter(this.state.date).add(2, 'quarter').endOf('quarter')
                break;
            default:
                startDate.startOf('year').add(6,'months')
                endDate.endOf('year').add(6,'months')
        }
        this.setState({ 
            startDate,
            endDate
        },() => this.getVendorTranslateIncome())
    }

    render() {
        let idr = Intl.NumberFormat('us-UD');

        const columns = [
            {
                Header: 'Nama Vendor',
                accessor: 'name_vendor_translate',
                filterable: false
            },
            {
                Header: 'Email',
                accessor: 'email_vendor_translate',
                filterable: false
            },
            {
                Header: 'Total Income',
                accessor: 'rate',
                filterable: false,
                Cell: (row) => (
                    <h6 className='pull-right'>{idr.format(row.original.total_income)}</h6>
                ),
                width:150
            },
        ]

        const child_columns = [
            {
                Header: 'Nama Student',
                accessor: 'student_name.nama',
                filterable: false
            },
            {
                Header: 'Total Rupiah',
                accessor: 'rate',
                filterable: false,
                Cell: (row) => (
                    <h6 className='pull-right'>{idr.format(row.original.total_rupiah)}</h6>
                ),
                width:150
            },
            {
                Header: 'Consultant',
                accessor: 'student_name.consultant.nama',
                filterable: false,
                Cell: (row) => (
                    <h6 className='pull-right'>{row.original.student_name.consultant.nama}</h6>
                ),
            }, 
        ]

        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div style={{marginBottom:'10px'}} className="row justify-content-md-center justify-content-xs-center">
                                            <div style={{display:this.state.staticFilter}} className='col-md-3 col-sm-4 mb-2'>
                                                <select name='date' value={this.state.date} onChange={this.handleInput} className='form-control'>
                                                    <option value='this_week'>This Week</option>
                                                    <option value='last_week'>Last Week</option>
                                                    <option value='this_month'>This Month</option>
                                                    <option value='last_month'>Last Month</option>
                                                    <option value='this_year'>This Year</option>
                                                    <option value='last_year'>Last Year</option>
                                                        <optgroup label='Quartal'>
                                                            <option value='1'>Quartal I</option>
                                                            <option value='2'>Quartal II</option>
                                                            <option value='3'>Quartal III</option>
                                                            <option value='4'>Quartal IV</option>
                                                        </optgroup>
                                                    <option value='dinammic_date'> --Dinammic Date-- </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div style={{display:this.state.dinamicFilter}} className='row mb-5'>
                                            <div className="col-md-4 mb-2">
                                                <input className="form-control" type="date" onChange={(e) => this.setState({ startDate: Moment(e.target.value)})} name="startDate"  value ={this.state.startDate.format('YYYY-MM-DD')} />
                                            </div>
                                            <div className="col-md-4 mb-2">
                                                <input className="form-control" type="date" onChange={(e) => this.setState({ endDate: Moment(e.target.value)})} name="endDate"  value ={this.state.endDate.format('YYYY-MM-DD')} />
                                            </div>
                                            <div className="col-md-4 btn-group" role='group'>
                                                <button style={{color:'white'}} type='button' className='btn btn-primary btn-md' onClick={() => this.filter()} ><i className='fa fa-filter'></i> Filter</button>
                                                <button style={{color:'white'}} className='btn btn-success btn-md' type='button' onClick={() => this.setState({dinamicFilter:'none', staticFilter:''})}><i className='fa fa-refresh'></i></button>
                                            </div>
                                        </div>
                                        {
                                            this.state.vendor_income.length > 0 ? 
                                            <ReactTable
                                                data={this.state.vendor_income}
                                                columns={columns}
                                                collapseOnDataChange={false}
                                                filtered={this.state.filtered}
                                                onFilteredChange={(filtered, column, value) => {
                                                    this.onFilteredChangeCustom(value, column.id || column.accessor);
                                                }}
                                                defaultPageSize={25}
                                                className="-striped -highlight"
                                                SubComponent={ row => {
                                                    return(
                                                        <>
                                                            <ReactTable
                                                                data={row.original.payment}
                                                                columns={child_columns}
                                                                filterable
                                                                defaultPageSize={5}
                                                                className="-striped -highlight"
                                                                showPaginationBottom={true}
                                                                defaultFilterMethod={(filter, row) =>
                                                                    String(row[filter.id]) === filter.value}
                                                            />
                                                        </>
                                                    )
                                                }}
                                            />
                                            :
                                            ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    userableData: state.Login.userableData
})

const reduxDispatch = (dispatch) =>{
    return {
        getUserableData:(token) => dispatch(getDataUserable(token))
    }
}

export default connect(reduxState, reduxDispatch)(VendorTranslateIncome)