import React, { Component } from 'react'
import { connect } from 'react-redux'
import API_URL from '../../ApiUrl'
import axios from 'axios'
import ReactTable from 'react-table'
import Moment from 'moment'
import { toast } from 'react-toastify'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

class CutiData extends Component {
 
    constructor(props){
        super(props)
        this.state = {
            HistoryCuti:[],
            modalCuti:false,
            type: 1,
            cuti_type:'',
            description:'',
            start_date:'',
            file:'',
            duration:'',
            textSave: 'Request',
            disableBtn:false,
            filtered:[],
            filterStatus:'',
            filterDuration:'',
            filterType:'',
        }
    }

    componentDidMount(){
        this.loadHistoryCuti()
    }

    closeModal = () => {
        this.setState({
            modalCuti: false
        })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleChangeFile = (e) => {
        this.setState({
            file:e.target.files[0]
        })
    }

    handleAddCuti = (e) => {
        this.setState({
            modalCuti:true,
            textSave: 'Request',
            disableBtn: false,
            description: '',
            cuti_type:'',
            duration:'',
            start_date:'',
            file:''
        })
    }

    loadHistoryCuti = () => {
        let formData = new FormData()
        formData.append('code_konsultan', this.props.dataUser.code_konsultan)
        axios.post(API_URL.superStudent+'/cuti/history', formData, {
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => {
            this.setState({
                HistoryCuti: response.data.data
            })
        })
        .catch(error => {

        })
    }

    approveData = (e) => {
        this.setState({
            textSave: 'Requesting...',
            disableBtn: true
        })
        let formData = new FormData()
        formData.append('code_konsultan',this.props.dataUser.code_konsultan)
        formData.append('type', this.state.type)
        formData.append('cuti_type', this.state.cuti_type)
        formData.append('description', this.state.description)
        formData.append('start_date', this.state.start_date)
        formData.append('duration',8)
        formData.append('file',this.state.file)
        axios.post(API_URL.superStudent+'/cuti/request-cuti',formData,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        }) 
        .then(() => {
            this.closeModal()
            this.loadHistoryCuti()
            toast.success('data added successfully')
        })
        .catch(error => {
            this.closeModal()
            toast.error('there is an error')
        })
    }

    getType = (row) => {
        let type = '';
        if(row.type === 1){
            switch (row.cuti_type) {
                case 1:
                    type = 'Cuti Tahunan'
                    break;
                case 2:
                    type = 'Cuti Khusus'
                    break;
                case 3: 
                    type = 'Permission Leave'
                    break;
                case 4: 
                    type = 'Unpaid Leave'
                    break;
                case 5: 
                    type = 'Cuti DP'
                    break;
                case 6: 
                    type = 'Cuti Sakit'
                    break;
                default:
                    break;
            }
        }
        else
            type = 'Day Payment';
        return type;
    }

    
    SetFilterStatus = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
          let filterStatus = newData; 
          return { filterStatus };                   
          }, () => {
            this.onFilteredChangeCustom(newData,"status");
          })
    }

    SetFilterDuration = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
          let filterDuration = newData; 
          return { filterDuration};                   
          }, () => {
            this.onFilteredChangeCustom(newData,"duration");
          })
    }

    SetFilterType = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
          let filterType = newData; 
          return { filterType};                   
          }, () => {
            this.onFilteredChangeCustom(newData,"type");
          })
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
    
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
    
        if (insertNewFilter) {
          filtered.push({ id: accessor, value: value });
        }
    
        this.setState({ filtered: filtered });
  }

    renderModalCuti() {
        return(
          <Modal className="modal-dialog" isOpen={this.state.modalCuti} toggle={() => this.closeModal()} size="md">
            <ModalHeader toggle={() => this.closeModal()}>Request Cuti </ModalHeader>
            <ModalBody style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto'
            }}>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                        <label className='f-w-600'>Jenis Cuti</label>
                            <select className='form-control' name='cuti_type' value={this.state.cuti_type} onChange={this.handleInput}>
                                <option>--Select Type--</option>
                                <option value={1}>Cuti Tahunan</option>
                                <option value={2}>Cuti Khusus</option>
                                <option value={3}>Permission Leave</option>
                                <option value={4}>Unpaid Leave</option>
                                <option value={5}>Cuti DP</option>
                                <option value={6}>Cuti Sakit</option>
                            </select>
                        </div>
                        {/* <div className='form-group'>
                            <label className='f-w-600'>Lama Waktu</label>
                            <select className='form-control' name='duration' value={this.state.duration} onChange={this.handleInput}>
                                <option>--Select Duration--</option>
                                <option value={4}>&#189; Hari</option>
                                <option value={8}>1 Hari</option>
                            </select>
                        </div> */}
                        <div className='form-group'>
                            <label className='f-w-600'>Description</label>
                            <textarea className='form-control' name='description' value={this.state.description} onChange={this.handleInput} />
                        </div>
                        <div className='form-group'>
                            <label className='f-w-600'>Tanggal</label>
                            <input type='date' className='form-control' name='start_date' value={this.state.start_date} onChange={this.handleInput} />
                        </div>
                        <div className='form-group'>
                            <label className='f-w-600'>Dokumen Pendukung <span style={{fontSize:'10px'}}>(jika ada)</span></label>
                            <input type='file' className='form-control' onChange={this.handleChangeFile}/>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
              <Button disabled={this.state.disableBtn} color="primary" onClick={(e) => this.approveData()}>{this.state.textSave}</Button>
              <Button color="secondary" onClick={e => this.closeModal()}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )
    }
 
    render(){
        const columns = [
            {
                Header: 'Type',
                accessor: 'type',
                style: {textAlign: 'center'},
                Cell: (row) => (
                  <>{this.getType(row.original)}</>
                ),
                filterable: true,
                Filter: () => 
                <select value={this.state.filterType || ''} onChange={(e) => this.SetFilterType(e)}>
                    <option key={0} value=''>Show All</option>
                    <option key={1} value='1'>Cuti</option>
                    <option key={2} value='2'>Day Payment</option>
                </select>,
            },
            {
                Header: 'Durasi',
                accessor: 'duration',
                style: {textAlign: 'center'},
                Cell: (row) => (
                  <>{row.original.duration === 8 ? '1' : <>&#189;</> } Hari</>
                ),
                filterable: true,
                Filter: () => 
                <select value={this.state.filterDuration || ''} onChange={(e) => this.SetFilterDuration(e)}>
                    <option key={0} value=''>Show All</option>
                    <option key={8} value='8'>1 Hari</option>
                    <option key={4} value='4'>&#189; Hari</option>
                </select>,
            },
            {
                Header: 'Description',
                accessor: 'description',
                style: {textAlign: 'center'},
                filterable: false,
            },
            {
                Header: 'Tanggal',
                accessor: 'start_date',
                style: {textAlign: 'center'},
                filterable: false,
                Cell: (row) =>(
                    <>{Moment(row.original.start_date).format('DD/MM/YYYY')}</>
                )
            },
            {
                Header: <b>Status</b>,
                accessor: 'status',
                Cell: (row) => (
                    row.original.status === 1 ? <span className='badge bg-success'>Approved</span> : row.original.status === 0 ? <span className='badge bg-danger'>Rejected</span> : row.original.status === 2 ? <span className='badge bg-info'>Used</span> : row.original.status === 3 ? <span className='badge bg-warning'>Expired</span> : <span className='badge bg-primary'>Waiting</span>
                ),
                style: { textAlign: 'center' },
                filterable: true,
                Filter: () => 
                <select value={this.state.filterStatus || ''} onChange={(e) => this.SetFilterStatus(e)}>
                    <option key={0} value=''>Show All</option>
                    <option key={1} value={1}>Approved </option>
                    <option key={2} value='0'>Rejected</option>
                    <option key={3} value='2'>Used</option>
                    <option key={4} value='3'>Expired</option>
                    <option key={5} value='null'>Waiting</option>
                </select>,
            }
        ]
        return(
            <> 
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    <button className='btn btn-success' onClick={this.handleAddCuti}> Add Data</button>
                                </div>
                                <div className='card-body'>
                                    <ReactTable
                                        data={this.state.HistoryCuti}
                                        filterable
                                        columns={columns}
                                        defaultPageSize={10}
                                        className="-striped -highlight"
                                        showPaginationBottom={true}
                                        onFilteredChange={(filtered, column, value) => {
                                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                                        }}
                                        filtered={this.state.filtered}
                                        defaultFilterMethod={(filter, row) =>
                                            String(row[filter.id]) === filter.value}
                                    /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModalCuti()}
            </>
        )
    }
}

const reduxState = (state) => ({
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState)(CutiData);