import React, { Component } from 'react'
import CKEditors from "react-ckeditor-component";
import { withRouter } from "react-router";
import API_URL from '../../../ApiUrl';
import { connect } from 'react-redux'
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import "react-datepicker/dist/react-datepicker.css";

class FormEvent extends Component {

    constructor(props){
        super(props)
        this.state = {
            event_id:'',
            event_name: '',
            description: '',
            respon_msg:'',
            category:'',
            jenis_event:'',
            meet_link: '',
            start_date:'',
            end_date:'',
            target_peserta:'',
            show_in_apps:'',
            event_berbayar:'',
            nominal_pembayaran:0,
            banner:'',
            disabledButton:false,
            buttonText:'Save',
            errors:[],
            kabupaten:[],
            operation:'',
            column_location: [
                { kode_kabkota:'', alamat:'',place:'',time_event:'',latitude:'',longitude:'' }
            ],
            column_date: [
                { date:'' }
            ]
        }
    } 

    componentDidMount(){
        this.loadDataKabupaten()
        if(this.props.location.state === undefined)
            this.setState({ operation: 'tambah' })
        else{
            let data = this.props.location.state
            console.log(data)
            this.setState({
                operation : 'update',
                event_id:data.id,
                event_name: data.name,
                description: data.description,
                respon_msg:data.respon_msg,
                category:data.category_event,
                jenis_event:data.jenis_event,
                meet_link: data.zoom_link,
                start_date:data.start_date,
                end_date:data.end_date,
                target_peserta:data.target_peserta,
                show_in_apps:data.show_in_apps,
                event_berbayar:data.status_payment,
                nominal_pembayaran: data.nominal_payment,
                column_location:data.event_lokasi,
                column_date:data.event_date,
                banner:'',
            })
        }
    }

    loadDataKabupaten() {
        axios.get(API_URL.superStudent+'/master/kabupaten',{
            headers: { 'Accept': 'application/json' }
        })
        .then(response => response.data.data)
        .then(result => this.setState({ kabupaten: result }))
        .catch(error => console.log(error))
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleBanner = (e) => {
        this.setState({
            banner:e.target.files[0]
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        let formData = new FormData()
        formData.append('name', this.state.event_name)
        formData.append('description', this.state.description)
        formData.append('jenis_event', this.state.jenis_event)
        formData.append('start_date', this.state.start_date)
        formData.append('end_date', this.state.end_date)
        formData.append('show_in_apps', this.state.show_in_apps)
        formData.append('target_peserta', this.state.target_peserta)
        formData.append('respon_msg', this.state.respon_msg)
        formData.append('zoom_link', this.state.meet_link)
        formData.append('image', this.state.banner)
        formData.append('category_event', this.state.category)
        formData.append('status_payment', this.state.event_berbayar)
        formData.append('nominal_payment', this.state.nominal_pembayaran)
        formData.append('location',JSON.stringify(this.state.column_location))
        formData.append('date',JSON.stringify(this.state.column_date))
        this.setState({
            disabledButton:true,
            buttonText:'Saving...'
        })

        let url = '/master-event';
        if (this.state.operation == 'update') {
            formData.append('_method', 'PATCH');
            url = '/master-event/'+this.state.event_id
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        })
        .then(response => {
            toast.success("Data Event berhasil diperbaharui");
            this.props.history.push('/pages/events/master-event/list');
        })
        .catch(err => {
            this.setState({
                errors:err.response.data.data,
                disabledButton:false,
                buttonText:'Simpan'
            })
            toast.error("Cek kembali Inputan Anda");
        })
    }

    onChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            description: newContent
        })
    }

    handleFormChange = (index, e) => {
        let data = [...this.state.column_location]
        data[index][e.target.name] = e.target.value
        this.setState({
            column_location: data
        })
    }

    handleFormChangeDate = (index, e) => {
        let data = [...this.state.column_date]
        data[index][e.target.name] = e.target.value
        this.setState({
            column_date: data
        })
    }

    handleKabupaten = (index,e) => {
        const code = e.length > 0 ? e[0].id : "";
        let data = [...this.state.column_location]
        data[index]['kode_kabkota'] = code
        this.setState({ column_location: data })
    }

    addFields = () => {
        let newFields = { kode_kabkota:'', alamat:'',place:'',time_event:'',latitude:'',longitude:'' }
        this.setState(prevState => ({ 
            column_location: [...prevState.column_location, newFields]
        }))
    }

    addFieldsTanggal = () => {
        let newFieldsTanggal = { date: '' }
        this.setState(prevState => ({
            column_date: [...prevState.column_date, newFieldsTanggal]
        }))
    }

    remoreFields = (index) => {
        let data = [...this.state.column_location]
        data.splice(index,1)
        this.setState({ column_location: data })
    }

    remoreFieldsDate = (index) => {
        let data = [...this.state.column_date]
        data.splice(index, 1)
        this.setState({ column_date: data })
    }

    render(){
        return (
            <>
                <div className='row m-1'>
                    <div className='col-md-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <form onSubmit={this.handleSubmit}>
                                    <div className='row'> 
                                        <div className='col-md-6 col-sm-12'>
                                            <div className="form-group">
                                                <label className='f-w-600'>name<span className='text-danger'>*</span> </label>
                                                <input className="form-control" onChange={this.handleInput} value={this.state.event_name} name='event_name' type="text" placeholder="example" />
                                                <div className="text-danger">{this.state.errors.event_name}</div>
                                            </div>
                                        </div>
                                        <div className='col-md-3 col-sm-12'>
                                            <label className='f-w-600'>Jenis Event<span className='text-danger'>*</span> </label>
                                            <select className='form-control' name='jenis_event' value={this.state.jenis_event} onChange={this.handleInput}>
                                                <option value=''>Pilih Jenis Event</option>
                                                <option value='1'>Global Youth</option>
                                                <option value='2'>Event Season</option>
                                                <option value='3'>Event Expo</option>
                                            </select>
                                            <div className="text-danger">{this.state.errors.jenis_event}</div>
                                        </div>
                                        <div className='col-md-3 col-sm-12'>
                                                <div className="form-group">
                                                    <label className='f-w-600'>Category<span className='text-danger'>*</span> </label>
                                                    <select className='form-control' name='category' value={this.state.category} onChange={this.handleInput}>
                                                        <option value=''>Pilih category</option>
                                                        <option value='Offline'>Offline</option>
                                                        <option value='Online'>Online</option>
                                                        <option value='Hybrid'>Hybrid</option>
                                                    </select>
                                                    <div className="text-danger">{this.state.errors.event_name}</div>
                                                </div>
                                            </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="f-w-600">Periode</label>
                                                <div className="input-group">
                                                    <input type="date" value={this.state.start_date} onChange={this.handleInput} name="start_date" className="form-control"/>
                                                    <input type="date" value={this.state.end_date} onChange={this.handleInput} name="end_date" className="form-control"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-3 col-sm-12'>
                                            <label className='f-w-600'>Target Peserta </label>
                                            <input className="form-control" onChange={this.handleInput} value={this.state.target_peserta} name='target_peserta' type="text" placeholder="100" />
                                        </div>
                                        <div className='col-md-3 col-sm-12'>
                                            <label className='f-w-600'>Tampilkan di Apps  </label>
                                            <select  name='show_in_apps' onChange={this.handleInput} value={this.state.show_in_apps} className='form-control'>
                                                    <option>Pilih satu</option>
                                                    <option value={1}>Ya</option>
                                                    <option value={0}>Tidak</option>
                                            </select>
                                        </div>
                                        <div className='col-md-3 col-sm-12'>
                                            <label className='f-w-600'>Banner</label>
                                            <input type='file' className='form-control' onChange={this.handleBanner} />
                                        </div>
                                        <div style={{display:this.state.category === 'Online' || this.state.category === 'Hybrid' ? '' : 'none' }} className='col-md-3 col-sm-12'>
                                            <label className='f-w-600'>Link Online </label>
                                            <input className="form-control" onChange={this.handleInput} value={this.state.meet_link} name='meet_link' type="text" placeholder="https://meet.google.com/ekr-dkqb-kvz" />
                                        </div>
                                        <div className='col-md-3 col-sm-12'>
                                            <label className='f-w-600'>Event Berbayar ?</label>
                                            <select  name='event_berbayar' onChange={this.handleInput} value={this.state.event_berbayar} className='form-control'>
                                                    <option>Pilih satu</option>
                                                    <option value={1}>Ya</option>
                                                    <option value={0}>Tidak</option>
                                            </select>
                                        </div>
                                        <div style={{display:this.state.event_berbayar == '1' ? '' : 'none' }} className='col-md-3 col-sm-12'>
                                            <label className='f-w-600'>Nominal Pembayaran (Rp) </label>
                                            <input className="form-control" onChange={this.handleInput} value={this.state.nominal_pembayaran} name='nominal_pembayaran' type="number" placeholder="30000" />
                                        </div>
                                        <div className='col-sm-12'></div>
                                        <div style={{display:this.state.category === 'Offline' || this.state.category === 'Hybrid' ? '' : 'none' }} className='card mt-1'>
                                            <div className='card-body'>
                                                <div className='col-sm-12 my-3'>
                                                    <button  onClick={this.addFields} className="btn btn-success" type="button" >Tambah</button>
                                                </div>
                                                { this.state.column_location.map((item, index) => {
                                                return (
                                                        <div className='row mb-4' key={index}>
                                                            <div className='col-md-4'>
                                                                <Typeahead labelKey="kabupaten_name" name="id" options={this.state.kabupaten}  onChange={(e) => this.handleKabupaten(index, e)} placeholder="--Pilih Lokasi--"/>
                                                            </div>
                                                            <div className='col-md-4 col-sm-12'>
                                                                <div className='form-group'>
                                                                    <input placeholder='alamat' onChange={e => this.handleFormChange(index, e)} type='text' name='alamat' value={item.alamat} className='form-control' />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-4 col-sm-12'>
                                                                <div className='form-group'>
                                                                    <input placeholder='tempat' onChange={e => this.handleFormChange(index, e)} type='text' name='place' value={item.place} className='form-control' />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-3 col-sm-12'>
                                                                <div className='form-group'>
                                                                    <input placeholder='waktu' onChange={e => this.handleFormChange(index, e)} type='datetime-local' name='time_event' value={item.time_event} className='form-control' />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6 col-sm-12'>
                                                                <div className="input-group">
                                                                    <input type="text" placeholder='latitude'  value={this.state.latitude} onChange={e => this.handleFormChange(index, e)} name="latitude" className="form-control"/>
                                                                    <input type="text" placeholder='longitude' value={this.state.longitude} onChange={e => this.handleFormChange(index, e)} name="longitude" className="form-control"/>
                                                                    <button type='button' onClick={() => this.remoreFields(index)} className='btn btn-outline-danger'><i className='fa fa-trash'></i></button>
                                                                </div>
                                                            </div>
                                                            <hr/>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className='col-md-12 card mt-3'>
                                            <div className='card-body'>
                                                <div className='col-sm-12 my-3'>
                                                    <button  onClick={this.addFieldsTanggal} className="btn btn-success" type="button" >Tambah Tanggal</button>
                                                </div>
                                                { this.state.column_date.map((item, index) => {
                                                    return (
                                                        <div className='row mb-4' key={index}>
                                                            <div className='col-lg-6'>
                                                                <input placeholder='date' onChange={e => this.handleFormChangeDate(index, e)} type='date' name='event_date' value={item.event_date} className='form-control' /> 
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                <button type='button' onClick={() => this.remoreFieldsDate(index)} className='btn btn-outline-danger'><i className='fa fa-trash'></i></button>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className='col-md-6 mt-3'>
                                            <div className="form-group mb-0">
                                                <label className='f-w-600'>Description<span className='text-danger'>*</span></label>
                                                <CKEditors
                                                    activeclassName="p10"
                                                    content={this.state.description}
                                                    events={{
                                                        "change": this.onChange.bind(this),
                                                    }}
                                                    config={{
                                                        toolbar:[
                                                            ["Bold","Italic",'Strike','RemoveFormat'],
                                                            ['NumberedList','BulletedList','Indent','Outdent'],
                                                            ['Link','Unlink'],
                                                            ['Styles','Format','Table','Blockquote','Maximize'],
                                                            ["Cut","Copy","Paste","Pasteasplaintext","Undo","Redo"]
                                                        ]
                                                    }}     
                                                />
                                                <div className="text-danger">{this.state.errors.description}</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 mt-3'>
                                            <div className="form-group mb-0">
                                                <label className='f-w-600'>Respawn Message<span className='text-danger'>*</span></label>
                                                <textarea rows={12} className='form-control' name='respon_msg' value={this.state.respon_msg} onChange={this.handleInput}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <button className='mt-3 btn btn-primary'  disabled={this.state.disabledButton} type="submit" >{this.state.buttonText}</button> */}
                                    <button className='mt-3 btn btn-primary' type="submit" >{this.state.buttonText}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const reduxState = (state) => ({
    dataUsers: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize
})

export default connect(reduxState,null)(withRouter(FormEvent));