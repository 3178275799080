import React ,{Component} from 'react';
import { connect } from 'react-redux';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import { withRouter } from "react-router";
import image from '../../../assets/images/user/6.jpg'

class StepOne extends Component{

    constructor(props){
        super(props)
        this.state =  {
            id:'',
            course_id:this.props.match.params.courseId,
            title:'',
            view_cover:'',
            cover:'',
            textSave:'Save',
            btnDisable: false,
            componentStyle:'',
            action:''
        }
    }

    componentDidMount(){
        if(this.props.location.state === undefined)
            this.setState({ action: 'tambah' })
        else {
            let data = this.props.location.state
            this.setState({
                action: 'edit',
                id:data.id,
                title: data.detail_course_name,
                cover: data.cover,
                view_cover:data.cover
            })
        }
    }


    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleFile = (e) => {
        this.setState({ 
            cover: e.target.files[0]
        })
    }


    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({
            textSave:'Saving...',
            btnDisable:true 
        })
        let formData = new FormData()
        formData.append('id', this.state.id)
        formData.append('title', this.state.title)
        formData.append('course_id', this.state.course_id)
        formData.append('cover', this.state.cover)

        let url = '/course/detail-course'

        if (this.state.action === 'edit'){
            formData.append('_method','PATCH');
            url = '/course/detail-course/'+this.state.id
        }
        axios({
            method:'POST',
            url: API_URL.superStudent+url,
            data: formData,
            headers: { Authorization: `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            this.setState({ componentStyle:'none' })
            this.props.SaveFormOne(response.data.data)
            toast.success('data saved successfully')
        })
        .catch(error => console.log(error))  
    }

    render(){
        return(
            <>
               <form style={{display:this.state.componentStyle}} onSubmit={this.handleSubmit}>
                    <div className='form-row'>
                        <div className="col-md-12 mb-3">
                            <label className='f-w-600'>Title</label>
                            <input className="form-control" value={this.state.title} onChange={this.handleInput} name="title" type="text" placeholder="title"/>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className="col-md-12 mb-3">
                            <label className='f-w-600'>Cover</label>
                            <input className="form-control" onChange={this.handleFile} type="file" placeholder="input here."/>
                            {
                                this.state.action === 'edit' ? 
                                <figure className="col-md-12 mt-2">
                                    <img src={this.state.view_cover !== null ? this.state.view_cover : image} alt="images" className="img-thumbnail" />
                                </figure>
                                :
                            ''
                            }
                        </div>
                    </div>
                    <div className='form-row'>
                        <button disabled={this.state.btnDisable} type='submit' className='btn btn-success float-right'>{this.state.textSave}</button>
                    </div>
                </form>
            </>
        )
    }
}

const reduxState = (state) => ({
    userable: state.Login.userableData.userable,
    dataToken: state.Login.lgnInitialize,
  })

export default connect(reduxState, null) (withRouter(StepOne));