import React, { Fragment } from 'react';
import DataTablesSchedulle from '../../components/tables/dataTableSchedulleInvoice';

const ScheduleInvoiceData = () => {
    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-md-12">
                            <DataTablesSchedulle />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ScheduleInvoiceData;