import React from 'react'

const ReadingTable = ({data, editParagraph}) => {

  return (
    <>
    {
    data?.map((s,i)=>{
        return(
        <>
        <button onClick={() => editParagraph(s)} className='btn btn-success btn-xs'>Edit Text Paragraph<i className='fa fa-pencil'></i></button>
        <div className='mb-5'><img src={s.img_file} alt="" width={250} /></div>
        {/* <button className='btn btn-success btn-xs'>Edit Paragraph <i className='fa fa-pencil'></i></button> */}
        <div key={i} dangerouslySetInnerHTML={{__html: s.text_question}} />
        </>
        )
    })
    }
    </>
  )
}

export default ReadingTable