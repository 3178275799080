import React, { Component, Fragment } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import IncomeConsultant from './income-consultant';
import IncomeBizdev from './income-bisdev';
import IncomeArea from './income-area';
import Moment from 'moment';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import setRangeDate from '../../../helper/general-function';

class DashboardLastYear extends Component {

    constructor(props) {
        super(props)
        this.state = {
            formData: { start_date: Moment().startOf('isoWeek').format('YYYY-MM-DD'), end_date: Moment().endOf('isoWeek').format('YYYY-MM-DD'), jobdesk: JSON.stringify(['JD002', 'JD004', 'JD007']) },
            date: '',
            show: false,
            staticFilter: '',
            dinamicFilter: 'none',
            start_date: '2023-07-01',
            end_date: '2024-06-30',
            fcm_token: ''
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            if (this.state.date === 'dinammic_date') {
                this.setState({
                    dinamicFilter: '',
                    staticFilter: 'none'
                })
            }
            else {
                this.setDate()
            }
        })
    }

    handlePickerMonth = (e) => {
        this.setState({
            start_date: Moment(e).startOf('month').format('YYYY-MM-DD'),
            end_date: Moment(e).endOf('month').format('YYYY-MM-DD'),
        })
    }

    setDate() {
        let data = setRangeDate(this.state.date);
        let formData = new FormData();
        formData.append('start_date', data.start)
        formData.append('end_date', data.end)
        formData.append('jobdesk', JSON.stringify(['JD002', 'JD004', 'JD007']))
        this.setState({
            start_date: data.start,
            end_date: data.end,
            formData
        })
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb parent="Report Last Year" title="Income Last Year" />
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-sm-12"></div>
                        <div style={{ display: this.state.staticFilter }} className='col-md-3 col-sm-4 mb-2'>
                            <select name='date' value={this.state.date} onChange={this.handleChange} className='form-control'>
                                <option value='last_year'>Last Year</option>
                                <option value='dinammic_date'> --Dinammic Date-- </option>
                            </select>
                        </div>
                        <div className="col-sm-12"></div>
                        <div style={{ display: this.state.dinamicFilter }} className='row justify-content-center'>
                            <div className='col-md-8 col-sm-6 col-xs-6 mb-2'>
                                <DatePicker dateFormat='MMMM yyyy' selected={Moment(this.state.start_date)._d} showMonthYearPicker showFullMonthYearPicker className='form-control' onChange={this.handlePickerMonth} />
                            </div>
                            <div className='col-md-2 col-sm-4 mb-2'>
                                <button className='btn btn-success btn-md' type='button' onClick={() => this.setState({ dinamicFilter: 'none', staticFilter: '' })}><i style={{ color: 'white' }} className='fa fa-refresh'></i></button>
                            </div>
                        </div>
                        <div className='col-sm-12 mb-3'>
                            <span style={{ fontSize: '1.5em' }} className='f-w-600'>{Moment(this.state.start_date).format('DD MMMM yyyy')} - {Moment(this.state.end_date).format('DD MMMM yyyy')}</span>
                        </div>
                        <div className='col-xl-12'>
                            <IncomeConsultant start_date={this.state.start_date} end_date={this.state.end_date} />
                        </div>
                        <div className='col-xl-12'>
                            <IncomeArea start_date={this.state.start_date} end_date={this.state.end_date} />
                        </div>
                        <div className='col-xl-12'>
                            <IncomeBizdev start_date={this.state.start_date} end_date={this.state.end_date} />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    code_roles: state.Login.detailUser.code_roles
})

export default connect(reduxState, null)(DashboardLastYear);