import React from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone-uploader';
import { useHistory } from "react-router";
import { withRouter } from "react-router";

const StepTwo = ({ data }) => {

    const history = useHistory()

    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    }));

    const getUploadParams = ({ file, meta }) => {
        let body = new FormData()
        body.append('video_url', file)
        body.append('id', data.id)
        return { 
            url: API_URL.superStudent+'/course/upload-video',  
            headers: {"Authorization": `Bearer ${dataToken}`},
            body
         } 
    }

    const handleChangeStatus = ({ meta, file, xhr}, status) => {
        if(status === 'done')
            toast.success("Trailer Video successfully uploaded !")
        else if(status === 'removed'){
            axios.get(API_URL.superStudent+'/course/delete-video/'+data.id,{
                headers: {"Authorization": `Bearer ${dataToken}`},
            })
            .then(() => {
                toast.success("Trailer Video successfully deleted !")
            })
            .catch(error => console.log(error))   
        }
    }

    return(
        <>
            <div className='row justify-content-center'>
                <div className='col-md-12'>
                    <label className='f-w-600'>Trailer Video</label>
                    <div className="dz-message needsclick">
                        <Dropzone
                            getUploadParams={getUploadParams}
                            onChangeStatus={handleChangeStatus}
                            maxFiles={1}
                            accept="video/*"
                            multiple={false}
                            canCancel={false}
                            inputContent="Drop A File"
                            styles={{
                                dropzoneActive: { borderColor: 'green' },
                            }}
                        />
                    </div>
                </div>
                <div className='col-md-3 mt-2'>
                    <button className="btn btn-success" onClick={() => history.push({
                        pathname: `${process.env.PUBLIC_URL}/pages/course/detail-course/`+data.course_id,
                        state: data
                    })}>Done</button>
                </div>
            </div>
        </>
    )
}

export default (withRouter(StepTwo))

