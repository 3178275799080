import React, {Component} from "react"
import ReactTable from 'react-table'
import API_URL from '../../ApiUrl'
import axios from 'axios'
import CKEditors from "react-ckeditor-component"
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

class DataTablesDonation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalDonation:false,
            id:'',
            btnAction:'',
            title: '',
            description:'',
            image:'',
            nominal:'',
            status:'',
            btnAddDisabled:false,
            btnTextSave:'Save',
            dataDonation: []
        }
    }

    componentDidMount(){
        this.loadDataDonate()
    }

    loadDataDonate = () => {
        axios.get(API_URL.superStudent+'/donate',{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({ dataDonation: response.data.data }))
        .catch(error => console.log(error))
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            description: newContent
        })
    }

    handleImages = (e) => {
        this.setState({
            image:e.target.files[0]
        })
    }

    formatRupiah = (nominal) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        }).format(nominal);
    }

    addDonation = () => {
        this.setState( {
            modalDonation:true,
            title:'',
            id:'',
            btnAction:'add',
            description:'',
            image:'',
            nominal:'',
            status:1
        })
    }

    editDonation = (row) => {

        this.setState({
            modalDonation:true,
            title:row.title,
            id:row.id,
            description:row.description,
            nominal: row.nominal,
            status: row.status,
            btnAction:'update'
        })
    }

    saveData = () => {
        let formData = new FormData()
        formData.append('title', this.state.title)
        formData.append('description', this.state.description)
        formData.append('image', this.state.image)
        formData.append('nominal', this.state.nominal)
        formData.append('status', this.state.status)
        this.setState({
            btnAddDisabled:true,
            btnTextSave:'Saving...'
        })
        let url = 'donate'

        if (this.state.btnAction === 'update'){
            formData.append('_method','PATCH');
            url = 'donate/'+this.state.id
        }

        axios.post(API_URL.superStudent+'/'+url, formData, {
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => {
            this.setState({
                btnAddDisabled:false,
                btnTextSave:'Save',
                modalDonation:false
            })
            this.loadDataDonate()
            toast.success('Data added successfully')
        })
        .catch(error => {
            console.log(error)
            this.setState({
                btnAddDisabled:false,
                btnTextSave:'Save',
                modalDonation:false
            })
            toast.error('Something wrong')
        })
    }

    renderModalDonate(){
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={this.state.modalDonation} toggle={() => this.setState({ modalDonation: false })} >
                    <ModalHeader toggle={() => this.setState({ modalDonation: false })}>Form Donation</ModalHeader>
                    <ModalBody>
                        <div className='row'>
                            <div className='col-md-12'>
                                <form>
                                    <div className='form-group'>
                                        <label className='control-label'>Title</label>
                                        <input className='form-control' type='text' name='title' value={this.state.title} onChange={this.handleInput} />
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Nominal</label>
                                        <input className='form-control' type='text' name='nominal' value={this.state.nominal} onChange={this.handleInput} />
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Status</label>
                                        <select name="status" className="form-control" value={this.state.status} onChange={this.handleInput}    >
                                            <option value=''>--Atur Status--</option>
                                            <option value={1}>Active</option>
                                            <option value={0}> Inactive</option>
                                        </select>
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Description</label>
                                        <CKEditors
                                            activeclassName="p10"
                                            content={this.state.description}
                                            events={{
                                                    "change": this.onChange.bind(this),
                                            }}
                                            config={{
                                                toolbar:[
                                                    ["Bold","Italic",'Strike','RemoveFormat'],
                                                    ['Indent','Outdent'],
                                                    ['Link','Unlink'],
                                                    ['Styles','Format','Table','Blockquote','Maximize'],
                                                    ["Cut","Copy","Paste","Pasteasplaintext","Undo","Redo"]
                                                ]
                                            }}     
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Image</label>
                                        <input className='form-control' type='file' onChange={this.handleImages}/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                    <Button disabled={this.state.btnAddDisabled} color="primary" onClick={(e) => this.saveData()}>{this.state.btnTextSave}</Button>
                    <Button  color="secondary" onClick={() => this.setState({ modalDonation: false })}>Cancel</Button>
                    </ModalFooter>
              </Modal>
            </div>
        )
    }

    render(){

        const columns = [
            {
                Header:'#',
                accessor:'image',
                style: {textAlign: 'center'},
                filterable: false,
                Cell:(row) => (
                    <div className="image-sm-size align-middle">
                        <img className="img-radius img-50 align-top" src={row.original.image !== null ? row.original.image : ''} alt="" />
                    </div>
                ),
            },
            {
                Header: 'Title',
                accessor: 'title',
                style: {textAlign: 'center'},
                filterable: false,
            },
            {
                Header: 'Nominal',
                accessor: 'nominal',
                style: {textAlign: 'center'},
                filterable: false,
                Cell: (row) => (
                    this.formatRupiah(row.original.nominal)
                )
            },
            {
                Header: 'Status',
                accessor: 'status',
                style: {textAlign: 'center'},
                filterable: false,
                Cell: (row) => (
                    row.original.status == 1 ? <span className='badge bg-success'>Active</span> : <span className='badge bg-danger'>Inactive</span>
                )
            },
            {
                Header: <b>Action</b>,
                id: 'action',
                filterable: false,
                style: { textAlign: 'center' },
                width:100,
                accessor: str => "action",
                Cell: (row) => (
                    <>
                        <button onClick={() => this.editDonation(row.original)} className='btn btn-info btn-xs'><i className='fa fa-pencil'></i></button> &nbsp;
                    </>
                )
            }
        ]
        return (
            <>
                <div className="card">
                    <div className="card-header">
                        <button className='btn btn-success' onClick={this.addDonation}> Add Data</button>
                    </div>
                    <div className="card-body">
                        <ReactTable
                            data={this.state.dataDonation}
                            filterable
                            columns={columns}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            showPaginationBottom={true}
                        /> 
                    </div>
                </div>
                {this.renderModalDonate()}
            </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
})


export default connect(reduxState, null)(DataTablesDonation)