import React, {Component} from 'react'
import { connect } from 'react-redux';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import Moment from 'moment';
import Podium from './podium'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import setRangeDate from '../../helper/general-function';

class HomeKPI extends Component{

    constructor(props){
        super(props)
        this.state = {
            timeType:2,
            targetYear:false,
            podiumData:[],
            filterStartDate:Moment().startOf('month').format('YYYY-MM-DD'),
            filterEndDate:Moment().endOf('month').format('YYYY-MM-DD'),
            this_year:{ start:'', end:''},
            last_year:{ start:'', end:''}
        }
    }

    componentDidMount(){
        this.loadGraphicKPI()
        this.setDateYear()
    }

    setDateYear(){
        let this_year = setRangeDate('this_year')
        let last_year = setRangeDate('last_year')
        this.setState({
            this_year: {start: this_year.start, end:this_year.end},
            last_year: {start: last_year.start, end:last_year.end}
        })
    }

    loadGraphicKPI = (e) => {
        let formData = new FormData()
        formData.append('start_date', this.state.filterStartDate)
        formData.append('end_date', this.state.filterEndDate)
        formData.append('time_type',this.state.timeType)
        formData.append('target_year',this.state.targetYear)
        formData.append('reference_type','1')
        axios.post(API_URL.superStudent+'/kpi/home-kpi', formData, {
            headers:{  Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => {
            let result = response.data.data.sort((a, b) => {
                return a.achivement - b.achivement;
            }).reverse().map((winner, position) => ({ ...winner, position }));
            this.setState({podiumData:result})
        })
        .catch(error => console.log(error))
    } 

    handleType = (e) => {
        let startDate = e.target.value == 2 ? Moment().startOf('month').format('YYYY-MM-DD') : e.target.value == 4 ? this.state.this_year.start : this.state.last_year.start
        let endDate =  e.target.value == 2 ? Moment().endOf('month').format('YYYY-MM-DD') : e.target.value == 4 ? this.state.this_year.end : this.state.last_year.end
        this.setState({
            targetYear: e.target.value == 4 ? true : false,
            timeType:e.target.value,
            filterStartDate: startDate,
            filterEndDate: endDate,
        },() => {
            this.loadGraphicKPI()
        })
    }

    handlePickerMonth = (e) => {
        this.setState({
            filterStartDate: Moment(e).startOf('month').format('YYYY-MM-DD'),
            filterEndDate: Moment(e).endOf('month').format('YYYY-MM-DD'),
            timeType:2
        },() => {
            this.loadGraphicKPI()
        })
    }

    handlePickerWeek = (e) => {
        this.setState({
            filterStartDate: Moment(e).startOf('isoWeek').format('YYYY-MM-DD'),
            filterEndDate: Moment(e).endOf('isoWeek').format('YYYY-MM-DD'),
            timeType:1,
            valueWeek:e
        },() => {
            this.loadGraphicKPI()
        })
    }
    render(){
        return(
            <>
                <div className="tab-content active default" id="tab-1">
                    <div className='row justify-content-center'>
                        <div className='col-sm-3 mb-2'>
                            <select value={this.state.timeType} onChange={this.handleType} className='form-control'>
                                <option value={2}>Monthly</option>
                                <option value={4}>This Year</option>
                                <option value={5}>Last Year</option>
                            </select>
                        </div>
                            {
                                this.state.timeType == 2 ?
                                <DatePicker dateFormat='MMMM yyyy' selected={Moment(this.state.filterStartDate)._d} showMonthYearPicker showFullMonthYearPicker className='form-control' onChange={this.handlePickerMonth} />
                                :
                                ''
                            }
                        <div className='col-sm-12'>
                            <Podium winners={this.state.podiumData} />
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

const reduxState = (state) => ({
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(HomeKPI)