import React, { Component, Fragment } from 'react'
import ReactTable from 'react-table';
import {matchSorter} from 'match-sorter';
import Moment from 'moment';
import { toast } from 'react-toastify';
import API_URL from '../../ApiUrl';
import axios from 'axios';

export class DataBooking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // operation: "",
            // modal: false,
            // modalDetail: false,
            // errors: {},
            row: [],
            filtered:[],
            booking_plan: [],
            booking:[],
            status_pembayaran: [
                {id: 1, status:'unpaid'}, {id: 2, status:'Menunggu Pembayaran'}, {id: 3, status:'Pembayaran Kadaluwarsa'}, {id: 4, status:'Sudah Bayar'}],
            filterBookingPlan: '',
            filterStatusPembayaran: '',
            approval_pembayaran: ''
        }
    }

    componentDidMount(){
        this.getDataBooking();
        this.getDataBookingPlan();
    }

    formatRupiah = (nominal) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currencyDisplay:'narrowSymbol',
            currency: 'IDR',
        }).format(nominal);
    }

    getDataBooking(){
        const url = API_URL.coworkingSpace + 'booking';
        axios.get(url,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${API_URL.coworkingToken}`
            }
        })
        .then(res => this.setState({
            booking: res.data
        }))
        .catch(err => console.log(err))
    }

    getDataBookingPlan(){
        const url = API_URL.coworkingSpace + 'booking-plan';
        axios.get(url,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${API_URL.coworkingToken}`
            }
        })  
        .then(res => this.setState({
            booking_plan: res.data
        }))
        .catch(err => console.log(err))
    }

    approveStatusPembayaran(e, row){
       e.preventDefault();
       const url = API_URL.coworkingSpace + 'status-payment';
       const data = { id_booking: row.id_booking, status_pembayaran: 'Sudah Bayar' }
       axios({
        method: 'POST',
        url: url,
        data: data,
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${API_URL.coworkingToken}`
        }
    }).then(res => {
        // console.log(res.data)
        this.getDataBooking();
        toast.success("Pembayaran Di Approve.....")
    })
    .catch(err => console.log(err))

    }

    SetFilterBookingPlan = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
        let filterBookingPlan = newData; 
        return { filterBookingPlan };                   
        }, () => {
            this.onFilteredChangeCustom(newData,"booking_plan.jenis_ruangan");
        })
    }

    SetFilterStatusPembayaran = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
        let filterStatusPembayaran = newData; 
        return { filterStatusPembayaran };                   
        }, () => {
            this.onFilteredChangeCustom(newData,"status_pembayaran");
        })
    }

  onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
    
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }
    
        this.setState({ filtered: filtered });
  }

  render() {
    const data_booking = [
        {
            Header: 'Code Booking',
            accessor: 'code_booking',
            filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["code_booking"] }),
            filterAll: true,
            style: {textAlign: 'center'}
        },
        {
            Header: 'Booking Plan',
            accessor: 'booking_plan.jenis_ruangan',
            Filter: () => 
                <select value={this.state.filterBookingPlan || ''} onChange={(e)=>this.SetFilterBookingPlan(e)}>
                    <option value=''>Show All</option>
                   {
                    this.state.booking_plan?.map( item => {
                        return <option key={item.id} value={item.jenis_ruangan}>{item.jenis_ruangan}</option>
                    })
                } 
                </select>,
            style: {textAlign: 'center'}
        },
        {
            Header: 'Nama Penyewa',
            accessor: 'nama_penyewa',
            style: {textAlign: 'center'},
            filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["nama_penyewa"] }),
            filterAll: true,
        },
        {
            Header: 'Email',
            accessor: 'email',
            style: {textAlign: 'center'},
        },
        {
            Header: 'Telepon',
            accessor: 'telepon',
            style: {textAlign: 'center'},
        },
        {
            Header: 'Durasi',
            accessor: 'durasi',
            style: {textAlign: 'center'},
            filterable:false,
            Cell: (row) => 
            (
                <>
                <span>{Moment(row.original.start_date).format('DD MMM YYYY')}</span><br />
                <span>{Moment(row.original.end_date).format('DD MMM YYYY')}</span>
                </>
                
                        
            )
        },
        {
            Header: 'Total Pembayaran',
            accessor: 'total_bayar',
            style: {textAlign: 'center'},
            filterable:false,
            Cell: (row) => (
                this.formatRupiah(row.original.total_bayar)
            )
        },
        {
            Header: 'Status Pembayaran',
            accessor: 'status_pembayaran',
            style: {textAlign: 'center'},
            width: 190,
            Filter: () => 
                <select value={this.state.filterStatusPembayaran || ''} onChange={(e) => this.SetFilterStatusPembayaran(e)}>
                    <option value='' >Show All</option>
                    {
                        this.state.status_pembayaran?.map( item => {
                            return <option key={item.id} value={item.status}>{item.status}</option>
                        })
                    } 
                </select>,
            Cell: (row) => (
                <span class={`badge badge-${
                    row.original.status_pembayaran === 'unpaid' ? 'danger' :
                    row.original.status_pembayaran === 'Menunggu Pembayaran'? 'secondary' :
                    row.original.status_pembayaran === 'Pembayaran Kadaluwarsa'? 'warning' :
                    row.original.status_pembayaran === 'Sudah Bayar'? 'success' : ''
                }`}>
                    {row.original.status_pembayaran}</span>
            )
        },
        {
            Header: <b>#</b>,
            id: 'action',
            accessor: str => "action",
            width:90,
            filterable:false,
            Cell: (row) => (
                <>
                    {
                        row.original.status_pembayaran === 'Sudah Bayar' ? '' :
                        <button style={{border:'none', fontSize: 18 }} onClick={(e)=>this.approveStatusPembayaran(e, row.original)} className="btn btn-outline-primary btn-xs" id="TooltipTop" data-placement="top"><i className="fa fa-check success-icon"></i></button> 
                    }
                </>
            ),
            style: { textAlign: 'center' }
        }

    ]
    return (
        <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='card'>
                    <ReactTable
                        data={this.state.booking}
                        columns={data_booking}
                        filtered={this.state.filtered}
                        defaultPageSize={15}
                        filterable  
                        className="-striped -highlight"
                        showPaginationBottom={true}
                        onFilteredChange={(filtered, column, value) => {
                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                        }}
                    /> 
                    </div>
                </div>
            </div>
        </div>
        </>
    )
  }
}


export default DataBooking;