import React from "react"
import { ArrowUp, ArrowDown } from 'react-feather';
import CountUp from 'react-countup';
import { color3 } from "../../../../constant/variable";

const DataKPI = ({kpi}) => {
    return(
        <>
        <div className="row justify-content-between">
            <div className="col-6 align-self-center  text-left">
                <div className="font-weight-bold">KPI</div>
                <h3 style={{color:color3}} className="total-num counter"><CountUp className="counter font-weight-bold" end={kpi.result} />%</h3>
            </div>
            <div className="col-6 media-body text-right">
                <h5 className="mb-0"> 
                    {
                    kpi.last_result > 0 ?
                    <>
                    <ArrowUp className="font-success font-weight-bold" />
                    <span className="counter font-success f-14">
                        <CountUp className="counter font-weight-bold font-success" end={Math.abs(kpi.last_result)} />%
                    </span>
                    </>
                    :
                    <>
                    <ArrowDown className="font-danger font-weight-bold" />
                    <span className="counter font-danger f-14">
                        <CountUp className="counter font-weight-bold font-danger" end={Math.abs(kpi.last_result)} />%
                    </span>
                    </>
                    }
                    </h5>
                <div style={{fontSize:'10px',color:'grey'}}>from last month</div>
            </div>
        </div>
        </>
    )
}

export default DataKPI;