import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Home, Settings, User, UserPlus, List } from 'react-feather';
import { connect } from 'react-redux';
import { withRouter } from "react-router";

import { Switch, Route, Link, Redirect} from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';

const Membership = (dataUser) => {
    const roles = dataUser.dataUser.code_roles
    return (
        <Fragment>
            <Breadcrumb title="Membership" parent="Dashboard" />
            <div className="container-fluid">
                <div className="row theme-tab">
                    <div className="col-sm-12">
                        <RoutedTabs>
                            <div className="tabs tab-title">
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/membership/home`}>
                                            {/* <ReactTab> */}
                                                <Home /> Home
                                            {/* </ReactTab> */}
                                        </NavTab>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/membership/data`}>
                                            {/* <ReactTab> */}
                                                <UserPlus /> Membership Data
                                            {/* </ReactTab> */}
                                        </NavTab>

                                         {roles === 'RL001' || 'RL005' || 'RL003' || 'RL006' || 'RL013' ?
                                            <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/membership/approval`}>
                                                <List />Payment Approval
                                            </NavTab>
                                        :
                                            <NavTab activeClassName="active" style={{display:"none"}} />
                                        }
                                </div>
                        </RoutedTabs>

                        <Switch>
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/pages/membership`}
                                render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/membership/home`} />}
                            />
                            <Route path={`${process.env.PUBLIC_URL}/pages/membership/home`}/>
                            {/* <Route path={`${process.env.PUBLIC_URL}/pages/membership/consultant`}/> */}
                            <Route path={`${process.env.PUBLIC_URL}/pages/membership/data`} />
                            {roles === 'RL001' || 'RL005' || 'RL003' || 'RL006' || 'RL013' ?
                                <Route path={`${process.env.PUBLIC_URL}/pages/membership/approval`} />
                            :
                                <Route style={{display:"none"}} />
                            }
                        </Switch>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

// export default Membership;
const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
  })
  
  export default connect(reduxState, null)(withRouter(Membership));