import React from "react"
import CountUp from 'react-countup';

const CardCount = ({backgroundColor, color='white' ,icon, label, amount, handleRedirect}) => {

    return (
        <div style={{cursor:'pointer'}} onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/${handleRedirect}`)}
          className="item">
            <div className="card">
                <div className="card-body ecommerce-icons text-center">
                    <i style={{color:'#4466f2'}} className={`fa fa-2x ${icon}`} ></i>
                    <div><span>{label}</span></div>
                    <h4 className="font-primary mb-0">
                        <CountUp className="counter" end={amount} />
                    </h4>
                </div>
            </div>
        </div>
    )
}

export default CardCount;

