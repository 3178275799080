import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import API_URL from '../../../ApiUrl';
import ReactTable from 'react-table';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from "react-router";
import { toast } from 'react-toastify'


class DataVoucher extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataVoucher: [],
            modalForm: false,
            btnDisabled: false,
            textBtn: 'Save',
            id_voucher: '',
            code_voucher: '',
            kuota: 0,
            status: '',
            operation: ''
        }
    }

    componentDidMount() {
        this.getAllData()
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    getAllData = () => {
        axios.get(API_URL.superStudent + '/simulasi-ielts/get-voucher', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(response => {
                this.setState({ dataVoucher: response.data.data })
            })
    }

    sendData = () => {
        this.setState({
            btnDisabled: true
        })
        var formData = new FormData();
        formData.append('code_voucher', this.state.code_voucher);
        formData.append('kuota', this.state.kuota);
        formData.append('status', this.state.status)
        formData.append('id', this.state.id_voucher)

        if (this.state.operation === 'edit') 
            formData.append('_method', 'PATCH');

        axios({
            method: 'POST',
            url: API_URL.superStudent+'/simulasi-ielts/update-voucher',
            data: formData,
            headers: { Authorization: `Bearer ${this.props.dataToken}` }
        })
        .then(() => {
            toast.success('Data Update Successfully')
            this.closeDialog()
            this.getAllData()
            this.setState({
                btnDisabled: false
            })
          })
          .catch(() => {
            toast.error('Something wrong')
            this.closeDialog()
            this.setState({
                btnDisabled: false
            })
          })

    }

    addVoucher = (operation) => {
        this.setState({
            modalForm: true,
            operation
        })
    }

    editVoucher = (data) => {
        this.setState({
            modalForm:true,
            id_voucher:data.id,
            code_voucher: data.code_voucher,
            kuota: data.kuota,
            status:data.status,
            operation:'edit'
        })

    }

    closeDialog() {
        this.setState({
            modalForm: false
        })
    }

    showModalForm() {
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={this.state.modalForm} toggle={() => this.setState({ modalForm: false })}>
                    <ModalHeader toggle={() => this.closeDialog()}>Form Kode Voucher</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" >
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Kode Voucher</label>
                                    <input className="form-control" required name="code_voucher" type="text" value={this.state.code_voucher} onChange={this.handleInput} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Kuota</label>
                                    <input className="form-control" name="kuota" required type="number" value={this.state.kuota} onChange={this.handleInput} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Status</label>
                                    <select className="form-control" required name="status" type="text" value={this.state.status}
                                        onChange={this.handleInput}>
                                        <option value="">--Pilih Status--</option>
                                        <option value="1">Active</option>
                                        <option value="0">Non Active</option>
                                    </select>
                                </div>
                            </div>

                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} onClick={(e) => this.sendData()} color="primary">{this.state.textBtn}</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }


    render() {
        const columns = [
            {
                Header: 'Code Voucher',
                accessor: 'code_voucher',
                style: { textAlign: 'center' },
            },
            {
                Header: 'Kuota',
                accessor: 'kuota',
                style: { textAlign: 'center' },
            },
            {
                Header: 'Status',
                accessor: 'status',
                style: { textAlign: 'center' },
                Cell: (row) => (
                    <div>
                        {
                            row.original.status == '1' ?
                                <span className={'badge bg-success'}>Active</span>
                                :
                                <span className={'badge bg-dark'}>Inactive</span>
                        }
                    </div>
                ),
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <button
                        onClick={() => this.editVoucher(row.original)}
                        className="btn btn-outline-secondary btn-xs" type="button" style={{ border: 'none' }} ><i className="fa fa-pencil fa-2x"></i>
                    </button>
                ),
                style: { textAlign: 'center' },
            },
        ]

        return (
            <>
                <button
                    onClick={() => this.addVoucher('add')}
                    className="btn btn-primary btn-xs  mb-2" type="button" style={{ border: 'none' }} ><i className="fa fa-plus fa-2x"></i>
                </button>
                <ReactTable
                    data={this.state.dataVoucher}
                    columns={columns}
                    className="-striped -highlight"
                    showPaginationBottom={false}
                    showPageSizeOptions={true}
                />
                {this.showModalForm()}
            </>
        );
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
})

export default connect(reduxState, null)(withRouter(DataVoucher))