import React, { Component, Fragment } from "react";
import Countup from "react-countup";
import ReactTable from "react-table";
import API_URL from "../../../ApiUrl";
import axios from "axios";
import { connect } from "react-redux";
import { ExportCSV } from "../../../services/ExportCSV";
import "react-datepicker/dist/react-datepicker.css";
import { matchSorter } from "match-sorter";
import Swal from "sweetalert2";
import Moment from "moment";

class RegisterEventPartner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registrasi_data: [],
      event_id: 0,
      event_data: [],
      selectedCategory: "",
      keterangan: "",
      attendance: "",
    };
  }

  componentDidMount() {
    this.getDataEvent();
  }

  getDataRegisterEvent = () => {
    axios
      .get(API_URL.eventUrl + "load-data/" + this.state.event_id + "/4", {
        headers: {
          Authorization: `Bearer ${API_URL.eventToken}`,
        },
      })
      .then((response) => response.data.data)
      .then((result) => {
        const formattedData = result.map((data) => {
          let keterangan = {};
          try {
            keterangan = JSON.parse(data.pivot.keterangan);
          } catch (e) {
            console.error("Invalid JSON in keterangan", e);
            keterangan = data.pivot.keterangan;
          }
          return {
            ...data,
            keterangan: keterangan,
          };
        });
        this.setState({ registrasi_data: formattedData });
      })
      .catch((error) => console.log(error));
  };

  getDataEvent = () => {
    const url = API_URL.superStudent + "/event/jenis-event/4";
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${this.props.dataToken}` },
      })
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          event_data: data.data,
        });
      });
  };

  handleChange = (e) => {
    const newEventId = e.target.value;
    if (newEventId !== this.state.event_id) {
      this.setState(
        {
          event_id: newEventId,
        },
        () => {
          this.getDataRegisterEvent();
        }
      );
    }
  };

  render() {
    const columns = [
      {
        Header: "Nama Lengkap",
        accessor: "name",
        style: { textAlign: "center" },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["name"] }),
        filterAll: true,
      },
      {
        Header: "Email",
        accessor: "email",
        style: { textAlign: "center" },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["email"] }),
        filterAll: true,
      },
      {
        Header: "Telepon/WA",
        accessor: "phone",
        style: { textAlign: "center" },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["phone"] }),
        filterAll: true,
      },
      {
        Header: "Sekolah",
        accessor: "sekolah.nama_sekolah",
        style: { textAlign: "center" },
        filterable: false,
      },
      {
        Header: "Keterangan",
        accessor: "keterangan",
        style: { textAlign: "center" },
        Cell: ({ value }) => {
          if (!value || typeof value !== "object") {
            return <div>{value}</div>; // Tampilkan langsung jika value adalah string

          }
          return (
            <div>
              <div>Kategori : {value.category || "N/A"}</div>
              <div>Deskripsi: {value.description || "N/A"}</div>
              <div>Kehadiran: {value.attendance || "N/A"}</div>
            </div>
          );
        },
        filterable: false,
      },
      {
        Header: "Attendance",
        accessor: "attendance",
        style: { textAlign: "center" },
        filterable: false,
      },
    ];

    return (
      <Fragment>
        <div className="page-wrapper">
          <div className="container-fluid p-0">
            <div className="row m-1">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="text-left">
                          <h5 className="mb-1">
                            Total Registration :{" "}
                            <span className="badge badge-primary">
                              <Countup
                                end={this.state.registrasi_data.length}
                              />
                            </span>{" "}
                          </h5>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="text-right">
                          <select
                            className="form-control"
                            onChange={this.handleChange}
                            name="event_id"
                            value={this.state.event_id}
                          >
                            <option value="0"> --Filter Peserta-- </option>
                            {this.state.event_data.map((event) => {
                              return (
                                <option value={event.id} key={event.id}>
                                  {event.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-1">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <h5 className="mb-3">Data Register</h5>
                    <div className="row mb-3">
                      <div className="col-sm-6">
                        <ExportCSV
                          csvData={this.state.registrasi_data.map((item) => ({
                            ...item,
                            Kategori:
                              item.keterangan && item.keterangan.category
                                ? item.keterangan.category
                                : "",
                            Deskripsi:
                              item.keterangan && item.keterangan.description
                                ? item.keterangan.description
                                : "",
                            Kehadiran:
                              item.keterangan && item.keterangan.attendance
                                ? item.keterangan.attendance
                                : "",
                          }))}


                          // csvData={this.state.registrasi_data}
                          fileName={"data-register-event-session"}
                        />
                      </div>
                    </div>
                    <ReactTable
                      data={this.state.registrasi_data}
                      columns={columns}
                      defaultPageSize={10}
                      filterable
                      defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value
                      }
                      className="-striped -highlight"
                      showPaginationBottom={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const reduxState = (state) => ({
  dataToken: state.Login.lgnInitialize,
  dataUser: state.Login.detailUser,
});

export default connect(reduxState, null)(RegisterEventPartner);
