import React, { Fragment, Component } from "react";
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo/Logo_NIEC_large.png';
import API_URL from "../ApiUrl";
import axios from "axios";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { updateCurrentLogin } from '../actions/login.action';

class otpPwd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textSave: 'Send',
            btnDisabled: false,
            otp: '',
            message: '',
            classMessage: '',
        }
    }

    sendOtp = (e) => {
        e.preventDefault()
        this.setState({
            textSave:'Sending...',
            btnDisabled:true
        }, () => {
            var formData = new FormData();
            formData.append('email', this.props.dataUser.email)
            formData.append('otp', this.state.otp)

            const url = API_URL.superStudentAuth+'/verified-otp';
            axios.post(url, formData, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${this.props.dataToken}`
                }
            })
            .then(response => response.data.data)
            .then((data) => {
                if (data != null) {
                    this.setState({
                        textSave:'Send',
                        btnDisabled:false
                    }, () => {
                        if(data.last_day == null || data.last_day >= 90) {
                            window.location.href = `${process.env.PUBLIC_URL}/pages/resetPwdOut`;
                        } else {
                            if(this.props.dataUser.code_roles ==='RL008') {
                                this.props.updateStatusLogin(true)
                                window.location.href = `${process.env.PUBLIC_URL}/pages/students/consultant`;
                            } else {
                                this.props.updateStatusLogin(true)
                                window.location.href = `${process.env.PUBLIC_URL}/`;
                            }
                        }
                    })
                } else {
                    this.setState({
                        textSave:'Send',
                        btnDisabled:false
                    }, () => {
                        this.props.updateStatusLogin(false)
                        Swal.fire({
                            title: 'Your OTP incorrect, please try again',
                            showConfirmButton: true,
                        });
                    })
                }
            })
            .catch(function(error){
                setTimeout(() => {
                  toast.error("Oppss.. OTP tidak di ketahui");
              }, 200);
            })
        })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    render() {
        return(
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid">
                        <div className="authentication-main">
                            <div className="row">
                                <div className="col-md-12 p-0">
                                    <div className="auth-innerright">
                                        <div className="reset-password-box">
                                            <div className="text-center"><img width='100' src={logo} alt="NIEC Logo" /></div>
                                            <div className="text-center card mt-4 mb-0">
                                                <h4>OTP</h4>
                                                <form onSubmit={this.sendOtp} className="theme-form">
                                                    <h6 className="f-14 mt-4 mb-3">ENTER YOUR OTP</h6>
                                                    <div className="form-group">
                                                        <input name='otp' value={this.state.otp} onChange={this.handleInput} className="form-control" placeholder="OTP" type="text"/>
                                                        <span className={this.state.classMessage}>{this.state.message}</span>
                                                    </div>
                                                    <div className="form-group form-row mb-2">
                                                        <div className="col-md-3">
                                                           <button disabled={this.state.btnDisabled} className='btn btn-primary' type='submit'>{this.state.textSave}</button>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <button className="btn btn-default">
                                                                <Link className="btn-link text-capitalize" to={`${process.env.PUBLIC_URL}/login`}>
                                                                    Back
                                                                </Link>    
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
};

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    lastDayPass: state.Login.totalLast,
})

const reduxDispatch = (dispatch) => ({
    updateStatusLogin: (status) => dispatch(updateCurrentLogin(status))
})
  
export default connect(reduxState, reduxDispatch)(withRouter(otpPwd));