import React, { Component} from 'react';
import ReactTable from 'react-table';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import Moment from 'moment';
import { connect } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import setRangeDate from '../../helper/general-function';

class DataTaskList extends Component {

    constructor(props){
        super(props)
        this.state = {
            taskList:[],
            dateType: 'this_week',
            startDate: Moment().startOf('isoWeek').format('YYYY-MM-DD'),
            endDate: Moment().endOf('isoWeek').format('YYYY-MM-DD'),
        }
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    handleDate = (e) => {
        this.setState({
            dateType: e.target.value
        }, () => this.setDate())
    }

    setDate(){     
        let data = setRangeDate(this.state.dateType);     
        this.setState({ 
            startDate: data.start,
            endDate: data.end
        },() => this.loadTodoList())
    }

    componentDidMount(){
        this.loadTodoList()
    }

    loadTodoList = () =>{
        let formData = new FormData ()
        formData.append('code_jobdesk','JD006')
        formData.append('start_date', this.state.startDate)
        formData.append('end_date', this.state.endDate)
        axios.post(API_URL.superStudent+'/todo/list-todo', formData, {
            headers:{
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({taskList: result}))
        .catch(error => console.log(error))
    }

    render(){
        const columns_todo = [
            {
                Header: '',
                accessor: 'created_at',
                style:{textAlign:'center'},
                width:80,
                Cell:(row) => (
                    <span>{Moment(row.original.created_at).format('DD MMM YY')}</span>
                )
            },
            {
                Header: 'Students',
                accessor: 'code_students',
                width:150,
                style: {textAlign: 'center'},
                Cell:(row) => (
                    row.original.status_approval === 0 ? <span>{row.original.student !== null ? row.original.student.nama : ''}<sup> <i style={{color:'red'}} className='fa fa-exclamation'></i></sup></span> : <span>{row.original.student !== null ? row.original.student.nama : ''}</span>
                )
            },
            {
                Header: 'Code Students',
                accessor: 'student.code_students',
                width:150,
                style: {textAlign: 'center'},
                Cell: (row) => (
                    <div className="row">
                        <div className="col-md-3">
                            <button 
                                onClick={() => 
                                    window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/`+row.original.student.token_students)
                                }
                                className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-list" style={{ fontSize: 16}}></i>
                            </button>
                        </div>
                    </div>
                ),
            },
            {
                Header: 'Description',
                accessor: 'description',
                Cell:(row) => (
                    <>
                        <span id={'code'+row.original.id} data-placement="top">{row.original.description}</span>
                        <UncontrolledTooltip placement="left" target={'code'+row.original.id}>{row.original.description}</UncontrolledTooltip>
                    </>
                ),
                style: {textAlign: 'center'},
            },
            {
                Header: 'Type',
                accessor: 'category.name',
                width:160,
                style: {textAlign: 'center'},
            },
            {
                Header: 'User',
                width:90,
                accessor: 'user.username',
                style: {textAlign: 'center'},
            },
            {
                Header: 'Status',
                accessor: 'status',
                width:70,
                style: {textAlign: 'center'},
                Cell: (row) => (
                    <> <span id={`code_status`+row.original.id} className={'badge bg-'+row.original.status.label}>{row.original.status.name}</span> <UncontrolledTooltip placement="top" target={`code_status`+row.original.id}>
                        {row.original.noted}
                  </UncontrolledTooltip> </>
                ),
            },
            {
                Header: 'Finish',
                accessor: 'date_finish',
                style:{textAlign:'center'},
                width:80,
                Cell:(row) => (
                    row.original.date_finish !== null ? 
                    <span>{Moment(row.original.date_finish).format('DD MMM YY')}</span> : ''
                )
            },
        ] 
        return (
            <>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div style={{marginBottom:'10px'}} className="row justify-content-md-center justify-content-xs-center">
                            <div className='col-md-3 col-sm-4 mb-2'>
                                <select name='dateType' value={this.state.dateType} onChange={this.handleDate} className='form-control'>
                                    <option value='this_week'>This Week</option>
                                    <option value='last_week'>Last Week</option>
                                    <option value='this_month'>This Month</option>
                                    <option value='last_month'>Last Month</option>
                                    <option value='this_year'>This Year</option>
                                    <option value='last_year'>Last Year</option>
                                </select>
                            </div>
                            <div className='col-md-12'>
                                <ReactTable
                                    data={this.state.taskList}
                                    columns={columns_todo}   
                                    defaultPageSize={15}
                                    className="-striped -highlight"
                                    showPaginationBottom={true}
                                />
                            </div>           
                        </div>
                    </div>
                </div>
            </>
    
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize
  })

export default connect(reduxState,null)(DataTaskList);