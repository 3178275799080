import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Home, List, FileText } from 'react-feather';
import { connect } from 'react-redux';
import { withRouter } from "react-router";

import { Switch, Route, Redirect} from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';

const KPI = () => {
    return (
        <Fragment>
            <Breadcrumb title="KPI" parent="" />
            <div className="container-fluid">
                <div className="row theme-tab">
                    <div className="col-sm-12">
                        <RoutedTabs
                            tabClassName="tab-link"
                            activeTabClassName="active"
                        >
                            <div className="tabs tab-title">
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/indikator-kpi/home`}><Home /> Home</NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/indikator-kpi/report-kpi`}><FileText /> Report KPI</NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/indikator-kpi/data-indikator-kpi`}>
                                <List /> List Indikator
                                </NavTab>
                            </div>
                        </RoutedTabs>
                        <Switch>
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/pages/indikator-kpi`}
                                render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/indikator-kpi/home`} />}
                            />
                            <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/data-report-kpi`}/>
                            <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/data-indikator-kpi`}/>
                        </Switch>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

// export default Students;
const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
  })
  
  export default connect(reduxState, null)(withRouter(KPI));