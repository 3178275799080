import React, { Component } from 'react'
import axios from 'axios'
import API_URL from '../../../../ApiUrl'
import ListeningTable from '../tables/listening-table'

export class ListeningTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listening: [],
    }
}

  componentDidMount(){
    this.getListeningQuestion();
  }
  
  loadListening = () => {
    this.getListeningQuestion();
  }

  getListeningQuestion(){
    axios.get(API_URL.placementTest + 'listeningQuestion', { headers: { Authorization: `Bearer ${API_URL.placementTestToken}` }})
    // .then(res => console.log(res.data))
    .then(res => {
        this.setState({listening: res.data})
    })
      .catch(err => console.log(err))
    }

  render() {
    return (
        <>
        <ListeningTable data={this.state.listening} loadListening={this.loadListening}/>
        </>
    )
  }
}

export default ListeningTest