import React,{ Fragment, useRef, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import API_URL from '../../../ApiUrl';
import axios from 'axios'

const Notification = ({ dataUser, dataToken }) => {
    const[dataNotifikasi, setDataNotifikasi] = useState([])
    const[dataNotifikasiNew, setDataNotifikasiNew] = useState('')

    const loadNotifikasi = () => {
        axios.get(API_URL.superStudent+'/master/notifikasi/'+dataUser.code_konsultan, {
            headers: { 'Authorization': `Bearer ${dataToken}` }
        })
        .then(response => {
            setDataNotifikasi(response.data.data[0].notifikasi_all);
            setDataNotifikasiNew(response.data.data[0].notifikasi_new)
        })
        .catch(error => console.log)
    }

    const updateStatusNotifikasi = (detail) => {
        let formData  = new FormData()
        formData.append('_method', 'PATCH');

        axios.post(API_URL.superStudent+'/master/notifikasi/'+detail.id_notifikasi, formData, {
            headers: { 'Authorization': `Bearer ${dataToken}` }
        })
        .then(response => {
            loadNotifikasi()
        })
        .catch(error => console.log)
    }
    
    useEffect(() => {
        loadNotifikasi()
    },[])

    return (
        <Fragment>
            <div>
                <ul className="notification-dropdown onhover-show-div p-0" style={{ overflow: 'scroll', height: '400px' }}>
                    <li>Notification <span className="badge badge-pill badge-primary pull-right">{dataNotifikasiNew}</span></li>
                    {
                        dataNotifikasi.map((notifikasi) =>(
                            <li onClick={() => updateStatusNotifikasi(notifikasi)} style={ notifikasi.status == 0 ? {backgroundColor: 'aqua'} : {backgroundColor: 'white'}}>
                                <div className="media">
                                    <div className="media-body">
                                        <h6 className="mt-0" style={{color: 'black'}}>{notifikasi.title}</h6>
                                        <div dangerouslySetInnerHTML={{ __html: notifikasi.body }}></div>
                                    </div>
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </Fragment>
    );
};

const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataToken: state.Login.lgnInitialize,
    dataId: state.Login.detailUser.id_users,
    dataUser: state.Login.detailUser,
})

export default connect(reduxState, null) (withRouter(Notification));