import React, { Fragment, Component } from 'react';
import DatatableOffice from '../common/datatable/datatableOffice';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { resettoken } from '../../actions/login.action';

class DataTablesOffice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listOffice: []
        }
    }

    componentDidMount() {
        this.getDataOffice();
    }

    getDataOffice(){
        axios.get(API_URL.superStudent+'/offices',{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
        .then(response => this.setState({ listOffice: response.data.data}))
        .catch(error => console.log(error))
    }

    render() {
        const data = this.state.listOffice;
        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body datatable-react">
                                    <DatatableOffice
                                        multiSelectOption={false}
                                        myData={data}
                                        title="Office Data"
                                        pageSize={10}
                                        pagination={true}
                                        class="-striped -highlight"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
    dataId: state.Login.detailUser.id_users,
  })

  
export default connect(reduxState, null)(withRouter(DataTablesOffice));