import axios from 'axios';
import React, { Component } from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import Moment from 'moment';
import { connect } from 'react-redux'
import API_URL from '../../ApiUrl';

export class TrainingProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list_training_master: [],
      list_employee_training: [],
      department_training: [],
      count_employee_training: '',
      persentase: '',
      month: Moment().format('MM'),
      year: Moment().format('YYYY')
      
    }
}

  handleFilterMonth(e){
    const {name, value} = e.target;
    this.setState({[name]: value})
    const monthYear = {month: value, year: this.state.year}
    this.filterDeptTraining(monthYear)
  }

  handleFilterYear(e){
    const {name, value} = e.target;
    this.setState({[name]: value})
    const monthYear = {month: this.state.month, year: value}
    this.filterDeptTraining(monthYear)
  }

  componentDidMount(){
    this.getDataTrainingMasterByRole();
    // this.getDepartmentTraining();
  }

  // filterDeptTraining(monthYear){
  //   monthYear = {...monthYear, code_jobdesk: this.props.data.code_jobdesk, code_konsultan: this.props.data.code_konsultan}
  //   axios.post(API_URL.superStudent + '/training/filter-dept-training', monthYear)
  //     .then(response => response.data)
  //     .then(result => {
  //       this.setState({ department_training: result})
  //   })
  //     .catch(error => console.log(error))
  // }

  getDataTrainingMasterByRole(){
    let data = {code_jobdesk: this.props.data.code_jobdesk, code_konsultan: this.props.data.code_konsultan}
    axios.post(API_URL.superStudent + '/training/training-by-role', data,{
      headers:{ Authorization: `Bearer ${this.props.dataToken}`}
    })
      .then(response => response.data)
      .then(result => {
        let sub_training = [];
        let list_training = [];
        for (let i = 0; i < result.length; i++) {
          sub_training = result[i].sub_training;
          for (let j = 0; j < sub_training.length; j++) {
            list_training.push(sub_training[j])            
          }
        }
      const sub = list_training.filter((m) => m.is_training === true)
      const count_sub_training = list_training.length
      const count_employee_training = list_training.reduce((n, {training_list_count}) => n + training_list_count, 0);
      const persentase = Math.round((sub.length / count_sub_training) * 100)

      this.setState({ 
        list_training_master: result,
        count_sub_training: count_sub_training, 
        count_employee_training: count_employee_training,
        persentase: persentase
      })
        
    })
      .catch(error => console.log(error))
  }
  
  render() {
    const {count_employee_training, count_sub_training, persentase} = this.state
    return (
      <>
      <div className='card'>
          <div className='card-header'>
            <h2>TRAINING HISTORY</h2>
          </div>
          <div className='card-body'>
              <h4>Personal Training</h4>
              <hr />
              <h5>Total Training Participated : {count_employee_training}/{count_sub_training} ({persentase}%)</h5>
              <div className="table-responsive mb-5">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      {this.state.list_training_master.map((m,i)=>{
                        return (<th key={i} scope="col" className='text-center' style={{backgroundColor: 'red', color: '#fff'}} colSpan={m.sub_training_count}>{m.category}</th>)
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {this.state.list_training_master.map((m,i)=>{
                        return m.sub_training.map((j, o)=>{
                          return (<td key={o} width={200}>{j.sub_category}</td>)
                        })
                      })}
                    </tr>
                    <tr>
                    {this.state.list_training_master.map((m,i)=>{
                        return m.sub_training.map((j, o)=>{
                          return (<td key={o} className='text-center'>{j.is_training === false ? <i className='fa fa-times text-danger'></i> 
                          : 
                            j.training_list?.map((t, m) => {
                              return (
                              <>
                                <i key={m} className='fa fa-star text-warning mr-3' id={`UncontrolledTooltipExample1${m}`}></i>
                                <UncontrolledTooltip placement="top" target={`UncontrolledTooltipExample1${m}`}>
                                    <p>{t.training_name} ({t.training_date})</p>
                                </UncontrolledTooltip> 
                              </>
                              )
                            })
                          }</td>)
                        })
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
      </div>
  </>
    )
  }
}

const reduxState = (state) => ({
  dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(TrainingProfile);