import React, { Fragment, Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../components/common/loader';
import {matchSorter} from 'match-sorter';
import Moment from 'moment';

class RewardKategori extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            title: "",
            operation: "",
            logName: "",
            errors: "",
            modal: false,
            modalDelete: false,
            filtered: [],
            dataKategori: [],
            kategori: "",
            id_kategori: "",
            point: ""

        }
    }

    componentDidMount() {
        this.getDataKategori()
    }

    getDataKategori() {
        this.setState({
            showLoading:true
        });

        const url = API_URL.superStudent+'/reward/kategori';
        axios.get(url, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            } 
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({
                showLoading:false,
                dataKategori:data.data
            });
        });
    }

    addRow() {
        this.setState({
            modal: true,
            title: 'Add Kategori Reward',
            operation: 'add',
            logName: 'ADD-KATEGORI-REWARD',
            kategori: "",
            point: "",
        })
    }

    editRow(row) {
        this.setState({
            modal: true,
            title: 'Edit Kategori Reward',
            operation: 'edit',
            logName: 'EDIT-KATEGORI-REWARD',
            id_kategori: row.id_kategori,
            kategori: row.kategori,
            point: row.point,
        })
    }

    deleteRow(row) {
        this.setState({
            modalDelete: true,
            title: 'Delete Kategori Reward',
            operation: 'delete',
            logName: 'DELETE-KATEGORI-REWARD',
            id_kategori: row.id_kategori,
            kategori: row.kategori,
        })
    }

    closeDialog() {
        this.setState({
            modal: false,
            modalDelete: false,
            kategori: "",
        });
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
    
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
    
        if (insertNewFilter) {
          filtered.push({ id: accessor, value: value });
        }
    
        this.setState({ filtered: filtered });
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });

        var formData = new FormData();
        formData.append('id_kategori', this.state.id_kategori);
        formData.append('kategori', this.state.kategori);
        formData.append('point', this.state.point);
        
        formData.append('user',this.props.dataId);
        formData.append('token',this.props.dataToken);

        let url = '/reward/kategori';

        if (this.state.operation == 'edit') {
            formData.append('_method', 'PATCH');
            url = '/reward/kategori/'+this.state.id_kategori
        } else if (this.state.operation == 'delete') {
            formData.append('_method', 'DELETE');
            url = '/reward/kategori/'+this.state.id_kategori
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            this.closeDialog();
            this.getDataKategori();
            this.setState({ 
                btnDisabled: false,
            });
            if (this.state.operation == "add") {
                toast.success("Save Sucsessfully");
            } else {
                toast.success("Update Sucsessfully");
            }
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    renderDialog() {
        const { title, modal } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Kategori</label>
                                    <input className="form-control" name="kategori" type="text" id="kategori"  value={this.state.kategori}
                                        onChange={(e) => this.handleInput(e)}
                                        ref={e => this.inputkategori = e}
                                    />
                                    <span>{this.state.errors.kategori}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Point</label>
                                    <input className="form-control" name="point" type="text" id="point"  value={this.state.point}
                                        onChange={(e) => this.handleInput(e)}
                                        ref={e => this.inputpoint = e}
                                    />
                                    <span>{this.state.errors.point}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDeleteDialog() {
        const { title, modalDelete } = this.state;

        return (
            <Modal isOpen={modalDelete} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{title}</ModalHeader>
                <ModalBody>
                    Delete this data {this.state.kategori} ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.sendData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        const columns = [
            {
                Header: 'Code Kategori',
                accessor: 'code_kategori',
                filterable: false
            },
            {
                Header: 'Kategori',
                accessor: 'kategori',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["kategori"] }),
                filterAll: true
            },
            {
                Header: 'Point',
                accessor: 'point',
                style: {textAlign: "center"},
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["point"] }),
                filterable: true
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>                     
                        <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                        <button onClick={() => this.deleteRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return(
            <>
                <Fragment>
                    <div className="page-wrapper">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <button onClick={() => this.addRow()} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} >
                                                <div>
                                                    <span>
                                                        <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i>
                                                    </span>
                                                </div>
                                            </button>
                                            <br/>
                                            {
                                                this.state.dataKategori.length > 0 ?
                                                <ReactTable
                                                    data={this.state.dataKategori}
                                                    filtered={this.state.filtered}
                                                    columns={columns}
                                                    filterable
                                                    defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                                    onFilteredChange={(filtered, column, value) => {
                                                        this.onFilteredChangeCustom(value, column.id || column.accessor);
                                                    }}
                                                    defaultPageSize={100}
                                                    className="-striped -highlight"
                                                /> 
                                                : 
                                                <Loader showLoading={this.state.showLoading} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
                {this.renderDialog()}
                {this.renderDeleteDialog()}
            </>
        )

    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(RewardKategori));