import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { List } from 'react-feather';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';

const RewardSubmitHome = () => {
    return (
        <Fragment>
            <Breadcrumb title="Submission Reward & Reedem" />
            <div className="container-fluid">
                <div className="row theme-tab">
                    <div className="col-sm-12">
                        <RoutedTabs>
                            <div className="tabs tab-title">
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/rewards/submit`}>
                                    <List /> Submit Reward
                                </NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/rewards/redeem`}>
                                    <List /> Reedem Reward
                                </NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/rewards/point`}>
                                    <List /> Employee Point
                                </NavTab>
                            </div>
                        </RoutedTabs>
                        <Switch>
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/pages/rewards`}
                                render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/rewards/submit`} />}
                            />
                        </Switch>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState, null)(RewardSubmitHome);