import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import {matchSorter} from 'match-sorter';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import axios from 'axios'
import API_URL from '../../../ApiUrl';

class DatatableConsultant extends Component {
  constructor(props) {
      super(props);
      this.state = {
          data: [],
          filtered:[],
          dataOffice:[],
          filterStatus:'',
          filterOffice:'',
      }
  }

  componentDidMount(){
    this.loadOffice()
  }

  loadOffice = () => {
    axios.get(API_URL.superStudent+'/master/office',{
        headers: { 'Authorization': `Bearer ${this.props.dataToken}`}
    })
    .then(response => this.setState({ dataOffice: response.data.data}))
    .catch(error => console.log(error))
}


  SetFilterStatus = (e) => {        
      const newData = e.target.value;
      this.setState(prevState => {
        let filterStatus = newData; 
        return { filterStatus };                   
        }, () => {
          this.onFilteredChangeCustom(newData,"status");
        })
  }

  SetFilterOffice = (e) => {        
    const newData = e.target.value;
    this.setState(prevState => {
      let filterOffice = newData; 
      return { filterOffice };                   
      }, () => {
        this.onFilteredChangeCustom(newData,"id_kantor");
      })
  }

  onFilteredChangeCustom = (value, accessor) => {
      let filtered = this.state.filtered;
      let insertNewFilter = 1;

      if (filtered.length) {
        filtered.forEach((filter, i) => {
          if (filter["id"] === accessor) {
            if (value === "" || !value.length) filtered.splice(i, 1);
            else filter["value"] = value;

            insertNewFilter = 0;
          }
        });
      }

      if (insertNewFilter) {
        filtered.push({ id: accessor, value: value });
      }

      this.setState({ filtered: filtered });
  }

  render() {
    const {myData } = this.props;
    const columns = [
      {
        Header: 'Name',
        accessor: 'nama',
        style: {textAlign: 'center'},
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["nama"] }),
          filterAll: true
      },
      {
        Header: 'Email',
        accessor: 'email',
        style: {textAlign: 'center'},
        filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["email"] }),
        filterAll: true
      },
      {
        Header: 'Phone',
        accessor: 'no_wa',
        style: {textAlign: 'center'},
        filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["no_wa"] }),
        filterAll: true
      },
      {
        Header: 'Office',
        accessor: 'id_kantor',
        style: {textAlign: 'center'},
        filterable: true,
        Cell:(row) => (
          <>{row.original.office.nama_kantor}</>
      ),
      Filter: () => 
      <select value={this.state.filterOffice || ''} onChange={(e) => this.SetFilterOffice(e)}>
          <option key={0} value=''>Show All</option>
          {
              this.state.dataOffice.map( item => {
                  return <option key={item.id_kantor} value={item.id_kantor}>{item.nama_kantor}</option>
              })
          } 
      </select>
      },
      {
        Header: 'Job Title',
        accessor: 'job_title',
        style: {textAlign: 'center'},
        filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["job_title"] }),
        filterAll: true
      },
      {
        Header: 'Status',
        accessor: 'status',
        style: {textAlign: 'center'},
        filterable: true,
        Cell:(row) =>(
          row.original.status === 0 ? <span className='badge bg-dark'>In-Active</span> : <span className='badge bg-success'>Active</span>
        ),
        Filter: () => 
        <select value={this.state.filterStatus || ''} onChange={(e) => this.SetFilterStatus(e)}>
            <option value=''>Show All</option>
            <option value='1'>Active</option>
            <option value='0'>In-Active</option>
        </select>,
      }
    ]

    if(this.props.dataUser.code_roles === 'RL004' || this.props.dataUser.code_roles === 'RL001' || this.props.dataUser.id_users === 280){
      columns.push(
        {
          Header: <b>Action</b>,
          id: 'delete',
          accessor: str => "delete",
          Cell: (row) => (
            <Link className='btn btn-outline-primary btn-xs' style={{border:'none'}} to={`${process.env.PUBLIC_URL}/pages/consultant/detail-employee/${row.original.code_konsultan}`}><i className='fa fa-eye fa-2x'></i></Link>
          ),
          style: {textAlign: 'center'},
          sortable: false,
          filterable: false
        }
      )
    }

    return (
      <Fragment>
        <ReactTable
          data={myData}
          filtered={this.state.filtered}
          columns={columns}
          pageSize={15}
          filterable
          showPagination={true}
          onFilteredChange={(filtered, column, value) => {
            this.onFilteredChangeCustom(value, column.id || column.accessor);
        }}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value}
        />
      </Fragment>
    );
  }
};

// export default Datatable;

const reduxState = (state) => ({
  dataUser: state.Login.detailUser,
  dataToken: state.Login.lgnInitialize,
})


export default connect(reduxState, null) (withRouter(DatatableConsultant));