import React, { Fragment, Component } from 'react';
import ReactTable from 'react-table';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {matchSorter} from 'match-sorter';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


class ReportPost extends Component {

    constructor(props){
        super(props)
        this.state = {
            listData:[],
            filtered:[],
            listSosmed:[],
            id_sosmed:'',
            title:'',
            textSave:'Save',
            date_post:'',
            total_view:'',
            filterSosmed:'',
            modal:false,
        }
    }

    componentDidMount(){
       this.loadRegisterData()
       this.loadSosmed()
    }

    formatNumber = (nominal) => {
        return new Intl.NumberFormat('id-ID').format(nominal);
    }

    loadRegisterData = () => {
        axios.get(API_URL.superStudent+'/post-sosmed', {
            headers: {
                Authorization: `Bearer `+this.props.dataToken
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({ listData: result}))
        .catch(error => console.log(error))
    }

    loadSosmed = () => {
        axios.get(API_URL.superStudent+'/list-sosmed', {
            headers:{
                Authorization: `Bearer `+this.props.dataToken
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({ listSosmed: result }))
        .catch(error => console.log(error))
    }

    addRow = () => {
        this.setState({
            modal:true
        })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    saveRow = () => {
        this.setState({
            btnDisabled:true,
            textSave:'Saving...'
        })
        let formData = new FormData()
        formData.append('social_media_type', this.state.id_sosmed)
        formData.append('title', this.state.title)
        formData.append('date_post',this.state.date_post)
        formData.append('total_view', this.state.total_view)
        axios.post(API_URL.superStudent+'/post-sosmed',formData,{
            headers:{
                Authorization: `Bearer `+this.props.dataToken
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({
                btnDisabled:false,
                textSave:'Save Data',
                modal: false,
            })
            toast.success("Data Berhasil di perbaharui")
            this.loadRegisterData()
        })
        .catch(error => {
        })
    }

    renderModal() {
        return (
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modal} toggle={() => this.setState({ modal: false })}>
                <ModalHeader toggle={() => this.setState({ modal: false })}>Tambah Report</ModalHeader>
                <ModalBody>
                <form >
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="form-group"> 
                            <label className='control-label'>Title<span className="text-danger">*</span></label>
                            <input className="form-control" value={this.state.title} onChange={this.handleInput} name="title" type="text" />
                            </div>
                            <div className="form-group"> 
                                <label className='control-label'>Total View<span className="text-danger">*</span></label>
                                <input className="form-control" value={this.state.total_view} onChange={this.handleInput} name="total_view" type="text" />
                            </div>
                            <div className="form-group"> 
                                <label className='control-label'>Date Post<span className="text-danger">*</span></label>
                                <input className="form-control" value={this.state.date_post} onChange={this.handleInput} name="date_post" type="date" />
                            </div>
                            <div className="form-group"> 
                                <label className='control-label'>Social Media<span className="text-danger">*</span></label>
                                <select name='id_sosmed' value={this.state.id_sosmed} className='form-control' onChange={this.handleInput}>
                                    <option value=''> --Select Sosmed Type-- </option>
                                    {
                                        this.state.listSosmed.length > 0 ?
                                        this.state.listSosmed.map(sosmed => {
                                            return <option key={sosmed.id} value={sosmed.id}>{sosmed.name}</option>
                                        })
                                        :
                                        ''
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
                </ModalBody>
                <ModalFooter>
                  <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>{this.state.textSave}</Button>
                  <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.setState({ modal: false })}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
          )
    }

    SetFilterSosmed = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
          let filterSosmed = newData; 
          return { filterSosmed };                   
          }, () => {
            this.onFilteredChangeCustom(newData,"social_media_type");
          })
    }
 
    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
    
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
    
        if (insertNewFilter) {
          filtered.push({ id: accessor, value: value });
        }
    
        this.setState({ filtered: filtered });
  }

  deletePost = (id) => {
    axios.delete(API_URL.superStudent+`/post-sosmed/${id}`, {
        headers: {
            Authorization: `Bearer `+this.props.dataToken
        }
    })
    .then(result => {
        this.setState({
            btnDisabled:false,
            textSave:'Save Data',
            modal: false,
        })
        toast.success("Data Berhasil di perbaharui")
        this.loadRegisterData()
    })
    .catch(error => console.log(error))
  }

    render(){

        const columns = [
            {
                Header: 'Title',
                accessor: 'title',
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["title"] }),
                filterAll: true
            },
            {
                Header: 'Category',
                accessor: 'social_media_type',
                Filter: () => 
                <select value={this.state.filterSosmed || ''} onChange={(e) => this.SetFilterSosmed(e)}>
                    <option key={0} value=''>Show All</option>
                    {
                        this.state.listSosmed.map( item => {
                            return <option key={item.id} value={item.id}>{item.name}</option>
                        })
                    } 
                </select>,
                   Cell:(row) => (
                    <>{row.original.sosmed.name}</>
                ),
            },
            {
                id: 'date_post',
                Header: 'Date Post',
                accessor: a => {
                    return Moment(a.date_post)
                    .local()
                    .format("DD/MM/YYYY")
                },
                filterable: false,
                style: {textAlign: 'center'}
            },
            {
                Header: 'Total View',
                accessor: 'total_view',
                filterable: false,
                style: {textAlign: 'center'},
                Cell: (row) =>(
                    <>{this.formatNumber(row.original.total_view)}</>
                )
            }, 
            {
                id: 'created_at',
                Header: 'Date Input',
                accessor: a => {
                    return Moment(a.created_at)
                    .local()
                    .format("DD/MM/YYYY")
                },
                filterable: false,
                style: {textAlign: 'center'}
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <>
                        <button
                        onClick={()=> this.deletePost(row.original.id)} 
                          className="btn btn-outline-danger btn-xs mr-1" type="button" style={{border:'none'}} ><i className="fa fa-trash fa-2x"></i>
                        </button>
                    </>
                ),
                style: {textAlign: 'center'},
                filterable: false,
                sortable: false
            }
            
        ]
        return(
            <Fragment>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='mb-2'>
                                        <button onClick={this.addRow} className="btn btn-success" type="button" style={{border:'none'}} >Add Data </button>
                                    </div>
                                    <ReactTable
                                        data={this.state.listData}
                                        columns={columns}
                                        filterable
                                        filtered={this.state.filtered}
                                        defaultFilterMethod={(filter, row) =>
                                        String(row[filter.id]) === filter.value}
                                        defaultPageSize={10}
                                        className="-striped -highlight"
                                        onFilteredChange={(filtered, column, value) => {
                                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
                {this.renderModal()}
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(ReportPost)