import React, { Component,Fragment } from 'react'
import Breadcrumb from '../../../components/common/breadcrumb'
import { connect } from 'react-redux';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { Link } from 'react-router-dom'
import DetailCourse from './detail-course-component';

import { withRouter } from "react-router";

class DetailCourseComponent extends Component {

    constructor(props){
        super(props)
        this.state = {
            listCourses:[]
        }
    }

    componentDidMount(){
        if(this.props.location.state !== undefined)
            this.loadCourses()
    }

    loadCourses = () => {
        axios.get(API_URL.superStudent+'/course/detail-course?course_id='+this.props.match.params.courseId,{
            headers: {"Authorization": `Bearer ${this.props.dataToken}`},
        })
        .then(response => this.setState({ listCourses: response.data.data}))
        .catch(error => console.log(error))
    }

    showForm = () => {
        this.setState({
            displayForm:'',
            displyaCourse:'none'
        })
    }

    render(){
        return(
            <Fragment>
                <Breadcrumb parent="User" title="Detail Course" />
                <div className="page-wrapper">
                    <div className="container-fluid">
                        {
                            this.props.location.state !== undefined ?
                            <div className="row">
                                <div className='col-md-12 mb-4'>
                                    <Link className="btn btn-success float-right" style={{color:'white'}} to={`/pages/course/detail-course/${this.props.match.params.courseId}/form-detail-course`}> Add Data</Link>
                                </div>
                            </div>
                            :
                            ''
                        }
                        <div className='my-gallery row gallery-with-description'>
                            {
                                this.state.listCourses.length > 0 ?
                                this.state.listCourses.map(course => {
                                    return <DetailCourse key={course.id} data={course}/>
                                })
                                :
                                ''
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}


const reduxState = (state) => ({
    userable: state.Login.userableData.userable,
    dataToken: state.Login.lgnInitialize,
  })

export default connect(reduxState, null)(withRouter(DetailCourseComponent))