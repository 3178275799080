import React from 'react'
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion'
import { Tooltip } from "reactstrap";


export default function PodiumStep({ podium, winner }) {
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
  const offset = podium.length - winner.position
  return (
    <div
      id={`Tooltip${winner.code_konsultan}`}
      style={{
        display: 'flex',
        flexDirection: 'column',
        placeContent: 'center',
      }}
    >
      <motion.div
        style={{
          alignSelf: 'center',
          marginBottom: '.25rem',
        }}
        initial="hidden"
        animate="visible"
        variants={{
          hidden: { opacity: 0 },
          visible: {
            opacity: 1,
            transition: {
              delay: 0.5,
              duration: 1,
            },
          },
        }}
      >
        <img
          src={`${winner.foto}`}
          alt={winner.nama}
          style={{
            borderRadius: 9999,
            height: '3rem',
            overflow: 'hidden',
            width: '3rem',
          }}
        />
      </motion.div>
      <motion.div
        style={{
          backgroundColor: 'rgb(66, 133, 244)',
          borderColor: 'rgba(190,24,93,1)',
          borderTopLeftRadius: '.5rem',
          borderTopRightRadius: '.5rem',
          display: 'flex',
          filter: `opacity(${0.1 + offset / podium.length})`,
          marginBottom: -1,
          placeContent: 'center',
          width: '3rem',
        }}
        initial="hidden"
        animate="visible"
        variants={{
          hidden: { height: 0, opacity: 0 },
          visible: {
            height: 250 * (offset / podium.length),
            opacity: 1,
            transition: {
              delay: 0.5,
              duration: 1,
              ease: 'backInOut',
            },
          },
        }}
      >
        <span style={{ alignSelf: 'flex-end', color: 'white' }}>
          {winner.position + 1}
        </span>
      </motion.div>
      <Tooltip
            placement="right"
            isOpen={tooltipOpen}
            target={`Tooltip${winner.code_konsultan}`}
            toggle={toggle}
        >
            {winner.nama} : {winner.achivement}
        </Tooltip>
    </div>
  )
}