import React, { Fragment, Component } from 'react';
import logo from '../assets/images/niec-logo.png'
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import API_URL from '../ApiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { updateDataMenu, updateCurrentLogin } from '../actions/login.action';

class ResetPwd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            model: {
                password:"",
                newpassword:"",
                repassword:"",
            },
        }
    }

    strongPasswordValidation = (data) => {
        var lowerCase = /[a-z]/g;
        var upperCase = /[A-Z]/g;
        var numbers = /[0-9]/g;
        var character = /['!','@','#','$','%','^','&','*','(',')','.',',',';',':','/','?','"',''','|','>','<']/g;

        if (!data.newpassword.match(lowerCase)) {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.newpassword = 'Password should contains lowercase letters!';    
                errors.newpasswordStatus = false;                             
                return { errors };                   
            })
        } else if (!data.newpassword.match(upperCase)) {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.newpassword = 'Password should contain uppercase letters!';    
                errors.newpasswordStatus = false;                             
                return { errors };                   
            })
        } else if (!data.newpassword.match(numbers)) {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.newpassword = 'Password should contains numbers also!';    
                errors.newpasswordStatus = false;                             
                return { errors };                   
            })
        } else if(!data.newpassword.match(character)) {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.newpassword = 'Password should contain charachter also!';    
                errors.newpasswordStatus = false;                             
                return { errors };                   
            })
        } else if (data.newpassword.length < 10) {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.newpassword = 'Password length should be more than 10.';    
                errors.newpasswordStatus = false;                             
                return { errors };                   
            })
        } else {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.newpassword = '';    
                errors.newpasswordStatus = true;                             
                return { errors };                   
            })
        }

    }

    setPassword = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
        model.password = newData; 
        return { model };                   
        }, () => {
        this.validatePassword();
        })
    }
    
    setNewPassword = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
        model.newpassword = newData; 
        return { model };                   
        }, () => {
        this.validateNewPassword();
        })
    }
    
    setRePassword = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
        model.repassword = newData; 
        return { model };                   
        }, () => {
        this.validateRePassword();
        })
    }

    validatePassword = () => {
        const data = this.state.model;
          if(data.password === "" || data.password === 0 || data.password === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.password = 'Password is required'; 
                errors.passwordStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.password = '';    
                errors.passwordStatus = true;                             
                return { errors };                   
            })
        }
    }
    
    validateNewPassword = () => {
        const data = this.state.model;
        if(data.newpassword === "" || data.newpassword === 0 || data.newpassword === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.newpassword = 'New Password is required'; 
                errors.newpasswordStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.strongPasswordValidation(data);
        }
    }
    
    validateRePassword = () => {
        const data = this.state.model;
          if(data.repassword === "" || data.repassword === 0 || data.repassword === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.repassword = 'Re Password is required'; 
                errors.repasswordStatus = false;                                 
                return { errors };                   
            })
        }else{
            if(data.repassword !== data.newpassword){
                this.setState(prevState => {
                    let errors = Object.assign({}, prevState.errors);  
                    errors.repassword = 'Re Password Not Match';    
                    errors.repasswordStatus = false;                             
                    return { errors };                   
                })
            }else{
                this.setState(prevState => {
                    let errors = Object.assign({}, prevState.errors);  
                    errors.repassword = '';    
                    errors.repasswordStatus = true;                             
                    return { errors };                   
                })
            }
        }
    }

    handleValidation(){
        let formIsValid = true;
        if(!this.state.errors.passwordStatus){
            formIsValid = false;
        }
        if(!this.state.errors.newpasswordStatus){
            formIsValid = false;
        }
        if(!this.state.errors.repasswordStatus){
            formIsValid = false;
        }

        return formIsValid;
    }

    saveData = (e) => {
        e.preventDefault()
        if(this.handleValidation()){
          this.sendData();
        }else{
          this.validatePassword();
          this.validateNewPassword();
          this.validateRePassword();
        }
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });
        
        let data = this.state.model;

        var formData = new FormData();
        formData.append('password', data.password);
        formData.append('new_password', data.newpassword);
        formData.append('code_users', this.props.codeUsers);

        axios.post(API_URL.superStudent+'/auth/change-password', formData,{
            headers: {  Authorization: `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            if(response.data.status === true) {
                this.props.updateStatusLogin(response.data.status)
                this.props.updateListMenu(response.data.listmenu)
                toast.success('Successfully updated password')
                window.location.href=`${process.env.PUBLIC_URL}/`
            } else {
                this.setState({
                    btnDisabled:false
                })
                toast.error('Ops, Current Password Wrong !!')
            }
        })
        .catch(error => console.log(error))
    }

    resetState(){
        this.setState({
          model: {
            password:"",
            newpassword:"",
            repassword:"",
          },
          errors: {},
        });
    }

    render(){
        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid">
                        {/* <!-- Reset Password page start--> */}
                        <div className="authentication-main">
                            <div className="row">
                                <div className="col-md-12 p-0">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">
                                            <div className="text-center"><img src={logo} alt="" /></div>
                                            <div className="card mt-4 p-4">
                                                <form onSubmit={this.saveData} className="theme-form needs-validation" noValidate="">
                                                    <h5 className="f-16 mb-3 f-w-600">CHANGE PASSWORD</h5>
                                                    <div className="form-row">
                                                        <label className="col-form-label">Current Password</label>
                                                            <input className="form-control" name="password" type="password" id="password" placeholder="*****"
                                                                onChange={(e) => this.setPassword(e)}
                                                                onBlur={(e) => this.validatePassword()}
                                                                ref={e => this.inputPassword = e}
                                                            />
                                                            <span>{this.state.errors.password}</span>
                                                            <div className="valid-feedback">Looks good!</div>
                                                    </div>
                                                    <div className="form-row">
                                                        <label className="col-form-label">New Password</label>
                                                            <input className="form-control" name="newpassword" type="password" id="newpassword" placeholder="*****"
                                                                onChange={(e) => this.setNewPassword(e)}
                                                                onBlur={(e) => this.validateNewPassword()}
                                                                ref={e => this.inputNewPassword = e}
                                                            />
                                                            <span>{this.state.errors.newpassword}</span>
                                                            <div className="valid-feedback">Looks good!</div>
                                                    </div>
                                                    <div className="form-row">
                                                        <label className="col-form-label">Re Password</label>
                                                            <input className="form-control" name="repassword" type="password" id="repassword" placeholder="*****"
                                                                onChange={(e) => this.setRePassword(e)}
                                                                onBlur={(e) => this.validateRePassword()}
                                                                ref={e => this.inputNama = e}
                                                            />
                                                            <span>{this.state.errors.repassword}</span>
                                                            <div className="valid-feedback">Looks good!</div>
                                                    </div>
                                                    <div className="form-group form-row mb-0 mt-2">
                                                        <div className="col-md-2">
                                                            <Button disabled={this.state.btnDisabled} color="primary" type='submit'>Save Changes</Button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Reset Password page end--> */}
                    </div>
                </div>
            </Fragment>
        );
    };
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    codeUsers: state.Login.detailUser.code_users,
    loginStatus: state.Login.lgnsts,
})

const reduxDispatch = (dispatch) => ({
    updateListMenu: (data) => dispatch(updateDataMenu(data)),
    updateStatusLogin: (status) => dispatch(updateCurrentLogin(status))
})
  
export default connect(reduxState, reduxDispatch)(withRouter(ResetPwd));