import { GET_ROLES } from "../constant/actionTypes";

const initialState = {
    status: false,
    dataRoles: []
}

const Roles = (state = initialState, {action,value}) => {

    switch (action) {
        case GET_ROLES:
            return {
                ...state,
                state: true,
                dataRoles: value
            }
        default:
            return state;
    }
} 

export default Roles