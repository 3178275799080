import React, { Component } from 'react';
import KPIReport from './kpi-report';
import { connect } from 'react-redux';

class IndexKPI extends Component{

    constructor(props){
        super(props)
        this.state = {

        }
    }

    render(){
        return(
            <>
                <KPIReport employe_code={this.props.dataUser.code_konsultan}/>
            </>
        )
    }
}

const reduxState = (state) => ({
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
  })

export default connect(reduxState, null)(IndexKPI);