import React from 'react'
import DataTablesLowongan from '../../components/tables/dataTablesLowongan'

const DataLowongan = () => {
  return (
    <>
    <div className="page-wrapper">
        <div className="container-fluid p-0">

            <div className="row">
                <div className="col-md-12">
                    <DataTablesLowongan />
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default DataLowongan