import React, { Component } from "react";
import { connect } from 'react-redux';
import {logout} from '../actions/login.action';

class Logout extends Component {

  componentDidMount(){
    const { history } = this.props;

    const config = {
      headers: { Authorization: `Bearer `+this.props.dataToken }
    };

    var formData = new FormData();
    formData.append('id_users',this.props.codeUsers);
    formData.append('api_token',this.props.dataToken);

    this.props.logOut(formData, config);

      sessionStorage.setItem('status_login', false);
      sessionStorage.setItem('status_payment', false);
  
      localStorage.clear();
      sessionStorage.clear();
      history.push('/');

  }


  render() {
    return(
<div></div>
    );
  };
}

const reduxState = (state) => ({
  dataId: state.Login.detailUser.id_users,
  codeUsers: state.Login.detailUser.code_users,
  dataToken: state.Login.lgnInitialize,
})


const reduxDispatch = (dispatch) => ({
 logOut: (data, config) => dispatch(logout(data, config))
})

export default connect(reduxState, reduxDispatch)(Logout);
