import React, { Component } from 'react'
import seven from '../../../assets/images/user/7.jpg';
import 'react-dropzone-uploader/dist/styles.css';
import { connect } from 'react-redux';
import API_URL from '../../../ApiUrl';
import axios from 'axios'
import { getDataUserable } from '../../../actions/login.action';

class MediaProfile extends Component {

    constructor(props){
        super(props)
        this.state = {
            btnDisabled:true,
            id_university: this.props.userableData.userable.id,
            textLogo: 'Edit',
            displayLogo: 'none',
            saveLogo: 'Save',
            disableBtnLogo:false,
            logo:'',
            cover:'',
            image_logo: '',
            image_cover: '',
            errors:[]
        }
    }

    componentDidMount(){
        this.getImageData()
    }

    getImageData = () => {
        let result = this.props.userableData.userable
        this.setState({
            image_logo:result.logo,
            image_cover:result.cover
        })
    }

    editLogo = () => {
        this.setState(prevstate => ({ 
            textLogo: prevstate.textLogo === 'Edit' ? 'Cancel' : 'Edit',
            displayLogo: prevstate.displayLogo === 'none' ? '' : 'none',
            errors:[]
        }));
    }

    handleChangeLogo = (e) => {
        this.setState({
            input_logo:e.target.files[0]
        })
    }

    handleChangeCover = (e) => {
        this.setState({
            input_cover: e.target.files[0]
        })
    }

    handleSubmitLogo =  async (e) => {
        e.preventDefault()
        const body = new FormData()
        body.append('logo', this.state.input_logo)
        body.append('cover', this.state.input_cover)
        body.append('id_users', this.props.userableData.id)
        body.append('id', this.props.userableData.userable.id)
        this.setState({
            disableBtnLogo:true,
            saveLogo:'Saving..'
        })
        await axios.post(API_URL.superStudent+'/update-university-image', body, {
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data',
            }
        })
        .then(response => {
            this.props.getUserableData(this.props.dataToken)
            .then(() => {
                this.getImageData()
            })
            this.setState({
                textLogo: 'Edit',
                displayLogo:'none',
                disableBtnLogo:false,
                saveLogo:'Save'
            })
        })
        .catch(err => {
            console.log(err)
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors:err.response.data,
                    disableBtnLogo:false,
                    saveLogo:'Save'
                })
            }
        })

    }

    render(){
        return(
            <>
                <div className='col-xs-12'>
                    <div className="card">
                        <div className="card-header">
                            <div className='row'>
                                <div className='col-sm-6 col-xs-6'> <h5>Univerity Image</h5></div>
                                <div className='col-sm-5 co-xs-5'>
                                    <button onClick={this.editLogo} className='btn btn-info'>{this.state.textLogo}</button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <form onSubmit={this.handleSubmitLogo}>
                                <div className="row gallery my-gallery">
                                    <figure itemProp="associatedMedia" className="col-md-12 img-hover hover-15">
                                        <h4>Logo</h4>
                                        <div className="">
                                            <img src={this.state.image_logo !== null ? this.state.image_logo : seven}  alt="description" />
                                        </div>
                                    </figure>
                                </div>
                                <div style={{display: this.state.displayLogo }} className='row mt-3'>
                                    <input type='file' onChange={this.handleChangeLogo} className='form-control' accept='image/*' />
                                    <div className='text-danger'>{this.state.errors.logo}</div>
                                </div>
                                <div className="row gallery my-gallery mt-4">
                                    <figure itemProp="associatedMedia" className="col-md-12 img-hover hover-15">
                                        <h4>Cover</h4>
                                        <div className="">
                                            <img src={this.state.image_cover !== null ? this.state.image_cover : seven}  alt="description" />
                                        </div>
                                    </figure>
                                </div>
                                <div style={{display: this.state.displayLogo }} className='row mt-3'>
                                    <input type='file' name='cover' accept='image/*' onChange={this.handleChangeCover} className='form-control'/>
                                    <div className='text-danger'>{this.state.errors.cover}</div>
                                </div>
                                <button style={{display: this.state.displayLogo, border:'none'}}  disabled={this.state.disableBtnLogo} className='btn btn-success mt-2 btn-block' type='submit'>{this.state.saveLogo}</button>
                            </form>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

const reduxState = (state) => ({
    dataUsers: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
    userableData: state.Login.userableData
})

const reduxDispatch = (dispatch) =>{
    return {
        getUserableData:(token) => dispatch(getDataUserable(token))
    }
}

export default connect(reduxState, reduxDispatch)(MediaProfile)