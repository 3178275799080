import React, { Fragment, Component } from 'react';
import ReactTable from 'react-table';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import Moment from 'moment';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { saveAs } from "file-saver";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UncontrolledTooltip } from 'reactstrap';
import setRangeDate from '../../../helper/general-function';

class TaskList extends Component {

    constructor(props){
        super(props)
        this.state = {
            dateType: 'this_month',
            id_todo:'',
            status:'',
            noted:'',
            attachment:'',
            modal_update:false,
            text_save: 'Save',
            btnSave:false,
            filtered:[],
            statusTaskList:[],
            internalUserList:[],
            filterStatus:'',
            filterHandler:'',
            filterRequestor:'',
            startDate: Moment().startOf('month'),
            endDate: Moment().endOf('month'),
            jobdesk_select:'JD008'
        }
    }

    componentDidMount(){
        this.loadTodoList()
        this.loadTodoListStatus()
        this.loadInternalUser()
    }

    handleUpdate = (e) => {
        this.setState({
            id_todo: e.id,
            status: e.status_id,
            noted: e.noted,
            modal_update:true
        })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleAttachment = (e) => {
        this.setState({
            attachment:e.target.files[0]
        })
    }

    handleDate = (e) => {
        this.setState({
            dateType: e.target.value
        }, () => this.setDate())
    }

    setDate(){   
        let data = setRangeDate(this.state.dateType); 
        this.setState({ 
            startDate : data.start,
            endDate : data.end
        },() => this.loadTodoList())
    }

    loadTodoList = () =>{
        let formData = new FormData ()
        formData.append('code_jobdesk',this.state.jobdesk_select)
        formData.append('start_date', this.state.startDate)
        formData.append('end_date', this.state.endDate)
        axios.post(API_URL.superStudent+'/todo/list-todo', formData, {
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => response.data.data)
        .then(result => this.setState({taskList: result}))
        .catch(error => console.log(error))
    }

    loadInternalUser = () => {
        let formData = new FormData()
        formData.append('code_roles',JSON.stringify(['RL001','RL003','RL004','RL005','RL006','RL009','RL012','RL013','RL016']));
        axios.post(API_URL.superStudent+'/internal-user-active',formData,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => response.data.data)
        .then(result => this.setState({internalUserList: result}))
        .catch(error => console.log(error))
    }

    loadTodoListStatus = () => {
        axios.get(API_URL.superStudent+'/todo/status-todo', {
            headers:{
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({ statusTaskList: result }))
        .catch(error => console.log(error))
    }

    SetFilterStatus = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
          let filterStatus = newData; 
          return { filterStatus };                   
          }, () => {
            this.onFilteredChangeCustom(newData,"status_id");
          })
    }

    SetFilterHandler = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
          let filterHandler = newData; 
          return { filterHandler };                   
          }, () => {
            this.onFilteredChangeCustom(newData,"handle_by");
          })
    }

    SetFilterRequestor = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
          let filterRequestor = newData; 
          return { filterRequestor };                   
          }, () => {
            this.onFilteredChangeCustom(newData,"code_user");
          })
    }

    onFilteredChangeCustom = (value, accessor) => {
          let filtered = this.state.filtered;
          let insertNewFilter = 1;
      
          if (filtered.length) {
            filtered.forEach((filter, i) => {
              if (filter["id"] === accessor) {
                if (value === "" || !value.length) filtered.splice(i, 1);
                else filter["value"] = value;
      
                insertNewFilter = 0;
              }
            });
          }
      
          if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
          }
      
          this.setState({ filtered: filtered });
    }

    saveUpdate(){
        let formData = new FormData()
        formData.append('id_todo', this.state.id_todo)
        formData.append('noted', this.state.noted)
        formData.append('status', this.state.status)
        formData.append('attachment', this.state.attachment)
        this.setState({
            text_save: 'Saving..',
            btnSave:true,
        })
        axios.post(API_URL.superStudent+'/todo/finish-todo', formData,{
            headers:{
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => {
            toast.success('Task list updated successfully')
            this.setState({
                modal_update:false,
                text_save: 'Save',
                btnSave:false,
            })
            this.loadTodoList()
        })
        .catch(error => {
            this.setState({
                modal_update:false,
                text_save: 'Save',
                btnSave:false,
            })
            toast.error('there is an error')
        })  
    }

    renderModalUpdate(){
        return(
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modal_update} toggle={() => this.setState({ modal_update: false })}>
                <ModalHeader toggle={() => this.setState({ modal_update: false })}>Update Data</ModalHeader>
                <ModalBody>
                <form >
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                    <label className='control-label'>Status</label>
                                    <select name='status' value={this.state.status} className='form-control' onChange={this.handleInput}>
                                        <option key={0} value='0'>Select Status</option>
                                        <option key={1} value='2'>Pending</option>
                                        <option key={4} value='1'>Progress</option>
                                        <option key={2} value='3'>Done</option>
                                        <option key={3} value='7'>Revisi</option>
                                    </select>
                                </div>
                            <div className='form-group'>
                                <label className='control-label'>Noted</label>
                                <textarea className='form-control' onChange={this.handleInput} name='noted' value={this.state.noted} />
                            </div>
                            <div className='form-group'>
                                <label className='control-label'>File</label>
                                <input name='attachment' className='form-control' type='file' onChange={this.handleAttachment}/>
                            </div>
                        </div>
                    </div>
                </form>
                </ModalBody>
                <ModalFooter>
                  <Button disabled={this.state.btnSave} color="primary" onClick={(e) => this.saveUpdate()}>{this.state.text_save}</Button>
                  <Button  color="secondary" onClick={() => this.setState({ modal_update: false })}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
        )
    }

    downloadFile = (row) => {
        let filename = row.substring(row.lastIndexOf('/'))
        saveAs(row,filename);
    }

    handleListJob = (e) => {
        this.setState({
            jobdesk_select: e.target.value
        },() => {
            this.loadTodoList()
        })
    }

    render(){
        const columns_todo = [
            {
                Header: 'Crated at',
                accessor: 'created_at',
                style:{textAlign:'center'},
                Cell:(row) => (
                    <>
                        <span>{Moment(row.original.created_at).format('DD MMM YY')}</span><p className='f-12 text-center'>{Moment(row.original.created_at).format('HH:mm')}</p>
                    </>
                )
            },
            {
                Header: 'Title',
                accessor: 'title',
                style: {textAlign: 'center'},
            },
            {
                Header: 'Category',
                accessor: 'category.name',
                style: {textAlign: 'center'},
            },
            {
                Header: 'From',
                accessor: 'code_user',
                style: {textAlign: 'center'},
                filterable: true,
                Cell:(row) => (
                    <>{row.original.user.username}</>
                ),
                Filter: () => 
                <select value={this.state.filterRequestor || ''} onChange={(e) => this.SetFilterRequestor(e)}>
                    <option key={0} value=''>Show All</option>
                    {
                        this.state.internalUserList.map( item => {
                            return <option key={item.code_user} value={item.code_users}>{item.username}</option>
                        })
                    } 
                </select>,
            },
            {
                Header: 'To',
                accessor: 'handle_by',
                style: {textAlign: 'center'},
                filterable: false,
                Cell:(row) => (
                    <>{row.original.handler.nama}</>
                ),
            },
            {
                Header: 'Status',
                accessor: 'status_id',
                filterable: true,
                style: {textAlign: 'center'},
                Cell: (row) => (
                    row.original.status_id == '2' ? <> <span id={`code_status`+row.original.id} className={'badge bg-'+row.original.status.label}>{row.original.status.name}</span> <UncontrolledTooltip placement="top" target={`code_status`+row.original.id}>
                        {row.original.noted}
                  </UncontrolledTooltip> </> : <span className={'badge bg-'+row.original.status.label}>{row.original.status.name}</span>
                ),
                Filter: () => 
                <select value={this.state.filterStatus || ''} onChange={(e) => this.SetFilterStatus(e)}>
                    <option key={0} value=''>Show All</option>
                            {
                            this.state.statusTaskList.map( item => {
                                return <option key={item.id} value={item.id}>{item.name}</option>
                            })
                            } 
                </select>,
            },
            {
                Header: 'Due date',
                accessor: 'due_date',
                style:{textAlign:'center'},
                Cell:(row) => (
                    row.original.due_date !== null ? 
                    <><span>{Moment(row.original.due_date).format('DD MMM YY')}</span><p className='f-12 text-center'>{Moment(row.original.due_date).format('HH:mm')}</p></> : ''
                )
            },
            {
                Header: 'Finish',
                accessor: 'date_finish',
                style:{textAlign:'center'},
                Cell:(row) => (
                    row.original.date_finish !== null ? 
                    <><span>{Moment(row.original.date_finish).format('DD MMM YY')}</span><p className='f-12 text-center'>{Moment(row.original.date_finish).format('HH:mm')}</p></> : ''
                )
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <>
                    {row.original.resources === null ? '' : <button
                        onClick={()=> this.downloadFile(row.original.resources)} 
                          className="btn btn-outline-primary btn-xs mr-1" type="button" style={{border:'none'}} ><i className="fa fa-archive fa-2x"></i>
                        </button> }
                    {row.original.attachment === null ? '' : <button
                        onClick={()=> this.downloadFile(row.original.attachment)} 
                          className="btn btn-outline-success btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-download fa-2x"></i>
                        </button> }
                    {row.original.status_id === 5 ? '' : <button
                        onClick={()=> this.handleUpdate(row.original)} 
                          className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-edit fa-2x"></i>
                        </button>
                    }
                    </>
                ),
                style: {textAlign: 'center'},
                sortable: false
            },
        ] 
        return(
            <Fragment>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    <h4>Task List Creative Team</h4>
                                </div>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-md-3 col-sm-4 mb-2'>
                                            <select name='dateType' value={this.state.dateType} onChange={this.handleDate} className='form-control'>
                                                <option value='this_week'>This Week</option>
                                                <option value='last_week'>Last Week</option>
                                                <option value='this_month'>This Month</option>
                                                <option value='last_month'>Last Month</option>
                                                <option value='this_year'>This Year</option>
                                            </select>
                                        </div>
                                        <div className='col-md-3 col-sm-4 mb-2'>
                                            <select onChange={this.handleListJob} className='form-control' name='jobdesk_select' value={this.state.jobdesk_select}>
                                                <option value='JD008'>Social Media</option>
                                                <option value='JD011'>Design</option>
                                                <option value='JD012'>Event & Comunity</option>
                                            </select>
                                        </div>
                                    </div>
                                <ReactTable
                                    data={this.state.taskList}
                                    columns={columns_todo}
                                    filtered={this.state.filtered}   
                                    defaultPageSize={100}
                                    className="-striped -highlight"
                                    showPaginationBottom={true}
                                    onFilteredChange={(filtered, column, value) => {
                                        this.onFilteredChangeCustom(value, column.id || column.accessor);
                                    }}
                                    SubComponent={row =>{
                                        const data = row.original;
                                        return (
                                            <div style={{ padding: "20px" }}>
                                                 <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div dangerouslySetInnerHTML={{__html:data.description}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }}
                                />
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
                {this.renderModalUpdate()}
                <ToastContainer />
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize
})

export default connect(reduxState, null)(TaskList);