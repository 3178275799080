import React, { Fragment, Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CKEditors from "react-ckeditor-component";
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from "react-router";
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { resettoken } from '../../actions/login.action';
import DatatableLowongan from '../common/datatable/datatableLowongan';
import { toast } from 'react-toastify';

class DataTablesLowongan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listLowongan: [],
            dataArea: [],
            list_jobdesk: [],
            addModal: false,
            editModal: false,
            btnLoading: false,
            fetchLoading: false,
            showLowongan: false,
            title_page: '',
            title: '',
            department: '',
            min_pendidikan: '',
            id_internal_area: '',
            deskripsi: '',
            gaji1: '',
            gaji2: '',
            id_job_vacancy: ''
        }
    }

    openAddModal() {
        this.setState({addModal: true})
    }
    openEditModal() {
        this.setState({editModal: true})
    }

    closeModalAdd(){
        this.setState({addModal: false})
    }
    closeModalEdit(){
        this.setState({editModal: false, deskripsi: ''})
    }

    onChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            deskripsi: newContent
        })
    }

    handleChange(e){
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    componentDidMount() {
        this.getDataLowongan();
        this.getDataArea();
        this.getJobDesk();
    }

    editLowongan = (row) => {
        this.openEditModal();
        this.setState({
            title: row.title,
            department: row.department,
            min_pendidikan: row.min_pendidikan,
            id_internal_area: row.id_internal_area,
            deskripsi: row.deskripsi,
            gaji1: row.gaji_1,
            gaji2: row.gaji_2,
            id_job_vacancy: row.id_job_vacancy
        });
    }

    changeStatusLowongan = (row) => {
        axios.post(API_URL.superStudent + '/niec-career/change-status', row, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}` 
            }
        })
        .then(res => {
            this.getDataLowongan()
            toast.success(`Status Lowongan Updated`)
        })
        .catch(err => console.log(err))
    }

    saveLowongan(method){
        this.setState({btnLoading: true})
        const {title, department, min_pendidikan, id_internal_area, deskripsi, gaji1, gaji2, id_job_vacancy} = this.state;

        let formData = new FormData();
        let msg;
        if (method === 'update') {
            formData.append('id_job_vacancy', id_job_vacancy);
            msg = 'Updated';
        } else {
            msg = 'Added';
        }

        let slug_title = 
            title.toLowerCase()
            .trim()
            .replace(/[^\w\s-]/g, '')
            .replace(/[\s_-]+/g, '-')
            .replace(/^-+|-+$/g, '');

        formData.append('title', title)
        formData.append('slug_title', slug_title)
        formData.append('department', department)
        formData.append('min_pendidikan', min_pendidikan)
        formData.append('id_internal_area', id_internal_area)
        formData.append('deskripsi', deskripsi)
        formData.append('gaji_1', gaji1)
        formData.append('gaji_2', gaji2)

        axios.post(API_URL.superStudent + '/niec-career/add', formData, {
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        }).then(res => {
            this.getDataLowongan();
            toast.success(`Data ${msg} Successfully`)
            this.setState({btnLoading: false})
            if (method === 'update') {
                this.closeModalEdit();
            } else {
                this.closeModalAdd();
            }
        }).catch(err => console.log(err))
    }

    getDataArea = () => {
        axios.get(API_URL.superStudent+'/master/area',{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({ dataArea: response.data.data}))
        .catch(error => console.log(error))
    }

    getJobDesk(){
        axios.get(API_URL.superStudent + '/consultant/list-jobdesk')
        .then(response => this.setState({ list_jobdesk: response.data.data}))
        .catch(error => console.log(error))
    }

    getDataLowongan(){
        this.setState({fetchLoading: true})
          axios.get(API_URL.superStudent + '/niec-career', {
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            }
          })
          .then(response => response.data)
          .then(result => {
            this.setState({ listLowongan: result, fetchLoading: false, showLowongan: true})
          })
          .catch(error => console.log(error))
    }

    renderAddModal(){
        return(
            <Modal isOpen={this.state.addModal} toggle={() => this.closeModalAdd()} size='lg'>
                <ModalHeader toggle={() => this.closeModalAdd()}>Add Lowongan</ModalHeader>
                <ModalBody>
                    <form>  
                        <div class="form-group">
                            <label htmlFor="title">Judul Pekerjaan</label>
                            <input autoFocus type="text" name='title' onChange={(e) => this.handleChange(e)} class="form-control" id="title" placeholder='contoh: Business Development Specialist' />
                        </div>
                        <div class="form-group">
                            <label htmlFor="department">Departemen/Divisi</label>
                            <select name='department' className='form-control' id='department' onChange={(e) => this.handleChange(e)}>
                                <option value="">Pilih Departemen</option>
                            {
                                this.state.list_jobdesk.map((m, i)=>{
                                   return (
                                    <option value={m.jobdesk}>{m.jobdesk}</option>
                                   ) 
                                })
                            }
                            </select>
                        </div>
                        <div class="form-group">
                            <label htmlFor="pendidikan">Pendidikan Minimal</label>
                            <select name='min_pendidikan' className='form-control' id='pendidikan' onChange={(e) => this.handleChange(e)}>
                                <option value="">Pilih Jenjang</option>
                                <option value="SMA/SMK">SMA/SMK</option>
                                <option value="S1">S1</option>
                                <option value="S2">S2</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label htmlFor="wilayah">Untuk Wilayah</label>
                            <select name='id_internal_area' className='form-control' id='wilayah' onChange={(e) => this.handleChange(e)}>
                                <option value="">Pilih Area/Wilayah</option>
                            {
                                this.state.dataArea.map((area)=>{
                                    return (<option key={area.id_area} value={area.id_area}>{area.area}</option>)
                                })
                            }
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor='deskripsi'>Deskripsi</label>
                                <CKEditors
                                    activeclassName="p10"
                                    content={this.state.deskripsi}
                                    events={{
                                            "change": this.onChange.bind(this),
                                    }}
                                    config={{
                                    toolbar:[
                                                ["Bold","Italic",'Strike','RemoveFormat'],
                                                ['NumberedList','BulletedList','Indent','Outdent'],
                                                ['Link','Unlink'],
                                                ['Styles','Format','Table','Blockquote','Maximize'],
                                                ["Cut","Copy","Paste","Pasteasplaintext","Undo","Redo"]
                                            ]
                                        }}     
                                />
                        </div>
                        <div className="form-group">
                            <label htmlFor="gaji">Range Gaji/Upah</label>
                            <div className="row">
                                <div className="col-5">
                                    <div className="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Rp.</span>
                                        </div>
                                        <input name='gaji1' type="number" class="form-control" placeholder="contoh: 1.000.000" onChange={(e) => this.handleChange(e)}/>
                                    </div>
                                </div>
                                <div className="col-2 text-center">
                                    <p className='f-w-600'>-</p>
                                </div>
                                <div className="col-5">
                                    <div className="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Rp.</span>
                                        </div>
                                        <input name='gaji2' type="number" class="form-control" placeholder="contoh: 2.000.000" onChange={(e) => this.handleChange(e)}/>
                                    </div>   
                                </div>
                            </div>
                        </div>

                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnLoading && true} color="primary" onClick={() => this.saveLowongan()}>Add Lowongan</Button>
                    <Button color="secondary" onClick={() => this.closeModalAdd()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
    renderEditModal(){
        const {title, department, min_pendidikan, id_internal_area, deskripsi, gaji1, gaji2, list_jobdesk} = this.state;
        return(
            <Modal isOpen={this.state.editModal} toggle={() => this.closeModalEdit()} size='lg'>
                <ModalHeader toggle={() => this.closeModalEdit()}>Edit Lowongan</ModalHeader>
                <ModalBody>
                    <form>  
                        <div class="form-group">
                            <label htmlFor="title">Judul Pekerjaan</label>
                            <input autoFocus type="text" value={title} name='title' onChange={(e) => this.handleChange(e)} class="form-control" id="title" placeholder='contoh: Business Development Specialist' />
                        </div>
                        <div class="form-group">
                            <label htmlFor="department">Departemen/Divisi</label>
                            <select name='department' value={department} className='form-control' id='department' onChange={(e) => this.handleChange(e)}>
                                <option value="">Pilih Departemen</option>
                            {
                                list_jobdesk.map((m, i)=>{
                                   return (
                                    <option value={m.jobdesk}>{m.jobdesk}</option>
                                   ) 
                                })
                            }
                            </select>
                        </div>
                        <div class="form-group">
                            <label htmlFor="pendidikan">Pendidikan Minimal</label>
                            <select name='min_pendidikan' value={min_pendidikan} className='form-control' id='pendidikan' onChange={(e) => this.handleChange(e)}>
                                <option value="">Pilih Jenjang</option>
                                <option value="SMA/SMK">SMA/SMK</option>
                                <option value="S1">S1</option>
                                <option value="S2">S2</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label htmlFor="wilayah">Untuk Wilayah</label>
                            <select name='id_internal_area' value={id_internal_area} className='form-control' id='wilayah' onChange={(e) => this.handleChange(e)}>
                                <option value="">Pilih Area/Wilayah</option>
                            {
                                this.state.dataArea.map((area)=>{
                                    return (<option key={area.id_area} value={area.id_area}>{area.area}</option>)
                                })
                            }
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor='deskripsi'>Deskripsi</label>
                                <CKEditors
                                    activeclassName="p10"
                                    content={deskripsi}
                                    events={{
                                            "change": this.onChange.bind(this),
                                    }}
                                    config={{
                                    toolbar:[
                                                ["Bold","Italic",'Strike','RemoveFormat'],
                                                ['NumberedList','BulletedList','Indent','Outdent'],
                                                ['Link','Unlink'],
                                                ['Styles','Format','Table','Blockquote','Maximize'],
                                                ["Cut","Copy","Paste","Pasteasplaintext","Undo","Redo"]
                                            ]
                                        }}     
                                />
                        </div>
                        <div className="form-group">
                            <label htmlFor="gaji">Range Gaji/Upah</label>
                            <div className="row">
                                <div className="col-5">
                                    <div className="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Rp.</span>
                                        </div>
                                        <input name='gaji1' value={gaji1} type="number" class="form-control" placeholder="contoh: 1.000.000" onChange={(e) => this.handleChange(e)}/>
                                    </div>
                                </div>
                                <div className="col-2 text-center">
                                    <p className='f-w-600'>-</p>
                                </div>
                                <div className="col-5">
                                    <div className="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Rp.</span>
                                        </div>
                                        <input name='gaji2' value={gaji2} type="number" class="form-control" placeholder="contoh: 2.000.000" onChange={(e) => this.handleChange(e)}/>
                                    </div>   
                                </div>
                            </div>
                        </div>

                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnLoading && true} color="primary" onClick={() => this.saveLowongan('update')}>Update Lowongan</Button>
                    <Button color="secondary" onClick={() => this.closeModalEdit()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {
        const {listLowongan, dataArea, showLowongan, fetchLoading,} = this.state;
        const pgsize = listLowongan.length;
        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                {
                                    fetchLoading && 
                                    (
                                    <div className="loader-box" style={{margin: 'auto'}}>
                                        <div className="loader">
                                            <div className="line bg-primary"></div>
                                            <div className="line bg-primary"></div>
                                            <div className="line bg-primary"></div>
                                            <div className="line bg-primary"></div>
                                        </div>
                                    </div>
                                    )
                                }
                                {
                                showLowongan && (
                                <div className="card-body datatable-react">
                                    <button onClick={() => this.openAddModal()} className='btn btn-success mb-4'>Add Lowongan +</button>
                                    <DatatableLowongan 
                                        myData={listLowongan}
                                        area={dataArea}
                                        editLowongan={this.editLowongan}
                                        changeStatusLowongan={this.changeStatusLowongan}
                                        showKandidat={this.showKandidat}
                                        title="Consultant Data" 
                                        pageSize={pgsize}
                                        pagination={true}
                                        class="-striped -highlight"
                                    />
                                </div>
                                )
                                }                                
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderAddModal()}
                {this.renderEditModal()}
            </Fragment>
        );
    }
};

const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
    dataId: state.Login.detailUser.id_users,
  })

const reduxDispatch = (dispatch) => ({
    resettoken: (data, headers) => dispatch(resettoken(data, headers))
   })
  
export default connect(reduxState, reduxDispatch)(withRouter(DataTablesLowongan));