import React, { Component } from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import DataVoucher from './data-voucher';

class SimulasiIELTSHome extends Component{

    constructor(props){
        super(props)
        this.state = {
            startDate: Moment().startOf('year').add(-6,'months'),
            endDate: Moment().endOf('year').add(-6,'months'),
            dataIncome:[]
        }
    }

    render(){
        return(
            <>
            <div className="tab-content active default" id="tab-1">
                <div className="row m-1">
                    <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                   
                                </div>
                                <div className="card-body">
                                    <DataVoucher/>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            </>
        );
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(SimulasiIELTSHome);