import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import ReactTable from 'react-table';
import API_URL from '../../../../ApiUrl';
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
import { saveAs } from "file-saver";
import { toast } from 'react-toastify';
import Moment from 'moment'
import RequiredTag from '../../../../components/dashboard/partition/component/required-tag';
import { formatRupiah } from '../../../../helper/format-rupiah';

class PaymentStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataPaymentData: [],
      dataJenisPembayaran: [],
      dataStatusPembayaran: [],
      dataVendorTranslate: [],
      dataEmployee: [],
      code_vendor_translate: '',
      harga_vendor: 0,
      harga_member: 0,
      harga_non_member: 0,
      dataMataUang: [],
      codeStudents: '',
      modalDetail: false,
      modalPreview: false,
      modal: false,
      modalDelete: false,
      operation: '',
      btnDisabled: false,
      textSave: 'Save',
      code_pembayaran: '',
      jenis_pembayaran: '',
      status_pembayaran: 'PM00001',
      code_mata_uang: 'IDR',
      jenis_course: '',
      status_course: '',
      nominal: '',
      total_rupiah: '',
      notes: '',
      bukti_pembayaran: '',
      jumlah_lembar: '',
      tanggal: Moment().format('YYYY-MM-DD'),
      rate: 1,
      formTag: [{ code_konsultan: '', code_jobdesk: '', date_input: Moment().format('YYYY-MM-DD') }],
    }
  }

  componentDidMount() {
    this.getJenisPembayaran();
    this.getStatusPembayaran();
    this.getCurrency();
    this.getVendorTranslate();
  }

  async getJenisPembayaran() {
    await axios.get(API_URL.superStudent + '/master/jenis-pembayaran', {
      headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
    })
      .then(response => response.data.data)
      .then(result => this.setState({ dataJenisPembayaran: result }))
  }

  async getStatusPembayaran() {
    await axios.get(API_URL.superStudent + '/master/status-pembayaran', {
      headers: {
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    }).then(response => response.data.data)
      .then(result => this.setState({ dataStatusPembayaran: result }))
  }

  async getVendorTranslate() {
    await axios.get(API_URL.superStudent + '/master/vendor-translate', {
      headers: {
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    }).then(response => response.data.data)
      .then(result => this.setState({ dataVendorTranslate: result }))
  }

  async getCurrency() {
    const url = API_URL.superStudent + '/master/mata-uang';
    await axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
      .then(response => response.data)
      .then((data) => {
        this.setState({ dataMataUang: data.data })
      })
  }


  async addPayment() {
    this.resetState()
    this.setState({
      modal: true,
      btnDisabled: false,
      textSave: 'Save',
    });
  }

  editPayment(row) {
    this.setState({
      code_pembayaran: row.code_pembayaran,
      jenis_pembayaran: row.code_jenis_pembayaran,
      code_mata_uang: row.code_mata_uang,
      nominal: row.nominal,
      notes: row.notes,
      rate: row.rate,
      status_pembayaran: row.code_status_pembayaran,
      bukti_pembayaran: row.bukti_pembayaran,
      tanggal: row.created,
      operation: 'edit',
      title: "Edit Payment",
      modal: true,
      btnDisabled: false,
      textSave: 'Save',
    });
  }

  deleteRow(row) {
    this.setState({
      modalDelete: true,
      code_pembayaran: row.code_pembayaran,
      btnDisabled: false,
      textSave: 'Save',
    });
  }

  closeModal() {
    this.setState({
      modal: false,
      modalDelete: false,
      modalDetail: false
    });
    this.resetState();
  }


  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleChangeTag = (index, event) => {
    const { name, value } = event.target;
    const formTag = [...this.state.formTag];
    formTag[index][name] = value;
    this.setState({ formTag });
  }


  addFieldTag = () => {
    this.setState((prevState) => ({
      formTag: [...prevState.formTag, { code_konsultan: '', date_input: Moment().format('YYYY-MM-DD') }],
    }));
  }

  removeFieldTag = (index) => {
    this.setState((prevState) => ({
      formTag: prevState.formTag.filter((_, i) => i !== index),
    }));
  }

  handleFile = (e) => {
    this.setState({
      bukti_pembayaran: e.target.files[0]
    })
  }

  handleVendorTranslate = (e) => {
    var filter = this.state.dataVendorTranslate.filter(function (data) {
      return data.code_vendor_translate == e.target.value;
    });
    this.setState({
      harga_vendor: filter[0].harga,
      harga_member: filter[0].harga_member,
      harga_non_member: filter[0].harga_non_member,
      code_vendor_translate: filter[0].code_vendor_translate
    })
  }

  resetState() {
    this.setState({
      code_pembayaran: '',
      jenis_pembayaran: '',
      status_pembayaran: 'PM00001',
      code_mata_uang: 'IDR',
      nominal: '',
      notes: '',
      tanggal: Moment().format('YYYY-MM-DD'),
      rate: '1',
      jumlah_lembar: '',
      bukti_pembayaran: null,
    });
  }

  sendData() {
    this.setState({
      btnDisabled: true,
      textSave: 'Saving...'
    })
    let nominal = this.state.nominal;
    let total_rupiah = 0;
    let rate = this.state.rate
    let code_mata_uang = this.state.code_mata_uang

    var formData = new FormData();

    let formTag = [];

    if (this.state.code_mata_uang === 'IDR') {
      total_rupiah = this.state.nominal
      rate = 1
    }
    else {
      total_rupiah = this.state.nominal * this.state.rate
    }

    if (this.state.jenis_pembayaran == 'JPB002') {
      formData.append('code_vendor', this.state.code_vendor_translate);
      let rating = this.props.profile.code_member != null ? this.state.harga_member : this.state.harga_non_member
      nominal = rating * this.state.jumlah_lembar
      total_rupiah = nominal - (this.state.harga_vendor * this.state.jumlah_lembar)
      rate = 1
      code_mata_uang = 'IDR'
    }
    else if (this.state.jenis_pembayaran == 'JPB010' || this.state.jenis_pembayaran == 'JPB011') {
      rate = 1
      code_mata_uang = 'IDR'
      nominal = 300000
      total_rupiah = 300000
    }

    if (this.state.jenis_pembayaran == 'JPB010' && this.props.profile.code_status != 'ST015') {
      formTag.push({ tagable_id: this.props.profile.consultant.id_area, tagable_type: 'App\\Models\\Area', nominal: nominal * rate, date_input: Moment(this.state.tanggal).format('YYYY-MM-DD') })
      formTag.push({
        tagable_id: this.props.profile.consultant.id_konsultan,
        tagable_type: 'App\\Models\\Consultant',
        nominal: total_rupiah,
        date_input: Moment(this.state.tanggal).format('YYYY-MM-DD')
      });
    }

    if (
      (this.state.jenis_pembayaran == 'JPB007' && this.props.profile.code_status == 'ST001') ||
      this.state.jenis_pembayaran == 'JPB002' ||
      this.state.jenis_pembayaran == 'JPB011'
    ) {
      formTag.push({ tagable_id: this.props.profile.consultant.id_area, tagable_type: 'App\\Models\\Area', nominal: nominal * rate, date_input: Moment(this.state.tanggal).format('YYYY-MM-DD') })
      formTag.push({
        tagable_id: this.props.profile.consultant.id_konsultan,
        tagable_type: 'App\\Models\\Consultant',
        nominal: total_rupiah,
        date_input: Moment(this.state.tanggal).format('YYYY-MM-DD')
      });
    } else if (this.props.profile.code_status == 'ST014' && this.state.jenis_pembayaran == 'JPB005') {
      total_rupiah = (this.state.nominal * this.state.rate) - 1900000;
      formTag.push({ tagable_id: this.props.profile.consultant.id_konsultan, tagable_type: 'App\\Models\\Consultant', nominal: total_rupiah, date_input: Moment(this.state.tanggal).format('YYYY-MM-DD') })
      formTag.push({ tagable_id: this.props.profile.consultant.id_area, tagable_type: 'App\\Models\\Area', nominal: nominal * rate, date_input: Moment(this.state.tanggal).format('YYYY-MM-DD') })

    }
    else if ((this.state.jenis_pembayaran == 'JPB007' && this.props.profile.code_status == 'ST015' && this.state.status_pembayaran == 'PM00003') || this.state.jenis_pembayaran == 'JPB013' ||
      this.state.jenis_pembayaran == 'JPB015') {
      formTag.push({ tagable_id: this.props.profile.consultant.id_area, tagable_type: 'App\\Models\\Area', nominal: nominal * rate, date_input: Moment(this.state.tanggal).format('YYYY-MM-DD') })
    }
    else if (this.state.jenis_pembayaran == 'JPB007' && this.props.profile.code_status == 'ST015' && this.state.status_pembayaran == 'PM00004') {
      formTag.push({ tagable_id: this.props.profile.consultant.id_area, tagable_type: 'App\\Models\\Area', nominal: nominal * rate, date_input: Moment(this.state.tanggal).format('YYYY-MM-DD') })
      formTag.push({
        tagable_id: this.props.profile.consultant.id_konsultan,
        tagable_type: 'App\\Models\\Consultant',
        nominal: total_rupiah,
        date_input: Moment(this.state.tanggal).format('YYYY-MM-DD')
      });
    }

    formData.append('code_students', this.props.codeStudents);
    formData.append('jenis_pembayaran', this.state.jenis_pembayaran);
    formData.append('status_pembayaran', this.state.status_pembayaran);
    formData.append('code_mata_uang', code_mata_uang);
    formData.append('nominal', nominal);
    formData.append('notes', this.state.notes);
    formData.append('bukti_pembayaran', this.state.bukti_pembayaran)
    formData.append('rate', rate)
    formData.append('tanggal', this.state.tanggal)
    formData.append('formTag', JSON.stringify(formTag))

    let url = '/students-payment';

    if (this.state.operation == 'edit') {
      formData.append('_method', 'PATCH');
      url = '/students-payment/' + this.state.code_pembayaran
    }

    axios.post(API_URL.superStudentV2 + url, formData, {
      headers: {
        Authorization: `Bearer ${this.props.dataToken}`,
        'Content-Type': 'multipart/form-data',
      }
    })
      .then(() => {
        toast.success('Payment Updated')
        this.closeModal();
        this.resetState()
        this.props.onReloadPayment();
        this.props.onReloadProfil();
        this.setState({
          btnDisabled: false,
          textSave: 'Save'
        })
      })
      .catch(error => console.log(error))
  }

  formatRupiah = (nominal) => {
    return new Intl.NumberFormat().format(nominal);
  }

  saveDeleteRow() {
    this.setState({
      btnDisabled: true
    });

    axios.delete(API_URL.superStudent + '/students-payment/' + this.state.code_pembayaran, {
      headers: { 'Authorization': `Bearer ${this.props.dataToken}` },
    })
      .then(() => {
        // window.location.reload(false);
        toast.success('Payment Deleted')
        this.props.onReloadPayment();
        this.setState({
          btnDisabled: false
        });
        this.closeModal();

      })
      .catch(error => console.log(error))
  }

  downloadFile = (url) => {
    saveAs(url, url.substring(url.lastIndexOf('/')));
  }


  renderEditDialog() {
    return (
      <Modal className="modal-dialog" isOpen={this.state.modal} toggle={() => this.closeModal()} size="lg">
        <ModalHeader toggle={() => this.closeModal()}>Form Payment</ModalHeader>
        <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
          <form className="needs-validation row" noValidate="" >
            <div className="col-md-6 mb-3">
              <label className='control-label' htmlFor="jenis_pembayaran">Jenis Pembayaran<RequiredTag /></label>
              <select className="form-control" name="jenis_pembayaran" type="text" value={this.state.jenis_pembayaran}
                onChange={this.handleChange} >
                <option value="0">--Pilih Jenis Pembayaran--</option>
                {this.state.dataJenisPembayaran.map((data1) => (
                  <option key={data1.code_jenis_pembayaran} value={data1.code_jenis_pembayaran}>{data1.jenis_pembayaran}</option>
                ))}
              </select>
            </div>
            {
              this.state.jenis_pembayaran === 'JPB007' && this.props.profile.code_status === 'ST015' ?
                <div className="col-md-6 mb-3">
                  <label className='control-label' htmlFor="status_pembayaran">Kategori Pembayaran<RequiredTag /> </label>
                  <select className="form-control" name="status_pembayaran" type="text" value={this.state.status_pembayaran} onChange={this.handleChange} >
                    <option value="0">--Pilih Kategori--</option>
                    {this.state.dataStatusPembayaran.map((data2, i) => (
                      <option key={i} value={data2.code_status_pembayaran}>{data2.status_pembayaran}</option>
                    ))}
                  </select>
                </div>
                :
                ''
            }
            {
              this.state.jenis_pembayaran === 'JPB002' || this.state.jenis_pembayaran === 'JPB011' || this.state.jenis_pembayaran === 'JPB010' ?
                <></>
                :
                <div className="col-md-6 mb-3">
                  <label className='control-label' htmlFor="code_mata_uang">Mata Uang<RequiredTag /></label>
                  <select className="form-control" name="code_mata_uang" type="text" value={this.state.code_mata_uang}
                    onChange={this.handleChange} >
                    <option value="0">--Pilih Mata Uang--</option>
                    {this.state.dataMataUang.map((data2, i) => (
                      <option key={i} value={data2.code_mata_uang}>{data2.code_mata_uang}</option>
                    ))}
                  </select>
                </div>

            }
            {
              this.state.code_mata_uang === 'IDR' ?
                <></>
                :
                <div className="col-md-6 mb-3">
                  <label className='control-label' htmlFor="nominal">Rate<RequiredTag /></label>
                  <input className="form-control" name="rate" type="number" value={this.state.rate}
                    onChange={this.handleChange} />
                </div>
            }
            {
              this.state.jenis_pembayaran === 'JPB002' ?
                <>
                  <div className='col-md-6 mb-3'>
                    <label className='control-label'>Vendor Translate<RequiredTag /></label>
                    <select onChange={this.handleVendorTranslate} className='form-control'>
                      <option value="0">--Pilih Vendor Translate--</option>
                      {this.state.dataVendorTranslate.map((data, i) => (
                        <option key={i} value={data.code_vendor_translate}>{data.name_vendor_translate}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className='control-label' htmlFor="jumlah_lembar">Jumlah Lembar Translate<RequiredTag /></label>
                    <input className="form-control" name="jumlah_lembar" type="text" value={this.state.jumlah_lembar}
                      onChange={this.handleChange} />
                  </div>
                </>
                :
                this.state.jenis_pembayaran === 'JPB010' || this.state.jenis_pembayaran === 'JPB011' ?
                  ''
                  :
                  <div className="col-md-6 mb-3">
                    <label className='control-label' htmlFor="nominal">Nominal<RequiredTag /></label>
                    <input className="form-control" name="nominal" type="number" value={this.state.nominal}
                      onChange={this.handleChange} />
                  </div>
            }
            <div className="col-md-6 mb-3">
              <label className='control-label' htmlFor="notes">Notes</label>
              <textarea className='form-control' name='notes' value={this.state.notes} onChange={this.handleChange} />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="notes">Bukti Pembayaran</label>
              <input type='file' className='form-control' name='bukti_pembayaran' accept='image/*' onChange={this.handleFile} />
              {
                this.state.operation == 'edit' && this.state.bukti_pembayaran != null ?
                  <img style={{ width: '200px' }} src={this.state.bukti_pembayaran} alt='bukti_pembayaran' className='img-fluid' />
                  :
                  ''
              }
            </div>
            <div className='col-md-6 col-mb-3'>
              <label htmlFor="notes">Tanggal<RequiredTag /></label>
              <input type='date' className='form-control' name='tanggal' onChange={this.handleChange} value={this.state.tanggal} />
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>{this.state.textSave}</Button>
          <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderDeleteDialog() {
    return (
      <Modal className="modal-dialog" isOpen={this.state.modalDelete} toggle={() => this.closeModal()} size="lg">
        <ModalHeader toggle={() => this.closeModal()}>Delete Data</ModalHeader>
        <ModalBody style={{
          maxHeight: 'calc(100vh - 210px)',
          overflowY: 'auto'
        }}>
          Delete this data ?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => this.saveDeleteRow()}>Save Changes</Button>
          <Button color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }


  render() {
    const data = this.props.data
    const columns = [
      {
        Header: 'Code Payment',
        accessor: 'code_pembayaran',
        style: { textAlign: 'center' },
        filterable: false
      },
      {
        Header: 'Type',
        accessor: 'jenis_pembayaran.jenis_pembayaran',
        style: { textAlign: 'center' },
        filterable: false,
        Cell: (row) => (
          <div>
            {row.original.jenis_pembayaran.jenis_pembayaran}
            <br></br>
            <span style={{ fontSize: '8px' }} className='badge bg-dark'>{row.original.code_jenis_pembayaran === 'JPB002' && row.original.vendor !== null ? row.original.vendor.name_vendor_translate : ''}</span>
          </div>
        )
      },
      {
        Header: 'Nominal',
        accessor: 'nominal',
        style: { textAlign: 'center' },
        filterable: false,
        Cell: (row) => (
          <div>
            {row.original.code_mata_uang} {this.formatRupiah(row.original.nominal)}
            <br></br>
            <span style={{ fontSize: '8px' }} className='badge bg-dark'>{row.original.code_mata_uang !== 'IDR' ? 'rate: ' + this.formatRupiah(row.original.rate) : ''}</span>
          </div>
        )
      },
      {
        Header: 'Status/Kategori',
        accessor: 'status_pembayaran.status_pembayaran',
        style: { textAlign: 'center' },
        filterable: false
      },
      {
        Header: 'Date Input',
        accessor: 'created',
        style: { textAlign: 'center' },
        filterable: false,
        Cell: (row) => (
          <>{Moment(row.original.created).format('DD MMM YYYY')}</>
        )
      },
      {
        Header: <b>Action</b>,
        id: 'delete',
        // width:90,
        accessor: str => "delete",
        Cell: (row) => (
          <div className='row justify-content-center'>
            {
              row.original.code_jenis_pembayaran == "JPB013" ?
                this.props.dataUser.code_roles == "RL005" ?
                  <div className='row justify-content-center'>
                    {
                      row.original.bukti_pembayaran !== null ?
                        <div className='col-md-3'>
                          <button onClick={() => this.downloadFile(row.original.bukti_pembayaran)} className="btn btn-primary btn-xs" type="button" style={{ border: 'none', display: this.props.display }}><i className="fa fa-download"></i></button>
                        </div>
                        :
                        ''
                    }
                    {/* <div className='col-md-3'>
                      <button onClick={() => this.editPayment(row.original)} className="btn btn-info btn-xs" type="button" style={{ border: 'none', display: this.props.display }}><i className="fa fa-pencil"></i></button>
                    </div> */}
                    <div className='col-md-3'>
                      <button onClick={() => this.deleteRow(row.original)} className="btn btn-danger btn-xs" type="button" style={{ border: 'none', display: this.props.display }}><i className="fa fa-trash"></i></button>
                    </div>
                  </div>
                  :
                  ""
                :
                <div className='row -justify-content-center'>
                  {
                    row.original.bukti_pembayaran !== null ?
                      <div className='col-md-3'>
                        <button onClick={() => this.downloadFile(row.original.bukti_pembayaran)} className="btn btn-primary btn-xs" type="button" style={{ border: 'none', display: this.props.display }}><i className="fa fa-download"></i></button>
                      </div>
                      :
                      ''
                  }
                  {
                    row.original.code_jenis_pembayaran == 'JPB001' || row.original.code_jenis_pembayaran == 'JPB012' ?
                      <></> : <>
                        {/* <div className='col-md-3'>
                        <button onClick={() => this.editPayment(row.original)} className="btn btn-info btn-xs" type="button" style={{ border: 'none', display: this.props.display }}><i className="fa fa-pencil"></i></button>
                      </div> */}
                        <div className='col-md-3'>
                          <button onClick={() => this.deleteRow(row.original)} className="btn btn-danger btn-xs" type="button" style={{ border: 'none', display: this.props.display }}><i className="fa fa-trash"></i></button>
                        </div></>
                  }
                </div>
            }
          </div>
        ),
        style: {
          textAlign: 'center'
        },
        sortable: false,
        filterable: false
      }
    ]

    const sub_columns = [
      {
        Header: 'Tag',
        accessor: 'id',
        style: { textAlign: 'center' },
        filterable: false,
        Cell: (row) => (
          <span className='badge bg-dark'>{row.original.tagable_type == 'App\\Models\\Area' ? row.original.tagable.area : row.original.tagable.nama}</span>
        )
      },
      {
        Header: 'Nominal',
        accessor: 'nominal',
        style: { textAlign: 'center' },
        filterable: false,
        Cell: (row) => (
          <>{formatRupiah(row.original.nominal)}</>
        )
      },
      {
        Header: 'Tanggal Payment',
        accessor: 'date_input',
        style: { textAlign: 'center' },
        filterable: false,
        Cell: (row) => (
          <>{Moment(row.original.date_input).format('DD MMM YYYY')}</>
        )
      },
    ];
    return (
      <Fragment>
        <div>
          <div className="card scroll">
            <div className="card-header bg-red-color">
              <div className="row">
                <div className="col-10">
                  <h5 style={{ fontSize: 15 }}>Payment </h5>
                </div>
                <div className="col-2">
                  <button onClick={() => this.addPayment()} className="btn btn-light btn-xs" type="button" style={{ marginLeft: 'auto', border: 'none', display: this.props.display }} ><i className="fa fa-plus color-red"></i></button>
                </div>
              </div>
            </div>
            <div>
              <div className="row">
                <div className="col-lg-12">
                  <ReactTable
                    data={data}
                    columns={columns}
                    defaultPageSize={15}
                    className="-striped -highlight"
                    showPaginationBottom={false}
                    style={{
                      height: "300px"
                    }}
                    SubComponent={row => {
                      return (
                        <div style={{ padding: "20px" }}>
                          <div className='mb-2'>
                            <strong>Notes: </strong>{row.original.notes}
                          </div>
                          <ReactTable
                            data={row.original.payment_tag}
                            columns={sub_columns}
                            className="-striped -highlight"
                            showPaginationBottom={false}
                          />
                        </div>
                      )
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderEditDialog()}
        {this.renderDeleteDialog()}
      </Fragment>
    );
  }
}

// export default Students;
const reduxState = (state) => ({
  loginStatus: state.Login.lgnsts,
  dataUser: state.Login.detailUser,
  codeUsers: state.Login.detailUser.code_users,
  dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(withRouter(PaymentStudent));
