import React, { Fragment, Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URL from "../../ApiUrl";
import axios from "axios";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import Loader from "../../components/common/loader";
import Breadcrumb from "../../components/common/breadcrumb";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Moment from "moment";
import CKEditors from "react-ckeditor-component";

class Artikel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      title: "",
      operation: "",
      logName: "",
      errors: {},
      modal: false,
      modalDelete: false,
      btnDisabled: false,
      dataArtikel: [],
      dataArtikelKategori: [],
      id_artikel: "",
      code_artikel_kategori: "",
      judul: "",
      sub_judul: "",
      isi: "",
      image: "",
      filename: "",
      author: "",
      is_publish: true,
    };
  }

  componentDidMount() {
    this.getArtikel();
    this.getArtikelKategori();
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeIsi(evt) {
    var newContent = evt.editor.getData();
    this.setState({
      isi: newContent,
    });
  }

  onChangeFileName = (e) => {
    this.setState({
      image: e.target.files[0],
      filename: e.target.value,
    });
  };

  getArtikelKategori() {
    const url = API_URL.superStudent + "/website/artikel-kategori";
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.dataToken}`,
        },
      })
      .then((response) => response.data.data)
      .then((data) => {
        this.setState({ dataArtikelKategori: data });
      });
  }

  getArtikel() {
    const url = API_URL.superStudent + "/website/artikel";
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.dataToken}`,
        },
      })
      .then((response) => response.data.data)
      .then((data) => {
        this.setState({ dataArtikel: data });
      });
  }

  addRow() {
    this.setState({
      modal: true,
      title: "Add Artikel Data",
      operation: "add",
      logName: "ADD-ARTIKEL",
      code_artikel_kategori: "",
      judul: "",
      sub_judul: "",
      isi: "",
      image: "",
      author: "",
      is_publish: true,
    });
  }

  editRow(row) {
    this.setState({
      modal: true,
      title: "Edit Artikel Data",
      operation: "edit",
      logName: "EDIT-ARTIKEL",
      code_artikel_kategori: row.code_artikel_kategori,
      is_publish: row.is_publish,
      id_artikel: row.id_artikel,
      judul: row.judul,
      sub_judul: row.sub_judul,
      isi: row.isi,
      author: row.author,
      dataRelatedArtikel: row.related,
    });
  }

  deleteRow(row) {
    this.setState({
      modalDelete: true,
      title: "Delete Artikel Data",
      operation: "delete",
      logName: "DELETE-ARTIKEL",
      id_artikel: row.id_artikel,
    });
  }

  sendData() {
    this.setState({
      btnDisabled: true,
    });

    let datetime = Moment(Date()).format("DDMMYYYY");

    let fileExtension = "";
    let newFileName = "";
    let nama = "";

    if (this.state.image.name != undefined) {
      fileExtension = this.state.image.name.split(".").pop();
      newFileName = "artikel" + "_" + datetime + "." + fileExtension;
      nama = "artikel" + "_" + datetime;
    }

    var formData = new FormData();
    formData.append("code_artikel_kategori", this.state.code_artikel_kategori);
    formData.append("judul", this.state.judul);
    formData.append("sub_judul", this.state.sub_judul);
    formData.append("isi", this.state.isi);
    formData.append("image", nama);
    formData.append("is_publish", this.state.is_publish ? "1" : "0");
    formData.append("author", this.props.dataUser.nama_lengkap);

    if (this.state.image.name != undefined) {
      formData.append("image", this.state.image, newFileName);
    }

    let url = "/website/artikel";

    if (this.state.operation == "edit") {
      formData.append("_method", "PATCH");
      url = "/website/artikel/" + this.state.id_artikel;
    } else if (this.state.operation == "delete") {
      formData.append("_method", "DELETE");
      url = "/website/artikel/" + this.state.id_artikel;
    }

    axios
      .post(API_URL.superStudent + url, formData, {
        headers: {
          Authorization: `Bearer ${this.props.dataToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((reply) => {
        if (reply.data.status == "Success") {
          this.closeDialog();
          this.getArtikel();
          this.setState({ btnDisabled: false });
          if (this.state.operation == "add") {
            toast.success("Save Sucsessfully");
          } else {
            toast.success("Update Sucsessfully");
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 422 || err.response.status === 409) {
          this.setState({
            errors: err.response.data.data,
            btnDisabled: false,
          });
        }
      });
  }

  closeDialog() {
    this.setState({
      modal: false,
      modalDelete: false,
    });
  }

  renderDialog() {
    const { title, modal } = this.state;

    return (
      <div className="card-body btn-showcase">
        <Modal
          className="modal-dialog"
          size="lg"
          isOpen={modal}
          toggle={() => this.setState({ modal: false })}
        >
          <ModalHeader toggle={() => this.setState({ modal: false })}>
            {title}
          </ModalHeader>
          <ModalBody>
            <form className="needs-validation" noValidate="">
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label>Publish</label>
                      <select
                        className="form-control"
                        name="is_publish"
                        value={this.state.is_publish ? "1" : "0"}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          // Ubah nilai menjadi boolean
                          const isPublishValue = value === "1";
                          this.setState({
                            [name]: isPublishValue,
                          });
                        }}
                      >
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>
                  </div>
                  <label htmlFor="name">Kategori</label>
                  <select
                    className="form-control"
                    name="code_artikel_kategori"
                    value={this.state.code_artikel_kategori}
                    onChange={(e) => this.handleInput(e)}
                  >
                    <option>--Choose Category--</option>
                    {this.state.dataArtikelKategori.map((kategori) => (
                      <option value={kategori.code_artikel_kategori}>
                        {kategori.kategori}
                      </option>
                    ))}
                  </select>
                  <span>{this.state.errors.judul}</span>
                  <div className="valid-feedback">Looks good!</div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="name">Judul</label>
                  <input
                    className="form-control"
                    name="judul"
                    type="text"
                    id="judul"
                    onChange={(e) => this.handleInput(e)}
                    value={this.state.judul}
                  />
                  <span>{this.state.errors.judul}</span>
                  <div className="valid-feedback">Looks good!</div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label>Sub Judul</label>
                  <input
                    className="form-control"
                    name="sub_judul"
                    type="text"
                    id="sub_judul"
                    onChange={(e) => this.handleInput(e)}
                    value={this.state.sub_judul}
                  />
                  <span>{this.state.errors.sub_judul}</span>
                  <div className="valid-feedback">Looks good!</div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label>Isi</label>
                  <CKEditors
                    activeclassName="p10"
                    content={this.state.isi}
                    events={{ change: this.onChangeIsi.bind(this) }}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label>Image</label>
                  <input
                    className="form-control"
                    name="image"
                    type="file"
                    id="image"
                    onChange={(e) => this.onChangeFileName(e)}
                  />
                  <span>{this.state.errors.image}</span>
                  <div className="valid-feedback"></div>
                </div>
              </div>
            </form>
            <br />
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={this.state.btnDisabled}
              color="primary"
              onClick={(e) => this.sendData()}
            >
              Save Changes
            </Button>
            <Button
              disabled={this.state.btnDisabled}
              color="secondary"
              onClick={() => this.closeDialog()}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  renderDeleteDialog() {
    const { title, modalDelete } = this.state;

    return (
      <Modal
        isOpen={modalDelete}
        toggle={() => this.setState({ modalDelete: false })}
      >
        <ModalHeader toggle={() => this.setState({ modalDelete: false })}>
          {title}
        </ModalHeader>
        <ModalBody>Delete this data ?</ModalBody>
        <ModalFooter>
          <Button
            disabled={this.state.btnDisabled}
            color="primary"
            onClick={() => this.sendData()}
          >
            Save Changes
          </Button>
          <Button
            disabled={this.state.btnDisabled}
            color="secondary"
            onClick={() => this.closeDialog()}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    const columns = [
      {
        Header: "Kategori",
        accessor: "kategori.kategori",
        filterable: false,
      },
      {
        Header: "Judul",
        accessor: "judul",
        filterable: false,
      },
      {
        Header: "Sub Judul",
        accessor: "sub_judul",
        filterable: false,
      },
      {
        Header: "Image",
        accessor: "image",
        filterable: false,
        Cell: (row) => (
          <div>
            <img src={row.original.image} className="img-fluid" />
          </div>
        ),
      },
      {
        Header: "Author",
        accessor: "author",
        filterable: false,
      },
      {
        Header: "Status",
        accessor: "is_publish",
        filterable: false,
        Cell: (row) => <div>{row.value ? "Published" : "Not Published"}</div>,
      },
      {
        Header: "Date",
        accessor: "created_at",
        filterable: false,
        Cell: (row) => (
          <div>{Moment(row.original.created_at).format("DD MMMM YYYY")}</div>
        ),
      },
      {
        Header: <b>Action</b>,
        id: "delete",
        accessor: (str) => "delete",
        Cell: (row) => (
          <div>
            <button
              onClick={() => this.editRow(row.original)}
              className="btn btn-outline-primary btn-xs"
              type="button"
              style={{ border: "none" }}
            >
              <i
                className="fa fa-pencil"
                style={{
                  width: 35,
                  fontSize: 16,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                }}
              ></i>
              {row.id}
            </button>
            <button
              onClick={() => this.deleteRow(row.original)}
              className="btn btn-outline-primary btn-xs"
              type="button"
              style={{ border: "none" }}
            >
              <i
                className="fa fa-trash"
                style={{
                  width: 35,
                  fontSize: 16,
                  padding: 11,
                  color: "#e4566e",
                }}
              ></i>
            </button>
          </div>
        ),
        style: {
          textAlign: "center",
        },
        sortable: false,
        filterable: false,
      },
    ];

    return (
      <Fragment>
        <div className="page-wrapper">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <button
                      onClick={() => this.addRow()}
                      className="btn btn-outline-primary btn-xs"
                      type="button"
                      style={{ border: "none" }}
                    >
                      <span>
                        <i
                          className="fa fa-plus"
                          style={{
                            width: 35,
                            fontSize: 20,
                            padding: 8,
                            color: "rgb(40, 167, 69)",
                          }}
                        ></i>
                      </span>
                    </button>
                    <br />
                    {this.state.dataArtikel.length > 0 ? (
                      <ReactTable
                        data={this.state.dataArtikel}
                        columns={columns}
                        filterable
                        defaultFilterMethod={(filter, row) =>
                          String(row[filter.id]) === filter.value
                        }
                        defaultPageSize={100}
                        className="-striped -highlight"
                        SubComponent={(row) => {
                          const data = row.original;
                          return (
                            <div className="row mt-3 p-3">
                              <div className="col-lg-6">
                                <label>Isi :</label>
                                <br />
                                <div
                                  dangerouslySetInnerHTML={{ __html: data.isi }}
                                />
                              </div>
                            </div>
                          );
                        }}
                      />
                    ) : (
                      <Loader showLoading={this.state.showLoading} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderDialog()}
        {this.renderDeleteDialog()}
      </Fragment>
    );
  }
}

const reduxState = (state) => ({
  dataToken: state.Login.lgnInitialize,
  dataUser: state.Login.detailUser,
  codeUsers: state.Login.detailUser.code_users,
  codeRoles: state.Login.detailUser.code_roles,
});

export default connect(reduxState)(withRouter(Artikel));
