import React, { Component, Fragment } from 'react';
import Countup from 'react-countup';
import { 
    Row, Col, Nav, NavItem, NavLink, TabContent,
    TabPane, Card, Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import ReactTable from 'react-table';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { ExportCSV } from '../../../services/ExportCSV';
import "react-datepicker/dist/react-datepicker.css";
import ReporRegisterByCity from './event-expo-detail';
import Swal from 'sweetalert2';


class RegisterEventExpo extends Component {

    constructor(props){
        super(props)
        this.state = {
            registrasi_data: [],
            event_id:0,
            event_data:[],
            register_city_all:[],
            register_city_member:[],
            register_city_invitation:[],
            register_city_general:[],
            data_member: [],
            data_invitation: [],
            data_general: [],
            list_consultants: [],
            potensial_detail: [],
            city: 'medan',
            currentActiveTab:"1",
            activeTab:"1",
            modalSet: false,
            showAction: this.props.dataUser.code_roles === 'RL023' ? '' : this.props.dataUser.code_roles === 'RL001' ? '' : this.props.dataUser.code_roles === 'RL003' ? '' : 'none',
            showActionBroadcast: this.props.dataUser.code_roles === 'RL001' ? '' : 'none',
        }
    }

    componentDidMount(){
        this.getDataRegisterEvent();
        this.getDataEvent();
        this.loadConsultant();
    }

    getDataEvent = () => {
        const url = API_URL.superStudent+'/event/jenis-event/3';
        axios.get(url, { headers: 
            { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            this.setState({
                event_data:data.data
            });
        });
    }

    getDataRegisterEvent = () => {
        axios.get(API_URL.eventUrl+'load-data/'+this.state.event_id+'/3', {
            headers:{
                'Authorization': `Bearer ${API_URL.eventToken}`,
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({ 
            registrasi_data: result
        }, () => {
            this.getDataByCity(result, this.state.city)
        }))
        .catch(error => console.log(error))
    }

    getDataByCity(data, city) {
        this.setState({
            register_city_all: []
        })

        data.map(item => {
            if (item.university[0].jadwal == city) {
                this.setState(prevState => ({
                    register_city_all: [
                        ...prevState.register_city_all, 
                        item
                    ]
                }))
            }
        })
    }

    loadConsultant = () => {
        axios.get(API_URL.superStudent + '/master/consultant/list-priotas', {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({
                list_consultants: result
            })
        })
        .catch(error => {
            console.log(error)
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        },() => {
            this.getDataRegisterEvent()
        })
    }

    handleChangeSet = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    setTab(tab) {
        this.setState({ currentActiveTab: tab });
    }

    renderModalSet = () => {
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={this.state.modalSet} toggle={() => this.setState({ modalSet: false })}>
                    <ModalHeader toggle={() => this.setState({ modalSet: false })}>Set Handle</ModalHeader>
                    <ModalBody>
                        <div className='form-group'>
                            <select onChange={this.handleChangeSet} name='consultant' className='form-control' value={this.state.consultant}>
                                <option value=''>Pilih Consultant</option>
                                {
                                    this.state.list_consultants.map(function (consultan) {
                                        return (<option key={consultan.id} value={consultan.code_konsultan}>{consultan.name}</option>)
                                    })
                                }
                            </select>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveData()}>Set</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.setState({ modalSet: false })}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

    saveData() {
        let formData = new FormData()
        formData.append('id_register_rep', this.state.potensial_detail.id_register)
        formData.append('code_konsultan', this.state.consultant)

        axios.post(API_URL.superStudent+'/event/set-consultant-register', formData, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => {
            Swal.fire({
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500
            })
            this.getDataRegisterEvent()
            this.setState({ modalSet: false })
        })
        .catch(error => {
            console.log(error)
        })
    }

    broadcastMail(e) {
        let formData = new FormData()

        formData.append('member', JSON.stringify(e))

        axios.post(API_URL.eventUrl+'world-education-festival/broadcast-mail-event', formData, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${API_URL.eventToken}`
            }
        })
        .then(response => {
            console.log(response.data)
        })
        .catch(error => console.log(error))
    }

    certivateMail(e) {
        let formData = new FormData()

        formData.append('member', JSON.stringify(e))

        axios.post(API_URL.eventUrl+'world-education-festival/certivicate-mail-event', formData, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${API_URL.eventToken}`
            }
        })
        .then(response => {
            console.log(response.data)
        })
        .catch(error => console.log(error))
    }
    
    render(){
        const columns = [
            {
                Header: 'Kode Regristasi',
                accessor: 'register_code',
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: 'Nama Lengkap',
                accessor: 'name',
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: 'Email',
                accessor: 'email',
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: 'Telepon/WA',
                accessor: 'phone',
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: 'Provinsi',
                accessor: 'province_name',
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: 'Kabupaten',
                accessor: 'kabupaten_name',
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: 'Consultant',
                accessor: 'consultant',
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: 'Handle By',
                accessor: 'handle_by',
                style: { textAlign: 'center' },
                Cell: (row) => (
                    <button className='btn btn-success btn-sm btn-successs' onClick={() => this.setState({ modalSet: true, potensial_detail: row.original })}>Set</button>
                ),
                filterable: false
            }
        ];

        let member = [];
        let invitation = [];
        let general = [];

        let checkin = [];
        let attandance = [];

        let total_medan = [];
        let total_tangerang = [];
        let total_makassar = [];
        let total_yogya = [];
        let total_singarja = [];
        let total_denpasar = [];
        let total_online = [];

        this.state.register_city_all.map(item => {
            switch (item.university[0].kategori) {
                case 'member':
                    member.push(item)
                break;
                case 'invitation':
                    invitation.push(item)
                break;
                case 'general':
                    general.push(item)
                break;
            
                default:
                    break;
            }
        })

        this.state.registrasi_data.map(item2 => {
            switch (item2.university[0].jadwal) {
                case 'medan':
                    total_medan.push(item2)
                    break;
                case 'tangerang':
                    total_tangerang.push(item2)
                    break;
                case 'makassar':
                    total_makassar.push(item2)
                    break;
                case 'yogyakarta':
                    total_yogya.push(item2)
                    break;
                case 'singaraja':
                    total_singarja.push(item2)
                    break;
                case 'denpasar':
                    total_denpasar.push(item2)
                    break;
                case 'online':
                    total_online.push(item2)
                    break;
            
                default:
                    break;
            }
        })

        this.state.register_city_all.map(item3 => {
            switch (item3.attandance) {
                case 1:
                    checkin.push(item3)
                    break;
                case 2:
                    attandance.push(item3)
                    break;
            
                default:
                    break;
            }
        })

        const tab = this.state.currentActiveTab;
        const Activetab = this.state.currentActiveTab;

        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row m-1">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="text-left">
                                                <h5 className="mb-1">Total Registration : <span className="badge badge-primary"><Countup end={this.state.registrasi_data.length} /></span> </h5>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="text-right">
                                                    <select className="form-control" onChange={this.handleChange} name="event_id" value={this.state.event_id}>
                                                        <option value='0'> --Filter Peserta-- </option>
                                                        {
                                                        this.state.event_data.map(event => {
                                                            return <option value={event.id} key={event.id}>{event.name}</option>
                                                        })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-1 justify-content-center">
                            <div className="col-md-12">
                                {
                                    this.state.register_city_all.length > 0 ?
                                    <ReporRegisterByCity 
                                        medan={total_medan} 
                                        tangerang={total_tangerang} 
                                        makassar={total_makassar} 
                                        yogya={total_yogya} 
                                        singaraja={total_singarja}
                                        denpasar={total_denpasar}
                                        online={total_online}
                                        member={member} 
                                        invitation={invitation} 
                                        general={general} 
                                    />
                                    :
                                    <ReporRegisterByCity 
                                        medan={total_medan} 
                                        tangerang={total_tangerang} 
                                        makassar={total_makassar} 
                                        yogya={total_yogya} 
                                        singaraja={total_singarja}
                                        denpasar={total_denpasar}
                                        online={total_online}
                                        member={member} 
                                        invitation={invitation} 
                                        general={general} 
                                    />
                                }
                            </div>
                        </div>
                        <div className="row m-1 justify-content-center mb-5">
                            <div className="col-md-3">
                                <select className="form-control" onChange={this.handleChange} name="city" value={this.state.city}>
                                    <option value='medan'>Medan</option>
                                    <option value='tangerang'>Tangerang</option>
                                    <option value='makassar'>Makassar</option>
                                    <option value='yogyakarta'>Yogyakarta</option>
                                    <option value='singaraja'>Singaraja</option>
                                    <option value='denpasar'>Denpasar</option>
                                    <option value='online'>Online</option>
                                </select>
                            </div>
                        </div>
                        <div className="row m-1 justify-content-center">
                            <div className="col-xl-3 col-sm-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h5 className="mb-0" >Total Member</h5>
                                        </div>
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={member.length} />
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h5 className="mb-0" >Total Invitation </h5>
                                        </div>
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={invitation.length} />
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h5 className="mb-0" >Total General </h5>
                                        </div>
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={general.length} />
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-1 justify-content-center">
                            <div className="col-xl-3 col-sm-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h5 className="mb-0" >Total Checkin </h5>
                                        </div>
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={checkin.length} />
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h5 className="mb-0" >Total Attandance </h5>
                                        </div>
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={attandance.length} />
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-1 justify-content-center">
                            <div className="col-xl-12 col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                        <Nav justified pills className='mb-5'>
                                            <NavItem>
                                                <NavLink className={Activetab === "1" ? 'active' : ''} onClick={() => this.setTab("1")}>All Register</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={Activetab === "2" ? 'active' : ''} onClick={() => this.setTab("2")}>Member</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={Activetab === "3" ? 'active' : ''} onClick={() => this.setTab("3")}>Invitation</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={Activetab === "4" ? 'active' : ''} onClick={() => this.setTab("4")}>General</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={Activetab === "5" ? 'active' : ''} onClick={() => this.setTab("5")}>Checkin</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={Activetab === "6" ? 'active' : ''} onClick={() => this.setTab("6")}>Attandance</NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent activeTab={tab}>
                                            <TabPane tabId="1">
                                                <Row>
                                                    <Col sm="12">
                                                        <Card body>
                                                            <Row>
                                                                <Col lg='6'>
                                                                    <div style={{display: this.state.showAction}}>
                                                                        <ExportCSV csvData={this.state.register_city_all} fileName={"data-register-edufest"} />
                                                                    </div>
                                                                </Col>
                                                                <Col lg='6' style={{display: this.state.showActionBroadcast}}>
                                                                    <button className='btn btn-primary pull-right' onClick={() => this.broadcastMail(this.state.register_city_all)}>Broadcast</button>
                                                                </Col>
                                                            </Row>
                                                            <br/>
                                                            <ReactTable
                                                                data={this.state.register_city_all}
                                                                columns={columns}
                                                                defaultPageSize={10}
                                                                className="-striped -highlight"
                                                                showPaginationBottom={true}
                                                            />
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <Row>
                                                    <Col sm="12">
                                                        <Card body>
                                                            <ReactTable
                                                                data={member}
                                                                columns={columns}
                                                                defaultPageSize={10}
                                                                className="-striped -highlight"
                                                                showPaginationBottom={true}
                                                            />
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="3">
                                                <Row>
                                                    <Col sm="12">
                                                        <Card body>
                                                            <ReactTable
                                                                data={invitation}
                                                                columns={columns}
                                                                defaultPageSize={10}
                                                                className="-striped -highlight"
                                                                showPaginationBottom={true}
                                                            />
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="4">
                                                <Row>
                                                    <Col sm="12">
                                                        <Card body>
                                                            <ReactTable
                                                                data={general}
                                                                columns={columns}
                                                                defaultPageSize={10}
                                                                className="-striped -highlight"
                                                                showPaginationBottom={true}
                                                            />
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="5">
                                                <Row>
                                                    <Col sm="12">
                                                        <Card body>
                                                            <Row style={{display: this.state.showActionBroadcast}}>
                                                                <Col lg='12'>
                                                                    <button className='btn btn-primary pull-right' onClick={() => this.certivateMail(checkin)}>Certivicate</button>
                                                                </Col>
                                                            </Row>
                                                            <br/>
                                                            <ReactTable
                                                                data={checkin}
                                                                columns={columns}
                                                                defaultPageSize={10}
                                                                className="-striped -highlight"
                                                                showPaginationBottom={true}
                                                            />
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="6">
                                                <Row>
                                                    <Col sm="12">
                                                        <Card body>
                                                            <Row style={{display: this.state.showActionBroadcast}}>
                                                                <Col lg='12'>
                                                                    <button className='btn btn-primary pull-right' onClick={() => this.certivateMail(attandance)}>Certivicate</button>
                                                                </Col>
                                                            </Row>
                                                            <br/>
                                                            <ReactTable
                                                                data={attandance}
                                                                columns={columns}
                                                                defaultPageSize={10}
                                                                className="-striped -highlight"
                                                                showPaginationBottom={true}
                                                            />
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-1">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row mb-1">
                                            <div style={{display: this.state.showAction}}  className="col-sm-6">
                                                <ExportCSV csvData={this.state.registrasi_data} fileName={"data-global-youth"} />
                                            </div>
                                        </div>
                                        <ReactTable
                                            data={this.state.registrasi_data}
                                            columns={columns}
                                            defaultPageSize={25}
                                            className="-striped -highlight"
                                            showPaginationBottom={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModalSet()}
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState, null) (RegisterEventExpo);