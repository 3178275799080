import React, { Fragment } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Home, List } from 'react-feather';
import { connect } from 'react-redux';

import { Switch, Route, Redirect } from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';

const MasterEvent = () => {
    return (
        <Fragment>
            <Breadcrumb title="NIEC Event" />
            <div className="container-fluid">
                <div className="row theme-tab">
                    <div className="col-sm-12">
                        <RoutedTabs>
                            <div className="tabs tab-title">
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/events/master-event/list`}>
                                    <Home /> Master Event
                                </NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/events/master-event/order`}>
                                    <List /> Order Event
                                </NavTab>
                            </div>
                        </RoutedTabs>
                        <Switch>
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/pages/events/master-event`}
                                render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/events/master-event/list`} />}
                            />
                            <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event/order`} />
                        </Switch>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState, null)(MasterEvent);