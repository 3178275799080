import React, { Fragment, Component } from 'react';
import DatatableMembershipApproval from '../common/datatable/datatableMembershipApproval';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import API_URL from '../../ApiUrl';
import axios from 'axios';

class DataTablesMemberApproval extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listStudentMember: [],
            showLoading: false
        }
    }

    componentDidMount() {
        this.getMemberApproval();
    }

    getMemberApproval = async () => {
        this.setState({ showLoading: true })
        const url = API_URL.superStudent+'/members/non-active';
        await axios.get(url, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } })
        .then(response => response.data.data)
        .then(result => {
            this.setState({listStudentMember:result, showLoading:false});
        })
        .catch(error => console.log(error))
    }

    renderLoading() {
        return (
          <Fragment>
                <div className={`loader-wrapper2 ${this.state.showLoading ? '' : 'loderhide2'}`} >
                    <div className="loader-box">
                        <div className="loader">
                            <div className="line bg-primary"></div>
                            <div className="line bg-primary"></div>
                            <div className="line bg-primary"></div>
                            <div className="line bg-primary"></div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

    render() {
        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body datatable-react">
                                    <DatatableMembershipApproval myData={this.state.listStudentMember} title="Users Data" />
                                    {this.renderLoading()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

// export default DataTableUsers;

const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
  })

  
export default connect(reduxState, null)(withRouter(DataTablesMemberApproval));