import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { DollarSign, Settings } from 'react-feather';
// import { Tabs as ReactTabs, TabList as ReactTablist } from 'react-tabs';
import { connect } from 'react-redux';
import { withRouter } from "react-router";

import { Switch, Route, Redirect} from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';

import "./styles/react-router-tabs.css";

const Campus = (dataUser) => {
    const roles = dataUser.dataUser.code_roles
    return (
        <Fragment>
            <Breadcrumb title="Campus" parent="Campus" />
        </Fragment>
    );
}

// export default Students;
const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
})
  
export default connect(reduxState, null)(withRouter(Campus));