import React ,{useEffect, useState} from 'react';
import { useSelector } from "react-redux";
import API_URL from '../../../ApiUrl';
import axios from 'axios'
import CardCountDetail from './component/card-count-detail';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import SweetAlert from 'react-bootstrap-sweetalert'

const CardTotal = ({start_date, end_date}) => {

    const[dataDinamic,setDataDinamic] = useState({ granted:0, j_one:0, graduate: 0, tourist:0, rejected:0, school:0, contract:0, mapping:0, course:0 })
    const[modal,setModal] = useState(false)
    const[detail,setDetail] = useState([])
    const[loader,setLoader] = useState(false)

    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    }));


    useEffect(() => {
        loadDataDinamics()
    },[start_date, end_date])


    const loadDataDinamics = () => {
        let formData = new FormData();
        formData.append('start_date', start_date)
        formData.append('end_date', end_date)
        formData.append('granted', 'true')
        
        axios.post(API_URL.superStudent+'/recap-home-dinamis', formData, {
            headers: { 'Authorization': `Bearer ${dataToken}` } 
        })
        .then(response => response.data.data)
        .then(result => {
            setDataDinamic({ 
                granted: result.granted, 
                j_one: result.j_one, 
                graduate: result.graduate, 
                tourist: result.tourist, 
             })
        })
        .catch(error => {
            console.log(error)
        })
    }

    const handleDetail = (type, code) => {
        setLoader(true)
        let formData = new FormData()
        formData.append('type', type)
        formData.append('code_status',code)
        formData.append('granted', 'true')
        formData.append('start_date', start_date)
        formData.append('end_date', end_date)

        axios.post(API_URL.superStudent+'/detail-home', formData,{
            headers: { 'Authorization': `Bearer ${dataToken}` } 
        })
        .then(response => {
            setLoader(false)
            setModal(true)
            setDetail(response.data.data)
        })
        .catch(error => console.log(error))
        
    }

    const modalDetail = () => {
        return (
            <Modal className="modal-dialog" isOpen={modal} toggle={() => setModal(false)}>
            <ModalHeader toggle={() => setModal(false)}>Detail Data</ModalHeader>
            <ModalBody>
                <div className='row'>
                    <div className='col-md-12'>
                        <ul style={{fontSize:'11px'}}>
                        {
                            detail.length > 0 ?
                            detail.map((item,i) => (
                                <li key={i}>{i+1}. ({item.code}) {item.label}</li>
                            ))
                            :
                            'Data Kosong'
                        }
                        </ul>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => setModal(false)}>Close</Button>
            </ModalFooter>
        </Modal>
        );
    }

    return(
        <>
        <SweetAlert title="Loading..."   showConfirm={false} showCancel={false} show={loader} />

            <div onClick={() => handleDetail('progress','ST001')} className="col-xs-6 col-sm-4 col-lg-3">
                <CardCountDetail backgroundColor='#f89c12' icon='fa-id-card' label='Student Granted' amount={dataDinamic.granted} />
            </div>
            <div onClick={() => handleDetail('progress','ST015')} className="col-xs-6 col-sm-4 col-lg-3">
                <CardCountDetail backgroundColor='#ee4444' icon='fa-book' label='J1 Granted' amount={dataDinamic.j_one} />
            </div>
            <div onClick={() => handleDetail('progress','ST012')} className="col-xs-6 col-sm-4 col-lg-3">
                <CardCountDetail backgroundColor='#A5C9CA' icon='fa-address-card' label='Graduate Granted' amount={dataDinamic.graduate} />
            </div>
            <div onClick={() => handleDetail('progress','ST014')} className="col-xs-6 col-sm-4 col-lg-3">
                <CardCountDetail backgroundColor='#00aa80' icon='fa-window-maximize' label='Tourist Granted' amount={dataDinamic.tourist} />
            </div>
            {modalDetail()}
        </>
    )
}


export default CardTotal;