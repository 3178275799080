import { combineReducers } from 'redux';
// import TodoApp from './todos.reducer';
// import EmailApp from './email.reducer';
// import ChatApp from './chat.reducer';
// import EcommerceApp from './ecommerce.reducer';
// import WishlistApp from './wishlist.reducer';
// import Filters from './filters.reducer';
import Customizer from './customizer.reducer';
import Login from './login.reducer';
import Students from './students.reducer';
import Roles from './roles.reducer';
import Master from './master.reducer';
import Expo2021 from './expo2021.reducer.js'

const reducers = combineReducers({
    Customizer,
    Login,
    Roles,
    Students,
    Master,
    Expo2021
    
});

export default reducers;
