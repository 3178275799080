import React, { Fragment, Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { matchSorter } from 'match-sorter';
import { ExportCSV } from '../../../services/ExportCSV';
import Moment from 'moment';

class DatatableMembershipApproval extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deletingRow: null,
            approveRow: null,
            titleList: this.props.title,
            title: this.props.title,
            operation: '',
            toastId: null,
            modal:false,
            modalDelete:false,
            modalUpdate:false,
            modalApprove:false,
            btnDisabled: false,
            filteredData: [],
            searchInput: "",
            id_member:'',
            category_id:'',
            id_harga:'',
            code_konsultan:'',
            categories:[],
            harga:[],
            listConsultant:[],
        }
    }

  componentDidMount(){
    this.loadCategory()
    this.loadHarga()
    this.loadConsultant()
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  setGlobalSearch = (e) => { 
    const newData = e.target.value;
    this.setState(prevState => {
      let searchInput = newData; 
      return { searchInput };                   
      }, () => {
      this.globalSearch();
      })
  }

  globalSearch = () => {
          let { searchInput } = this.state;
          let filteredData = this.props.myData.filter(value => {
            return (
              value.nama_lengkap.toLowerCase().includes(searchInput.toLowerCase()) ||
              value.email.toString().toLowerCase().includes(searchInput.toLowerCase())
            );
          });
          this.setState({ filteredData });
  }

  loadCategory = () => {
      axios.get(API_URL.superStudent+'/master/categories',{
          headers: { 'Authorization': `Bearer ${this.props.dataToken}` },
      })
      .then(response => this.setState({ categories: response.data.data}))
      .catch(error => console.log(error))
  }

  loadHarga = () => {
      axios.get(API_URL.superStudent+'/promo-member/active', {
        headers: { 'Authorization': `Bearer ${this.props.dataToken}` },
      })
      .then(response => this.setState({ harga: response.data.data }))
  }

  
  loadConsultant = () => {
    axios.get(API_URL.superStudent+'/master/consultant/list-active', {
      headers: { 'Authorization': `Bearer ${this.props.dataToken}` },
    })
    .then(response => this.setState({ listConsultant: response.data.data }))
  }

    approveMember(row){
      this.setState({
          approveRow: row,
          title: 'Approve Data',
          modalApprove: true
        });
    }
    
      editRow(row) {
        this.setState({
          id_member: row.id_member,
          category_id: row.potensial,
          id_harga: row.id_harga,
          modalUpdate: true
        });
      }

      deleteRow(row) {
        this.setState({
          deletingRow: row,
          title: 'Delete User Data',
          modalDelete: true
        });
      }

      closeDialog(){
        this.setState({
          modal: false,
          modalDelete: false,
          modalUpdate:false,
          modalApprove: false
        });
      }

      saveDeleteRow() {
        this.setState({
          btnDisabled: true
        });
            let data = this.state.deletingRow;
            var formData = new FormData();
            formData.append('id_member',data.id_member);            
            axios.post(API_URL.superStudent+'/members/delete-member',formData, 
              { headers: { 'Authorization': `Bearer ${this.props.dataToken}` },
          }).then((reply)=>{
              toast.success('Members has been deleted')
              window.location.reload(false);
            }).catch(error => console.log(error) );
      }
      
      saveApproveRow() {
          this.setState({
            btnDisabled:true
          })
          let data = this.state.approveRow;
          var formData = new FormData();
          formData.append('id_member',data.id_member);
          axios.post(API_URL.superStudent+'/members/manual-approved',formData, { 
              headers: { 'Authorization': `Bearer ${this.props.dataToken}` },
          })
          .then(() => {
                toast.success('Membership Approved')
                window.location.reload(false);
          })
          .catch(error => console.log(error))
      }

      saveUpdateRow(){
        var formData = new FormData();
        formData.append('id_member',this.state.id_member)
        formData.append('potensial', this.state.category_id)
        formData.append('id_harga', this.state.id_harga)
        formData.append('code_konsultan', this.state.code_konsultan)
        axios.post(API_URL.superStudent+'/members/update-member',formData, { 
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` },
        })
        .then(() => {
              toast.success('Membership Updated')
              window.location.reload(false);
        })
        .catch(error => console.log(error))
      }

      renderDeleteDialog() {
        const { title } = this.state;
        const modal2 = this.state.modalDelete;
          return (
            <Modal isOpen={modal2} toggle={() => this.setState({ modalDelete: false })}>
              <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{title}</ModalHeader>
              <ModalBody>
                Delete this data ?
              </ModalBody>
              <ModalFooter>
                <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveDeleteRow()}>Save Changes</Button>
                <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
              </ModalFooter>
            </Modal>
        )
      }

      renderEditDialog() {
          return (
            <Modal isOpen={this.state.modalUpdate} toggle={() => this.setState({ modalUpdate: false })}>
              <ModalHeader toggle={() => this.setState({ modalUpdate: false })}>Update Data</ModalHeader>
              <ModalBody>
                  <form>
                      <div className='form-group'>
                          <label className='f-w-600'>Status Potensial</label>
                          <select onChange={this.handleInput} className='form-control' name='category_id' value={this.state.category_id}>
                              <option> --Select Category-- </option>
                              {
                                this.state.categories.length > 0 ?
                                this.state.categories.map(category => (
                                  <option key={category.id_students_category} value={category.code_students_category}>{category.category}</option>
                                ))
                                :
                                ''
                              }
                          </select>
                      </div>
                      <div className='form-group'>
                          <label className='f-w-600'>harga</label>
                          <select onChange={this.handleInput} className='form-control' name='id_harga' value={this.state.id_harga}>
                              <option> --Select Price-- </option>
                              {
                                this.state.harga.length > 0 ?
                                this.state.harga.map(item => (
                                  <option key={item.id_harga} value={item.id_harga}>{item.nama} ({this.formatRupiah(item.harga)})</option>
                                ))
                                :
                                ''
                              }
                          </select>
                      </div>
                      <div className='form-group'>
                          <label className='f-w-600'>Konsultan</label>
                          <select onChange={this.handleInput} className='form-control' name='code_konsultan' value={this.state.code_konsultan}>
                              <option> --Select Consultant-- </option>
                              {
                                this.state.listConsultant.length > 0 ?
                                this.state.listConsultant.map(item => (
                                  <option key={item.code} value={item.code}>{item.name}</option>
                                ))
                                :
                                ''
                              }
                          </select>
                      </div>
                  </form>
              </ModalBody>
              <ModalFooter>
                <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveUpdateRow()}>Save Changes</Button>
                <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
              </ModalFooter>
            </Modal>
        )
      }
      
      renderApproveDialog() {
        const { title } = this.state;
        const modal2 = this.state.modalApprove;
          return (
            <Modal isOpen={modal2} toggle={() => this.setState({ modalApprove: false })}>
              <ModalHeader toggle={() => this.setState({ modalApprove: false })}>{title}</ModalHeader>
              <ModalBody>
                Yakin Untuk approve ?
              </ModalBody>
              <ModalFooter>
                <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveApproveRow()}>Save Changes</Button>
                <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
              </ModalFooter>
            </Modal>
        )
      }

      formatRupiah = (nominal) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        }).format(nominal);
    }

    render() {
      const { myData } = this.props;
      const columns = [
        {
          Header: 'Name',
          accessor: 'nama_lengkap',
          style: {textAlign: 'left'},
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["nama_lengkap"] }),
            filterAll: true
        },
        {
          Header: 'Email',
          accessor: 'email',
          style: {textAlign: 'left'},
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["email"] }),
            filterAll: true
        },
        {
          Header: 'Consultant',
          accessor: 'consultant.nama',
          style: {textAlign: 'center'}, 
          filterable: false
        },
        {
          Header: 'Status',
          accessor: 'category.category',
          style: {textAlign: 'center'},
          filterable: false
        },
        {
          Header: 'Harga',
          accessor: 'harga.harga',
          style: {textAlign: 'center'},
          filterable: false,
          Cell:(row) => (
            <>{this.formatRupiah(row.original.harga.harga)}</>
          )
        },
        {
          id:'created',
          Header: 'Tanggal Registrasi',
          accessor: a => {
            return Moment(a.created)
            .local()
            .format("DD/MM/YYYY")
          },
          style: {textAlign: 'center'},
        },
        {
          Header: <b>Action</b>,
          width:120,
          id: 'delete',
          accessor: str => "delete",
          Cell: (row) => (
            <div className='row justify-content-center'>
              <div className='col-md-3'>
                <button  onClick={() => this.editRow(row.original)} className="btn btn-info btn-xs"  ><i className='fa fa-pencil'></i></button>
              </div>
             <div className='col-md-6' style={{display: this.props.dataUser.code_roles === 'RL005' || this.props.dataUser.code_roles === 'RL003' ? '' : 'none'}}>                     
                <button onClick={() => this.approveMember(row.original)} className="btn btn-success btn-xs" type="button"  ><i className="fa fa-check"></i>{row.id_member}</button> &nbsp;
                <button onClick={() => this.deleteRow(row.original)} className="btn btn-danger btn-xs" type="button"><i className="fa fa-times"></i></button>
              </div>
            </div>
          ),
          style: {
              textAlign: 'center'
          },
          sortable: false,
          filterable: false
      }
      ]
       
        const csvData = this.props.myData;

        return (
            <Fragment>
              <div className="row">
                <div className="col-md-6">

                </div>
                <div className="col-md-6">
                    <div className="card-header">
                      <div className="search-form">
                        <div className="form-group m-0">
                            <input
                                className="form-control" type="search"
                                placeholder="search"
                                size="large"
                                name="searchInput"
                                value={this.state.searchInput || ""}
                                onChange={(e) => this.setGlobalSearch(e)}
                                label="Search"
                              />
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                      <div className="text-left">
                        <ExportCSV csvData={csvData} fileName={"data-membership-approval"} />
                      </div>
                      <br/>
                      <ReactTable
                        data={this.state.filteredData && this.state.filteredData.length ? this.state.filteredData : myData}
                        columns={columns}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        SubComponent={row => {
                          const data = row.original;
                          return (
                            // START - Penampilan Detail data pada setiap row table
                            <div style={{ padding: "20px" }}>
                              <div style={{paddingLeft:"10px", fontStyle:"", color:"#A6A6A6"}}>
                                <div className="row">
                                  <div className="col-md-8">
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div align="right" style={{ color:"black" }}>No WA</div>
                                      </div>
                                      <div className="col-md-1">
                                        <div align="center">:</div>
                                      </div>
                                      <div className="col-md-7">
                                        <div align="left">{data.no_wa}</div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div align="right" style={{ color:"black" }}>Consultant</div>
                                      </div>
                                      <div className="col-md-1">
                                        <div align="center">:</div>
                                      </div>
                                      <div className="col-md-7">
                                        <div align="left">{data.consultant.nama}</div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div align="right" style={{ color:"black" }}>Provinsi</div>
                                      </div>
                                      <div className="col-md-1">
                                        <div align="center">:</div>
                                      </div>
                                      <div className="col-md-7">
                                        <div align="left">{data.province !== null ? data.province.nama_propinsi : ''} </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div align="right" style={{ color:"black" }}>Kab / Kota</div>
                                      </div>
                                      <div className="col-md-1">
                                        <div align="center">:</div>
                                      </div>
                                      <div className="col-md-7">
                                        <div align="left">{data.kabupaten !== null ? data.kabupaten.nama_kabkota : ''}</div>
                                      </div>
                                    </div>
                               
                                  </div>
                                </div>
                              </div>
                            </div>
                            // END - Penampilan Detail data pada setiap row table
                          );
                        }}
                      />
                </div>
              </div>
              <ToastContainer />
              {this.renderDeleteDialog()}
              {this.renderApproveDialog()}
              {this.renderEditDialog()}
            </Fragment>
        );
    }
};

// export default Datatable;

const reduxState = (state) => ({
  dataId: state.Login.detailUser.code_users,
  dataToken: state.Login.lgnInitialize,
  dataUser: state.Login.detailUser
})


export default connect(reduxState, null)(DatatableMembershipApproval);