import React from 'react'
import Breadcrumb from '../../components/common/breadcrumb'
import { Switch, Route, Redirect} from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';
import { List, FileText } from 'react-feather';

const Donate = () => {

    return(
        <>
            <div className='page-wrapper'>
                <div className="container-fluid p-0">
                    <Breadcrumb title="Donate" parent="Donate" />
                    <div className='container-fluid'>
                        <div className="row theme-tab">
                            <div className="col-md-12">
                                <RoutedTabs>
                                    <div className="tabs tab-title">
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/donate/data-donate`} >
                                            <FileText/> Data Donate
                                        </NavTab>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/donate/user-donate`}>
                                            <List/> User donate
                                        </NavTab>
                                    </div>
                                </RoutedTabs>
                                <Switch>
                                    <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/pages/donate`}
                                    render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/donate/data-donate`} />}
                                    />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/donate/data-donate`}/>
                                    <Route path={`${process.env.PUBLIC_URL}/pages/donate/user-donate`}/>
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
 
}

export default Donate;
