import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import ReactTable from 'react-table';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import Countup from 'react-countup';
import { ExportCSV } from '../../services/ExportCSV';
import Moment from 'moment';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import setRangeDate from '../../helper/general-function';

class GlobalAmbassadorDataComponent extends Component {    
    constructor(props) {
      super(props);
      this.state = {
        dataAmbassador: [],
        dataConsultant: [],
        no:0,
        category:'all',
        searchInput: '',
        filteredData: [],
        modalDelete: false,
        modalQuick: false,
        detailRow: '',
        id_ambassador: '',
        code_acc_manager: '',
        detail_account: [],
        date: '',
        show: false,
        staticFilter:'',
        dinamicFilter:'none',
        start_date: '',
        end_date: '',
      }
    }

    componentDidMount(){
      this.getAmbassador();
      this.getConsultant();
    }

    getConsultant(){
      const url = API_URL.superStudent+'/consultant/list-consultant';
      axios.get(url,{
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${this.props.dataToken}`
        }
      })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({ dataConsultant: data })
      })
    }

    handleInput = (e) => {
      this.setState({
        [e.target.name] : e.target.value
      })
    }

    getAmbassador(){
      const url = API_URL.superStudent+'/ambassadors?category='+this.state.category+'&start_date='+this.state.start_date+'&end_date='+this.state.end_date;
      axios.get(url, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${this.props.dataToken}`
        }
      })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({ dataAmbassador: data })
      })
    }

    getSerialNo(row){
      return row + 1;
    }

    setGlobalSearch = (e) => { 
      const newData = e.target.value;
      this.setState(prevState => {
      let searchInput = newData; 
        return { searchInput };                   
      }, () => {
        this.globalSearch();
      })
    }

    globalSearch = () => {
      let { searchInput } = this.state;
      let filteredData = this.state.dataAmbassador.filter(value => {
        return (
          value.code_ambassador.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.name.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
      this.setState({ filteredData });
    };

    closeModal() {
      this.setState({
        modalDelete: false,
        modalQuick: false,
      })
    }

    deleteRow(data) {
      this.setState({
        detailRow: data,
        modalDelete: true
      })
    }

    saveDeleteRow() {
      var formData = new FormData();
      formData.append('id', this.state.detailRow.id);
      formData.append('_method', 'DELETE');

      axios.post(API_URL.superStudent+'/ambassadors/'+this.state.detailRow.id, formData, {
        headers:{
          Authorization: `Bearer ${this.props.dataToken}`,
          'Content-Type': 'multipart/form-data',
        }
      })
      .then(response => {
        this.setState({
          btnText:'Save',
          btnDisabled:false
        });
        this.getAmbassador();
        this.closeModal();
        toast.success("Data Berhasil Di Hapus");
      })
      .catch(error => {
        this.setState({
          btnText:'Save',
          errors:error.response.data.data,
          btnDisabled:false
        });
      })
    }

    handleChange = (e) => {
      this.setState({
        [e.target.name]:e.target.value
      }, () => {
        if(this.state.date == 'all_time') {
          this.setState({
            start_date: '',
            end_date: ''
          })
        } else if(this.state.date === 'dinammic_date') {
          this.setState({
            dinamicFilter:'',
            staticFilter:'none'
          })
        } else {
          this.setDate()
        }
      })
    }

    setDate(){   
      let data = setRangeDate(this.state.date);     

      this.setState({ 
        start_date : data.start,
        end_date : data.end,
      })
    }

    handlePickerMonth = (e) => {
      this.setState({
        start_date: Moment(e).startOf('month').format('YYYY-MM-DD'),
        end_date: Moment(e).endOf('month').format('YYYY-MM-DD'),
      })
    }

    editRecomender(row) {
      this.setState({
        modalQuick: true,
        detail_account: row,
        id_ambassador: row.id,
        code_acc_manager: row.manager != null ? row.manager.code : ""
      })
    }

    saveQuickRow() {
      var formData = new FormData();
      formData.append('id_register', this.state.id_ambassador)
      formData.append('name', this.state.detail_account.name);
      formData.append('email', this.state.detail_account.email)
      formData.append('phone', this.state.detail_account.phone);
      formData.append('province', this.state.detail_account.province.id);
      formData.append('kabupaten', this.state.detail_account.kabupaten.id);
      formData.append('about', this.state.detail_account.about);
      formData.append('photo', this.state.detail_account.foto);
      formData.append('code_recommender', this.state.detail_account.recommender.code)
      formData.append('code_acc_manager', this.state.code_acc_manager)
      formData.append('is_students_ambassador', this.state.detail_account.is_students_ambassador)
      formData.append('no_rek', this.state.detail_account.no_rek);
      formData.append('bank_name', this.state.detail_account.bank_name);
      formData.append('account_name', this.state.detail_account.account_name);
      formData.append('_method','PATCH');

      axios.post(API_URL.superStudent+'/ambassadors/'+this.state.id_ambassador, formData, {
        headers:{
          Authorization: `Bearer ${this.props.dataToken}`,
          'Content-Type': 'multipart/form-data',
        }
      })
      .then(response => {
        this.setState({
          btnText:'Save',
          btnDisabled:false
        });
        this.getAmbassador();
        this.closeModal();
        toast.success("Data Berhasil Di Update");
      })
      .catch(error => {
        this.setState({
          btnText:'Save',
          errors:error.response.data.data,
          btnDisabled:false
        });
      })
    }

    renderUpdateConselor() {
      return (
        <Modal className="modal-dialog" isOpen={this.state.modalQuick} toggle={() => this.closeModal()} size="lg">
          <ModalHeader toggle={() => this.closeModal()}>Update Conselor</ModalHeader>
            <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
              <h4>Pilih Konsultan</h4>
              <select className='form-control' name='code_acc_manager' id='code_acc_manager' value={this.state.code_acc_manager} onChange={this.handleInput}>
                <option>-- Pilih Conselor --</option>
                {
                  this.state.dataConsultant.map((cons) => (
                    <option value={cons.code}>{cons.name}</option>
                  ))
                }
              </select>
            </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.saveQuickRow()}>Save Changes</Button>
            <Button color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
          </ModalFooter>
        </Modal>
      )
    }

    renderDeleteDialog() {
      return (
        <Modal className="modal-dialog" isOpen={this.state.modalDelete} toggle={() => this.closeModal()} size="lg">
          <ModalHeader toggle={() => this.closeModal()}>Delete Data</ModalHeader>
            <ModalBody style={{
                maxHeight: 'calc(100vh - 210px)',
                overflowY: 'auto'
            }}>
              Delete this data ?
            </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.saveDeleteRow()}>Save Changes</Button>
            <Button color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
          </ModalFooter>
        </Modal>
      )
    }

    render(){
        const data = this.state.dataAmbassador
        
        const columns = [
          {
            Header: 'Nama',
            accessor: 'name',
            style: {textAlign: 'center'},
          },
          {
            Header: 'Propinsi',
            accessor: 'province.province_name',
            style: {textAlign: 'center'},
            filterable: false
          },
          {
            Header: 'Recomender',
            accessor: 'recommender.name',
            style: {textAlign: 'center'},
            filterable: false,
          },
          {
            Header: 'Counsellor',
            accessor: 'manager.name',
            style: {textAlign: 'center'},
            filterable: false,
            Cell: (row) => (
              <div onClick={() => this.editRecomender(row.original)}>
                {row.original.manager != null ? row.original.manager.name : '""'}
              </div>
            )
          },
          {
            Header: 'Kategori',
            accessor: 'id_category',
            Cell: (row) => (
              <div>
                <p>{row.original.id_category == "1" ? 'Personal' : 'Institution'}</p>
              </div>
            )
          },
          {
            id:'created',
            Header: 'Tanggal Register',
            accessor: a => {
              return Moment(a.registration_date)
              .local()
              .format("DD/MM/YYYY")
            },
            style: {textAlign: 'center'},
          },
          {
            Header: 'Total Granted',
            accessor: 'progress',
            style: {textAlign: 'center'},
            filterable: false
          },
          {
            Header: 'Total Students',
            accessor: 'students_count',
            style: {textAlign: 'center'},
            filterable: false
          },
          {
            Header: <b>Action</b>,
            id: 'delete',
            accessor: str => "delete",
            Cell: (row) => (
              <div>                     
                <button style={{border:'none'}} onClick={() => 
                  this.props.history.push({
                    pathname: `${process.env.PUBLIC_URL}/pages/ambassadordetail/`+row.original.code_ambassador+'/'+row.original.id_category,
                    state: row.original,
                  })}
                    className="btn btn-outline-primary btn-xs" type="button" ><i className="fa fa-list" style={{fontSize: 14, color: '#e4566e' }}></i></button>
                {
                  this.props.dataUser.code_roles == 'RL001' || this.props.dataUser.code_roles == 'RL003' ? 
                  <button onClick={() => this.deleteRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}}><i className="fa fa-trash" style={{ fontSize: 14, color: '#e4566e' }}></i></button>
                  :
                  ''
                }
              </div>
            ),
            style: {
                textAlign: 'center'
            },
            width:110,
            sortable: false,
            filterable: false
          }
        ]

        const csvData = data;

        return (
          <Fragment>
            <div className="tab-content active default" id="tab-1">
              <div className="row m-1">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="text-left">
                            <h5 className="mb-0">Total Registration <Countup end={this.state.dataAmbassador.length} /></h5>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="text-right">
                            <ExportCSV csvData={csvData} fileName={"data-registration-ambasador"} />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="text-right">
                            <a href='https://ambassador.niecindonesia.com/' target='_blank'><button className='btn btn-primary'>Landing Page</button></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-1">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className='row'>
                      <div className="col-md-4">
                        <div className="form-group">
                          <input
                            className="form-control" 
                            type="text"
                            placeholder="search"
                            size="medium"
                            name="searchInput"
                            value={this.state.searchInput || ""}
                            onChange={(e) => this.setGlobalSearch(e)}
                            label="Search"
                          />
                        </div>
                      </div>
                      <div className='col-md-2'>
                        <select value={this.state.category} onChange={this.handleChange} className='form-control' name='category'>
                          <option key={0} value='all'>All Data</option>
                          <option key={1} value='1'>Personal</option>
                          <option key={2} value='2'>Institution</option>
                        </select>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div style={{marginBottom:'10px'}} className="row">
                          <div style={{display:this.state.staticFilter}} className='col-md-6 col-sm-6 mb-2'>
                            <select name='date' value={this.state.date} onChange={this.handleChange} className='form-control'>
                              <option value='all_time'>All Time</option>
                              <option value='this_week'>This Week</option>
                              <option value='last_week'>Last Week</option>
                              <option value='this_month'>This Month</option>
                              <option value='last_month'>Last Month</option>
                              <option value='this_year'>This Year</option>
                              <option value='last_year'>Last Year</option>
                              <optgroup label='Quartal'>
                                  <option value='1'>Quartal I</option>
                                  <option value='2'>Quartal II</option>
                                  <option value='3'>Quartal III</option>
                                  <option value='4'>Quartal IV</option>
                              </optgroup>
                              <option value='dinammic_date'> --Dinammic Date-- </option>
                            </select>
                          </div>
                          {
                            this.state.start_date != '' ?
                              <div style={{display:this.state.dinamicFilter}}  className='row justify-content-center'>
                              <div className='col-md-8 col-sm-6 col-xs-6 mb-2'>
                                <DatePicker dateFormat='MMMM yyyy' selected={Moment(this.state.start_date)._d} showMonthYearPicker showFullMonthYearPicker className='form-control' onChange={this.handlePickerMonth} />
                              </div>
                              <div className='col-md-2 col-sm-4 mb-2'>
                                <button className='btn btn-success btn-md' type='button' onClick={() => this.setState({dinamicFilter:'none', staticFilter:''})}><i  style={{color:'white'}}  className='fa fa-refresh'></i></button>
                              </div>
                            </div>
                            :
                            ''
                          }
                          <div className="col-md-2 col-sm-6"><Button onClick={() => this.getAmbassador()}  color="primary"><i className="fa fa-filter"></i></Button></div>
                        </div>
                      </div>
                    </div>
                    <ReactTable
                      data={this.state.filteredData && this.state.filteredData.length ? this.state.filteredData : data}
                      columns={columns}
                      aut
                      defaultPageSize={10}
                      className="-striped -highlight"
                      showPaginationBottom={true}
                      SubComponent={row => {
                        const data = row.original;
                        return(
                            <div style={{ padding: "20px" }}>
                                <div style={{paddingLeft:"10px", fontStyle:"italic", color:"#A6A6A6"}}>
                                    <div className="row">
                                        <div className="" align="left" style={{ color:"black" }}>Email : </div>
                                        <div className="col-md-9" align="left">{data.phone}</div>
                                    </div>
                                    <div className="row">
                                        <div className="" align="left" style={{ color:"black" }}>HP/Telepon : </div>
                                        <div className="col-md-9" align="left">{data.email}</div>
                                    </div>
                                    <div className="row">
                                        <div className="" align="left" style={{ color:"black" }}>Provinsi : </div>
                                        <div className="col-md-9" align="left">{data.province.province_name}</div>
                                    </div>
                                    <div className="row">
                                        <div className="" align="left" style={{ color:"black" }}>Kabupaten : </div>
                                        <div className="col-md-9" align="left">{data.kabupaten.kabupaten_name}</div>
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {this.renderDeleteDialog()}
            {this.renderUpdateConselor()}
          </Fragment>
        );
    }
}

// export default Students;
const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    dataToken: state.Login.lgnInitialize,
  })
  
  export default connect(reduxState, null)(withRouter(GlobalAmbassadorDataComponent));