import React, { Fragment, Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CKEditors from "react-ckeditor-component";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { resettoken } from '../../actions/login.action';
import { toast } from 'react-toastify';
import DataTableKandidat from '../common/datatable/dataTableKandidat';

class DataTablesKandidat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listKandidat: [],
            listLowongan: [],
            addModal: false,
            editModal: false,
            btnLoading: false,
            nama_lengkap: '',
            id_job_vacancy: '',
            email: '',
            no_hp: '',
            file_cv: '',
            file_cv_name: '',
            text_promosi_diri: '',
            id_hire_employee: ''
        }
    }

    openAddModal() {
        this.setState({addModal: true})
    }

    closeModalAdd(){
        this.setState({addModal: false})
    }

    onChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            text_promosi_diri: newContent
        })
    }

    handleChange(e){
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleFile(e){
        const {name, files} = e.target
        this. setState({file_cv: files[0], file_cv_name: files[0].name})
    }

    componentDidMount() {
        this.getDataKandidat();
        this.getDataLowongan();
    }

    saveKandidat(method){
        this.setState({btnLoading: true})
        const {nama_lengkap, email, no_hp, text_promosi_diri, file_cv, file_cv_name, id_hire_employee, listLowongan} = this.state;
        
        let formData = new FormData();
        let msg, closeModal;
        if (method === 'update') {
            formData.append('id_hire_employee', id_hire_employee);
            msg = 'Updated';
        } else {
            msg = 'Added';
        }

        let job_vacancy = listLowongan.filter((d) => d.id_job_vacancy == this.props.match.params.id_job_vacancy);
        formData.append('nama_lengkap', nama_lengkap)
        formData.append('job_vacancy', job_vacancy[0].title)
        formData.append('id_job_vacancy', this.props.match.params.id_job_vacancy)
        formData.append('email', email)
        formData.append('no_hp', no_hp)
        formData.append('text_promosi_diri', text_promosi_diri)
        formData.append('file_cv', file_cv)
        formData.append('file_cv_name', file_cv_name)

        axios.post(API_URL.superStudent + '/niec-career-candidate/add', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
        .then(res => {
            this.getDataKandidat();
            toast.success(`Data ${msg} Successfully`)
            this.setState({btnLoading: false})
            if (method === 'update') {
                this.closeModalEdit();
            } else {
                this.closeModalAdd();
            }
        })
        .catch(err => console.log(err))
    }

    loadDataKandidat = () => {
        this.getDataKandidat();
    }

    getDataKandidat(){
          axios.post(API_URL.superStudent + '/niec-career-candidate', this.props.match.params, {
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            }
          })
          .then(response => response.data)
          .then(result => this.setState({ listKandidat: result}))
          .catch(error => console.log(error))
    }

    getDataLowongan(){
        axios.get(API_URL.superStudent + '/niec-career')
          .then(response => response.data)
          .then(result => {
            this.setState({ listLowongan: result})
        })
          .catch(error => console.log(error))
    }

    renderAddModal(){
        return(
            <Modal isOpen={this.state.addModal} toggle={() => this.closeModalAdd()} size='lg'>
                <ModalHeader toggle={() => this.closeModalAdd()}>Add Lowongan</ModalHeader>
                <ModalBody>
                    <form>  
                        <div className="form-group">
                            <label htmlFor="nama_lengkap">Nama Lengkap</label>
                            <input type="text" name='nama_lengkap' onChange={(e) => this.handleChange(e)} className="form-control" id="nama_lengkap" placeholder='Nama Lengkap' />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input type="text" name='email' onChange={(e) => this.handleChange(e)} className="form-control" id="email" placeholder='Email' />
                        </div>
                        <div className="form-group">
                            <label htmlFor="no_hp">No HP</label>
                            <input type="text" name='no_hp' onChange={(e) => this.handleChange(e)} className="form-control" id="no_hp" placeholder='No HP' />
                        </div>
                        <div className="form-group">
                            <label htmlFor="file_cv">File CV</label>
                            <input type="file" name='file_cv' onChange={(e) => this.handleFile(e)} className="form-control" id="file_cv" placeholder='File CV' />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnLoading && true} color="primary" onClick={() => this.saveKandidat()}>Add Kandidat</Button>
                    <Button color="secondary" onClick={() => this.closeModalAdd()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderEditModal(){
        const {nama_lengkap, email, no_hp, text_promosi_diri, file_cv, id_job_vacancy} = this.state;
        return(
            <Modal isOpen={this.state.editModal} toggle={() => this.closeModalEdit()} size='lg'>
                <ModalHeader toggle={() => this.closeModalEdit()}>Edit Lowongan</ModalHeader>
                <ModalBody>
                    <form>  
                        <div class="form-group">
                            <label htmlFor="nama_lengkap">Nama Lengkap</label>
                            <input autoFocus type="text" value={nama_lengkap} name='nama_lengkap' onChange={(e) => this.handleChange(e)} class="form-control" id="nama_lengkap" placeholder='Nama Lengkap' />
                        </div>
                        <div class="form-group">
                            <label htmlFor="job_vacancy">Lowongan Kerja</label>
                            <select name='id_job_vacancy' value={id_job_vacancy} className='form-control' id='job_vacancy' onChange={(e) => this.handleChange(e)}>
                                <option value="">Pilih Lowongan Kerja</option>
                                {
                                this.state.listLowongan.map((loker)=>{
                                    return (<option key={loker.id_job_vacancy} value={loker.id_job_vacancy}>{loker.title}</option>)
                                })
                            }
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor='deskripsi'>Teks Promosi Diri</label>
                                <CKEditors
                                    activeclassName="p10"
                                    content={this.state.text_promosi_diri}
                                    events={{
                                            "change": this.onChange.bind(this),
                                    }}
                                    config={{
                                        toolbar:[
                                            ["Bold","Italic",'Strike','RemoveFormat'],
                                            ['NumberedList','BulletedList','Indent','Outdent'],
                                            ['Link','Unlink'],
                                            ['Styles','Format','Table','Blockquote','Maximize'],
                                            ["Cut","Copy","Paste","Pasteasplaintext","Undo","Redo"]
                                        ]
                                    }}
                                />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnLoading && true} color="primary" onClick={() => this.saveKandidat('update')}>Update Lowongan</Button>
                    <Button color="secondary" onClick={() => this.closeModalEdit()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {
        const {listKandidat} = this.state;
        const pgsize = listKandidat.length;
        return (
            <Fragment>
                <div className="container-fluid">
                    <button onClick={()=>this.props.history.goBack()} className='btn btn-danger mb-3'><i className="fa fa-arrow-left"></i> Back</button>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body datatable-react">
                                    <h3 className='text-center'>{listKandidat[0]?.job_vacancy.title}</h3>
                                    <button onClick={() => this.openAddModal()} className='btn btn-success mb-4'>Add Kandidat +</button>
                                    <DataTableKandidat 
                                        myData={listKandidat}
                                        loadDataKandidat={this.loadDataKandidat}
                                        title="Consultant Data" 
                                        pageSize={pgsize}
                                        pagination={true}
                                        class="-striped -highlight"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderAddModal()}
                {this.renderEditModal()}
            </Fragment>
        );
    }
};

const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
    dataId: state.Login.detailUser.id_users,
  })

const reduxDispatch = (dispatch) => ({
    resettoken: (data, headers) => dispatch(resettoken(data, headers))
   })
  
export default connect(reduxState, reduxDispatch)(withRouter(DataTablesKandidat));